require([
    "dojo/query",
    "dojo/domReady!"
], function (query) {
    "use strict";

        var sideNavToggle = document.getElementById('side-nav-button'),
    	sideNav = document.querySelectorAll(".side-nav")[0],
    	sideNavClose = document.querySelectorAll(".side-nav .close")[0];

        if (sideNavToggle){
    	sideNavToggle.onclick = function(){
    		sideNav.classList.add("show");
    	}

    	    	sideNavClose.onclick = function(){
    		sideNav.classList.remove("show");
    	}
    }

        var accordion = document.getElementsByClassName('accordion');
    for(var i = 0; i < accordion.length; i++) {
    	var thisAccordion = accordion[i];

    	        accordion[i].onclick = function(e) {
        	e.preventDefault();
        	e.stopPropagation();
        	var content = document.getElementsByClassName('accordion-content');
        	for(var j = 0; j < content.length; j++) {
    			var thisElement = content[j],
    				shownTitle = document.getElementById('shown-accordion-title'),
    				hiddenTitle = document.getElementById('hidden-accordion-title');

								if(thisElement.classList.contains('open')) {
					thisElement.classList.remove('open');

										if(shownTitle && hiddenTitle) {
						shownTitle.className += (' hide');
						hiddenTitle.classList.remove('hide');
					}
				} else {
					thisElement.className += ' open';

					if(shownTitle && hiddenTitle) {
						shownTitle.classList.remove('hide');
						hiddenTitle.className += (' hide');
					}
				}
        	}
        };
    }

    $('.carousel[data-wrap="false"]').bind('slid.bs.carousel', function(e){  
        var $this = $(this);

                $this.children('.carousel-control').removeClass('disabled');

        if ($this.find('.item:last').hasClass('active')){
            $this.children('.right').addClass('disabled');
        } else if ($this.find('.item:first').hasClass('active')){
            $this.children('.left').addClass('disabled');
        }
    });

    var x, i, j, selElmnt, a, b, c;
    x = document.getElementsByClassName("custom-select");
    for (i = 0; i < x.length; i++) {
    	selElmnt = x[i].getElementsByTagName("select")[0];
    	a = document.createElement("DIV");
    	a.setAttribute("class", "select-selected");
    	a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    	x[i].appendChild(a);
    	b = document.createElement("DIV");
    	b.setAttribute("class", "select-items hide");
    	for (j = 0; j < selElmnt.length; j++) {
    		c = document.createElement("DIV");
    		c.innerHTML = selElmnt.options[j].innerHTML;
    		c.addEventListener(
    						"click",
    						function(e) {
    							var y, i, k, s, h;
    							s = this.parentNode.parentNode
    									.getElementsByTagName("select")[0];
    							h = this.parentNode.previousSibling;
    							for (i = 0; i < s.length; i++) {
    								if (s.options[i].innerHTML == this.innerHTML) {
    									s.selectedIndex = i;
    									h.innerHTML = this.innerHTML;
    									y = this.parentNode
    											.getElementsByClassName("same-as-selected");
    									for (k = 0; k < y.length; k++) {
    										y[k]
    												.removeAttribute("class");
    									}
    									this.setAttribute("class",
    											"same-as-selected");
    									break;
    								}
    							}
    							h.click();
    							s.onchange();
    							if (this.innerHTML == 'Other') {
    								$('#othersReason').removeClass(
    										'hide');
    							} else {
    								$('#othersReason').addClass('hide');
    							}

    						});
    		b.appendChild(c);
    	}
    	x[i].appendChild(b);
    	a.addEventListener("click", function(e) {
    		e.stopPropagation();
    		closeAllSelect(this);
    		this.nextSibling.classList.toggle("hide");
    		this.classList.toggle("select-arrow-active");
    	});
    }
    function closeAllSelect(elmnt) {
    	var x, y, i, arrNo = [];
    	x = document.getElementsByClassName("select-items");
    	y = document.getElementsByClassName("select-selected");
    	for (i = 0; i < y.length; i++) {
    		if (elmnt == y[i]) {
    			arrNo.push(i)
    		} else {
    			y[i].classList.remove("select-arrow-active");
    		}
    	}
    	for (i = 0; i < x.length; i++) {
    		if (arrNo.indexOf(i)) {
    			x[i].classList.add("hide");
    		}
    	}
    }
    document.addEventListener("click", closeAllSelect);


    dojo.addOnLoad(function(){    	
    	console.log("dojo.addOnLoad() has fired.");
    });
});
