petcoPersonalizationJS={
		langId: "-1",

		storeId: "",

		catalogId: "",

		petInfoDropdownId: "",

		vetInfoDropdownId: "",

				vetSourceErrors : false,

				isRxFood : "",

			setCommonParameters : function(langId,storeId,catalogId){
		petcoPersonalizationJS.langId = langId;
		petcoPersonalizationJS.storeId = storeId;
		petcoPersonalizationJS.catalogId = catalogId;		
	},
	isPersonalizedItem : function (){
		var isPersonalized = false;
		var isPersonalizeItem = document.getElementById("personalization");
		var personalizationList = document.getElementById("personalizationAttrNames");
		if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null){
			if(isPersonalizeItem.value == 'true'){
				isPersonalized = true;
			}else{
				if(personalizationList!=null){
					var pName = personalizationList.value;
					 pName= pName+"_1";
					 if(document.getElementById(pName)!=null){
						 isPersonalized = true;
					 }
				}

							}
		}
		return isPersonalized;
	},
	isRxMedicineItem : function (){
		var isRxMedicine = false;
		var isRxMedicineItem = document.getElementById("rxMedicine");
		if(isRxMedicineItem != 'undefined' && isRxMedicineItem!=null && isRxMedicineItem.value == 'true'){
				isRxMedicine = true;
		}
		return isRxMedicine;
	},
	findMaxUserInputQuantity : function(){
		var maxUserInputQuantity =  document.getElementById("maxUserInputQuantity").value;
		return maxUserInputQuantity;
	},
	findPersonalizedAttr : function (quantity){
		var attrIdValueList = "";
		if(this.isPersonalizedItem()){
			var previousQuantity =  document.getElementById("previousQuantity").value;
			var maxUserInputQuantity =  document.getElementById("maxUserInputQuantity").value;
			var personalizationList = document.getElementById("personalizationAttrNames");
			for(var i=0;i<maxUserInputQuantity;i++){	

								var personalizationName = personalizationList.value.split(',');
				for(var j = 0;j<(personalizationName.length);j++){
					var nameAttr = personalizationName[j]+'_'+(i+1);
					var personalizedName = document.getElementById(nameAttr);
					if(personalizedName!=undefined || personalizedName!=null){
						if(personalizedName.value!=""){
							attrIdValueList = attrIdValueList+personalizationName[j]+'_'+[i+1]+'='+personalizedName.value;
							attrIdValueList = attrIdValueList+"$";	
						}
					}
				}				
			}
		}
		return attrIdValueList;
	},
	reAssignPersonalizedAttr : function (attrIdValueList){		
		if(this.isPersonalizedItem()){
			var personalizationNameValueArray = attrIdValueList.split('$');
			for(var i = 0;i<personalizationNameValueArray.length-1;i++){
				var nameValueAttr = personalizationNameValueArray[i];
				var nameValueAttrArray = nameValueAttr.split('=');
				var nameAttr = "";
				var valueAttr = "";
				if(nameValueAttrArray.length == 2){
					nameAttr = nameValueAttrArray[0];
					valueAttr = nameValueAttrArray[1];
				}
				if(document.getElementById(nameAttr)==null || document.getElementById(nameAttr)=='undefined' ){
					var hiddenUnmatcedAttribute = document.getElementById("hiddenUnmatcedAttribute");
					if(hiddenUnmatcedAttribute =='undefined' || hiddenUnmatcedAttribute==null){
					}else{
						hiddenUnmatcedAttribute.innerHTML = hiddenUnmatcedAttribute.innerHTML+"<input id="+nameAttr+" value="+valueAttr+" type='hidden' />";
					}

									}else{
					document.getElementById(nameAttr).value = valueAttr;
				}
			}
		}
	},
	addPersonalizationAttr : function(catEntryIdentifier, quantity,params){
		var isPersonalizeItem = document.getElementById("personalization");
		var personalizationList = document.getElementById("personalizationAttrNames");
		var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");
		var pFlag=false;
		var pName = personalizationList.value;
		 pName= pName+"_1";
		if(document.getElementById(pName)!=null)
				pFlag = true;


							if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null){
			if(isPersonalizeItem.value == 'true' || pFlag){
				for(var i=0;i<quantity;i++){	
					if(isSameValueForAllQty!='undefined' && isSameValueForAllQty!=null && isSameValueForAllQty.checked == true){
						if(i==1){
							break;
						}
						params["catEntryId_" + (i+1)] = catEntryIdentifier;
						params["quantity_" + (i+1)]	= quantity;	
						params["isPersonalized_"+(i+1)] = true;
					}else{
						params["catEntryId_" + (i+1)] = catEntryIdentifier;
						params["quantity_" + (i+1)]	= 1;	
						params["isPersonalized_"+(i+1)] = true;	
					}

										var personalizationName = personalizationList.value.split(',');
					for(var j = 0;j<(personalizationName.length);j++){
						var nameAttr = personalizationName[j]+'_'+(i+1);
						var personalizedName = document.getElementById(nameAttr);
						if(personalizedName!=undefined || personalizedName!=null){
								params["pAttrId"+(j+1)+'_'+(i+1)] = personalizationName[j];
								params["pAttrValue"+(j+1)+'_'+(i+1)] = personalizedName.value;

															}
					}
				}

							}
		}
		return params;
	},
	addRxMedicinePersonalizationAttr : function(catEntryIdentifier, quantity,params){
		var isPersonalizeItem = document.getElementById("personalization");
		var isRxMedicinePersonalizeItem = document.getElementById("rxMedicine");

				if(document.getElementById("rxFood") != 'undefined' && document.getElementById("rxFood") != null ){
			var isRxMedicinePersonalizeItem = document.getElementById("rxFood");
		}

				var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");

				if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null && isPersonalizeItem.value == 'true' && 
				isRxMedicinePersonalizeItem != 'undefined' && isRxMedicinePersonalizeItem!=null && isRxMedicinePersonalizeItem.value == 'true'){
			  var jsonFieldEleContent = $("#fieldGroup").html();
		        var jsonField = JSON.parse(jsonFieldEleContent);
		      var PersonalizationCheckBox = $('[id^="PersonalizationCheckBox_"]:checked');
		      var countIfCheckBoxSelected = 0;
		      if(PersonalizationCheckBox.length > 0){
		    	  countIfCheckBoxSelected = PersonalizationCheckBox[0].id.replace("PersonalizationCheckBox_","")
		      }

		        		      var isPersonalizationCheckBoxSelected = false;  

		      			for(var i=0;i<quantity;i++){					
				var ix = i;
				if(countIfCheckBoxSelected != 0){
					ix = (countIfCheckBoxSelected*1)-1;
					isPersonalizationCheckBoxSelected = true;
				}
				if(isPersonalizationCheckBoxSelected){
					if(i == 1){
						break;
					}
					params["catEntryId_" + ((i*1)+1)] = catEntryIdentifier;
					params["quantity_" + ((i*1)+1)]	= quantity;	
					params["isRxPersonalized_"+((i*1)+1)] = true;
					params["isPersonalized_"+(i+1)] = true;
					params["isTemplatePersonalizedOrder_"+(i+1)] = true;
				}else{
					params["catEntryId_" + ((i*1)+1)] = catEntryIdentifier;
					params["quantity_" + ((i*1)+1)]	= 1;	
					params["isRxPersonalized_"+((i*1)+1)] = true;
					params["isPersonalized_"+(i+1)] = true;
					params["isTemplatePersonalizedOrder_"+(i+1)] = true;
				}


														        for (var j in jsonField) {
				        	var isValidForSubmit = true;
				            var idOfField = $('[id^="' + jsonField[j].Field+"_"+((ix*1)+1) + '"]');
				            var LabelRx = jsonField[j].Label;
				            var uniqueIdentifier = jsonField[j].Field ;
				            var uniqueIdentifier_id = jsonField[j].uniqueNumber ;
				            var OrderOfLoad = jsonField[j].Order;
				            var isRequired = jsonField[j].IsRequired;
				            var isRequiredInCart = jsonField[j].IsRequiredInCart;
				            if (idOfField.length > 0) {
				                var isNumric = false;
				                var isTeliphonic = false;
				                var isOptionOthersExists = false;
				                var isOptionOthersMultipleExists = false;
				                var isDateField = false;
				                var isRadio = false;
				                if (idOfField[0].className.indexOf("NUMERIC") > -1) {
				                    isNumric = true;
				                } else if (idOfField[0].className.indexOf("TELEPHONE") > -1) {
				                    isTeliphonic = true;
				                } else if(idOfField[0].className.indexOf("MULTICHECKBOX") > -1){
				                	isOptionOthersMultipleExists = true;
				                }else if (idOfField[0].className.indexOf("CHECKBOX") > -1) {
				                    isOptionOthersExists = true;
				                } else if (idOfField[0].className.indexOf("DATE") > -1) {
				                    isDateField = true;
				                } else if (idOfField[0].className.indexOf("RADIO") > -1) {
				                    isRadio = true;
				                }
				                    if (!isOptionOthersMultipleExists && !isOptionOthersExists && !isDateField && !isRadio) {
				                    	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                    	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
										params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = idOfField[0].value.trim(); 
										params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
										params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
										params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
										params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                    } else if (isRadio) {
				                            var checkedRadio = $('input[type="radio"][name="' + jsonField[j].Field+"_"+((ix*1)+1) + '"]:checked');
				                            if (checkedRadio.length != 0) {
				                            	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                            	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
												params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = $(checkedRadio).val();  
												params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
												params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired;
												params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
												params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                            }
				                     } else if (isOptionOthersExists) {
				                            var checkedRadioOthers = $('input[type="checkbox"][id="' + jsonField[j].Field+"_"+((ix*1)+1) + '"]:checked');
				                            if (checkedRadioOthers.length == 0) {
				                            	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                            	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
												params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = "No";  
												params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
												params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
												params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
												params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                            } else if(checkedRadioOthers.length > 0 && $(checkedRadioOthers).val() == "Yes" &&  $('[id^="'+jsonField[j].Field+'_'+((ix*1)+1)+'_optionContainer_"]').length > 0) {
				                            	var filedsList = "";
				                            	$.each($('[id^="'+jsonField[j].Field+'_'+((ix*1)+1)+'_optionContainer_"]'),function(x,obj){
				                            		if(x == 0){
				                            			if(obj.value.trim()!=""){
				                            				filedsList = obj.value.trim();
				                            			}
				                            		}else{
				                            			if(obj.value.trim()!=""){
				                            			filedsList=filedsList+","+obj.value.trim();
				                            		}
				                            		}
				                            	})
				                            	var othercehckboxvalue = $("#"+jsonField[j].Field+"_"+((ix*1)+1)+"_Others").val();
				                            	if(othercehckboxvalue.trim() != ""){
				                            	if(filedsList==""){
				                            		filedsList=othercehckboxvalue;				                            		
				                            	}else{
				                            		filedsList=filedsList+","+othercehckboxvalue;
				                            	}
				                            	}
				                            	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                            	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
				                            	if(filedsList !=""){
												params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = filedsList;
				                            	}else{
				                            		params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = "No";
				                            	}

																								params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
												params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
												params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
												params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                            }
				                        } else if(isOptionOthersMultipleExists){


				                        					                            var checkedRadioOthers = $('input[type="checkbox"][id="' + jsonField[j].Field+"_"+((ix*1)+1) + '"]:checked');
				                            if (checkedRadioOthers.length == 0) {
				                            	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                            	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
												params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = "No";  
												params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
												params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
												params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
												params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                            } else if(checkedRadioOthers.length > 0 && $(checkedRadioOthers).val() == "Yes" &&  $('[id^="'+jsonField[j].Field+'_'+((ix*1)+1)+'-_options_-"]:checked').length > 0) {
				                            	var filedsList = "";
				                            	$.each($('[id^="'+jsonField[j].Field+'_'+((ix*1)+1)+'-_options_-"]:checked'),function(x,obj){
				                            		if(x == 0){
				                            			filedsList = obj.value.trim();
				                            		}else{
				                            			filedsList=filedsList+","+obj.value.trim();
				                            		}
				                            	})
				                            	var othercehckboxvalue = $("#"+jsonField[j].Field+"_"+((ix*1)+1)+"_Others").val();
				                            	if(filedsList==""){
				                            		filedsList=othercehckboxvalue;				                            		
				                            	}else{
				                            		filedsList=filedsList+","+othercehckboxvalue;
				                            	}
				                            	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                            	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
												params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = filedsList;
												params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
												params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
												params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
												params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                            }else if($('[id^="'+jsonField[j].Field+'_'+((ix*1)+1)+'-_options_-"]:checked').length == 0){
				                            	var othercehckboxvalue = $("#"+jsonField[j].Field+"_"+((ix*1)+1)+"_Others").val();
				                             	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                            	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
				                            	if(othercehckboxvalue.trim() != ""){
				                            		params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = othercehckboxvalue;
				                            	}else{
				                            		params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = "No";
				                            	}
												params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
												params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
												params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
												params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                            }


				                        					                        					                        }else if (isDateField) {
				                        	var dateVal="";
				                            if ($("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_MON").length > 0 && $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_MON").val() != "" &&
				                                $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_Date").length > 0 && $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_Date").val() != "" &&
				                                $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_Year").length > 0 && $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_Year").val() != ""
				                            ) {
				                                var date = $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_Date").val();
				                                var month = $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_MON").val();
				                                var year = $("#" + jsonField[j].Field+"_"+((ix*1)+1) + "_Year").val();
				                                var datetoValidate = new Date();
				                                datetoValidate.setFullYear(year, (month - 1), date);
				                                if (datetoValidate.getDate() == date && datetoValidate.getMonth() == (month - 1)) {
				                                	dateVal= month+"-"+date+"-"+year;
				                                	params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = LabelRx;
				                                	params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier_id;
													params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = dateVal;
													params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = OrderOfLoad; 
													params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = isRequired; 
													params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = uniqueIdentifier;
													params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = isRequiredInCart;
				                                }

				                            } 
				                        }


				                    				                    				                    var newKey = params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)]+"||^||";
				                    newKey=newKey+params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)]+"||^||";
				                    newKey=newKey+params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)]+"||^||";
				                    newKey=newKey+params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)]+"||^||";
				                    newKey=newKey+params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)]+"||^||";
				                    newKey=newKey+params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)]+"||^||";
				                    newKey=newKey+params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)];
				                    if(typeof(params["rxDetailsUniqueKey_"+((i*1)+1)]) !="undefined"){
				                    	params["rxDetailsUniqueKey_"+((i*1)+1)] = params["rxDetailsUniqueKey_"+((i*1)+1)] +"||$||"+newKey;
				                    }else{
				                    	params["rxDetailsUniqueKey_"+((i*1)+1)] = newKey;
				                    }


				                    				                    				            }


				            				            				        }
				}
			var correctRxQuanity = false;
			var rxDetailsUniqueKeyMap = {};
			for(var ih in params){
				if(ih.indexOf("rxDetailsUniqueKey_") > -1){
					if(typeof(rxDetailsUniqueKeyMap[params[ih]]) !="undefined"){
						rxDetailsUniqueKeyMap[params[ih]] = rxDetailsUniqueKeyMap[params[ih]]+1;
						correctRxQuanity = true;
					}else{
						rxDetailsUniqueKeyMap[params[ih]]=1;
					}
					delete params[ih];
				}

							}
			if(correctRxQuanity){
				var count=0;
				var newparams = {};
				for(var rxDetailsUniqueKey in rxDetailsUniqueKeyMap){
					newparams["catEntryId_" + ((count*1)+1)] = catEntryIdentifier;
					newparams["quantity_" + ((count*1)+1)]	= rxDetailsUniqueKeyMap[rxDetailsUniqueKey];	
					newparams["isRxPersonalized_"+((count*1)+1)] = true;
					newparams["isPersonalized_"+(count+1)] = true;
					newparams["isTemplatePersonalizedOrder_"+(count+1)] = true;
					var rxattrArray = rxDetailsUniqueKey.split("||$||");
					for ( var tx = 0 ; tx < rxattrArray.length ; tx++){
						var rxattrValArray = rxattrArray[tx].split("||^||");
						newparams["pAttrName"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[0];
						newparams["pAttrId"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[1];
						newparams["pAttrValue"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[2];
						newparams["pAttrOrder"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[3]; 
						newparams["pAttrReq"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[4]; 
						newparams["pAttrFieldId"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[5];
						newparams["pAttrReqInCart"+((tx*1)+1)+'_'+((count*1)+1)] = rxattrValArray[6];
					}
					count++;
				}
				if(typeof(params["storeId"]) != "undefiend"){
					newparams.storeId		= params.storeId;
				}
				if(typeof(params["catalogId"]) != "undefiend"){
					newparams.catalogId		= params.catalogId;
				}
				if(typeof(params["langId"]) != "undefiend"){
					newparams.langId		= params.langId;
				}
				if(typeof(params["orderId"]) != "undefiend"){
					newparams.orderId		= params.orderId;
				}
				if(typeof(params["shipModeId"]) != "undefiend"){
					newparams.shipModeId		= params.shipModeId;
				}
				if(typeof(params["inventoryValidation"]) != "undefiend"){
					newparams.inventoryValidation		= params.inventoryValidation;
				}
				if(typeof(params["fulfillmentCenterId"]) != "undefiend"){
					newparams.fulfillmentCenterId		= params.fulfillmentCenterId;
				}
				if(typeof(params["fromBopusPage"]) != "undefiend"){
					newparams.fromBopusPage		= params.fromBopusPage;
				}
				if(typeof(params["contractId"]) != "undefiend"){
					newparams.contractId		= params.contractId;
				}
				if(typeof(params["rdFrequency"]) != "undefiend"){
					newparams.rdFrequency		= params.rdFrequency;
				}
					if(typeof(params["rdInitialFirstOrder"]) != "undefiend"){
					newparams.rdInitialFirstOrder		= params.rdInitialFirstOrder;
				}
				params = newparams
			}

		}
		return params;
	},
	findInvalidCharSetForUserInput : function(quantity){
		var invalidCharSetValue="";
		var isPersonalizeItem = document.getElementById("personalization");
		var personalizationList = document.getElementById("personalizationAttrNames");
		var invalidCharFound =  false;
		if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null && personalizationList != 'undefined' && personalizationList!=null){
			if(isPersonalizeItem.value == 'true'){

											for(var i=0;i<quantity ;i++){	
					var personalizationName = personalizationList.value.split(',');
					for(var j = 0;j<(personalizationName.length);j++){
						var nameAttr = personalizationName[j]+'_'+(i+1);
						var personalizedName = document.getElementById(nameAttr);
						if(personalizedName!=undefined || personalizedName!=null){
							if(personalizedName.value != ""){
								var invalidCharsSet = document.getElementById("invalidChars_"+personalizationName[j]+"_"+(i+1));
								if(invalidCharsSet){
									if(!this.checkForInvalidChars(personalizedName.value,invalidCharsSet.value)){
										invalidCharSetValue = invalidCharsSet.value;
										if(!invalidCharFound){
										invalidCharFound = true;
										}
										dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
										dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ MessageHelper.messages["PERSONALIZATION_ERR_INVALID_CHARSET"] + '</span>';
										for(var k = 1;k<=(quantity);k++){
											if(k==(i+1)){
												document.getElementById("Personalization_"+k).style.display='block'; 
											}else{
												document.getElementById("Personalization_"+(k)).style.display='none'; 
											}
										}
									}
								}

															}else{
								dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
							}
						}
					}
				}

							}
		}
		return invalidCharSetValue;
	},
	validatePersonalizedRequiredField : function(quantity,ltlorRxflag){
		var isPersonalizeItem = document.getElementById("personalization");
		var personalizationList = document.getElementById("personalizationAttrNames");
		var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");
		var reqdFieldValidated =  true;
		if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null){
			if(isPersonalizeItem.value == 'true'){

											for(var i=0;i<quantity ;i++){
					if(isSameValueForAllQty!='undefined' && isSameValueForAllQty!=null && isSameValueForAllQty.checked == true){
						if(i==1){
							break;
						}
					}
					var personalizationName = personalizationList.value.split(',');
						for(var j = 0;j<(personalizationName.length);j++){
							var nameAttr = personalizationName[j]+'_'+(i+1);
							var personalizedName = document.getElementById(nameAttr);
							if(personalizedName!=undefined || personalizedName!=null){
									var minLengthDiv = document.getElementById("minLength_"+nameAttr);
									var reqdFlagDiv = document.getElementById("reqdFlag_"+nameAttr);
									if(minLengthDiv != 'undefined' && minLengthDiv!=null){
										if(reqdFlagDiv != 'undefined' && reqdFlagDiv!=null){

																						var minLength = minLengthDiv.value;
											var reqdFlag = reqdFlagDiv.value;	
											if( reqdFlag == "Y" && personalizedName.value.trim().length < (minLength)){
												if(!ltlorRxflag){
													reqdFieldValidated = false;
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ MessageHelper.messages["PERSONALIZATION_ERR_REQUIRED_FIELD"] + minLengthDiv.value +'</span>';
												}else{
													reqdFieldValidated = false;
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ 'The field is required' +'</span>';
												}	
												for(var k = 1;k<=(quantity);k++){
													if(k==(i+1)){
														document.getElementById("Personalization_"+k).style.display='block';
													}else{
														document.getElementById("Personalization_"+k).style.display='none'; 
													}
												}
											}else{
												dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
											}
										}
									}
							}

													}
					if(ltlorRxflag){
						if(!this.isAllPersonalizationFieldEmpty(personalizationName,i)){
							for(var j = 0;j<(personalizationName.length);j++){
								var nameAttr = personalizationName[j]+'_'+(i+1);
								var personalizedName = document.getElementById(nameAttr);
								if(personalizedName!=undefined || personalizedName!=null){
									var minLengthDiv = document.getElementById("minLength_"+nameAttr);
									var reqdFlagDiv = document.getElementById("reqdFlag_"+nameAttr);
									if(minLengthDiv != 'undefined' && minLengthDiv!=null){
										if(reqdFlagDiv != 'undefined' && reqdFlagDiv!=null){

																						var minLength = minLengthDiv.value;
											var reqdFlag = reqdFlagDiv.value;		
											if( reqdFlag == "Y" && personalizedName.value.trim().length < (minLength)){
												reqdFieldValidated = false;
												dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
												dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ 'Minimum Length should be '+ minLengthDiv.value +'</span>';
											}

																						}
									}
								}
							}
						}

									}
				}
			}
		}
		if(reqdFieldValidated) {
			this.closeAll(quantity);
		}
		return reqdFieldValidated;
	},

		validatePersonalizedTextInputType : function(quantity,ltlorRxflag){
		var isPersonalizeItem = document.getElementById("personalization");
		var personalizationList = document.getElementById("personalizationAttrNames");
		var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");
		var inputTypeTextValidated =  true;
		var vetNamePersonizationName = "";
		if(document.getElementById("vetNamePersonizationName") != 'undefined' && document.getElementById("vetNamePersonizationName") != null ){
			vetNamePersonizationName = document.getElementById("vetNamePersonizationName").value;
		}
		if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null){
			if(isPersonalizeItem.value == 'true'){

											for(var i=0;i<quantity ;i++){
					if(isSameValueForAllQty!='undefined' && isSameValueForAllQty!=null && isSameValueForAllQty.checked == true){
						if(i==1){
							break;
						}
					}
					var personalizationName = personalizationList.value.split(',');
					if(this.isAllPersonalizationFieldEmpty(personalizationName,i)!=true){	
                      var counter=0;					
						for(var j = 0;j<(personalizationName.length);j++){
							var nameAttr = personalizationName[j]+'_'+(i+1);
							var personalizedName = document.getElementById(nameAttr);
							if(personalizedName!=undefined || personalizedName!=null){
								var inputTypeDiv = document.getElementById("inputType_"+nameAttr);
								if(inputTypeDiv != 'undefined' && inputTypeDiv!=null){
									var inputType = inputTypeDiv.value;
									if( personalizedName.value.trim().length > 0){
										if(!ltlorRxflag){
											if(inputType == 'Number'){
												if(!MessageHelper.IsNumeric(personalizedName.value.trim(),false)){
													if(inputTypeTextValidated){
														inputTypeTextValidated = false;
													}
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ MessageHelper.messages["PERSONALIZATION_ERR_NUMERIC"] +'</span>';
													for(var k = 1;k<=(quantity);k++){
														if(k==(i+1)){
															document.getElementById("Personalization_"+k).style.display='block';
														}else{
															document.getElementById("Personalization_"+k).style.display='none'; 
														}
													}
												}else{
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
												}
											}
											else if(inputType == 'Text'){
												var alphaOnly = new RegExp(/^[a-zA-Z]+$/i);
												var alphaWithSpace = new RegExp(/^[a-zA-Z\s]+$/i);
												if(!alphaWithSpace.test(personalizedName.value.trim())){
													if(inputTypeTextValidated){
														inputTypeTextValidated = false;
													}
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+  MessageHelper.messages["PERSONALIZATION_ERR_ALPHA_TEXT"] +'</span>';
													for(var k = 1;k<=(quantity);k++){
														if(k==(i+1)){
															document.getElementById("Personalization_"+k).style.display='block';
														}else{
															document.getElementById("Personalization_"+k).style.display='none'; 
														}
													}
												}
												else{
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
												}

																						}
										}else{
											if(inputType == 'Number'){
												if(!MessageHelper.IsNumeric(personalizedName.value.trim(),false)){
													if(inputTypeTextValidated){
														inputTypeTextValidated = false;
													}
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ MessageHelper.messages["PERSONALIZATION_ERR_NUMERIC"] +'</span>';
													for(var k = 1;k<=(quantity);k++){
														if(k==(i+1)){
															document.getElementById("Personalization_"+k).style.display='block';
														}else{
															document.getElementById("Personalization_"+k).style.display='none'; 
														}
													}
												}else{
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
												}
											}else if(inputType == 'String'){ 
												var alphaNumericOnly = new RegExp(/^[a-zA-Z0-9]+$/i);
												var alphaNumericWithSpace = new RegExp(/^[a-zA-Z0-9\s]+$/i);

																								var personalizationMessage = document.getElementById("personalizationMessage") != 'undefined' && document.getElementById("personalizationMessage") != null ? document.getElementById("personalizationMessage").value :'';
												var personalizedPetInfo = document.getElementById("personalizedPetInfo") != 'undefined' && document.getElementById("personalizedPetInfo") != null ? document.getElementById("personalizedPetInfo").value :'';
												var personalizedPetName = document.getElementById("personalizedPetName") != 'undefined' && document.getElementById("personalizedPetName") != null ? document.getElementById("personalizedPetName").value :'';

																								if(personalizationMessage != '' && personalizationMessage == personalizationName[j] ){
													var alphaNumericWithSpaceSingleperiod = new RegExp(/^[a-zA-Z0-9~!@#%&*()_+\-=\[\]`{}':"\\|,.<>\/?\s]+$/i);
												}else if((personalizedPetInfo != '' && personalizedPetInfo == personalizationName[j] ) || (personalizedPetName != '' && personalizedPetName == personalizationName[j] ) ){
													var alphaNumericWithSpaceSingleperiod = new RegExp(/^[a-zA-Z0-9&-\s]+$/i);
												}else{
													var alphaNumericWithSpaceSingleperiod = new RegExp(/^(?!\.)(?!.*?\.\.)[a-zA-Z0-9\s.]+$/i);
												}

												if(vetNamePersonizationName != '' && vetNamePersonizationName == personalizationName[j]){
													checkVetName = true;
												}

																								var alphaNumericWithSpaceSingleperiod = new RegExp(/^[^~*=":^$\\]+$/i);
												if(counter == 0)
												{
												 if(!alphaNumericWithSpaceSingleperiod.test(personalizedName.value.trim())){
													   if(inputTypeTextValidated){
															  inputTypeTextValidated = false;
													   }
													   dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													   dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ MessageHelper.messages["PERSONALIZATION_ERR_INVALID_CHARACTERS"] +'</span>';
													   for(var k = 1;k<=(quantity);k++){
															  if(k==(i+1)){
															  document.getElementById("Personalization_"+k).style.display='';
															  }else{
															  document.getElementById("Personalization_"+k).style.display='none'; 
															  }
															 }

													   														 }

													   												 } 
												else if(!alphaNumericWithSpaceSingleperiod.test(personalizedName.value.trim())){


																																							if(inputTypeTextValidated){
														inputTypeTextValidated = false;
													}
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","block");
													dojo.byId('persFieldError_'+personalizationName[j]+'_'+(i+1)).innerHTML = '<span class="help-block">'+ MessageHelper.messages["PERSONALIZATION_ERR_INVALID_CHARACTERS"] +'</span>';
													for(var k = 1;k<=(quantity);k++){
														if(k==(i+1)){
															document.getElementById("Personalization_"+k).style.display='block';
														}else{
															document.getElementById("Personalization_"+k).style.display='none'; 
														}
													}
												}

																																				else{
													dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
												}
												counter++;	
											}
										}
									}else{
										dojo.style("persFieldError_"+personalizationName[j]+"_"+(i+1),"display","none");
									}
								}
							}
						}
					}
				}
			}
		}
		return inputTypeTextValidated;
	},
	validateSubmitButtonEnabled : function(index){
		var isPersonalizeItem = document.getElementById("personalization");
		var personalizationList = document.getElementById("personalizationAttrNames");
		var reqdFieldValidated =  true;
		if(isPersonalizeItem != 'undefined' && isPersonalizeItem!=null){
			if(isPersonalizeItem.value == 'true'){
				var personalizationName = personalizationList.value.split(',');
				for(var j = 0;j<(personalizationName.length);j++){
					var nameAttr = personalizationName[j]+'_'+(index);
					var personalizedName = document.getElementById(nameAttr);
					if(personalizedName!=undefined || personalizedName!=null){
						var minLengthDiv = document.getElementById("minLength_"+nameAttr);
						var reqdFlagDiv = document.getElementById("reqdFlag_"+nameAttr);
						if(minLengthDiv != 'undefined' && minLengthDiv!=null){
							if(reqdFlagDiv != 'undefined' && reqdFlagDiv!=null){
								var minLength = minLengthDiv.value;
								var reqdFlag = reqdFlagDiv.value;		
								if( reqdFlag == "Y" && personalizedName.value.trim().length < (minLength)){
									reqdFieldValidated = false;
								}
							}
						}
					}
				}	
			}
		}
		if(reqdFieldValidated) {
			document.getElementById("personalizationSubmit_"+index).classList.remove("disabled");
		} else {
			document.getElementById("personalizationSubmit_"+index).classList.add("disabled");
		}
	},
	checkPersonalizationUserInput : function(catalogEntryID,quantity){
		if(!MessageHelper.IsNumeric(quantity,false)){
			return false;
		}
		var invalidChars = this.findInvalidCharSetForUserInput(quantity);
		if(invalidChars!=""){
			return false;
		}
		return true;
	},

		checkForInvalidChars : function(text,invalidChars){
		var IsValid=false;
		var Char;

	 		for (i = 0; i < text.length; i++) 
		{ 
			Char = text.charAt(i); 

						if (invalidChars.indexOf(Char) == -1) 
			{

								IsValid = true;
			}
			else
			{
				IsValid = false;
				return IsValid;
			}
		}

				return IsValid;   
	}, 

	findPersonalizedAttrForShoppingCart : function (quantity,prefix){
		var attrIdValueList = "";		
		var personalizationList = document.getElementById(prefix+"_personalizationAttrNames");
		if(personalizationList!=null && personalizationList!=undefined){
			for(var i=0;i<quantity;i++){	
				var personalizationName = personalizationList.value.split('|');
				for(var j = 0;j<(personalizationName.length);j++){
					var nameAttr = prefix+'_'+personalizationName[j]+'_'+(i+1);
					var personalizedName = document.getElementById(nameAttr);
					if(personalizedName!=undefined || personalizedName!=null){
						if(personalizedName.value!=""){
							attrIdValueList = attrIdValueList+personalizationName[j]+'_'+[i+1]+'='+personalizedName.innerText.trim();
							attrIdValueList = attrIdValueList+"$";	
						}
					}
				}				
			}
		}
		return attrIdValueList;
	},
	isAllPersonalizationFieldEmpty : function(personalizationName,index){
		var allFieldEmpty = true;
		var fieldValue =[];
		for(var i=0;i<personalizationName.length;i++ ){
			var nameAttr = personalizationName[i]+'_'+(index+1);
			var personalizedName = document.getElementById(nameAttr);
			if(personalizedName!=undefined || personalizedName!=null){
				if(personalizedName.value.trim() != ""){
					allFieldEmpty = false;
					break;
				}
			}
		}
		return allFieldEmpty;
	},
	chooseNotToPersonalize : function(isChecked,index){
		if(isChecked)
		{
			document.getElementById("personalizationSubmit_"+index).classList.remove("disabled");
			var personalizationList = document.getElementById("personalizationAttrNames");
			var personalizationName = personalizationList.value.split(',');			
			for(var i=0;i<personalizationName.length;i++ ){
				var nameAttr = personalizationName[i]+'_'+(index);
				var personalizedName = document.getElementById(nameAttr);
				if(personalizedName!=undefined || personalizedName!=null){
					if($("#"+nameAttr).is('select')){
						$("#"+nameAttr).val('None');
					}else{
						$("#"+nameAttr).val("");	
					}
				}
			}
		}else {
			validateSubmitButtonEnabled(index);
		}
	},
	updateSameMessageInAllPages : function(quantity, checkbox){
		var quantSelected = checkbox.id.replace("PersonalizationCheckBox_","");
		var personalizationList = document.getElementById("personalizationAttrNames");
		var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");
		if (checkbox) {
			isSameValueForAllQty.checked = checkbox.checked;
			isSameValueForAllQty = checkbox;

					}
		var personalizationName = personalizationList.value.split(',');

		if(isSameValueForAllQty!='undefined' && isSameValueForAllQty!=null && isSameValueForAllQty.checked == true){
			for(var i=1;i<=quantity;i++){
				var thisCheckbox = document.getElementById("PersonalizationCheckBox_" + i);
				if (thisCheckbox) thisCheckbox.checked = true;
				for(var j=0;j<personalizationName.length;j++ ){
					var nameAttr = personalizationName[j]+'_'+i;
					var personalizedName = document.getElementById(nameAttr);
					if(personalizedName!=undefined || personalizedName!=null){
						personalizedName.value = document.getElementById(personalizationName[j]+'_'+quantSelected).value;
					}
				}
				dojo.forEach(dojo.query("div#Personalization_"+i+" div#personalizePaging"),function(obj){
					dojo.addClass(obj,"hide");
				})
			}
			this.gotoPage(quantSelected, quantity);


								}else if(isSameValueForAllQty.checked == false){
			for(var i=1;i<=quantity;i++){
				var thisCheckbox = document.getElementById("PersonalizationCheckBox_" + i);
				if (thisCheckbox) thisCheckbox.checked = false;
				for(var j=0;j<personalizationName.length;j++ ){
					var nameAttr = personalizationName[j]+'_'+(i);
					var personalizedName = document.getElementById(nameAttr);
					if(personalizedName!=undefined || personalizedName!=null){
					}
				}
				dojo.forEach(dojo.query("div#Personalization_"+i+" div#personalizePaging"),function(obj){
					dojo.removeClass(obj,"hide");
				})
			}

					}

			},
	updatePersonalizationAttr : function(catEntryIdentifier, quantity,orderId,orderItemId,rxItem){
		var params = [];
		params.storeId		= this.storeId;
		params.catalogId	= this.catalogId;
		params.langId		= this.langId;
		params.orderId		= orderId;
		params.orderItemId		= orderItemId;		
		if(typeof(rxItem) != "undefined" && rxItem){
			params = this.addRxMedicinePersonalizationAttr(catEntryIdentifier, quantity,params);
		}else{
		params = this.addPersonalizationAttr(catEntryIdentifier, quantity,params);
		}

		wc.service.declare({
			id: "AjaxOrderItemPersonalizationUpdate",
			actionId: "AjaxOrderItemPersonalizationUpdate",
			url: "PetcoOrderItemPersonalizationUpdate",
			formId: ""

				,successHandler: function(serviceResponse) {
				window.location.reload();
			}

				,failureHandler: function(serviceResponse) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			}
		});
		 if(dojo.byId('modal-Personalization') != null){
			 petcoCommonJS.hideModal('modal-Personalization');
		 }
		  if(dojo.byId('personilaztionDisplay') != null){
			 $('#personilaztionDisplay').removeClass('active');
		 }

		  		  if(dojo.byId('modal-rxmedicine') != null){
				 petcoCommonJS.hideModal('modal-rxmedicine');
			 }
			  if(dojo.byId('rxPersonilaztionDisplay') != null){
				 $('#rxPersonilaztionDisplay').removeClass('active');
			 }
		wc.service.invoke("AjaxOrderItemPersonalizationUpdate", params);

			},
	updatePetAttr : function(catEntryIdentifier, orderId, orderItemId, personalizationId, petId) {
		var params = [];
		params.storeId			= this.storeId;
		params.catalogId		= this.catalogId;
		params.langId			= this.langId;
		params.orderId			= orderId;
		params.orderItemId		= orderItemId;
		params.isMembership 	= true;
		params.isPersonalized	= true;
		params.catEntryId_1		= catEntryIdentifier;
		params.quantity_1		= 1; 
		params.pAttrId1_1		= personalizationId;
		params.pAttrValue1_1	= petId;

		if(dojo.byId('modal-Personalization') != null){
			petcoCommonJS.hideModal('modal-Personalization');
		}
		if(dojo.byId('personilaztionDisplay') != null){
			$('#personilaztionDisplay').removeClass('active');
		}
		if(dojo.byId('modal-rxmedicine') != null){
			petcoCommonJS.hideModal('modal-rxmedicine');
		}
		if(dojo.byId('rxPersonilaztionDisplay') != null){
			$('#rxPersonilaztionDisplay').removeClass('active');
		}
		cursor_wait();
		wc.service.invoke("AjaxOrderItemPersonalizationUpdate", params);

	},
	prevNextPage : function(counter,quantity,action){
		  var totalPage = parseInt(quantity);
          var currentPage = parseInt(counter);
          var nextPage = currentPage+1;
          var previousPage =  currentPage-1;


          		if(action == 'PREVIOUS'){
			for (var n=0; n<document.querySelectorAll("#personalizePaging .prev").length; n++){
         		document.querySelectorAll("#personalizePaging .prev")[n].classList.remove("disabled");                		
         	}
			for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
         		document.querySelectorAll("#personalizePaging .next")[n].classList.remove("disabled");                		
         	}
			 if(previousPage == 0){
             	for (var n=0; n<document.querySelectorAll("#personalizePaging .prev").length; n++){
             		document.querySelectorAll("#personalizePaging .prev")[n].classList.add("disabled");                		
             	}
	            return ;
			 }else{

								 document.getElementById("rxmedicineContent_"+currentPage).style.display='none';
	                document.getElementById("rxmedicineContent_"+previousPage).style.display='block';
	                if(previousPage == 1){
	                	for (var n=0; n<document.querySelectorAll("#personalizePaging .prev").length; n++){
	                 		document.querySelectorAll("#personalizePaging .prev")[n].classList.add("disabled");                		
	                 	}
	                }

	                	                if($("#isVetWidgetEnabled").val() != 'true'){
	                	petcoPersonalizationJS.loadVetClicnicInfos(1,previousPage,true);	
	                }

	                			 }



			 			 			 		}else if( action == 'NEXT' ){
			for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
         		document.querySelectorAll("#personalizePaging .next")[n].classList.remove("disabled");                		
         	}
			for (var n=0; n<document.querySelectorAll("#personalizePaging .prev").length; n++){
         		document.querySelectorAll("#personalizePaging .prev")[n].classList.remove("disabled");                		
         	}
			 if(totalPage == currentPage){
             	for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
             		document.querySelectorAll("#personalizePaging .next")[n].classList.add("disabled");                		
             	}
                 return ;
			 }else{
				 document.getElementById("rxmedicineContent_"+currentPage).style.display='none';
	             document.getElementById("rxmedicineContent_"+nextPage).style.display='block';
	             if(currentPage == totalPage-1){
	                	for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
	                 		document.querySelectorAll("#personalizePaging .next")[n].classList.add("disabled");                		
	                 	}
	                }

	             	             if($("#isVetWidgetEnabled").val() != 'true'){
	                 petcoPersonalizationJS.loadVetClicnicInfos(1,nextPage,true);
	             } 
	         }

					}

		 	},
	nextPage : function(counter,quantity){
                var totalPage = parseInt(quantity);
                var currentPage = parseInt(counter);
                var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");

                                var vInputQuery = 'div#Personalization_'+counter+' input[type="text"]';
                dojo.forEach(dojo.query(vInputQuery),function(obj){
                	var idv = obj.id.split("_"+counter).join("")+"_"+(counter*1+1);
                	if(dojo.byId(idv) != null && obj.value.trim() != "" && dojo.byId(idv).value.trim() == ""){
                		dojo.byId(idv).value = obj.value;
                	}
                });
                var vSelectQuery = 'div#Personalization_'+counter+' select';
                dojo.forEach(dojo.query(vSelectQuery),function(obj){
                	var idv = obj.id.split("_"+counter).join("")+"_"+(counter*1+1);
                	if(dojo.byId(idv) != null&& obj.value.trim() != ""&& dojo.byId(idv).value.trim() == ""){
                		dojo.byId(idv).value = obj.value;
                	}
                });

                                petcoPersonalizationJS.validateSubmitButtonEnabled((counter*1+1));
                if(totalPage == currentPage || isSameValueForAllQty.checked == true){
	                	for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
	                		document.querySelectorAll("#personalizePaging .next")[n].classList.add("disabled");                		
	                	}
                        return ;
                }
                for (var p=0; p<document.querySelectorAll("#personalizePaging .prev").length; p++){              		
            		document.querySelectorAll("#personalizePaging .prev")[p].classList.remove("disabled");
            	}
                var nextPage = currentPage+1;

                              document.getElementById("Personalization_"+counter).style.display='none';
                document.getElementById("Personalization_"+nextPage).style.display='block'; 
        },
        prevPage : function(counter){
                var currentPage = parseInt(counter);
                var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");


                                var vInputQuery = 'div#Personalization_'+counter+' input[type="text"]';
                dojo.forEach(dojo.query(vInputQuery),function(obj){
                	if((counter-1) > 0){
                	var idv = obj.id.split("_"+counter).join("")+"_"+(counter*1-1);

                	                	if(dojo.byId(idv) != null && obj.value.trim() != "" && dojo.byId(idv).value.trim() == ""){
                		dojo.byId(idv).value = obj.value;
                	}
                	}
                });
                var vSelectQuery = 'div#Personalization_'+counter+' select';
                dojo.forEach(dojo.query(vSelectQuery),function(obj){
                	if((counter-1) > 0){
                	var idv = obj.id.split("_"+counter).join("")+"_"+(counter*1-1);
                	if(dojo.byId(idv) != null && obj.value.trim() != "" && dojo.byId(idv).value.trim() == ""){
                		dojo.byId(idv).value = obj.value;
                	}
                	}
                });
                petcoPersonalizationJS.validateSubmitButtonEnabled((counter*1-1));

                                if(1 == currentPage || isSameValueForAllQty.checked == true){
	                	for (var p=0; p<document.querySelectorAll("#personalizePaging .prev").length; p++){              		
	                		document.querySelectorAll("#personalizePaging .prev")[p].classList.add("disabled");
	                	}
                        return ;
                }
                for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
            		document.querySelectorAll("#personalizePaging .next")[n].classList.remove("disabled");                		
            	}
                var prevPage = currentPage-1;
                document.getElementById("Personalization_"+currentPage).style.display='none';
                document.getElementById("Personalization_"+prevPage).style.display='block';
        },
        gotoPage : function(pageNumber, quantity) {
            for (var pageIndex = 1; pageIndex <= quantity; pageIndex++) {
                var pageElement = document.getElementById("Personalization_" + pageIndex);
                if (pageElement) {
                    pageElement.style.display = (pageIndex == pageNumber) ? "block" : "none";
                }
            }
        },
    close : function(counter) {
        document.getElementById("Personalization_"+counter).style.display='none';
        $('#personilaztionDisplay').removeClass('active');
    },
    closeAll : function(quantity) {
        for(var i=0;i<quantity ;i++){
            document.getElementById("Personalization_"+(i+1)).style.display='none';
        }
    },
    checkIfpersonalizationValid:function(quantity){
    	var customParams = true;
    	var isValid = true;
    	if(petcoPersonalizationJS.isPersonalizedItem()){ 
    		var invalidChars = petcoPersonalizationJS.findInvalidCharSetForUserInput(quantity,customParams);
    		if(invalidChars!=""){
    			isValid = false;
    		}	if(!petcoPersonalizationJS.validatePersonalizedRequiredField(quantity,customParams)){
    			isValid = false;
    		}	if(!petcoPersonalizationJS.validatePersonalizedTextInputType(quantity,customParams)){
    			isValid = false;
    		}   
    		if(!isValid){
    			petcoCommonJS.showModal('Personalization'); 
    			$('#Personalization_1').show();
    			$('#personilaztionDisplay').addClass('active');
    		}
    	}
    	return isValid;
    },
    toggle: function(obj, elem, triggerCoreMetrics) {
    	 var par = $(obj).parent(),
         sub = $(par).children("ul.form-field-listing"),
         icon = $(obj).children(),
         collapsing = false;
    	 var parId= par[0].id;
    	  if (elem != '' && elem == 'disabled-section') {
              return false;
          } else{
        	  $.each($(".colapsibleArea").children("ul.form-field-listing"),function(i,node){
        		 if( $(node).parent()[0].id != parId){
           		  if($(node).hasClass("field-listing-visible")){
        			  var localObj =$(node).parent().children("span.click-row");
        			  $(node).removeClass("field-listing-visible");
        			  $(node).children("li.form-field-sublist").css("display", "none");
        			  $(localObj).children().children("i.icon-minus").css("display", "none");
                      $(localObj).children().children("i.icon-plus").css("display", "block");
                      $(localObj).attr('aria-expanded', 'false');
                      $(node).attr('aria-hidden', 'true');
        		  }
        		 }
        	  });

        	          	  $.each(sub,function(i,node){
        		  if($(node).hasClass("field-listing-visible")){
        			  collapsing = true;
        			  $(node).removeClass("field-listing-visible");
        			  $(node).children("li.form-field-sublist").css("display", "none");
        			  $(icon).children("i.icon-minus").css("display", "none");
                      $(icon).children("i.icon-plus").css("display", "block");
                      $(obj).attr('aria-expanded', 'false');
                      $(node).attr('aria-hidden', 'true'); 
        		  }else {
        			  $(node).addClass("field-listing-visible");
        			  $(node).children("li.form-field-sublist").css("display", "block");
                      $(icon).children("i.icon-minus").css("display", "block");
                      $(icon).children("i.icon-plus").css("display", "none");
                      $(obj).attr( 'aria-expanded', 'true');
                      $(node).attr('aria-hidden', 'false');
                  }        		  
        	  });
        	  if (triggerCoreMetrics) {
                  var coreMetricsTag = $(obj).attr("data-manual_cm_sp");
                  $(obj).attr("manual_cm_sp", coreMetricsTag + (collapsing ? "_Collapse" : "_Expand"));
                  cmManuallyTriggerEventTrack(obj);
              }
          }
    },
    petTypeBreed :{},
    petVetInfoData:{},
    loadPetBreedValues: function(petTypeIdval,petBreedIDval,defaultValue,defaultTypeValue, loadBreeds) {
    	if(typeof(defaultTypeValue) != "undefined" && defaultTypeValue != null){
    		var toCatObjVal = defaultTypeValue;
    		var optionList = {};

    		    		$("#"+petTypeIdval+" option").each(function(){
    			optionList[($(this).val()).toUpperCase()] = $(this).val();
    		});
    		var selectBoxValue = optionList[toCatObjVal];
    		if(typeof(selectBoxValue) != 'undefined' && $("#"+petTypeIdval).length > 0 && $("#"+petTypeIdval).val() == ""){
    			$("#"+petTypeIdval).val(selectBoxValue);
    		}

    		    	}

    	        var petTypeId = "#"+petTypeIdval;
        var petTypeObj = $(petTypeId);
        if (petTypeObj.length > 0) {
            var petType = petTypeObj.val();
            var petBreedID = "#"+petBreedIDval;
            var petBreedObj = $(petBreedID);
            if (petBreedObj.length > 0) {
                while (petBreedObj[0].firstChild) {
                    petBreedObj[0].removeChild(petBreedObj[0].firstChild);
                }
                var petTypeBreedArray = petcoPersonalizationJS.petTypeBreed[petType];
                var option = document.createElement("option");
                option.text = "Select Breed";
                option.value = "";
                option.selected = "selected";
                petBreedObj[0].add(option);
                if (typeof(petTypeBreedArray) != "undefined") {
                    for (var i = 0; i < petTypeBreedArray.length; i++) {
                        option = document.createElement("option");
                        option.text = petTypeBreedArray[i].split("<<DBLQUATS>>").join('"').split("<<SNGLQUATS>>").join("'");
                        option.value = petTypeBreedArray[i].split("<<DBLQUATS>>").join('"').split("<<SNGLQUATS>>").join("'");
                        petBreedObj[0].add(option);
                    }
                }

            }
            var counter = petBreedIDval.replace("rxMedicinePetBreed_","");
            var autoBoxobj = $("#autoSuggestLoader_"+counter);
            if(typeof(defaultValue) != "undefined" && defaultValue != null){
            $(petBreedObj).val(defaultValue);
            autoBoxobj.val(defaultValue);
        }else{
        	 $(petBreedObj).val("");
        	 autoBoxobj.val("");
        }
        }

        var counterArray = petBreedIDval.split("_");
        var counterval = 0;
        if(counterArray.length > 1){
        	counterval = counterArray[1];
        }
        if (loadBreeds) {
        	petcoPersonalizationJS.loadAutoCOmpleteForBreed($("#autoSuggestLoader_"+counterval)[0],petBreedIDval);
        }
    },
	triggerFocus: function(selector,selectedValue) {
		previousSelectedValue = selectedValue;
	},

	    triggerChange: function(selector) {
			if(selector.substring(selector.length -1, selector.length) == '0'){
		$('[id^='+selector+']').find("option:disabled").attr('disabled', false);

				$('[id^='+selector+']').each(function(i,obj){
			if(obj.value != ""){
				$('[id^='+selector+']').not(this).find("option[value='"+ obj.value + "']").attr('disabled', true);
		}				
		})
			}
	},
    rxMedicineValidateSubmitButtonEnabled: function(obj,counter) {

    	        var errorFiledMessage = {};
        var groupWithError = {};
        var groupWithErrorArray = [];
        var groupWithoutError = {};
        var jsonFieldEleContent = $("#fieldGroup").html();
        var jsonField = JSON.parse(jsonFieldEleContent);
        for (var i in jsonField) {
        	var isValidForSubmit = true;
            var idOfField = $('[id^="' + jsonField[i].Field+"_"+counter + '"]');
            var isFiledRequired = jsonField[i].IsRequired;
            var fieldGroup = jsonField[i].FieldGroup+"_"+counter;
            if (idOfField.length > 0) {
            	var isNumric = false;
                var isTeliphonic = false;
                var isOptionOthersExists = false;
                var isMultiOtherOptionsExists= false;
                var isDateField = false;
                var isRadio = false;
                var isAlpha = false;
                if (idOfField[0].className.indexOf("NUMERIC") > -1) {
                    isNumric = true;
                } else if (idOfField[0].className.indexOf("TELEPHONE") > -1) {
                    isTeliphonic = true;
                }  else if (idOfField[0].className.indexOf("MULTICHECKBOX") > -1) {
                	isMultiOtherOptionsExists = true;
                }else if (idOfField[0].className.indexOf("CHECKBOX") > -1) {
                    isOptionOthersExists = true;
                } else if (idOfField[0].className.indexOf("DATE") > -1) {
                    isDateField = true;
                } else if (idOfField[0].className.indexOf("RADIO") > -1) {
                    isRadio = true;
                } else if (idOfField[0].className.indexOf("ALPHA") > -1) {
                	isAlpha = true;
                }


                                                if (isFiledRequired == 'Y') {
                    if (!isOptionOthersExists && !isDateField && !isRadio && idOfField[0].value.trim() == "") {
                          isValidForSubmit = false;                     
                    } else if (isRadio) {
                            var checkedRadio = $('input[type="radio"][name="' + jsonField[i].Field+"_"+counter + '"]:checked');
                            if (checkedRadio.length == 0) {
                                isValidForSubmit = false;
                            }
                        } else if (isOptionOthersExists) {
                        	var checkedRadioOthers = $("#"+ jsonField[i].Field+"_"+counter +":checked");
                            if (checkedRadioOthers.length > 0) {
                            	var isBlank = true;
                            	$.each($('[id^="'+ jsonField[i].Field+'_'+counter +'_optionContainer"]'),function(index,obj){
	                            		if(obj.value.trim() != ""){
	                            			isBlank = false;
	                            		}
                            		})
                                if (isBlank && $("#"+jsonField[i].Field+'_'+counter+"_Others").val().trim()=="") {
                                    isValidForSubmit = false;
                                }
                            }
                        }else if(isMultiOtherOptionsExists){
                        	var checkedRadioOthers = $("#"+ jsonField[i].Field+"_"+counter +":checked");
                        	 if (checkedRadioOthers.length > 0 && $('[id^="'+jsonField[i].Field+'_'+counter+'-_options"]:checked').length == 0) {
                        		 if($("#"+jsonField[i].Field+'_'+counter+"_Others").val().trim()==""){
                        			 isValidForSubmit = false;
                        		 }

                        		                         	 }
                        } else if (isDateField) {
                            if ($("#" + jsonField[i].Field+"_"+counter + "_MON").length > 0 && $("#" + jsonField[i].Field+"_"+counter + "_MON").val() != "" &&
                                $("#" + jsonField[i].Field+"_"+counter + "_Date").length > 0 && $("#" + jsonField[i].Field+"_"+counter + "_Date").val() != "" &&
                                $("#" + jsonField[i].Field+"_"+counter + "_Year").length > 0 && $("#" + jsonField[i].Field+"_"+counter + "_Year").val() != ""
                            ) {
                                var date = $("#" + jsonField[i].Field+"_"+counter + "_Date").val();
                                var month = $("#" + jsonField[i].Field+"_"+counter + "_MON").val();
                                var year = $("#" + jsonField[i].Field+"_"+counter + "_Year").val();
                                var datetoValidate = new Date();
                                var todaysDate = new Date();
                                datetoValidate.setFullYear(year, (month - 1), date);
                                if (datetoValidate.getDate() != date && datetoValidate.getMonth() != (month - 1)) {
                                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Given date is not a valid date"
                                    isValidForSubmit = false;
                                }
                               if((datetoValidate - todaysDate) > 0 ){
                            	   errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Date of birth cannot be a future date"
                            	   isValidForSubmit = false;
                               }


                                                           } else {
                                isValidForSubmit = false;
                            }
                        }

                                    }
                var rxroundnumber = new RegExp(/^\d+.{1}\d{2,}$/);
                var rxnumber = new RegExp(/^\d+(0|[1-9][0-9]*)?$/);
                var petWeightDecimalEnabled =  $('#petWeightDecimalEnabled').val();
                if(petWeightDecimalEnabled&&petWeightDecimalEnabled=="true"){
                	var rxnumber = new RegExp(/^\d+.?\d{0,1}$/);
                }               
            	if(petWeightDecimalEnabled &&petWeightDecimalEnabled=="true"&&isNumric && idOfField[0].value.trim() != "" && rxroundnumber.test(idOfField[0].value.trim()) ){                	
            		var rounded = Math.round( idOfField[0].value.trim() * 10 ) / 10;
                	idOfField[0].value=rounded;                	
                }

                               var rxPhnumber = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
                var Alphapattern = new RegExp(/^[a-z\d\-_\s\.\_\']+$/i);
                if(isAlpha && idOfField[0].value.trim() != "" && !Alphapattern.test(idOfField[0].value.trim()) 
                		&& jsonField[i].Field == 'rxMedicinePetName'){
                	  isValidForSubmit = false;
                      errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Please enter valid pet name.";
                }else  if (isNumric && idOfField[0].value.trim() != "" && isNaN(idOfField[0].value.trim()) ) {
                    isValidForSubmit = false;
                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Enter numeric values only.";
                }
                else if(isNumric &&(!petWeightDecimalEnabled||(petWeightDecimalEnabled &&petWeightDecimalEnabled!="true"))&& idOfField[0].value.trim() != "" && !rxnumber.test(idOfField[0].value.trim()) ){                	
                	isValidForSubmit = false;
                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Enter whole numbers only. No decimal allowed.";
                }
                else if(isNumric &&petWeightDecimalEnabled &&petWeightDecimalEnabled=="true"&& idOfField[0].value.trim() != "" && !rxnumber.test(idOfField[0].value.trim()) ){                	
                	isValidForSubmit = false;
                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Enter valid numbers only.";
                }
                else if(isNumric && idOfField[0].value.trim() != "" && (idOfField[0].value.trim()*1) < 1){
                	isValidForSubmit = false;
                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Enter weight greater than 0.";
                } else if(isTeliphonic && idOfField[0].value.trim() != "" && !rxPhnumber.test(idOfField[0].value.trim()) ){
                	isValidForSubmit = false;
                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Enter valid phonenumber.";
                } else if (isTeliphonic && idOfField[0].value.trim() != "" && (idOfField[0].value.trim()).length < 10) {
                    isValidForSubmit = false;
                    errorFiledMessage["persFieldError_" + jsonField[i].Field+"_"+counter] = "Enter 10 digit Phone number.";
                }
            }
            if (!isValidForSubmit) {
                groupWithError[fieldGroup] = "Error";
                groupWithErrorArray.push(fieldGroup);
            }
            groupWithoutError[fieldGroup] = "Valid";
        }

        var errorFields = $('[id^="persFieldError_"]');
        $.each(errorFields, function(i, obj) {
            obj.style.display = 'none';
            $(obj).html('');

        })
        $.each(errorFields, function(i, obj) {
            if (typeof(errorFiledMessage[obj.id]) != "undefined") {
                obj.style.display = 'block';
                $(obj).html('<span class="help-block">' + errorFiledMessage[obj.id] + '</span>');
            }
        })
               var buttonEnabledGroup = [];
        var buttonDisabledGroup = [];
        $.each(groupWithoutError, function(i, obj) {
        	if($.inArray(i,groupWithErrorArray) == -1){
        		buttonEnabledGroup.push(i);
        	}
        	buttonDisabledGroup.push(i);
        })
        for(var xd = 0 ; xd<buttonDisabledGroup.length  ; xd++){
        	$("#"+buttonDisabledGroup[xd]+"_button").addClass("disabled");
        	$("#"+buttonDisabledGroup[xd]+"_saveandreviewbutton").addClass("disabled");

        	        }
        for(var xe = 0 ; xe<buttonEnabledGroup.length  ; xe++){
        	$("#"+buttonEnabledGroup[xe]+"_button").removeClass("disabled");
        	$("#"+buttonEnabledGroup[xe]+"_saveandreviewbutton").removeClass("disabled");
        }
        var groupWithoutError = {};
        var isError = false;
        $.each(groupWithError, function(i, obj) {
            isError = true;
        })

                var pdpAddtoCartButton = $('[id^="rxMedicinePersonalizationSubmit_PDP_'+counter+'"]');
        var pdpAddToWishListButton = $('[id^="rxMedicinePersonalizationSubmit_WishList_'+counter+'"]');
        var pdpEditInCartButton = $('[id^="rxMedicinePersonalizationSubmit_Cart_'+counter+'"]');

                if($('[id^="rxMedicinePetDetailsSubmit_SPC"]').length > 0){
        	var spcSubtmitPetButton = $('[id^="rxMedicinePetDetailsSubmit_SPC"]');
        	if(isError){
        		$(spcSubtmitPetButton).addClass("disabled");
        	}else{
        		$(spcSubtmitPetButton).removeClass("disabled");
        	}
        }

                if(isError){
        	$(pdpAddtoCartButton).addClass("disabled");
        	$(pdpAddToWishListButton).addClass("disabled");
        	$(pdpEditInCartButton).addClass("disabled");
        }else{
        	$(pdpAddtoCartButton).removeClass("disabled");
        	$(pdpAddToWishListButton).removeClass("disabled");
        	$(pdpEditInCartButton).removeClass("disabled");
        }
    },
	reAssignRxPersonalizedAttr: function(attrIdValueList,quanity){
		if(typeof(quanity) == 'undefined'){
			quanity = "1";
		}
		var personalizationNameValueArray = attrIdValueList.split('$');
		var petTypeId = "";
		var petBreedId = "";
		var petBreedValue = "";
		var petName = "";
		var vetName = "";
		for(var i = 0;i<personalizationNameValueArray.length-1;i++){
			var nameValueAttr = personalizationNameValueArray[i];
			var nameValueAttrArray = nameValueAttr.split('=');
			var nameAttr = "";
			var valueAttr = "";

				if(nameValueAttrArray.length == 2){

						nameAttr = nameValueAttrArray[0];
				valueAttr = nameValueAttrArray[1];
				if(nameAttr.indexOf('rxMedicinePetName') > -1){
					petName = valueAttr;
				}else if(nameAttr.indexOf('rxMedicineVetName') > -1){
					vetName = valueAttr;
				}
				var idOfField = $('[id^="' + nameAttr + '"]');
				 if (idOfField.length > 0) {
					 var isNumric = false;
		                var isTeliphonic = false;
		                var isOptionOthersExists = false;
		                var isDateField = false;
		                var isRadio = false;
		                var isOptions = false;
		                var isMultiOtherOptionsExists = false;
		                if (idOfField[0].className.indexOf("NUMERIC") > -1) {
		                    isNumric = true;
		                } else if (idOfField[0].className.indexOf("TELEPHONE") > -1) {
		                    isTeliphonic = true;
		                } else if(idOfField[0].className.indexOf("MULTICHECKBOX") > -1){
		                	isMultiOtherOptionsExists = true;
		                }else if (idOfField[0].className.indexOf("CHECKBOX") > -1) {
		                    isOptionOthersExists = true;
		                }  else if (idOfField[0].className.indexOf("DATE") > -1) {
		                    isDateField = true;
		                } else if (idOfField[0].className.indexOf("RADIO") > -1) {
		                    isRadio = true;
		                }
		                else if (idOfField[0].className.indexOf("OPTIONS") > -1) {
		                	isOptions = true;
		                }
		                if (!isMultiOtherOptionsExists && !isOptionOthersExists && !isDateField && !isRadio && !isOptions) {
		                	idOfField[0].value =  valueAttr;   
		                }else if (isOptions){
		                	if(nameAttr.toLowerCase().indexOf("pettype") > -1 ){
		                		petTypeId = nameAttr;
		                		if(valueAttr != ""){
		                			idOfField[0].value =  valueAttr;
		                		}

		                				                	}else if(nameAttr.toLowerCase().indexOf("petbreed") > -1 ){
		                		petBreedId = nameAttr;
		                		petBreedValue = valueAttr;
		                		idOfField[0].value =  petBreedValue;
		                	}else{
		                		idOfField[0].value = valueAttr;
		                	}
	                    } else if (isRadio) {
	                            var checkedRadio = $('input[type="radio"][name="' + nameAttr + '"]');
	                            if (checkedRadio.length > 0) {
	                               $.each(checkedRadio,function(i,obj){
	                            	   if(obj.value == valueAttr){
	                            		   obj.checked = true;
	                            	   }
	                               })
	                            }
	                        } else if (isOptionOthersExists) {
	                            var checkedRadioOthers = $('input[type="checkbox"][id="' + nameAttr + '"]');
	                            if (checkedRadioOthers.length > 0) {
	                            	if(valueAttr != "No" && valueAttr != ""){
	                            		checkedRadioOthers[0].checked  = true;
	                            		var valueAttrArr = valueAttr.split(",");
	                            		var valueAtrArrayInOpt = [];
	                            		var isOthersExists = false;
	                            		var refererSelectBox = nameAttr+"_optionContainer_0";
	                            		var refererSelectBoxValArray = {};
	                            		$.each($("#"+nameAttr+"_optionContainer_0 option"),function(ixc,obj){
	                            			if(obj.value != ""){
	                            				refererSelectBoxValArray[obj.value] = obj.value;
	                            			}                 			

	                            				                            		})
	                            		petcoPersonalizationJS.openContainerTemplateEnabled($("#"+nameAttr)[0]);
                        				for(var ixc = 0 ; ixc < valueAttrArr.length ; ixc++){
                        					if(typeof(refererSelectBoxValArray[valueAttrArr[ixc]]) != "undefined"){
                        						valueAtrArrayInOpt.push(valueAttrArr[ixc]);
                        					}else{
                        						isOthersExists = true;
                        						$("#"+nameAttr+"_Others").val(valueAttrArr[ixc]);
                        					}
                        				}
	                            		for(var ixd = 0 ; ixd < valueAtrArrayInOpt.length ; ixd++){
	                            			if(ixd == 0){
	                            				$("#"+nameAttr+"_optionContainer_0").val(valueAtrArrayInOpt[ixd]);
	                            			}else{
	                            				petcoPersonalizationJS.addMoreFOrMultiselect($("#"+nameAttr+"_addnewLink")[0])
	                            				$("#"+nameAttr+"_optionContainer_0"+ixd).val(valueAtrArrayInOpt[ixd]);
	                            			}
	                            		}

	                            			                            	}
	                            } 
	                        } else if (isMultiOtherOptionsExists) {
	                            var checkedRadioOthers = $('input[type="checkbox"][id="' + nameAttr + '"]');
	                            if (checkedRadioOthers.length > 0) {
	                            	if(valueAttr != "No" && valueAttr != ""){
	                            		checkedRadioOthers[0].checked  = true;
	                            		var valueAttrArr = valueAttr.split(",");
	                            		var valueAtrArrayInOpt = [];
	                            		var isOthersExists = false;
	                            		var refererSelectBoxValArray = {};
	                            		$.each($('[id^="'+nameAttr+'-_options_"]'),function(ixc,obj){
	                            			if(obj.value != ""){
	                            				refererSelectBoxValArray[obj.value] = obj.value;
	                            			}                 			

	                            				                            		})
	                            		petcoPersonalizationJS.openContainerTemplateEnabled($("#"+nameAttr)[0]);
                        				for(var ixc = 0 ; ixc < valueAttrArr.length ; ixc++){
                        					if(typeof(refererSelectBoxValArray[valueAttrArr[ixc]]) != "undefined"){
                        						valueAtrArrayInOpt.push(valueAttrArr[ixc]);
                        					}else{
                        						isOthersExists = true;
                        						$("#"+nameAttr+"_Others").val(valueAttrArr[ixc]);
                        					}
                        				}
	                            		for(var ixd = 0 ; ixd < valueAtrArrayInOpt.length ; ixd++){
	                            			$.each($('[id^="'+nameAttr+'-_options_"]'),function(ixc,obj){
		                            			if(obj.value == valueAtrArrayInOpt[ixd]){
		                            				obj.checked = true;
		                            			}                 			

		                            					                            		})
	                            		}

	                            			                            	}
	                            } 
	                        }else if (isDateField) {
	                        	var valueDateAttrArr = valueAttr.split("-");
	                        	var day = 0;
	                        	var month = 0;
	                        	var year = 0;
	                        	if(valueDateAttrArr.length > 0){
	                        		if(valueDateAttrArr[0].length > 2){
	                        			day = valueDateAttrArr[2]*1
	                        			month=valueDateAttrArr[1]*1
	                        		    year=valueDateAttrArr[0]*1;
	                        		}else{
	                        			day = valueDateAttrArr[1]*1
	                        			month=valueDateAttrArr[0]*1
	                        		    year=valueDateAttrArr[2]*1;
	                        		}
	                        	}

	                        		                        	if($("#prescription-information").length > 0){
	                        		$("#" + nameAttr + "_Date").val(day);
	                                $("#" + nameAttr + "_MON").val(month);
	                                $("#" + nameAttr + "_Year").val(year);
	                        	}else{
	                        		$("#" + nameAttr + "_Date").val(valueDateAttrArr[1]);
	                                $("#" + nameAttr + "_MON").val(valueDateAttrArr[0]);
	                                $("#" + nameAttr + "_Year").val(valueDateAttrArr[2]);
	                        	}

	                                	                        }
				 }


				 							}
		}
		if(petTypeId != "" && petBreedId != "" &&  petBreedValue != "" ){

							eval($("#petBreedTypeLoadJS").html());
			for(var ix= 1 ;ix <= quanity; ix++){
				$("#autoSuggestLoader_"+ix).val(petBreedValue);
				$("#rxMedicinePetBreed_"+ix).val(petBreedValue);
			}

					}
		$.each($(".colapsibleArea").children("ul.form-field-listing"),function(i,node){
				 if(!$(node).hasClass("field-listing-visible")){
		  			  var localObj =$(node).parent().children("span.click-row");
		  			  $(node).addClass("field-listing-visible");
		  			  $(node).children("li.form-field-sublist").css("display", "block");
		  			  $(localObj).children().children("i.icon-minus").css("display", "block");
		                $(localObj).children().children("i.icon-plus").css("display", "none");
		                $(localObj).attr('aria-expanded', 'true');
		                $(node).attr('aria-hidden', 'false');

		                  			}


     		 				 	    	  });

			if(petName != "" &&  $('[id^="RX_MyPet_"]').length > 0){
		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#RX_MyPet_"+quanity+"_button")[0],quanity);
			petcoPersonalizationJS.rxContinueToNextPage($("#RX_MyPet_"+quanity+"_button")[0],'RX_MyPet_'+quanity,'PET',quanity,false)

				}
		if(vetName != "" && $('[id^="RX_MyVet_"]').length > 0){
			petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#RX_MyPet_"+quanity+"_button")[0],quanity);
			petcoPersonalizationJS.rxContinueToNextPage($("#RX_MyVet_"+quanity+"_button")[0],'RX_MyVet_'+quanity,'VET',quanity,false)
		}
		if($('[id^="RX_MyPrescription_"]').length  > 0){
			petcoPersonalizationJS.rxContinueToNextPage($("#RX_MyPrescription_"+quanity+"_button")[0],'RX_MyPrescription_'+quanity,'Prescription',quanity,false)
		}


					},
	rxGoToPage:function(count,quanity){
		var contentArr = $('[id^="rxmedicineContent_"]');
		$.each(contentArr,function(i,obj){
			obj.style.display = "none";
		})
		var contentToload = $("#rxmedicineContent_"+count);
		if(contentToload.length > 0){
			contentToload[0].style.display = "block";
		}
		if(count > 1){
			for (var n=0; n<document.querySelectorAll("#personalizePaging .prev").length; n++){
	     		document.querySelectorAll("#personalizePaging .prev")[n].classList.remove("disabled");                		
	     	}
		}else{
			for (var n=0; n<document.querySelectorAll("#personalizePaging .prev").length; n++){
	     		document.querySelectorAll("#personalizePaging .prev")[n].classList.add("disabled");                		
	     	}
		}
		if(typeof(quanity)!="undefined" && count == quanity){
			for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
         		document.querySelectorAll("#personalizePaging .next")[n].classList.add("disabled");                		
         	}
		}else if(typeof(quanity)!="undefined" && count < quanity){
			for (var n=0; n<document.querySelectorAll("#personalizePaging .next").length; n++){
         		document.querySelectorAll("#personalizePaging .next")[n].classList.remove("disabled");                		
         	}
		}
	},
	rxMedSubmitFromPDP:function (obj,ltlorRxflag,catentryId,sinUrl,count,quantity){
		if (!(obj.classList.contains('disabled'))) {

						if($("#PersonalizationCheckBox_"+count+":checked").length == 0){
				if(count < quantity ){
					petcoPersonalizationJS.rxGoToPage(count+1,quantity);
					return;
				}else if(count == quantity){
					var lastUnfilledArea=null;
					$.each($('[id^="rxMedicinePersonalizationSubmit_PDP_"].disabled'),function(i,obj){
						lastUnfilledArea = obj;
					})
					if(lastUnfilledArea != null){
						petcoPersonalizationJS.rxGoToPage(lastUnfilledArea.id.replace("rxMedicinePersonalizationSubmit_PDP_",""),quantity);				
						return;
					}
				}
			}


								    if (null !=document.getElementById('repeat-delivery-add-on') && document.getElementById('repeat-delivery-add-on').checked) {
		        productDisplayJS.rdAddOnLoginOptions(sinUrl, document.getElementById('quantity_'+catentryId).value,ltlorRxflag );
		        petcoCommonJS.hideModal('modal-rxmedicine');
		        $('#rxPersonilaztionDisplay').removeClass('active');
		    } else {
		        setCurrentId('add2CartBtn');
		        petcoCommonJS.hideModal('modal-rxmedicine');
		        $('#rxPersonilaztionDisplay').removeClass('active');
		        productDisplayJS.Add2ShopCartAjax('entitledItem_'+catentryId, document.getElementById('quantity_'+catentryId).value, false, false);
		    };
		    cmManuallyTriggerEventTrack(obj);
		}
    },
    rxUpdateSameInAllPage:function(obj){
    	if(obj.checked){
    		$("#personalizePaging").addClass("hide");
    	}else{
    		$("#personalizePaging").removeClass("hide");
    	}
    },
    isloadedPetAndVetInfoInModel:false,
    petandvetInfoPulated:false,
    loadPetAndVetInfoInModel: function(isFirtLoad){
    	var urlTOConstruct="";
    	if(typeof(isFirtLoad) != "undefined" && isFirtLoad){
    		urlTOConstruct = getAbsoluteURL()+"loadPetAndVetInfo?loadpetFromSebel=true&"+(new Date().getDate()+''+new Date().getMonth()+''+new Date().getYear())
    	}else{
    		urlTOConstruct = getAbsoluteURL()+"loadPetAndVetInfo?"+(Math.floor(new Date().getDate()+''+new Date().getMonth()+''+new Date().getYear()+''+new Date().getHours()+''+(Math.floor(new Date().getMinutes()/3))));
    	}
    	if("R" == dojo.cookie("WC_UserType")){
    	 	$.ajax({
    	 		url : urlTOConstruct,
    	 		type :'GET',
    	 		 cache: true,
    	 		contentType: 'application/json',
    	 		dataType: 'json',
    	 		data: '',
    	 		success: function(data){
    	 			if(typeof(isFirtLoad) != "undefined" && isFirtLoad){
    	 				 petcoPersonalizationJS.petandvetInfoPulated = true;
    	 				return;
    	 			}
    	 		petcoPersonalizationJS.petVetInfoData = data;
    	 		var savedPetInfo = data.savedpetinfo;
    	 		var savedVetInfo = data.savedvetinfo;

    	 		    	 		var jsonFieldEleContent = $("#fieldGroup").html();
    	        var jsonField = JSON.parse(jsonFieldEleContent);
    	        var FiledMapping  = {};
    	        for(var j in jsonField){
    	        	FiledMapping[jsonField[j].Label] = jsonField[j].Field;
    	        }

    	            	     var quantOfItems =   document.getElementById('quantity_'+productDisplayJS.bopusShowStoreDetailsProductId).value;
    	     var inforForPopulate = "";
    	     for(var i = 1 ; i <= quantOfItems ; i++){
    	    	 inforForPopulate = "";
     	        if($('[id^="rxMedicinePersonalizationSubmit_Cart_"]').length == 0){
     	        if(typeof(savedPetInfo) != "undefined" && typeof(savedPetInfo.pettype) != "undefined" ){
     	        	inforForPopulate = FiledMapping["Pet Type"]+"_"+i+"="+savedPetInfo.pettype.split("&#034;").join('"');
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Pet Name"]+"_"+i+"="+savedPetInfo.petname;
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Breed"]+"_"+i+"="+savedPetInfo.breed.split("&#034;").join('"');
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Gender"]+"_"+i+"="+savedPetInfo.gender;
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Weight"]+"_"+i+"="+savedPetInfo.weight;
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Is Pet Alergic to Medication"]+"_"+i+"="+savedPetInfo.petalergic;
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Is Pet Taking to Medication"]+"_"+i+"="+savedPetInfo.petmedication;
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Pet Having Medical Condition"]+"_"+i+"="+savedPetInfo.petmedicationcondition;
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Pet DOB"]+"_"+i+"="+savedPetInfo.dateofbirth.split("-")[1]*1+"-"+savedPetInfo.dateofbirth.split("-")[2]*1+"-"+savedPetInfo.dateofbirth.split("-")[0]*1;  
     	        }else{
     	        	inforForPopulate = FiledMapping["Pet Type"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Pet Name"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Breed"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Gender"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Weight"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Is Pet Alergic to Medication"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Is Pet Taking to Medication"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Pet Having Medical Condition"]+"_"+i+"=";
     	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Pet DOB"]+"_"+i+"=--";  
     	        }
 	    	        if(typeof(savedVetInfo) != "undefined"){
 	    	        	inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet First Name"]+"_"+i+"="+savedVetInfo.vetname;
 	    	        	inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Last Name"]+"_"+i+"="+savedVetInfo.vetlastname;
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Clinic Name"]+"_"+i+"="+savedVetInfo.vatclinicname;
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Fax No"]+"_"+i+"="+savedVetInfo.vetfaxno;
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Phone No"]+"_"+i+"="+savedVetInfo.vetphoneno;
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet API ID"]+"_"+i+"="+savedVetInfo.vetapiid;
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet CLINIC ID"]+"_"+i+"="+savedVetInfo.vetclinicidapi;
 	    	        }else{
 	    	        	inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet First Name"]+"_"+i+"=";
 	    	        	inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Last Name"]+"_"+i+"=";
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Clinic Name"]+"_"+i+"=";
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Fax No"]+"_"+i+"=";
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet Phone No"]+"_"+i+"=";
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet API ID"]+"_"+i+"=";
 	    	 			inforForPopulate = inforForPopulate+"$"+FiledMapping["Vet CLINIC ID"]+"_"+i+"=";
 	    	        }

     	       	 			     	 			inforForPopulate = inforForPopulate+"$"; 	 	
     	 			petcoPersonalizationJS.reAssignRxPersonalizedAttr(inforForPopulate,i);	
     	 			if($("#PersonalizationCheckBox_1").length > 0 ){
     	 				$("#PersonalizationCheckBox_1")[0].checked = true;
     	 				petcoPersonalizationJS.rxUpdateSameInAllPage($("#PersonalizationCheckBox_1")[0])

     	 				     	 			}
     	 			petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#rxMedicinePrescriptionNumber_"+i)[0],i);

    	    	     	     }

    	        	 		}


    	 		    	 		    	 		var petJsonInfo = data.petinfo;
    	 		if(typeof(petJsonInfo) != 'undefined'){
    	 			$.each($('[id^="rxMedicinePetName_"]'),function(ij,objj){

        	 			        	 			var quanitity = objj.id.replace("rxMedicinePetName_","");
        	 			$("#petinfoaddnewpet_"+quanitity).removeClass("hide");
        	 			if($("#savedPetData_"+quanitity).length == 0){
	        	 			var petSelectBox = "<select id='savedPetData_"+quanitity+"' onchange='petcoPersonalizationJS.populatePetInfoData(this,"+quanitity+")'>";
	        	 			var isPetInfoExists = false;
	        	 			for (var i in petJsonInfo){
	            	 			isPetInfoExists = true;
	            	 			if(objj.value != "" && objj.value == petJsonInfo[i]["petname"]){
	            	 				petSelectBox =petSelectBox + "<option value='"+petJsonInfo[i]["petname"]+"' selected>"+petJsonInfo[i]["petname"]+"</option>"
	            	 			}else{
	            	 				petSelectBox =petSelectBox + "<option value='"+petJsonInfo[i]["petname"]+"'>"+petJsonInfo[i]["petname"]+"</option>"
	            	 			}

	            	 				            	 		}
	        	 			petSelectBox = petSelectBox+"</select>"
	        	 			if(isPetInfoExists){
	        	 				$(petSelectBox).insertAfter($(objj));
	        	 			}
        	 			}

        	 			        	 			$(objj).addClass("hide");
        	 		})
    	 		}

    	 		    	 		var vetJsonInfo = data.vetinfo;
    	 		if(typeof(vetJsonInfo) != 'undefined'){
    	 			 $.each($('[id^="rxMedicineVetName_"]'),function(ij,objj){

    	     	 		    	    	 			var quanitity = objj.id.replace("rxMedicineVetName_","");
    	    	 			var containerForList = $('#vetinfoaddnewvetContainer_'+quanitity);
    	    	 			$("#vetinfoaddnewvet_"+quanitity).removeClass("hide");
    	    	 			var vetSelectBox = "<div class='row'><div class='col-12'><select id='savedVetData_"+quanitity+"' onchange='petcoPersonalizationJS.populateVetInfoData(this,"+quanitity+")'>";
    	    	 			var isVetInfoExists = false;
    	    	 			for (var i in vetJsonInfo){
    	        	 			isVetInfoExists = true;
    	        	 			var firstName = vetJsonInfo[i]["vetname"];
    	        	 			var vetlastname= vetJsonInfo[i]["vetlastname"];
    	        	 			var vetId= vetJsonInfo[i]["vetapiid"];
    	        	 			if(objj.value != "" && objj.value == vetJsonInfo[i]["vetname"]){
    	        	 				vetSelectBox =vetSelectBox + "<option value='"+vetId+"||"+firstName+"||"+vetlastname+"' selected>"+firstName+" "+vetlastname+"</option>"
    	        	 			}else{
    	        	 				vetSelectBox =vetSelectBox + "<option value='"+vetId+"||"+firstName+"||"+vetlastname+"'>"+firstName+" "+vetlastname+"</option>"
    	        	 			}

    	        	 			    	        	 		}
    	    	 			vetSelectBox = vetSelectBox+"</select></div></div>"
    	    	 			if(isVetInfoExists){
    	    	 				$(vetSelectBox).insertAfter($(containerForList));
    	    	 			}
    	    	 		})
    	 		}
    	 		if($('[id^="RX_MyPrescription_"]').length > 0){
    	 			petcoPersonalizationJS.rxEditThisPage($("#RX_MyPrescription_1_editLink")[0],"RX_MyPrescription_1","PRESCRIPTION",1,["RX_MyVet","RX_MyPrescription"]);
    	 		}
    	 		cursor_clear();
    	 		 petcoPersonalizationJS.petandvetInfoPulated = false;
    	 		},
    	 		error: function(){
    	 			cursor_clear();
    	 			if($("#isVetWidgetEnabled").val() != 'true'){
    	 			   petcoPersonalizationJS.loadVetClicnicInfos(1,1,true);
    	 			}
    	 			console.log("PET INFORMATION ERROR OUT");
    	 		}
    	 	});
    	}else{    		
    		 if($("#isVetWidgetEnabled").val() != 'true'){
    			 petcoPersonalizationJS.loadVetClicnicInfos(1,1,true);	 
    		 }    		
    	}
    },
    getDefaultPetType:function(){
    	var petType ="";
    	 if($("#topCategoryValuePetType").length > 0 && $("#topCategoryValuePetType").val().trim() != ""){
    		 var petTypeListTmp = $("#topCategoryValuePetType").val().trim().split(" ");
			 var petTypeListTmpMap = {};
			 var petTypeList=[];
			 	 $("ol.breadcrumb li a").each(function(iy,obj_y){
			 if(typeof(petTypeListTmpMap[$(obj_y).text().toUpperCase()]) == 'undefined'){
				petTypeListTmpMap[$(obj_y).text().toUpperCase()] = "exists";
				petTypeList.push($(obj_y).text().toUpperCase());
				}
			 })
			 $(petTypeListTmp).each(function(ix,oject_x){
				if(typeof(petTypeListTmpMap[oject_x]) == 'undefined'){
				petTypeListTmpMap[oject_x] = "exists";
				petTypeList.push(oject_x);
				}
			 })
    		 var petTypeValList = [];
			 var petTypeValListMap = {};
    		 $("#rxMedicinePetType_1 option").each(function(i,obj){
    			 petTypeValList[i] = obj.value.toUpperCase();
    			 petTypeValListMap[obj.value.toUpperCase()] = obj.value;
    		 })
    		 $(petTypeList).each(function(j,oject){
    			 if(petTypeValList.includes(oject.toUpperCase()) && petType == ""){
    				 petType = petTypeValListMap[oject];
    			 }
    		 });
    	 }
    	 return petType;
    },
    clearAndRenewRx:function(obj,type){

    	    	if(type == "PET"){
    		var quanitity = obj.id.replace("addanewpet_","");
    		$("#petinfoaddnewpet_"+quanitity).addClass("hide");
    		$("#rxMedicinePetName_"+quanitity).removeClass("hide");
    		$("#rxMedicinePetName_"+quanitity).val("");
    		$("#savedPetData_"+quanitity).addClass("hide");
    		$("#rxMedicinePetType_"+quanitity).val(petcoPersonalizationJS.getDefaultPetType());
     	 	petcoPersonalizationJS.loadPetBreedValues("rxMedicinePetType_"+quanitity,"rxMedicinePetBreed_"+quanitity,null,petcoPersonalizationJS.getDefaultPetType().toUpperCase());
    		$("#rxMedicinePetBreed_"+quanitity).val("");
			$("#autoSuggestLoader_"+quanitity).val("");
    		$("#rxMedicinePetDateOfBirth_"+quanitity+"_MON").val("");
    		$("#rxMedicinePetDateOfBirth_"+quanitity+"_Date").val("");
    		$("#rxMedicinePetDateOfBirth_"+quanitity+"_Year").val("");
    		$("#rxMedicinePetWeight_"+quanitity).val("");
    		$("#rxMedicinePetAllergies_"+quanitity).val("");

    		    		$.each($('[id^="rxMedicinePetGender_'+quanitity+'-_-"]:checked'),function(ixc,obj){
    			obj.checked=false;
    		})
    		$("#rxMedicinePetAllergic_"+quanitity)[0].checked = false;
    		petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetAllergic_"+quanitity)[0]);

    		    		$.each($('[id^="rxMedicinePetAllergic_'+quanitity+'_optionContainer_0"]'),function(ixc,obj){
    			if(obj.id != 'rxMedicinePetAllergic_'+quanitity+'_optionContainer_0' ){
    				$(obj).parent().remove();
    			}else{
    				obj.value="";
    			}
    		})
    		$("#rxMedicinePetAllergic_"+quanitity+"_addnewLink").removeClass("hide");
    		$("#rxMedicinePetAllergic_"+quanitity+"_Others").val("");

    		    		$("#rxMedicinePetMedications_"+quanitity)[0].checked = false;
    		petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedications_"+quanitity)[0]);

    		    		$.each($('[id^="rxMedicinePetMedications_'+quanitity+'_optionContainer_0"]'),function(ixc,obj){
    			if(obj.id != 'rxMedicinePetMedications_'+quanitity+'_optionContainer_0' ){
    				$(obj).parent().remove();
    			}else{
    				obj.value="";
    			}
    		})
    		$("#rxMedicinePetMedications_"+quanitity+"_addnewLink").removeClass("hide");
    		$("#rxMedicinePetMedications_"+quanitity+"_Others").val("");

    		    		$("#rxMedicinePetMedicalConditions_"+quanitity)[0].checked = false;
    		petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedicalConditions_"+quanitity)[0]);

    		    		$.each($('[id^="rxMedicinePetMedicalConditions_'+quanitity+'-_options_-"]:checked'),function(ixc,obj){
    			obj.checked = false;
    		})
    		$("#rxMedicinePetMedicalConditions_"+quanitity+"_Others").val("");
    		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#rxMedicinePetName_"+quanitity)[0],quanitity);

    		    		petcoPersonalizationJS.addValueForBreedField($("#rxMedicinePetBreed_"+quanitity)[0],'autoSuggestLoader_'+quanitity);
    	}else if(type == "VET"){
    		var quanitity = obj.id.replace("addanewvet_","");

    		    		$("#savedVetData_"+quanitity).addClass("hide");
    		$("#vetinfoaddnewvet_"+quanitity).addClass("hide");
    		$("#rxMedicineVetName_"+quanitity).removeClass("hide");
    		$("#rxMedicineVetName_"+quanitity).val("");
    		$("#rxMedicineVetCliName_"+quanitity).val("");
    		$("#rxMedicineVetPhNo_"+quanitity).val("");
    		$("#rxMedicineVetFxNo_"+quanitity).val("");
    		$("#rxMedicineVetLastName_"+quanitity).val("");
    		$("#rxMedicineVetAPIId_"+quanitity).val("");
    		$("#rxMedicineVetClinicId_"+quanitity).val("");
    		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#rxMedicineVetFxNo_"+quanitity)[0],quanitity);
    	}

    	    },    
    populatePetInfoData:function(obj,counter){
    	var jsonFieldEleContent = $("#fieldGroup").html();
        var jsonField = JSON.parse(jsonFieldEleContent);
        var FiledMapping  = {};
        for(var j in jsonField){
        	FiledMapping[jsonField[j].Label] = jsonField[j].Field;
        }
        var vPetValueSelected = obj.value;
 		var petJsonInfo = petcoPersonalizationJS.petVetInfoData.petinfo;
 		if(typeof(petJsonInfo) != "undefined"){
 			for(var i in petJsonInfo){
 				if(petJsonInfo[i].petname == vPetValueSelected){

 					 					$("#"+FiledMapping["Pet Type"]+"_"+counter).val(petJsonInfo[i].pettype.split("&#034;").join('"'));
 					petcoPersonalizationJS.loadPetBreedValues(FiledMapping["Pet Type"]+"_"+counter,FiledMapping["Breed"]+"_"+counter,petJsonInfo[i].breed.split("&#034;").join('"'));
 					$("#autoSuggestLoader_"+counter).val(petJsonInfo[i].breed.split("&#034;").join('"'));
 					$("#"+FiledMapping["Pet Name"]+"_"+counter).val(vPetValueSelected); 					
 					$("#"+FiledMapping["Pet Name"]+"_"+counter).val(vPetValueSelected);
 					$("#"+FiledMapping["Weight"]+"_"+counter).val(petJsonInfo[i].weight);
 					var valueOfRadiopetalergic = petJsonInfo[i].petalergic ;
 					if(valueOfRadiopetalergic == "No" || valueOfRadiopetalergic == "" ){
 						$("#rxMedicinePetAllergic_"+counter)[0].checked = false;
 						petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetAllergic_"+counter)[0]);
 					}else{
 						$("#rxMedicinePetAllergic_"+counter)[0].checked = true;
 						petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetAllergic_"+counter)[0]);
 			    		$.each($('[id^="rxMedicinePetAllergic_'+counter+'_optionContainer_0"]'),function(ixc,obj){
 			    			if(obj.id != 'rxMedicinePetAllergic_'+counter+'_optionContainer_0' ){
 			    				$(obj).parent().remove();
 			    			}else{
 			    				obj.value="";
 			    			}
 			    		})
 			    		var refererSelectBoxValArray = {};
                		$.each($("#rxMedicinePetAllergic_"+counter+"_optionContainer_0 option"),function(ixc,obj){
                			if(obj.value != ""){
                				refererSelectBoxValArray[obj.value] = obj.value;
                			}                 			
                		})
 			    		$("#rxMedicinePetAllergic_"+counter+"_Others").val("");
                		$("#rxMedicinePetAllergic_"+counter+"_addnewLink").removeClass("hide");
                		var isOthersExists = false;
                		var isOthersExistsVal = "";
                		var valueOfRadiopetalergicArr = valueOfRadiopetalergic.split(",");
                		var ixdval = 0;
                		for(var ins = 0 ; ins < valueOfRadiopetalergicArr.length ; ins++){
                			if(typeof(refererSelectBoxValArray[valueOfRadiopetalergicArr[ins]]) !="undefined"){
                				var rxMedicinePetAllergicval = $("#rxMedicinePetAllergic_"+counter+"_optionContainer_0");
                				if($(rxMedicinePetAllergicval).val() == ""){
                					$(rxMedicinePetAllergicval).val(valueOfRadiopetalergicArr[ins])
                				}else{
                					ixdval++;
                					petcoPersonalizationJS.addMoreFOrMultiselect($("#rxMedicinePetAllergic_"+counter+"_addnewLink")[0])
                    				$("#rxMedicinePetAllergic_"+counter+"_optionContainer_0"+ixdval).val(valueOfRadiopetalergicArr[ins]);
                				}

                				                			}else{
                				$("#rxMedicinePetAllergic_"+counter+"_Others").val(valueOfRadiopetalergicArr[ins]);
                			}
                		}
 					}

 				 					var valueOfRadiopetmedication = petJsonInfo[i].petmedication ;
 					if(valueOfRadiopetmedication == "No" || valueOfRadiopetmedication == ""){
 						$("#rxMedicinePetMedications_"+counter)[0].checked = false;
 						petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedications_"+counter)[0]);
 					}else{
 						$("#rxMedicinePetMedications_"+counter)[0].checked = true;
 						petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedications_"+counter)[0]);

 						 			    		$.each($('[id^="rxMedicinePetMedications_'+counter+'_optionContainer_0"]'),function(ixc,obj){
 			    			if(obj.id != 'rxMedicinePetMedications_'+counter+'_optionContainer_0' ){
 			    				$(obj).parent().remove();
 			    			}else{
 			    				obj.value="";
 			    			}
 			    		})
 			    		var refererSelectBoxValArray = {};
                		$.each($("#rxMedicinePetMedications_"+counter+"_optionContainer_0 option"),function(ixc,obj){
                			if(obj.value != ""){
                				refererSelectBoxValArray[obj.value] = obj.value;
                			}                 			
                		})
 			    		$("#rxMedicinePetMedications_"+counter+"_Others").val("");
                		$("#rxMedicinePetMedications_"+counter+"_addnewLink").removeClass("hide");
                		var isOthersExists = false;
                		var isOthersExistsVal = "";
                		var valueOfRadiopetalergicArr = valueOfRadiopetmedication.split(",");
                		var ixdval = 0;
                		for(var ins = 0 ; ins < valueOfRadiopetalergicArr.length ; ins++){
                			if(typeof(refererSelectBoxValArray[valueOfRadiopetalergicArr[ins]]) !="undefined"){
                				var rxMedicinePetAllergicval = $("#rxMedicinePetMedications_"+counter+"_optionContainer_0");
                				if($(rxMedicinePetAllergicval).val() == ""){
                					$(rxMedicinePetAllergicval).val(valueOfRadiopetalergicArr[ins])
                				}else{
                					ixdval++;
                					petcoPersonalizationJS.addMoreFOrMultiselect($("#rxMedicinePetMedications_"+counter+"_addnewLink")[0])
                    				$("#rxMedicinePetMedications_"+counter+"_optionContainer_0"+ixdval).val(valueOfRadiopetalergicArr[ins]);
                				}

 						                			}else{
                				$("#rxMedicinePetMedications_"+counter+"_Others").val(valueOfRadiopetalergicArr[ins]);
                			}
 					}
 					}


 					 					 					var valueOfRadiopetmedicationcondition = petJsonInfo[i].petmedicationcondition ;
 					if(valueOfRadiopetmedicationcondition == "No" || valueOfRadiopetmedicationcondition == ""){
 						$("#rxMedicinePetMedicalConditions_"+counter)[0].checked = false;
 						petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedicalConditions_"+counter)[0]);
 					}else{
 						$("#rxMedicinePetMedicalConditions_"+counter)[0].checked = true;
 						petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedicalConditions_"+counter)[0]);

 						 			    		$.each($('[id^="rxMedicinePetMedicalConditions_'+counter+'-_options_-"]:checked'),function(ixc,obj){
 			    			obj.checked = false;
 			    		})
 			    		var refererSelectBoxValArray = {};
                		$.each($('[id^="rxMedicinePetMedicalConditions_'+counter+'-_options_-"]'),function(ixc,obj){
                			if(obj.value != ""){
                				refererSelectBoxValArray[obj.value] = obj.value;
                			}                 			
                		})
 			    		$("#rxMedicinePetMedicalConditions_"+counter+"_Others").val("");

 						                		var isOthersExists = false;
                		var isOthersExistsVal = "";
                		var valueOfRadiopetalergicArr = valueOfRadiopetmedicationcondition.split(",");
                		var ixdval = 0;
                		for(var ins = 0 ; ins < valueOfRadiopetalergicArr.length ; ins++){
                			if(typeof(refererSelectBoxValArray[valueOfRadiopetalergicArr[ins]]) !="undefined"){
                				$.each($('[id^="rxMedicinePetMedicalConditions_'+counter+'-_options_-"]'),function(ixc,obj){
                        			if(obj.value == valueOfRadiopetalergicArr[ins]){
                        				obj.checked = true;
                        			}                 			
                        		})

 						                			}else{
                				$("#rxMedicinePetMedicalConditions_"+counter+"_Others").val(valueOfRadiopetalergicArr[ins]);
                			}
 					}
 					}






 					 					 					 		 					 					 					var valueOfRadiogender = petJsonInfo[i].gender ;
 					$.each($('[name="'+FiledMapping["Gender"]+"_"+counter+'"]'),function(i,obj){
 						if( obj.value == valueOfRadiogender){
                 		   obj.checked = true;
                 	   }
 					})
 					var valueOfDOB = petJsonInfo[i].dateofbirth ;
 					var valueofDOBArr = valueOfDOB.split("-");
 					$("#"+FiledMapping["Pet DOB"]+"_"+counter+"_MON").val(valueofDOBArr[1]*1);
 					$("#"+FiledMapping["Pet DOB"]+"_"+counter+"_Date").val(valueofDOBArr[2]*1);
 					$("#"+FiledMapping["Pet DOB"]+"_"+counter+"_Year").val((valueofDOBArr[0]*1));
 					petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#"+FiledMapping["Pet DOB"]+"_"+counter+"_MON"),counter);
 				}
 			}
 		}

		petcoPersonalizationJS.addValueForBreedField($("#rxMedicinePetBreed_"+counter)[0],'autoSuggestLoader_'+counter);




		 				 		    },
    populateVetInfoData : function (obj,counter){
    	var jsonFieldEleContent = $("#fieldGroup").html();
        var jsonField = JSON.parse(jsonFieldEleContent);
        var FiledMapping  = {};
        for(var j in jsonField){
        	FiledMapping[jsonField[j].Label] = jsonField[j].Field;
        }
        var vVetValueSelected = obj.value;
        var fistName = vVetValueSelected.split("||")[1];
        var secondName = vVetValueSelected.split("||")[2];
        var vetId = vVetValueSelected.split("||")[0];
 		var vetJsonInfo = petcoPersonalizationJS.petVetInfoData.vetinfo;
 		if(typeof(vetJsonInfo) != "undefined"){
 			for(var i in vetJsonInfo){
 				if(vetJsonInfo[i].vetname == fistName && vetJsonInfo[i].vetlastname == secondName && vetJsonInfo[i].vetapiid == vetId ){
 					$("#"+FiledMapping["Vet First Name"]+"_"+counter).val(fistName);
 					$("#"+FiledMapping["Vet Last Name"]+"_"+counter).val(vetJsonInfo[i].vetlastname);
 					$("#"+FiledMapping["Vet Clinic Name"]+"_"+counter).val(vetJsonInfo[i].vatclinicname); 					
 					$("#"+FiledMapping["Vet Fax No"]+"_"+counter).val(vetJsonInfo[i].vetfaxno);
 					$("#"+FiledMapping["Vet Phone No"]+"_"+counter).val(vetJsonInfo[i].vetphoneno);
 					$("#"+FiledMapping["Vet API ID"]+"_"+counter).val(vetJsonInfo[i].vetapiid);
 					$("#"+FiledMapping["Vet CLINIC ID"]+"_"+counter).val(vetJsonInfo[i].vetclinicidapi);
 					petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#"+FiledMapping["Vet Phone No"]+"_"+counter),counter);
 				}
 			}
 		}
    },
    openContainerTemplateEnabled : function(obj){
    	var ids = obj.id;
    	var idVal = $("#"+ids+"_optionsifyes");
    	if(obj.checked == true){
    		$(idVal).removeClass("hide");
    	}else{
    		$(idVal).addClass("hide");
    	}
    },
    addMoreFOrMultiselect: function(obj){
    	if($(obj).hasClass("hide")){
    		return false;
    	}
    	var ids = obj.id;
    	var idArr = ids.split("_");
    	var idOfOptions= idArr[0]+"_"+idArr[1]+"_optionContainer_0";
    	var idOfOptionsEle = $("#"+idOfOptions);
    	if(idOfOptionsEle.length > 0){
    		var parentItem = $(idOfOptionsEle).parent();
			var coloneObj = $(parentItem).clone();
			$(coloneObj[0]).find('select')[0].id = $(coloneObj[0]).find('select')[0].id+$('[id^="'+idOfOptions+'"]').length;
			$(coloneObj).insertBefore($(obj).parent());
			petcoPersonalizationJS.triggerChange(idOfOptions);
			if($('[id^="'+idOfOptions+'"]').length == 3){
				$(obj).addClass("hide");
			}

			    	}
    },
    rxEditThisPage: function(obj,id,fildList,counter,idArray){
    	var editDivId = "#"+id+"_edit";
    	var reviewDivId = "#"+id+"_review";
    	var editLink = "#"+id+"_editLink";
    	$(editDivId).removeClass("hide");
    	$(editDivId).addClass("show");
    	if(fildList == "PET"){
    		if($("#savedPetData_"+counter).length > 0 && $("#savedPetData_"+counter).hasClass("hide")){
    			$("#savedPetData_"+counter).removeClass("hide");
    			$("#rxMedicinePetName_"+counter).addClass("hide");
    			$("#petinfoaddnewpet_"+counter).removeClass("hide");

    			    			var petName = $("#rxMedicinePetName_"+counter).val();
    			var petType = $("#rxMedicinePetType_"+counter).val();
    			var allergies = $("#rxMedicinePetAllergies_"+counter).val();
    			var breed =  $("#rxMedicinePetBreed_"+counter).val();
    			var dateofbirth = $("#rxMedicinePetDateOfBirth_"+counter+"_Year").val()+"-"+$("#rxMedicinePetDateOfBirth_"+counter+"_MON").val()+"-"+ $("#rxMedicinePetDateOfBirth_"+counter+"_Date").val();
    			var gender = $('input[type="radio"][name="rxMedicinePetGender_'+counter+'"]').val();
    			var petalergic = "" ;
    			if($("#rxMedicinePetAllergic_"+counter+":checked").length > 0){
    				$.each($('[id^="rxMedicinePetAllergic_'+counter+'_optionContainer_"]'),function(i,obj){
    					if($(obj).val() != ""){
    						if(petalergic != ""){
    							petalergic = petalergic+","+$(obj).val();
    						}else{
    							petalergic = $(obj).val();
    						}
    					}
    				})
    			}else{
    				petalergic = "No" ;
    			}
    			var petmedication = "";
    			if($("#rxMedicinePetMedications_"+counter+":checked").length > 0){
    				$.each($('[id^="rxMedicinePetMedications_'+counter+'_optionContainer_"]'),function(i,obj){
    					if($(obj).val() != ""){
    						if(petmedication != ""){
    							petmedication = petmedication+","+$(obj).val();
    						}else{
    							petmedication = $(obj).val();
    						}
    					}
    				})
    			}else{
    				petmedication = "No" ;
    			}
    			var petmedicationcondition ="";
    			if($("#rxMedicinePetMedicalConditions_"+counter+":checked").length > 0){
    				$.each($('[id^="rxMedicinePetMedicalConditions_'+counter+'_optionContainer_"]'),function(i,obj){
    					if($(obj).val() != ""){
    						if(petmedicationcondition != ""){
    							petmedicationcondition = petmedicationcondition+","+$(obj).val();
    						}else{
    							petmedicationcondition = $(obj).val();
    						}
    					}
    				})
    			}else{
    				petmedicationcondition = "No" ;
    			}
    			var weight =$("#rxMedicinePetWeight_"+counter).val();
    			var newFIled = {};
    			newFIled["allergies"]=allergies;
    			newFIled["breed"]=breed;
    			newFIled["dateofbirth"]=dateofbirth;
    			newFIled["gender"]=gender;
    			newFIled["petalergic"]=petalergic;
    			newFIled["petmedication"]=petmedication;
    			newFIled["petmedicationcondition"]=petmedicationcondition;
    			newFIled["petname"]=petName;
    			newFIled["pettype"]=petType;
    			newFIled["weight"]=weight;
    			$("#savedPetData_"+counter).append("<option selected value='"+petName+"'>"+petName+"</option>")
    			petcoPersonalizationJS.petVetInfoData.petinfo[petcoPersonalizationJS.petVetInfoData.petinfo.length] = newFIled;

       		}
 	 		$("#modal-rxmedicine select.CHECKBOX").each(function(i,xobj){
    			petcoPersonalizationJS.triggerChange(xobj.id);
    		})

       	}else if(fildList == "VET"){
    		if($("#savedVetData_"+counter).length > 0 && $("#savedVetData_"+counter).hasClass("hide")){
    			$("#savedVetData_"+counter).removeClass("hide");
    			$("#rxMedicinePetName_"+counter).addClass("hide");
    			$("#vetinfoaddnewvet_"+counter).removeClass("hide");
    			var vetName = $("#rxMedicineVetName_"+counter).val();
    			var vetLastName = $("#rxMedicineVetLastName_"+counter).val();
    			var allergies = $("#rxMedicinePetAllergies_"+counter).val();
    			var clinicName =  $("#rxMedicineVetCliName_"+counter).val();
    			var vetPhoneNo = $("#rxMedicineVetPhNo_"+counter).val();
    			var vetFaxNo = $("#rxMedicineVetFxNo_"+counter).val();
    			var vetapiid = $("#rxMedicineVetAPIId_"+counter).val();
    			var vetclinicidapi = $("#rxMedicineVetClinicId_"+counter).val();
    			var newvetId = {};
    			newvetId["vatclinicname"]=vetName;
    			newvetId["vetapiid"]=vetapiid;
    			newvetId["vetclinicidapi"]=vetclinicidapi;
    			newvetId["vetfaxno"]=vetFaxNo;
    			newvetId["vetlastname"]=vetLastName;
    			newvetId["vetname"]=vetName;
    			newvetId["vetphoneno"]=vetPhoneNo;
    			$("#savedVetData_"+counter).append("<option selected value='"+vetapiid+"||"+vetName+"||"+vetLastName+"'>"+vetName+" "+vetLastName+"</option>")
    			petcoPersonalizationJS.petVetInfoData.vetinfo[petcoPersonalizationJS.petVetInfoData.vetinfo.length] = newvetId;
    		}
    	}


    	    	    	$(reviewDivId).addClass("hide");
    	$(reviewDivId).removeClass("show");
    	$(editLink).addClass("hide");
    	$(editLink).removeClass("show");
    	$("#submitArea_"+counter).removeClass("show");
		$("#submitArea_"+counter).addClass("hide");
		var isOpenInSaveAndReview=true;
		for(var i = 0 ; i < idArray.length ; i++){
			var idv = "#"+idArray[i]+"_"+counter+"_review";
			if($(idv).hasClass("hide")){
				isOpenInSaveAndReview = false;
			}
		}
		var saveAndCOntinueButton = "#"+id+"_saveandreviewbutton";
		var continueButton =  "#"+id+"_button";
		if(fildList != 'Prescription'){
			if(isOpenInSaveAndReview){
				$(saveAndCOntinueButton).removeClass("hide");
				$(continueButton).addClass("hide");
			}else{
				$(saveAndCOntinueButton).addClass("hide");
				$(continueButton).removeClass("hide");
			}
		}
		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($(continueButton)[0],counter);

				if(fildList == 'VET'){
			if($("#rxMedicineVetAPIId_"+counter).val() != "" && $("#rxMedicineVetClinicId_"+counter).val() != ""){
				$("#RX_MyVet_"+counter+"_searchPannel").addClass("show");
				$("#RX_MyVet_"+counter+"_searchPannel").removeClass("hide");
				$("#RX_MyVet_"+counter+"_editForm").addClass("hide");
				$("#RX_MyVet_"+counter+"_editForm").removeClass("show");


												$("#vetSearchResults_"+counter).html('<div class="text-center"><img src="/wcsstore/PetcoSAS/images/miniloading.gif" class="text-center" border="0"/></div>');
				petcoPersonalizationJS.loadVetClinicInfoForEdit(counter);
			}else{
				$("#RX_MyVet_"+counter+"_searchPannel").addClass("hide");
				$("#RX_MyVet_"+counter+"_searchPannel").removeClass("show");
				$("#RX_MyVet_"+counter+"_editForm").addClass("show");
				$("#RX_MyVet_"+counter+"_editForm").removeClass("hide");
			}
		}

				petcoPersonalizationJS.addValueForBreedField($("#rxMedicinePetBreed_"+counter)[0],'autoSuggestLoader_'+counter);
    },

       rxContinueToNextPage : function(obj,id,fildList,counter,isSave){
    	if(obj != null && $(obj).hasClass('disabled')){
    		return;
    	}
    	var editDivId = "#"+id+"_edit";
    	var reviewDivId = "#"+id+"_review";
    	var editLink = "#"+id+"_editLink";
    	$(editDivId).addClass("hide");
    	$(editDivId).removeClass("show");
    	$(reviewDivId).removeClass("hide");
    	$(reviewDivId).addClass("show");
    	$(editLink).removeClass("hide");
    	$(editLink).addClass("show");
    	if(fildList == 'PET' && $("#vet-pickup-method_"+counter).length > 0){
    		$(reviewDivId).html("");
    		$("#vet-pickup-method_"+counter).find('li.form-field-sublist')[0].style.display="block";
    		var PetName = $("#rxMedicinePetName_"+counter).val();
    		var PetType = $("#rxMedicinePetType_"+counter).val();
    		var PetBreed = $("#rxMedicinePetBreed_"+counter).val();
    		var dobDay = $("#rxMedicinePetDateOfBirth_"+counter+"_Date").val();
    		var dobMonth = $("#rxMedicinePetDateOfBirth_"+counter+"_MON").val();
    		var dobYear = $("#rxMedicinePetDateOfBirth_"+counter+"_Year").val();
    		var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    		var dobMonthString =months[((dobMonth*1)-1)];
    		var dobString = dobMonthString+" "+dobDay+", "+dobYear;
    		var htmlPet = "<p class='spacer-lg-left spacer-xsm-bottom spacer-sm-top'><strong>"+PetName+"</strong></p>";
    		htmlPet = htmlPet+"<p class='spacer-lg-left'><strong>Type: </strong>"+PetType+"</p>";
    		htmlPet = htmlPet+"<p class='spacer-lg-left'><strong>Breed: </strong>"+PetBreed+"</p>"
    		htmlPet = htmlPet+"<p class='spacer-lg-left spacer-xsm-bottom'><strong>Birthday: </strong>"+dobString+"</p>";
    		$(reviewDivId).html(htmlPet);
    	}else if(fildList == 'VET'){
    		$(reviewDivId).html("");   
    		var VetName = $("#rxMedicineVetName_"+counter).val();
    		var VetLastName = $("#rxMedicineVetLastName_"+counter).val();
    		var VetPhone = $("#rxMedicineVetPhNo_"+counter).val();
    		var VetClinicName =  $("#rxMedicineVetCliName_"+counter).val();
    		var clinicName = VetClinicName.substring(0,VetClinicName.indexOf("Address:"));
    		var clinicId = $("#rxMedicineVetClinicId_"+counter).val();
    		var VetAttribute = $("#rxVetDietVetAttribute_"+counter).val();
    		var vetId = $("#rxMedicineVetAPIId_"+counter).val();

    		    		var htmlvet = "<p class='spacer-lg-left spacer-sm-top'><strong>Veterinarian Name: </strong>"+VetName+" "+VetLastName+"</p>"
    		if(VetAttribute == 'vetsource'){
    			var editDivId = "#RX_MyVetClinic_" + counter + "_edit";
    	    	var reviewDivId =  "#RX_MyVetClinic_" + counter + "_review";
    	    	var editLink = "#RX_MyVetClinic_" + counter + "_editLink";

    	    	    	    	$(editDivId).removeClass("show");
    	    	$(editDivId).addClass("hide");

    	    	    	    	$(reviewDivId).removeClass("hide");
    	    	$(reviewDivId).addClass("show");

    	    	    	    	$(editLink).removeClass("hide");
    	    	$(editLink).addClass("show");

    	    	    	    	htmlvet = "";
    		}


    		    		    		 htmlvet = htmlvet+ "<p class='spacer-lg-left'><strong>Clinic Name: </strong>"+VetClinicName+"</p>"
    		 htmlvet = htmlvet+ "<p class='spacer-lg-left spacer-xsm-bottom'><strong>Phone Number: </strong>"+VetPhone+"</p>"
    		 $(reviewDivId).html(htmlvet);
    		if($('[id^="RX_MyPrescription_"]').length > 0 && $("#RX_MyPrescription_"+counter+"_review").hasClass("hide")){
    			$("#RX_MyPrescription_"+counter).find('li.form-field-sublist')[0].style.display="block";
    		}else{

    			        		$("#submitArea_"+counter).addClass("show");
        		$("#submitArea_"+counter).removeClass("hide");
    		}
    	}else if(fildList == 'PRESCRIPTION' || fildList == 'Prescription' ){
    		$(reviewDivId).html("");  
    		var prescriotnName = $("#rxMedicineRcvPrescrition_"+counter).val();
    		var prescriotnNumber = $("#rxMedicinePrescriptionNumber_"+counter).val();
    		var htmlvet="<p class='spacer-lg-left spacer-sm-top'><strong>Prescription Number : </strong>"+prescriotnNumber+"</p>";
    		$(reviewDivId).html(htmlvet);
    		$("#submitArea_"+counter).addClass("show");
    		$("#submitArea_"+counter).removeClass("hide");
    	}

    	    	if(isSave){
    		$("#submitArea_"+counter).addClass("show");
    		$("#submitArea_"+counter).removeClass("hide");
    	}
    },
    getClicnicInfoVetUrl:function(page,counter,latLOng){
    	var urlVal = $("#VetInfoLookupUrl").val();
    	var zipcode = "";
    	if($('#enterZipcodeSearch_'+counter).length > 0){
    		zipcode = $('#enterZipcodeSearch_'+counter).val();
    		if(zipcode == ""){
    			latLOng = true;
    		}
    	}else{
    		latLOng = true;
    	}
		if(zipcode == ""){
			if(typeof(latLOng) != "undefined" && latLOng){
				var latLong = localStorage.getItem('petcostores_latitude_longitude');
				if(latLong != null){
					var latLongJson = JSON.parse(latLong);
					 var geoLatitude = latLongJson.latitude;
					 var geoLongitude = latLongJson.longitude;
					 if(typeof(geoLatitude) != 'undefined' && typeof(geoLongitude) != 'undefined'){
						 urlVal = urlVal+"&latitude="+geoLatitude;
						 urlVal = urlVal+"&longitude="+geoLongitude;
					 }
				}
				urlVal = urlVal+"&page="+page;
			}


					}else{
			urlVal = urlVal+"&zipcode="+zipcode.trim();
			urlVal = urlVal+"&page="+page;
		}
		urlVal=urlVal+"&"+(Math.floor(new Date().getDate()+''+new Date().getMonth()+''+new Date().getYear()+''+(new Date().getHours()/2)))
		return urlVal; 
    },
   cancleVetCLinicInfo: function(counter){
    	var containerFOrVetClinic = $("#vetSearchResults_"+counter);
    	if(containerFOrVetClinic.length > 0){
    		containerFOrVetClinic.html("");
    	}
    	if($('#enterZipcodeSearch_'+counter).length > 0){
    		$('#enterZipcodeSearch_'+counter).val("");
    	}

       },
    loadVetClicnicInfos : function(page,counter,shouldLatLong,clearList){
    	if(typeof(clearList) != "undefined" && clearList){
    		var containerFOrVetClinic = $("#vetSearchResults_"+counter);
        	if(containerFOrVetClinic.length > 0){
        		containerFOrVetClinic.html("");
        	}
    	}
    	if($("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").length == 0){
    		$("#vetSearchResults_"+counter).html('<div class="text-center"><img src="/wcsstore/PetcoSAS/images/miniloading.gif" class="text-center clinicnamecontainer" border="0"/></div>');
    	}
    	var timeoutVal = 8000;
    	if(shouldLatLong){
    		timeoutVal = 3000;
    	}
    	$.ajax({
	 		url : petcoPersonalizationJS.getClicnicInfoVetUrl(page,counter,shouldLatLong),
	 		type : 'GET',
	 		 cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		timeout:timeoutVal,
	 		data: '',
	 		success: function(data){
	 			var containerFOrVetClinic = $("#vetSearchResults_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				var Items = data.items;
	 				var htmlVal = "";
	 				if(typeof(Items) != "undefined"){
	 					var count=0;
	 					for( var ixi in Items){
	 						var adrVetClinic = Items[ixi].address;
	 						var countryVetClinic= Items[ixi].country;
	 						var stateVetClinic= Items[ixi].state;
	 						var phone_numberVetClinic= Items[ixi].phone_number;
	 						var zip_codeVetClinic= Items[ixi].zip_code;
	 						var cityVetClinic= Items[ixi].city;
	 						var nameVetClinic= Items[ixi].name;
	 						var idVetClinic= Items[ixi].id;
	 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
	 						var wrxOnlyClinic = Items[ixi].written_rx_only;	
	 						var internalHospital = Items[ixi].internal_hospital;
	 						if(idVetClinic == '999999999'){
	 							cityVetClinic = 'Vetco Vaccination Clinics are Petco\'s prevention and wellness clinics. Select Vetco Vaccination Clinic if you have an active prescription from a Vetco Vaccination Clinic veterinarian';
	 							stateVetClinic="";
	 							zip_codeVetClinic="";
	 						}
	 						htmlVal = htmlVal+"<div class='row vetclinicrow'>";
	 						htmlVal = htmlVal+"<div class='col-2 col-2-sm'>";
	 						htmlVal = htmlVal+"<input type='radio' id='selectClinNameId_"+idVetClinic+"_"+counter+"' name='selectClinName"+counter+"' onclick='petcoPersonalizationJS.selectAndOpenVetClinic("+idVetClinic+","+counter+")'/>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"<div class='col-10 col-10-sm'>";
	 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' >"+nameVetClinic+"</br>"+adrVetClinic+"</br>"+cityVetClinic+" "+stateVetClinic+" "+zip_codeVetClinic+"</br>"+phone_numberVetClinic+"</label>";
	 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='wrxOnlyVetClinic_"+idVetClinic+"_"+counter+"' value='"+wrxOnlyClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='internalHospital_"+idVetClinic+"_"+counter+"' value='"+internalHospital+"'/>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"<div class='row vetresultrow hide margin-bottom-sm' id='vetresultrowId_"+idVetClinic+"_"+counter+"'>";
	 						htmlVal = htmlVal+"<div class='text-center'><img src='"+$("#miniloadingImg").val()+"' class='text-center' border='0' /></div>";

	 							 						htmlVal = htmlVal+"</div>";
	 						count++;
	 					}
	 					if(count == 0){
	 						$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 					}
	 				}else{
	 					$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 				}
	 				var currentCount = ((((page*1))*10)+(data.page_items*1))-10;
	 				if(currentCount > (data.total_items*1)){
	 					currentCount = (data.total_items*1);
	 				}
	 				if(typeof(data) != 'undefined' && !isNaN(currentCount) && currentCount != (data.total_items*1) && (data.page_items*1) > 0 ){
	 					$("#RX_VetLoadNextPage_"+counter).remove();
	 					$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 						htmlVal = htmlVal+"<div class='row'>";
		 					htmlVal = htmlVal+"<div class='col-12 text-center'>";
	 					htmlVal = htmlVal+"<a href='javaScript:void(0)'  class='editLink' id='RX_VetLoadNextPage_"+counter+"' onclick='petcoPersonalizationJS.loadVetClicnicInfos("+((page*1)+1)+","+counter+");'>Load More</a>";
		 					htmlVal = htmlVal+"</div></div>";
	 					$(containerFOrVetClinic).append(htmlVal);
	 				}else{
	 					$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 					$("#RX_VetLoadNextPage_"+counter).remove();
	 					$(containerFOrVetClinic).append(htmlVal);
	 					}
	 			}else{
	 				$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 			}
	 		},
	 		error: function(){
	 			console.log("PET INFORMATION ERROR OUT");
	 			cursor_clear();
	 			var containerFOrVetClinic = $("#vetSearchResults_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	});








    	    	    	                        }, getVetInfoVetUrl:function(clinicId,counter){
    	var urlVal = $("#VetInfoLookupUrl").val();
    	var clientId = "";
    	if($("#idVetClinic_"+clinicId+"_"+counter).length > 0){
    		clientId = $("#idVetClinic_"+clinicId+"_"+counter).val();
    	}

    			if(clientId != ""){
			 urlVal = urlVal+"&clinicId="+clientId;
		}
    	return urlVal+"&"+(Math.floor(new Date().getDate()+''+new Date().getMonth()+''+new Date().getYear()+''+(new Date().getHours()/2))); 
    },
    getClicnicInfoVetEditUrl:function(clinicName,counter){
    	var urlVal = $("#VetInfoLookupUrl").val();
			var zipcode = "";
	    	if($('#enterZipcodeSearch_'+counter).length > 0){
	    		zipcode = $('#enterZipcodeSearch_'+counter).val();
	    		if(zipcode != ""){
	    			urlVal=urlVal+"&zipcode="+zipcode;
	    		}
	    	}
    	return urlVal+"&"+(Math.floor(new Date().getDate()+''+new Date().getMonth()+''+new Date().getYear()+''+(new Date().getHours()/2))); 

    	    },
    selectAndOpenVetClinic:function(clinicid,counter){

    	    	$.each($('[id^="vetresultrowId_"].show'),function(i,obj){
    		$(obj).addClass("hide");
    		$(obj).removeClass("show");
    	});
    	$("#vetresultrowId_"+clinicid+"_"+counter).removeClass("hide");
    	$("#vetresultrowId_"+clinicid+"_"+counter).addClass("show");
    	var vetinfoLabel = "Save My Vet Info";
    	if($(".vetConitnueLabel").length > 0){
    		vetinfoLabel = $(".vetConitnueLabel")[0].value;
    	}
    	$.ajax({
	 		url : petcoPersonalizationJS.getVetInfoVetUrl(clinicid,counter),
	 		type : 'GET',
	 		 cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		data: '',
	 		success: function(data){
	 			var containerFOrVetClinic = $("#vetresultrowId_"+clinicid+"_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				var Items = data.items;
	 				if(typeof(Items) != "undefined"){
	 					var htmlVal = "";
	 					var updateMyVetClinicFinderEnabled = $("#updateMyVetClinicFinderEnabled");
	 					var disableVetSelectDropDown = false;
	 					if(updateMyVetClinicFinderEnabled.length > 0 && updateMyVetClinicFinderEnabled.val() == 'true'){
	 						if(Items != ""){
	 							for( var ixi in Items){
		 							if(((Items[ixi].first_name == undefined || Items[ixi].first_name == "") && (Items[ixi].last_name == undefined || Items[ixi].last_name == ""))
		 									|| (Items[ixi].first_name == 'Vetco' && Items[ixi].last_name == 'Vet' && Items[ixi].id == '999999999')){
		 								disableVetSelectDropDown =  true;
			 						}else{
			 							disableVetSelectDropDown =  false;
			 						}
		 						}
	 						}else{
	 							disableVetSelectDropDown =  true;
	 						}

	 							 					}

	 						 					if(!disableVetSelectDropDown){
	 						htmlVal = htmlVal+"<div class='row'>";
		 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div>";
		 					htmlVal = htmlVal+"<div  class='col-6 col-10-sm text-center'>";

		 							 					htmlVal = htmlVal+"<select id='vetresultselectBoxId_"+clinicid+"_"+counter+"' onchange='petcoPersonalizationJS.enableSaveVetInfoButton("+clinicid+","+counter+",this)'>";
		 					htmlVal = htmlVal+"<option value=''>Select Your Vet</option>";
		 					for( var ixi in Items){
		 						var last_name = Items[ixi].last_name.split("'").join(" ");
		 						last_name = last_name.split('"').join(" ");
		 						var first_name= Items[ixi].first_name.split("'").join(" ");
		 						first_name = first_name.split('"').join(" ");
		 						var idVet= Items[ixi].id;
		 						var phone_numberVetClinic = Items[ixi].phone_number;
		 						var fax_numberVetClinic = Items[ixi].fax_phone_number;
		 						htmlVal = htmlVal+"<option value='"+idVet+"||"+first_name+"||"+last_name+"||"+phone_numberVetClinic+"||"+fax_numberVetClinic+"'>"+first_name+" "+last_name+"</option>";
		 					}
		 					htmlVal = htmlVal+"</select>";
		 					htmlVal = htmlVal+"</div><div class='col-3 col-1-sm'>&nbsp;</div>";
		 					htmlVal = htmlVal+"</div>";
	 					}


	 						 						 					htmlVal = htmlVal+"<div class='row'>";
	 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div>";
	 					htmlVal = htmlVal+"<div  class='col-6 col-10-sm text-center'>";
	 					if(!disableVetSelectDropDown){
	 						htmlVal = htmlVal+"<a class='btn btn-primary btn-full disabled' id='RX_MyVetSaveInfo_"+clinicid+"_"+counter+"'";
	 					}else {
	 						htmlVal = htmlVal+"<a class='btn btn-primary btn-full' id='RX_MyVetSaveInfo_"+clinicid+"_"+counter+"'";
	 					}
	 					htmlVal = htmlVal+" onclick='petcoPersonalizationJS.saveVetInfoFromAPI("+clinicid+","+counter+",this);' ";
	 					htmlVal = htmlVal+" href='javascript:void(0)'>"+vetinfoLabel+"</a>";



	 						 					htmlVal = htmlVal+"</div>";
	 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div></div>";


	 						 						 				}
	 				$(containerFOrVetClinic).html(htmlVal);

	 					 			}

	 			 		},
	 		error: function(){
	 			console.log("PET INFORMATION ERROR OUT");

	 				 			var containerFOrVetClinic = $("#vetresultrowId_"+clinicid+"_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	});

    	    },enableSaveVetInfoButton:function(id,counter,obj){
    	var idForButton = "RX_MyVetSaveInfo_"+id+"_"+counter;
		var idForButtonObj = $("#"+idForButton);
		if(idForButtonObj.length > 0){
    	if(obj.value != ""){

    		    			$(idForButtonObj).removeClass("disabled");

    		    	}else{
    		$(idForButtonObj).addClass("disabled");
    	}
		}
    },
    saveVetInfoFromAPI:function(id,counter,obj){


    	    	    	if($(obj).hasClass('disabled')){
    		return;
    	}
    	var vFirstName="";
    	var vLastName="";
    	var vClinicName="";
    	var vPhoneNumber = "";
    	var vFaxNo = "";
    	var vClinicId = "";
    	var vVetId = "";
    	var selectId = "vetresultselectBoxId_"+id+"_"+counter;
    	if($("#"+selectId).length > 0){
    		var valSelect = $("#"+selectId).val();
    		vFirstName = valSelect.split("||")[1];
    		vLastName = valSelect.split("||")[2];
    		vVetId = valSelect.split("||")[0];
    		vPhoneNumber= valSelect.split("||")[3];
    		vFaxNo= valSelect.split("||")[4];
    	}else{
    		vVetId = "";
    		vFirstName = "Clinic";	
    		vLastName = "Veterinarian";
    		var vetAttribute = "vetsource";
    		if($("#wrxOnlyVetClinic_"+id+"_"+counter).val() == 'true'){
    			vetAttribute = vetAttribute+",wrx";
    		}
    		if($("#internalHospitalFilterEnabled").length >0  && $("#internalHospitalFilterEnabled").val() == 'true' && $("#internalHospital_"+id+"_"+counter).val() == 'true'){
    			vetAttribute = vetAttribute+",internal_hospital";
    		}
    		$("#rxVetDietVetAttribute_"+counter).val(vetAttribute);
    	}

    	if(vPhoneNumber == "" || vFaxNo == "" ){
    		vPhoneNumber = $("#phone_numberVetClinic_"+id+"_"+counter).val();
    		vFaxNo = $("#fax_phone_numberVetClinic_"+id+"_"+counter).val();
    	}

    	    	if($("#isUseClinicPhoneNumber").length > 0 && $("#isUseClinicPhoneNumber").val() == 'true'){
    		vPhoneNumber = $("#phone_numberVetClinic_"+id+"_"+counter).val();
    		vFaxNo = $("#fax_phone_numberVetClinic_"+id+"_"+counter).val();
    	}
    	vClinicId = $("#idVetClinic_"+id+"_"+counter).val();
    	if($("#updateMyVetClinicFinderEnabled").length > 0 && $("#updateMyVetClinicFinderEnabled").val() == 'true' && $("#adrVetClinic_"+id+"_"+counter).val().length == 0){
    		vClinicName=$("#nameVetClinic_"+id+"_"+counter).val();
    	}else{
    		vClinicName=$("#nameVetClinic_"+id+"_"+counter).val()+" Address: "+$("#adrVetClinic_"+id+"_"+counter).val();
    	}
    	vClinicName=decodeURIComponent(vClinicName);

    	    	if(vClinicName.length > 250){
    		vClinicName=vClinicName.substring(0, 250);
    	}
    	$("#rxMedicineVetName_"+counter).val(vFirstName);
    	if(vLastName != null && vLastName != 'null' && vLastName != ''){
    	$("#rxMedicineVetLastName_"+counter).val(vLastName);
    	}
    	$("#rxMedicineVetCliName_"+counter).val(vClinicName);
    	$("#rxMedicineVetPhNo_"+counter).val(vPhoneNumber);
    	if(vFaxNo != null && vFaxNo != 'null' && vFaxNo != ''){
    	$("#rxMedicineVetFxNo_"+counter).val(vFaxNo);
    	}

    	    	$("#rxMedicineVetAPIId_"+counter).val(vVetId);
    	$("#rxMedicineVetClinicId_"+counter).val(vClinicId);

    	    	if(typeof(document.savemyvetprofile)!='undefined'){
    		document.savemyvetprofile.submit();
    	}else{
    	var buttonObj = $("#RX_MyVet_"+counter+"_button");
    	$(buttonObj).removeClass("disabled");
        	var isCOntinueToSave = true;
           	if($('[id^="RX_MyPrescription_"]').length > 0){
           		isCOntinueToSave = false;
        	}
        		petcoPersonalizationJS.rxContinueToNextPage(buttonObj[0],'RX_MyVet_'+counter,'VET',counter,isCOntinueToSave);
    		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled(obj,counter);
    	}


    	    },loadVetClinicInfoForEdit:function(counter){
    	var clinicId = $("#rxMedicineVetClinicId_"+counter).val();
    	var VetClinicName = $("#rxMedicineVetCliName_"+counter).val();
    	var clinicName = VetClinicName.substring(0,VetClinicName.indexOf("Address:"));

    	$("#vetSearchResults_"+counter).html('<div class="text-center"><img src="/wcsstore/PetcoSAS/images/miniloading.gif" class="text-center clinicnamecontainer" border="0"/></div>');
    	$.ajax({
	 		url : petcoPersonalizationJS.getClicnicInfoVetEditUrl(clinicName,counter),
	 		type : 'GET',
	 		 cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		data: '',
	 		success: function(data){
    		var containerFOrVetClinic = $("#vetSearchResults_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				var Items = data.items;
	 				var htmlVal = "";
	 				if(typeof(Items) != "undefined"){
	 					for( var ixi in Items){
	 						if(Items[ixi].id ==  clinicId){
	 							var adrVetClinic = Items[ixi].address;
 		 						var countryVetClinic= Items[ixi].country;
 		 						var stateVetClinic= Items[ixi].state;
 		 						var phone_numberVetClinic= Items[ixi].phone_number;
 		 						var zip_codeVetClinic= Items[ixi].zip_code;
 		 						var cityVetClinic= Items[ixi].city;
 		 						var nameVetClinic= Items[ixi].name;
 		 						var idVetClinic= Items[ixi].id;
 		 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
 		 						htmlVal = htmlVal+"<div class='row vetclinicrow'>";
 		 						htmlVal = htmlVal+"<div class='col-2 col-2-sm'>";
 		 						htmlVal = htmlVal+"<input type='radio' checked='checked' id='selectClinNameId_"+idVetClinic+"_"+counter+"' name='selectClinName"+counter+"' onclick='petcoPersonalizationJS.selectAndOpenVetClinic("+idVetClinic+","+counter+")'/>";
 		 						htmlVal = htmlVal+"</div>";
 		 						htmlVal = htmlVal+"<div class='col-10 col-10-sm'>";
 		 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' >"+nameVetClinic+"</br>"+adrVetClinic+"</label>";
 		 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
 		 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
 		 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
 		 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
 		 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
 		 						htmlVal = htmlVal+"</div>";
 		 						htmlVal = htmlVal+"</div>";
 		 						htmlVal = htmlVal+"<div class='row vetresultrow margin-bottom-sm' id='vetresultrowId_"+idVetClinic+"_"+counter+"'>";
 		 						htmlVal = htmlVal+"<div class='text-center'><img src='"+$("#miniloadingImg").val()+"' class='text-center' border='0' /></div>";
 		 						htmlVal = htmlVal+"</div>";
 		 						$(containerFOrVetClinic).html(htmlVal);
 		 						break;
	 		 				}
	 						}

	 						 			    	if($("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").length > 0){
	 			       	$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 			    	}
	 					petcoPersonalizationJS.loadVetExpertsInfoForEdit(clinicId,counter);
	 					}else{
	 				    	$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 					}


	 					 					 			}else{
	 				$(containerFOrVetClinic).html("");
	 			}
	 			},
	 		error: function(){
	 		    	$("#vetSearchResults_"+counter+" div.text-center img.clinicnamecontainer").addClass("hide");
	 			console.log("PET INFORMATION ERROR OUT");
	 			var containerFOrVetClinic = $("#vetSearchResults_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	});

    	    },
    loadVetExpertsInfoForEdit:function(clinicid,counter){
     	var vetinfoLabel = "Save My Vet Info";
    	if($(".vetConitnueLabel").length > 0){
    		vetinfoLabel = $(".vetConitnueLabel")[0].value;
    	}
    	$.ajax({
	 		url : petcoPersonalizationJS.getVetDetailsEditUrl(clinicid),
	 		type : 'GET',
	 		 cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		data: '',
	 		success: function(data){
    		var containerFOrVetClinic = $("#vetresultrowId_"+clinicid+"_"+counter);
 			if(containerFOrVetClinic.length > 0 ){
 				var Items = data.items;
 				if(typeof(Items) != "undefined"){
 					var htmlVal = "";
 					htmlVal = htmlVal+"<div class='row'>";
 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div>";
 					htmlVal = htmlVal+"<div  class='col-6 col-10-sm text-center'>";

 					 					htmlVal = htmlVal+"<select id='vetresultselectBoxId_"+clinicid+"_"+counter+"' onchange='petcoPersonalizationJS.enableSaveVetInfoButton("+clinicid+","+counter+",this)'>";
 					htmlVal = htmlVal+"<option value=''>Select Your Vet</option>";
 					for( var ixi in Items){
 						var last_name = Items[ixi].last_name.split("'").join(" ");
 						last_name = last_name.split('"').join(" ");
 						var first_name= Items[ixi].first_name.split("'").join(" ");
 						first_name = first_name.split('"').join(" ");
 						var idVet= Items[ixi].id;
 						var phone_numberVetClinic = Items[ixi].phone_number;
 						var fax_numberVetClinic = Items[ixi].fax_phone_number;
 						htmlVal = htmlVal+"<option value='"+idVet+"||"+first_name+"||"+last_name+"||"+phone_numberVetClinic+"||"+fax_numberVetClinic+"'>"+first_name+" "+last_name+"</option>";
 					}
 					htmlVal = htmlVal+"</select>";
 					htmlVal = htmlVal+"</div><div class='col-3 col-1-sm'>&nbsp;</div>";
 					htmlVal = htmlVal+"</div>";
 					htmlVal = htmlVal+"<div class='row'>";
 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div>";
 					htmlVal = htmlVal+"<div  class='col-6 col-10-sm text-center'>";
 					htmlVal = htmlVal+"<a class='btn btn-primary btn-full disabled' id='RX_MyVetSaveInfo_"+clinicid+"_"+counter+"'";
 					htmlVal = htmlVal+" onclick='petcoPersonalizationJS.saveVetInfoFromAPI("+clinicid+","+counter+",this)' ";
 					htmlVal = htmlVal+" href='javascript:void(0)'>"+vetinfoLabel+"</a>";



 					 					htmlVal = htmlVal+"</div>";
 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div></div>";


 					 					 				}
 				$(containerFOrVetClinic).html(htmlVal);
 				var vetId = $("#rxMedicineVetAPIId_"+counter).val();
 				var firstName = $("#rxMedicineVetName_"+counter).val();
 				var lastName = $("#rxMedicineVetLastName_"+counter).val();
 				$("#vetresultselectBoxId_"+clinicid+"_"+counter).val(vetId+"||"+firstName+"||"+lastName);
 				petcoPersonalizationJS.enableSaveVetInfoButton(clinicid,counter,$("#vetresultselectBoxId_"+clinicid+"_"+counter)[0]);
 			}

    			 			},
	 		error: function(){
	 			console.log("PET INFORMATION ERROR OUT");
	 			var containerFOrVetClinic = $("#vetresultrowId_"+clinicId+"_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	});
    },
    getVetDetailsEditUrl:function(clinicId){
    	var urlVal = $("#VetInfoLookupUrl").val();
    	if(clinicId != ""){
			 urlVal = urlVal+"&clinicId="+clinicId;
		}
    	return urlVal+"&"+(Math.floor(new Date().getDate()+''+new Date().getMonth()+''+new Date().getYear()+''+(new Date().getHours()/2))); 
    },
    openRXVetEditPannel:function(counter){
    	$("#RX_MyVet_"+counter+"_searchPannel").addClass("hide");
    	$("#RX_MyVet_"+counter+"_searchPannel").removeClass("show");
    	$("#RX_MyVet_"+counter+"_editForm").addClass("show");
    	$("#RX_MyVet_"+counter+"_editForm").removeClass("hide");
    },openRXVetClinicSearchPannel:function(counter){
    	$("#RX_MyVet_"+counter+"_searchPannel").addClass("show");
    	$("#RX_MyVet_"+counter+"_searchPannel").removeClass("hide");
    	$("#RX_MyVet_"+counter+"_editForm").addClass("hide");
    	$("#RX_MyVet_"+counter+"_editForm").removeClass("show");
    },
    closeRxModal:function(){
    	try{
			var omnitureEnabled;
			if($("#omnitureEnabled").size() > 0)
				omnitureEnabled = $("#omnitureEnabled").val();
			if(omnitureEnabled != "undefined" && null != omnitureEnabled && omnitureEnabled !='' && omnitureEnabled == 'true'){
				if(typeof PetcoDataLayer.page.page_type != "undefined" && PetcoDataLayer.page.page_type == 'Product'){
					var formNotFilled = false;
					if($('div#modal-rxmedicine div.rxmedicineContent div.submit-row div.col-center.show a.btn-primary.disabled').length >0){
						formNotFilled = true;
					}
					var closeEvent = {};
					if(formNotFilled){
						var qty = 1;
						if($('#quantity_'+productDisplayJS.bopusShowStoreDetailsProductId).length > 0){
							qty = $('#quantity_'+productDisplayJS.bopusShowStoreDetailsProductId).val();
						}

												 var errors = petcoPersonalizationJS.omniatureFindMissingFieldsForRx(qty);
						if(typeof(errors["partiallyFilled"])!= 'undefined' && errors["partiallyFilled"] == 'false'){
							formNotFilled = false;
						}
						if(formNotFilled){
						if(typeof(errors["LEFTATERRORFIELD"]) != 'undefined'){
							closeEvent["Form left at"] = errors["LEFTATERRORFIELD"];
						}
						}
						if(typeof(errors["ERRORFIELDS"]) != 'undefined'){
							closeEvent["Invalid Fileds"] = errors["ERRORFIELDS"];
						}


																}

										closeEvent["Partially Filled"] = formNotFilled;

										closeEvent["event_name"] = "Close Rx Modal";
					pushEvent(closeEvent);
				}

							}				
		}catch(err){
			console.log(err);
		}
    },
    omniatureFindMissingFieldsForRx: function(quantity) {
        var jsonFieldEleContent = $("#fieldGroup").html();
        var jsonField = JSON.parse(jsonFieldEleContent);
        var errorField = "";
        var invalidFileds = {};
        var errorFileds = [];
        var totalIsrequired = 0;
        var actualfilled = 0;
        for (var counter = 1; counter <= quantity; counter++) {
            for (var i in jsonField) {
                var isValidForSubmit = true;
                var idOfField = $('[id^="' + jsonField[i].Field + "_" + counter + '"]');
                var isFiledRequired = jsonField[i].IsRequired;
                var fieldGroup = jsonField[i].FieldGroup + "_" + counter;
                if (idOfField.length > 0) {
                    var isNumric = false;
                    var isTeliphonic = false;
                    var isOptionOthersExists = false;
                    var isMultiOtherOptionsExists = false;
                    var isDateField = false;
                    var isRadio = false;
                    if (idOfField[0].className.indexOf("NUMERIC") > -1) {
                        isNumric = true;
                    } else if (idOfField[0].className.indexOf("TELEPHONE") > -1) {
                        isTeliphonic = true;
                    } else if (idOfField[0].className.indexOf("MULTICHECKBOX") > -1) {
                        isMultiOtherOptionsExists = true;
                    } else if (idOfField[0].className.indexOf("CHECKBOX") > -1) {
                        isOptionOthersExists = true;
                    } else if (idOfField[0].className.indexOf("DATE") > -1) {
                        isDateField = true;
                    } else if (idOfField[0].className.indexOf("RADIO") > -1) {
                        isRadio = true;
                    }

                                     if (isFiledRequired == 'Y') {
                    	if(!isMultiOtherOptionsExists && !isOptionOthersExists){
                    		totalIsrequired++;
                    	}
                        if (!isOptionOthersExists && !isDateField && !isRadio && !isMultiOtherOptionsExists && idOfField[0].value.trim() == "") {
                            isValidForSubmit = false;
                            actualfilled++;
                        } else if (isRadio) {
                            var checkedRadio = $('input[type="radio"][name="' + jsonField[i].Field + "_" + counter + '"]:checked');
                            if (checkedRadio.length == 0) {
                                isValidForSubmit = false;
                                actualfilled++;
                            }
                        } else if (isOptionOthersExists) {
                            var checkedRadioOthers = $("#" + jsonField[i].Field + "_" + counter + ":checked");
                            if (checkedRadioOthers.length > 0) {
                                var isBlank = true;
                                $.each($('[id^="' + jsonField[i].Field + '_' + counter + '_optionContainer"]'), function(index, obj) {
                                    if (obj.value.trim() != "") {
                                        isBlank = false;
                                    }
                                })
                                if (isBlank && $("#" + jsonField[i].Field + '_' + counter + "_Others").val().trim() == "") {
                                    isValidForSubmit = false;

                                                                    }
                            }
                        } else if (isMultiOtherOptionsExists) {
                            var checkedRadioOthers = $("#" + jsonField[i].Field + "_" + counter + ":checked");
                            if (checkedRadioOthers.length > 0 && $('[id^="' + jsonField[i].Field + '_' + counter + '-_options"]:checked').length == 0) {
                                if ($("#" + jsonField[i].Field + '_' + counter + "_Others").val().trim() == "") {
                                    isValidForSubmit = false;
                                }
                            }
                        } else if (isDateField) {
                            if ($("#" + jsonField[i].Field + "_" + counter + "_MON").length > 0 && $("#" + jsonField[i].Field + "_" + counter + "_MON").val() != "" &&
                                $("#" + jsonField[i].Field + "_" + counter + "_Date").length > 0 && $("#" + jsonField[i].Field + "_" + counter + "_Date").val() != "" &&
                                $("#" + jsonField[i].Field + "_" + counter + "_Year").length > 0 && $("#" + jsonField[i].Field + "_" + counter + "_Year").val() != ""
                            ) {
                                var date = $("#" + jsonField[i].Field + "_" + counter + "_Date").val();
                                var month = $("#" + jsonField[i].Field + "_" + counter + "_MON").val();
                                var year = $("#" + jsonField[i].Field + "_" + counter + "_Year").val();
                                var datetoValidate = new Date();
                                datetoValidate.setFullYear(year, (month - 1), date);
                                if (datetoValidate.getDate() != date && datetoValidate.getMonth() != (month - 1)) {
                                    isValidForSubmit = false;
                                }
                            } else {
                                isValidForSubmit = false;
                                actualfilled++;
                            }
                        }
                    }
                    if ((isTeliphonic || isNumric) && idOfField[0].value.trim() != "" && isNaN(idOfField[0].value.trim())) {
                        isValidForSubmit = false;
                    } else if (isTeliphonic && idOfField[0].value.trim() != "" && (idOfField[0].value.trim()).length < 10) {
                        isValidForSubmit = false;
                    }
                }
                if (!isValidForSubmit) {
                    if (errorField == "") {
                        errorField = jsonField[i].Label;
                    }
                    errorFileds.push(jsonField[i].Label + "_" + counter);
                }
            }
        }
        if (errorField != "") {
            invalidFileds["LEFTATERRORFIELD"] = errorField;
        }
        if (errorFileds.length > 0) {
            invalidFileds["ERRORFIELDS"] = errorFileds.join(",");
        }
        if(totalIsrequired*1 == actualfilled*1){
        	invalidFileds["partiallyFilled"] = "false";
        }
        return invalidFileds;
    },
    loadAutoCOmpleteForBreed : function(objMain,selctObj){
	    if(typeof(objMain) =='undefined' || objMain == null){
	    	return;
	    }
	    var counterVale = objMain.id.replace("autoSuggestLoader_","");
	    var conatinerValue = "rxMedicinePetBreed_list_"+counterVale;
    	var breadValue = objMain.value.trim();
    	$('#'+selctObj+' option').each(function(i,objectOpt){
			if($(objectOpt).attr("value") == ''){
				$(objectOpt).addClass("optionHideAutoComplete");
			}else{
				$(objectOpt).removeClass("optionHideAutoComplete");
			}
		});
    	if( breadValue != ""){
    		var isOptionsAvailable = false;
    		$('#'+selctObj+' option').each(function(i,objectOpt){
    			if(($(objectOpt).attr("value").toUpperCase()).indexOf(breadValue.toUpperCase()) == -1 ){
    				$(objectOpt).addClass("optionHideAutoComplete");
    			}else{
    				isOptionsAvailable = true;
    				$(objectOpt).removeClass("optionHideAutoComplete");
    			}
    		})
    		if(typeof(event) != "undefined" && event != null && event.keyCode == 40 && isOptionsAvailable){
        		$("#"+selctObj)[0].focus();
        		$("#"+selctObj+" option")[0].focus();
        	}


    		    		    	}else{

    		    		if(breadValue == ""){
        		$('#'+conatinerValue).height(50);
        	}
    		$("#"+selctObj).val("");


    			    	}
    	$('#'+conatinerValue)[0].style.maxHeight= "80px";
    	$('#'+conatinerValue)[0].style.overflowY="auto";
    	$('#'+conatinerValue).html("");
    	if($('#'+selctObj+' option.optionHideAutoComplete').length == $('#'+selctObj+' option').length && breadValue != ""){
    		$(objMain).addClass("error");
    		$('#'+conatinerValue).height(20);
    		$('#'+conatinerValue).append($('<div class="optionAutoCompleteNoResults">No matches found</div>'));
    	}else{
    		$('#'+conatinerValue).height(($('#'+selctObj+' option').length - $('#'+selctObj+' option.optionHideAutoComplete').length)*20);
    		$(objMain).removeClass("error");
    		$('#'+conatinerValue+' div.optionAutoCompleteNoResults').remove();
    	}


    	    			$('#'+selctObj+' option').each(function(i,objectOpt){
		if(objectOpt.style.display != 'none' && !$(objectOpt).hasClass("optionHideAutoComplete")){
		$('#'+conatinerValue).append($('<div class="selectListItem" onclick="petcoPersonalizationJS.selectValueFromList(\''+objectOpt.value.split('"').join('<<DOUBLEQUOTS>>').split('\'').join('<<QUOTS>>')+'\',\''+objMain.id+'\',\''+selctObj+'\',\''+conatinerValue+'\'), petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled(this,\''+counterVale+'\');">'+objectOpt.value+'</div>'));		}})
    },

        selectValueFromList:function(val,autoboxID,slectBoxID,containerVal){
    	val = val.split('<<DOUBLEQUOTS>>').join('"').split('<<QUOTS>>').join("\'")
    	$("#"+slectBoxID).val(val);
    	$("#"+autoboxID).val(val);
    	$('#'+containerVal).html("");
    	$('#'+containerVal).height(0);
    },
    addValueForBreedField:function(obj,autosuggest){
    	if(obj.value != ""){
    		if($('#'+obj.id+' option[value="'+obj.value+'"]').length > 0){
    			$("#"+autosuggest).val(obj.value);
    		}
    		obj.style.display="none";
    		var counterVale = autosuggest.replace("autoSuggestLoader_","");
    	    var conatinerValue = "rxMedicinePetBreed_list_"+counterVale;
        	$('#'+conatinerValue).html("");
        	$('#'+conatinerValue).height(0);
    	}else{
    		$("#"+autosuggest).val("");
    		if($('#'+obj.id+' option').length < 2){
    			obj.style.display="none";
    		}else{
    			obj.style.display="block";
    		}
    	}
    },
    openAutoCOmpleteForBreed:function(obj,breed){
    	$('#'+breed+' option[value=""]').css("display","none");
    	this.loadAutoCOmpleteForBreed(obj,breed);
    },
    selectBreedValueForBreedField:function(obj,autosuggest){
    	if(typeof(event) != 'undefined' && event != null && event.keyCode == 13){
    		obj.style.display = 'none';
    	}

    	    },



   saveVetInfoFromVetServiceResponse:function(selectedVetObject, counter){

    	if(selectedVetObject != 'undefined' && selectedVetObject!=null ){
    		var vFirstName = selectedVetObject.first_name;
    		var vLastName = selectedVetObject.last_name;
    		var vVetId = selectedVetObject.id;
    		var vPhoneNumber = selectedVetObject.phone_number;
    		var vFaxNo = selectedVetObject.fax_phone_number;
    		var vClinicId = selectedVetObject.clinic.id;
    		var vClinicName = selectedVetObject.clinic.name + " Address: " + selectedVetObject.clinic.street_address;

    	    		vClinicName=decodeURIComponent(vClinicName);

    		    		if(vClinicName.length > 250){
        		vClinicName=vClinicName.substring(0, 250);
        	}

        	if(vPhoneNumber == "" || vFaxNo == "" ){
        		vPhoneNumber = selectedVetObject.clinic.phone_number;
        		vFaxNo = selectedVetObject.clinic.fax_number;
        	}

    		        	if($("#isUseClinicPhoneNumber").length > 0 && $("#isUseClinicPhoneNumber").val() == 'true'){
        		vPhoneNumber = selectedVetObject.clinic.phone_number;
        		vFaxNo = selectedVetObject.clinic.fax_number;
        	}

                    	if(vFirstName != null && vFirstName != 'null' && vFirstName != ''){
        		$("#rxMedicineVetName_"+counter).val(vFirstName);	
        	}
        	if(vLastName != null && vLastName != 'null' && vLastName != ''){
        	    $("#rxMedicineVetLastName_"+counter).val(vLastName);
        	}
        	if(vClinicName != null && vClinicName != 'null' && vClinicName != ''){
        		$("#rxMedicineVetCliName_"+counter).val(vClinicName);
        	}
        	if(vPhoneNumber != null && vPhoneNumber != 'null' && vPhoneNumber != ''){
        		$("#rxMedicineVetPhNo_"+counter).val(vPhoneNumber);
        	}
        	if(vFaxNo != null && vFaxNo != 'null' && vFaxNo != ''){
        	    $("#rxMedicineVetFxNo_"+counter).val(vFaxNo);
        	}
        	if(vVetId != null && vVetId != 'null' && vVetId != ''){
        		$("#rxMedicineVetAPIId_"+counter).val(vVetId);
        	}
            if(vClinicId != null && vClinicId != 'null' && vClinicId != ''){
            	$("#rxMedicineVetClinicId_"+counter).val(vClinicId);
        	}   	

                		var editDivId = "#RX_MyVet_" + counter + "_edit";
        	var reviewDivId = "#RX_MyVet_" + counter +"_review";
        	var editLink = "#RX_MyVet_" + counter +"_editLink";

        	        	$(editDivId).addClass("hide");
        	$(editDivId).removeClass("show");
        	$(reviewDivId).removeClass("hide");
        	$(reviewDivId).addClass("show");
        	$(editLink).removeClass("hide");
        	$(editLink).addClass("show");

        	        	$(reviewDivId).html("");    		
    		var VetName = $("#rxMedicineVetName_"+counter).val();
    		var VetLastName = $("#rxMedicineVetLastName_"+counter).val();
    		var VetPhone = $("#rxMedicineVetPhNo_"+counter).val();
    		var VetClinicName =  $("#rxMedicineVetCliName_"+counter).val();
    		var clinicName = VetClinicName.substring(0,VetClinicName.indexOf("Address:"));
    		var clinicId = $("#rxMedicineVetClinicId_"+counter).val();
    		var vetId = $("#rxMedicineVetAPIId_"+counter).val();

    		    		var htmlvet = "<p class='spacer-lg-left spacer-sm-top'><strong>Veterinarian Name: </strong>"+VetName+" "+VetLastName+"</p>"
    		htmlvet = htmlvet+ "<p class='spacer-lg-left'><strong>Clinic Name: </strong>"+VetClinicName+"</p>"
    		 htmlvet = htmlvet+ "<p class='spacer-lg-left spacer-xsm-bottom'><strong>Phone Number: </strong>"+VetPhone+"</p>"
    		 $(reviewDivId).html(htmlvet);

    		       		$("#submitArea_"+counter).addClass("show");
    		$("#submitArea_"+counter).removeClass("hide");

        	        	var pdpAddtoCartButton = $('[id^="rxMedicinePersonalizationSubmit_PDP_'+counter+'"]');
        	var pdpAddToWishListButton = $('[id^="rxMedicinePersonalizationSubmit_WishList_'+counter+'"]');
        	var pdpEditInCartButton = $('[id^="rxMedicinePersonalizationSubmit_Cart_'+counter+'"]');

        	$(pdpAddtoCartButton).removeClass("disabled");
        	$(pdpAddToWishListButton).removeClass("disabled");
        	$(pdpEditInCartButton).removeClass("disabled");

        	        	if($('[id^="rxMedicineVetDetailsSubmit_SPC"]').length > 0){
            	var spcSubtmitVetButton = $('[id^="rxMedicineVetDetailsSubmit_SPC"]');
            	$(spcSubtmitVetButton).removeClass("disabled");
            	$(spcSubtmitVetButton).addClass("show");
        		$(spcSubtmitVetButton).removeClass("hide");
            }

                		    	}
    	else{
    		console.log("Response from vet service is null or undefined");
    	}    	
    },

    	editVetInfoFromVetServiceResponse:function(counter){

		   	   	var editDivId = "#RX_MyVet_" + counter + "_edit";
    	var reviewDivId =  "#RX_MyVet_" + counter + "_review";
    	var editLink = "#RX_MyVet_" + counter + "_editLink";

    	    	$(editDivId).removeClass("hide");
    	$(editDivId).addClass("show");

    	    	$(reviewDivId).addClass("hide");
    	$(reviewDivId).removeClass("show");

    	    	$(editLink).addClass("hide");
    	$(editLink).removeClass("show");

    	    	$("#submitArea_"+counter).removeClass("show");
		$("#submitArea_"+counter).addClass("hide");

			},

		editVetClinicInfoFromVetServiceResponse:function(counter){

		   	   	var editDivId = "#RX_MyVetClinic_" + counter + "_edit";
    	var reviewDivId =  "#RX_MyVetClinic_" + counter + "_review";
    	var editLink = "#RX_MyVetClinic_" + counter + "_editLink";

    	    	$(editDivId).removeClass("hide");
    	$(editDivId).addClass("show");

    	    	$(reviewDivId).addClass("hide");
    	$(reviewDivId).removeClass("show");

    	    	$(editLink).addClass("hide");
    	$(editLink).removeClass("show");	
	},

	updateRXMedModalOnPDPQtyChange:function(SIGN_IN_URL, ltlorRxflag, catentryId, quantity, fromPage, persAttr, maxUserInputQuantity){

		     	var loadRxPersonilaztionPageWidgetUrl = $("#loadRxPersonilaztionPageWidgetUrl").val();
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&ltlorRxflag="+ ltlorRxflag;
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&catentryId="+ catentryId;
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&quantity="+ quantity;
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&fromPage="+ fromPage;
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&persAttr=" + persAttr;
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&maxUserInputQuantity="+ maxUserInputQuantity;
     	loadRxPersonilaztionPageWidgetUrl = loadRxPersonilaztionPageWidgetUrl + "&SIGN_IN_URL=" + SIGN_IN_URL;

		     	var loadRxPersonilaztionPageWidgetTimeout = $("#loadRxPersonilaztionPageWidgetTimeout").val();
		var isModalPopulated = false;
		if($("#modal-rxmedicine.in").length > 0){
			isModalPopulated = true;
		}
		$.ajax({
	 		url : loadRxPersonilaztionPageWidgetUrl,
	 		type : 'GET',
	 		cache: false,
	 		timeout: loadRxPersonilaztionPageWidgetTimeout,
	 		data:'',
	 		success: function(responseDetails){  
	 			$("#rxPersonilaztionDisplay").html(responseDetails);
	 			petcoPersonalizationJS.petandvetInfoPulated = true;
	 			if(isModalPopulated){
	 				productDisplayJS.showAddToCartSubmit();
	 			}
	 		},
	 		error: function(){		 			
	 			console.log("updateRXMedModalOnPDPQtyChange FAILURE");	 							    	
	 		}
	 	}); 

			},


		validatePersonalizationPageSubmit:function () 
	{
		var validPhoneNumberFlag = 'true';
		var numeric = new RegExp(/^[0-9]+$/);

				if ( document.getElementById("petcoPersPhNumber") != "undefined" && null != document.getElementById("petcoPersPhNumber") ) {
			var phone_field =  document.getElementById( document.getElementById("petcoPersPhNumber").value).value;
			var phoneError_div = "persFieldError_"+document.getElementById("petcoPersPhNumber").value;
			if(phone_field.length < 10) {
			    validPhoneNumberFlag = 'false';
				document.getElementById(phoneError_div).innerHTML='<span class="error">'+ MessageHelper.messages["REGISTRATION_PHONE_ERR_BELOW_10_CHAR"] + '</span>';
			} else if ( phone_field.length > 0 && (phone_field[0] == '0' || phone_field[0] == '1' || phone_field[0] == '+') ) {
			    validPhoneNumberFlag = 'false';
				document.getElementById(phoneError_div).innerHTML='<span class="error">' + MessageHelper.messages["REGISTRATION_PHONE_ERR_NOPREFIX_1"] + '</span>';
			} else if (!numeric.test(phone_field) ) {
			    validPhoneNumberFlag = 'false';
				document.getElementById(phoneError_div).innerHTML= '<span class="error">'+ MessageHelper.messages["BOPUS_PICKUP_NUMERIC"] + '</span>';
			} else {
			    document.getElementById("helpBlkId").style="display:none";
			    document.getElementById(phoneError_div).innerHTML="";
			}
			if( validPhoneNumberFlag ) {
				document.getElementById(phoneError_div).style="display:inline";
				document.getElementById("helpBlkId").style="display:none";
			}
		} else if ( document.getElementById("petcoVetsPhNumber") != "undefined" && null != document.getElementById("petcoVetsPhNumber") ) {
			var phone_field =  document.getElementById( document.getElementById("petcoVetsPhNumber").value).value;
			var phoneError_div = "persFieldError_"+document.getElementById("petcoVetsPhNumber").value;
			if(phone_field.length < 10) {
			    validPhoneNumberFlag = 'false';
				document.getElementById(phoneError_div).innerHTML='<span class="error">'+ MessageHelper.messages["REGISTRATION_PHONE_ERR_BELOW_10_CHAR"] + '</span>';
			} else if ( phone_field.length > 0 && (phone_field[0] == '0' || phone_field[0] == '1' || phone_field[0] == '+') ) {
			    validPhoneNumberFlag = 'false';
				document.getElementById(phoneError_div).innerHTML='<span class="error">' + MessageHelper.messages["REGISTRATION_PHONE_ERR_NOPREFIX_1"] + '</span>';

							} else if (!numeric.test(phone_field) ) {
			    validPhoneNumberFlag = 'false';
				document.getElementById(phoneError_div).innerHTML= '<span class="error">'+ MessageHelper.messages["BOPUS_PICKUP_NUMERIC"] + '</span>';
			} else {
			    document.getElementById("helpBlkVetPhId").style="display:none";
			    document.getElementById(phoneError_div).innerHTML="";
			}
			if( validPhoneNumberFlag ) {
				document.getElementById(phoneError_div).style="display:inline";
				document.getElementById("helpBlkVetPhId").style="display:none";
			}

					}

				return validPhoneNumberFlag;
	},

		removeRXQtyRestrictionAttr:function(){		

					if($("[id^=rxMedQtyError_ordItem_]").length <= 0)
				return false;

			var removeRXMedQtyRestrictionAttrURL = $("#PetcoRemoveRXMedQtyRestrictionAttrCmdURL").val();

									var orderId = $("#currentOrderId").val();
			if(orderId){
				removeRXMedQtyRestrictionAttrURL = removeRXMedQtyRestrictionAttrURL + "&orderId="+ orderId;
			}
			var counter = 1;
			$("[id^=rxMedQtyError_ordItem_]").each(function() {   
				removeRXMedQtyRestrictionAttrURL = removeRXMedQtyRestrictionAttrURL + "&orderItemId_" + counter + "="+ $(this).attr('value');
				counter++;
			});

				$.ajax({
		 		url : removeRXMedQtyRestrictionAttrURL,
		 		type : 'POST',
		 		cache: false,
		 		timeout: '3000',
		 		contentType: 'application/json',
		 		dataType: 'String',	
		 		data : '',
		 		success: function(data){
		 			console.log("Restricted qty Attribute attribute removed successfully");
		 		},
		 		error: function(){		 			
		 			console.log("Restricted qty Attribute attribute removed failure");	 							    	
		 		}
		    });

			},



		rxMedShowSeparateItems : function(orderItemId){

				$("[id^=RxMedItem_ItemBlock_" + orderItemId + "_]").each(function(index, element) {   
			$(element).removeClass("hide");
			$(element).addClass("show");
		});
		var catentryId = $("#catentry_"+orderItemId).val();
		var params = {};
		params.orderItemId	= orderItemId;
		params.orderId	= $("#orderId").val();
		params.action	= "splitOrderItems";
		params.catEntryId_1	= catentryId;
		var approvalContainer = $("#rxMedApprovalMethodSelection_"+orderItemId+"_1");
		params.channel	= approvalContainer.val();
		cursor_wait();
		wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
	},

		resetPetInfoDropdown: function() {
		var orderItemId = $("#selectedOrderItemForEdit").val();
		var selectedPetId = $('#selectedPet_'+orderItemId).val();
		$.each($('#rxMedPetDataSelection_'+orderItemId+'_1 option'),function(o,obj){
			if($(obj).html().trim() == selectedPetId){
				$(obj)[0].selected = true;	
				$('#rxMedVetDataSelection_'+orderItemId+'_1').attr('disabled', false);
			}else{
				$('#'+petcoPersonalizationJS.petInfoDropdownId).val('');
				petcoPersonalizationJS.petInfoDropdownId = '';
			}
			if ($('#rxMedEditPetInfo_' + orderItemId)){
                $('#rxMedEditPetInfo_' + orderItemId).removeClass('hide');
            }
		})
		petcoPersonalizationJS.resetMandatoryFieldWarnings();
		CheckoutHelperJS.disablePlaceOrderButton();

	},

		resetVetInfoDropdown: function() {
		var orderItemId = $("#selectedOrderItemForEdit").val();
		var selectedVetId = $('#selectedVet_'+orderItemId).val();
		$.each($('#rxMedVetDataSelection_'+orderItemId+'_1 option'),function(o,obj){
			if($(obj).html().trim() == selectedVetId){
				$(obj)[0].selected = true;	


											}else{
				$('#'+petcoPersonalizationJS.vetInfoDropdownId).val('');
				petcoPersonalizationJS.vetInfoDropdownId = '';
			}
            if ($('#rxMedEditVetInfo_' + orderItemId)){
                $('#rxMedEditVetInfo_' + orderItemId).removeClass('hide');                                   
            }
            if ($('#rxVetDietEditVetInfo_' + orderItemId)){
				$('#rxVetDietEditVetInfo_' + orderItemId).removeClass('hide');									
			}
		})
				CheckoutHelperJS.disablePlaceOrderButton();
	},

		resetVetClinicInfoDropdown:function(){
		var orderItemId = $("#selectedOrderItemForEdit").val();
		var selectedVetId = $('#selectedVet_'+orderItemId).val();
		$.each($('#rxMedVetDataSelection_'+orderItemId+'_1 option'),function(o,obj){
			if($(obj).html().trim() == selectedVetId){
				$(obj)[0].selected = true;		
			}
            if ($('#rxVetDietEditVetInfo_' + orderItemId)){
				$('#rxVetDietEditVetInfo_' + orderItemId).removeClass('hide');									
			}
		})
				CheckoutHelperJS.disablePlaceOrderButton();
	},


		rxMedSPCChangePet : function(id, value){
		$("#modal-rxmedicineAddpet h3").text("Add a Pet");
		$('#rxMedicinePetDetailsSubmit_SPC').text('Submit');

			this.clearAndRenewRxSPC(1,'PET');
		var identifier = id.split("_");
		var orderItemId = identifier[identifier.length - 2];
		var loopIndex = identifier[identifier.length - 1];
		var catentryId = $("#catentry_"+orderItemId).val();
		var vetDataContainer = $('#rxMedVetDataSelection_' + orderItemId + '_' + loopIndex);
		var isPetWeightAlertMsgEnable =$('#isPetWeightAlertMsgEnable');
		if(value == 'Add a New Pet'){
			console.log("Add a new Pet");
			$('#'+id).removeClass('selected');
			vetDataContainer.attr('disabled', true);
			$("#selectedOrderItemForEdit").val(orderItemId);
			petcoCommonJS.showModal('modal-rxmedicineAddpet'); 
			petcoPersonalizationJS.petInfoDropdownId = id;
			$("#rxMedicinePetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxMedSubmitPetFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");
		}
		if (value == '') {
			if ($('#rxMedEditPetInfo_' + orderItemId)){
				$('#rxMedEditPetInfo_' + orderItemId).addClass('hide');
			}
			if ($('#rxMedEditVetInfo_' + orderItemId)){
				$('#rxMedEditVetInfo_' + orderItemId).addClass('hide');									
			}
			if ($('#rxVetDietEditVetInfo_' + orderItemId)){
				$('#rxVetDietEditVetInfo_' + orderItemId).addClass('hide');									
			}
		}
		if (value == '' || value == 'Add a New Pet' ){
				$('#'+id).removeClass('selected');
				vetDataContainer.attr('disabled', true);
			CheckoutHelperJS.disablePlaceOrderButton();
		} else{
				if ($('#rxMedEditPetInfo_' + orderItemId)){
					$('#rxMedEditPetInfo_' + orderItemId).removeClass('hide');
				}
				if ($('#rxMedEditVetInfo_' + orderItemId)){
					$('#rxMedEditVetInfo_' + orderItemId).removeClass('hide');									
				}
				if ($('#rxVetDietEditVetInfo_' + orderItemId)){
					$('#rxVetDietEditVetInfo_' + orderItemId).removeClass('hide');									
				}
				var params = {};
				params.orderItemId	= orderItemId;
				params.orderId	= $("#orderId").val();
				params.action	= "updatePetDetails";
				params.catEntryId_1	= catentryId;
				params.petId	= value;
				var modalname='attachmentMessage_'+orderItemId;
				if(isPetWeightAlertMsgEnable && isPetWeightAlertMsgEnable.val()=='true')
				$('#petChangemodel').val(modalname);
				var approvalContainer = $("#rxMedApprovalMethodSelection_"+orderItemId+"_1");
				params.channel	= approvalContainer.val();
				cursor_wait();
				wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
				console.log("Selected Existing Pet");
				$('#'+id).addClass('selected');
				vetDataContainer.removeAttr('disabled');
		}		
	},

	    rxMedSPCChangeVet : function(id, value,isItemRxFood){
    	$("#modal-rxmedicineAddvet h3").text("Add a Vet");
    	this.clearAndRenewRxSPC(1,'VET');
    	var identifier = id.split("_");
		var orderItemId = identifier[identifier.length - 2];
		var catentryId = $("#catentry_"+orderItemId).val();
		petcoPersonalizationJS.isRxFood = isItemRxFood;
		if(value == 'Add a New Vet'){
			console.log("Add a new Vet");
			$('#'+id).removeClass('selected');
			$("#selectedOrderItemForEdit").val(orderItemId);
			petcoPersonalizationJS.vetInfoDropdownId = id;
			petcoCommonJS.showModal('modal-rxmedicineAddvet');
			petcoPersonalizationJS.editVetInfoFromVetServiceResponse(1);
	    	$("#rxMedicineVetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxMedSubmitVetFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");

		}
		if(value == 'Add a New Vet Clinic'){
			console.log("Add a new Vet Clinic");
			$("#modal-rxVetDietAddvet h3").text("Veterinary Clinic Search");
			$('#'+id).removeClass('selected');
			$("#selectedOrderItemForEdit").val(orderItemId);
			petcoPersonalizationJS.vetInfoDropdownId = id;
			petcoCommonJS.showModal('modal-rxVetDietAddvet');
			petcoPersonalizationJS.editVetClinicInfoFromVetServiceResponse(1);
	    	$("#rxVetDietVetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxVetDietVetSubmitFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");
		}
		if (value == '') {
			if ($('#rxMedEditVetInfo_' + orderItemId)){
				$('#rxMedEditVetInfo_' + orderItemId).addClass('hide');									
			}
			if ($('#rxVetDietEditVetInfo_' + orderItemId)){
				$('#rxVetDietEditVetInfo_' + orderItemId).addClass('hide');									
			}
		}
		if (value == '' || value == 'Add a New Vet' || value == 'Add a New Vet Clinic') {
			$('#' + id).removeClass('selected');
			CheckoutHelperJS.disablePlaceOrderButton();
		} else {
			if ($('#rxMedEditVetInfo_' + orderItemId)){
				$('#rxMedEditVetInfo_' + orderItemId).removeClass('hide');									
			}
			if ($('#rxVetDietEditVetInfo_' + orderItemId)){
				$('#rxVetDietEditVetInfo_' + orderItemId).removeClass('hide');									
			}
			var params = {};
			params.orderItemId	= orderItemId;
			params.orderId	= $("#orderId").val();
			params.action	= "updateVetDetails";
			params.catEntryId_1	= catentryId;
			params.vetId	= value;
			cursor_wait();
			var approvalContainer = $("#rxMedApprovalMethodSelection_"+orderItemId+"_1");
			if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true'){
				if($("#isClinicWRX_"+value).length >0 && $("#isClinicWRX_"+value).val() == 'true'){
					params.channel	= "mail";
				}else{
					params.channel	= "online";
				}
			}else{
				params.channel	= approvalContainer.val();
			}
			wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
			console.log("Selected Existing Vet");
			$('#'+id).addClass('selected');
		}		
	},

		rxMedSubmitPetFormSPC:function (obj,ltlorRxflag,orderItemId,count){

				if($("#rxMedicinePetDetailsSubmit_SPC").hasClass("disabled")){
			return;
		}
		var modalname='attachmentMessage_'+orderItemId;
		var isPetWeightAlertMsgEnable =$('#isPetWeightAlertMsgEnable');
		if(isPetWeightAlertMsgEnable && isPetWeightAlertMsgEnable.val()=='true')
		$('#petChangemodel').val(modalname);
		var catentryId = $("#catentry_"+orderItemId).val();
		var quantity = $("#displayQty_"+orderItemId).val();
		var params = {};
		params.orderItemId	= orderItemId;
		params.orderId	= $("#orderId").val();
		var approvalContainer = $("#rxMedApprovalMethodSelection_"+orderItemId+"_1");
		params.channel	= approvalContainer.val();
		params = this.addRxMedicinePersonalizationAttr(catentryId,quantity,params);
		cursor_wait();
		wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
	},
	rxMedSubmitVetFormSPC:function (obj,ltlorRxflag,orderItemId,count){

				var catentryId = $("#catentry_"+orderItemId).val();
		var quantity = $("#displayQty_"+orderItemId).val();

				var params = {};
		params.catEntryId_1	= catentryId;
		params.orderItemId	= orderItemId;
		params.orderId	= $("#orderId").val();
		params.rxMedicineVetName = $("#rxMedicineVetName_1").val();
		params.rxMedicineVetLastName = $("#rxMedicineVetLastName_1").val();
		params.rxMedicineVetCliName = $("#rxMedicineVetCliName_1").val();
		params.rxMedicineVetPhNo = $("#rxMedicineVetPhNo_1").val();
		params.rxMedicineVetFxNo = $("#rxMedicineVetFxNo_1").val();
		params.rxMedicineVetAPIId = $("#rxMedicineVetAPIId_1").val();
		params.rxMedicineVetClinicId = $("#rxMedicineVetClinicId_1").val();
		params.storeId = $("#storeId").val();
		params.catalogId = $("#catalogId").val();
		params.langId = $("#langId").val();
		params.isUpdateVetDetails = "true";
		cursor_wait();
		var approvalContainer = $("#rxMedApprovalMethodSelection_"+orderItemId+"_1");
		params.channel	= approvalContainer.val();
		wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
	},

		rxVetDietVetSubmitFormSPC: function(obj,ltlorRxflag,orderItemId,count){

				var catentryId = $("#catentry_"+orderItemId).val();
		var quantity = $("#displayQty_"+orderItemId).val();

				var params = {};
		params.catEntryId_1	= catentryId;
		params.orderItemId	= orderItemId;
		params.orderId	= $("#orderId").val();
		params.rxMedicineVetName = $("#rxVetDietVetName_1").val();
		params.rxMedicineVetLastName = $("#rxVetDietVetLastName_1").val();
		params.rxMedicineVetCliName = $("#rxVetDietVetCliName_1").val();
		params.rxMedicineVetPhNo = $("#rxVetDietVetPhNo_1").val();
		params.rxMedicineVetFxNo = $("#rxVetDietVetFxNo_1").val();
		params.rxMedicineVetAPIId = $("#rxVetDietVetAPIId_1").val();
		params.rxMedicineVetClinicId = $("#rxVetDietVetClinicId_1").val();
		params.rxVetAttribute = $("#rxVetDietVetAttribute_1").val();
		params.storeId = $("#storeId").val();
		params.catalogId = $("#catalogId").val();
		params.langId = $("#langId").val();
		params.isUpdateVetDetails = "true";
		cursor_wait();
		var approvalContainer = $("#rxMedApprovalMethodSelection_"+orderItemId+"_1");
		if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true'){
			if($("#rxVetDietVetAttribute_1").length >0 && $("#rxVetDietVetAttribute_1").val() == 'vetsource,wrx'){
				params.channel	= "mail";
			}else{
				params.channel	= "online";
			}
		}else{
			params.channel	= approvalContainer.val();
		}
		wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
	},


			rxMedEditPetInfo:function (orderItemId,petId){
		console.log("Edit a new Pet");
		$("#modal-rxmedicineAddpet h3").text("Edit Pet");
		$('#rxMedicinePetDetailsSubmit_SPC').text('Save');
		$('#rxmedicineAddpetClose').removeClass('hide');
		petcoCommonJS.showModal('modal-rxmedicineAddpet');
		petcoPersonalizationJS.reAssignRxPersonalizedAttr(petcoPersonalizationJS.findPersonalizedAttrForShoppingCart(1,orderItemId));
		$("#rxMedicinePetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxMedSubmitPetFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");
		petcoPersonalizationJS.loadPetBreedValues("rxMedicinePetType_1","rxMedicinePetBreed_1",$("#modal-rxmedicineAddpet #autoSuggestLoader_1").val(),$("#modal-rxmedicineAddpet #rxMedicinePetType_1").val().toUpperCase());

	},
	isRxMedicineItemSPCEnabled : function (){
		var isRxMedicineItemSPCEnabled = false;
		if($("#isRxSPCModalEnabled").length >0 && $("#isRxSPCModalEnabled").val() == 'true'){
			var isRxThrottleEnabled = $("#isRxThrottleEnabled").val();
			var rxPetVetSource = localStorage.getItem('rxPetVetSource');
			if(isRxThrottleEnabled == 'true'){
				if('pdp' != rxPetVetSource ){
					isRxMedicineItemSPCEnabled = true;
					return isRxMedicineItemSPCEnabled;
				}else{
					isRxMedicineItemSPCEnabled = false;
					return isRxMedicineItemSPCEnabled;
				}
			}else{
				isRxMedicineItemSPCEnabled = true;
				return isRxMedicineItemSPCEnabled;
			}
		}
		return isRxMedicineItemSPCEnabled;
	},
	clearAndRenewRxSPC:function(quanitity,type){

    	    	if(type == "PET"){
    		$("#petinfoaddnewpet_"+quanitity).addClass("hide");
    		$("#rxMedicinePetName_"+quanitity).removeClass("hide");
    		$("#rxMedicinePetName_"+quanitity).val("");
    		$("#savedPetData_"+quanitity).addClass("hide");
    		$("#rxMedicinePetType_"+quanitity).val(petcoPersonalizationJS.getDefaultPetType());
     	 	petcoPersonalizationJS.loadPetBreedValues("rxMedicinePetType_"+quanitity,"rxMedicinePetBreed_"+quanitity,null,petcoPersonalizationJS.getDefaultPetType().toUpperCase());
    		$("#rxMedicinePetBreed_"+quanitity).val("");
			$("#autoSuggestLoader_"+quanitity).val("");
    		$("#rxMedicinePetDateOfBirth_"+quanitity+"_MON").val("");
    		$("#rxMedicinePetDateOfBirth_"+quanitity+"_Date").val("");
    		$("#rxMedicinePetDateOfBirth_"+quanitity+"_Year").val("");
    		$("#rxMedicinePetWeight_"+quanitity).val("");
    		$("#rxMedicinePetAllergies_"+quanitity).val("");

    		    		$.each($('[id^="rxMedicinePetGender_'+quanitity+'-_-"]:checked'),function(ixc,obj){
    			obj.checked=false;
    		})
    		$("#rxMedicinePetAllergic_"+quanitity)[0].checked = false;
    		petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetAllergic_"+quanitity)[0]);

    		    		$.each($('[id^="rxMedicinePetAllergic_'+quanitity+'_optionContainer_0"]'),function(ixc,obj){
    			if(obj.id != 'rxMedicinePetAllergic_'+quanitity+'_optionContainer_0' ){
    				$(obj).parent().remove();
    			}else{
    				obj.value="";
    			}
    		})
    		$("#rxMedicinePetAllergic_"+quanitity+"_addnewLink").removeClass("hide");
    		$("#rxMedicinePetAllergic_"+quanitity+"_Others").val("");

    		    		$("#rxMedicinePetMedications_"+quanitity)[0].checked = false;
    		petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedications_"+quanitity)[0]);

    		    		$.each($('[id^="rxMedicinePetMedications_'+quanitity+'_optionContainer_0"]'),function(ixc,obj){
    			if(obj.id != 'rxMedicinePetMedications_'+quanitity+'_optionContainer_0' ){
    				$(obj).parent().remove();
    			}else{
    				obj.value="";
    			}
    		})
    		$("#rxMedicinePetMedications_"+quanitity+"_addnewLink").removeClass("hide");
    		$("#rxMedicinePetMedications_"+quanitity+"_Others").val("");

    		    		if($("#rxMedicinePetMedicalConditions_"+quanitity)[0]){
    		$("#rxMedicinePetMedicalConditions_"+quanitity)[0].checked = false;
    		petcoPersonalizationJS.openContainerTemplateEnabled($("#rxMedicinePetMedicalConditions_"+quanitity)[0]);
    		}

    		    		$.each($('[id^="rxMedicinePetMedicalConditions_'+quanitity+'-_options_-"]:checked'),function(ixc,obj){
    			obj.checked = false;
    		})
    		$("#rxMedicinePetMedicalConditions_"+quanitity+"_Others").val("");
    		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#rxMedicinePetName_"+quanitity)[0],quanitity);

    		    		petcoPersonalizationJS.addValueForBreedField($("#rxMedicinePetBreed_"+quanitity)[0],'autoSuggestLoader_'+quanitity);
    	}else if(type == "VET"){
    		$("#rxMedicineVetName_"+quanitity).removeClass("hide");
    		$("#rxMedicineVetName_"+quanitity).val("");
    		$("#rxMedicineVetCliName_"+quanitity).val("");
    		$("#rxMedicineVetPhNo_"+quanitity).val("");
    		$("#rxMedicineVetFxNo_"+quanitity).val("");
    		$("#rxMedicineVetLastName_"+quanitity).val("");
    		$("#rxMedicineVetAPIId_"+quanitity).val("");
    		$("#rxMedicineVetClinicId_"+quanitity).val("");
    		petcoPersonalizationJS.rxMedicineValidateSubmitButtonEnabled($("#rxMedicineVetFxNo_"+quanitity)[0],quanitity);
    	}

    	    },
	isRxDetailsInCart:function(){
		var isRxSPCModalEnabled = $("#isRxSPCModalEnabled").val();
		var isRxThrottleEnabled = $("#isRxThrottleEnabled").val();
		if('true' == isRxThrottleEnabled){
			var rxPetVetSource = localStorage.getItem('rxPetVetSource');
			if("" == rxPetVetSource || null == rxPetVetSource ){
				var rxPetVetSource = localStorage.getItem('rxPetVetSource');
				var expiresIn = localStorage.getItem('rxPetVetSource_expiresIn');
				var now = Date.now();  
			    if (expiresIn===undefined || expiresIn===null) { 
			    	expiresIn = 0;
			    }
				var updateLocalStorage = false;

								if (rxPetVetSource===undefined || rxPetVetSource===null) {
					var expires = (24*60*60);
					updateLocalStorage = true;
			    }else if(expiresIn < now){
			    	localStorage.removeItem('rxPetVetSource_expiresIn');
			    	localStorage.removeItem('rxPetVetSource');
			    	var expires = (24*60*60);
			    	updateLocalStorage = true;
			    }

				if(updateLocalStorage){
					var schedule = now + expires*1000; 
				    try {
				        localStorage.setItem('rxPetVetSource', $("#rxPetVetSource").val());
				        localStorage.setItem('rxPetVetSource_expiresIn', schedule);
				        var rxPetVetSource = $("#rxPetVetSource").val();
					} catch(e) {
				        console.log('setStorage: Error setting key ['+ key + '] in localStorage: ' + JSON.stringify(e) );
				    }
				}				

			}
			if('pdp' == rxPetVetSource){
				$("div[id^='product-attribute-info-id']").each(function() {
					  $(this).removeClass("hide");
				});
			}
		}
		if('pdp' != rxPetVetSource){
			$("p[id^='rxMed_pdp_qty_container_']").each(function() {
				  $(this).addClass("hide");
			});
			$("div[id^='rxMed_spc_qty_container_']").each(function() {
				  $(this).removeClass("hide");
			});
		}
	},
	rxMedEditVetInfo :function(orderItemId){
		$("#modal-rxmedicineAddvet h3").text("Edit Vet");
		this.clearAndRenewRxSPC(1,'VET');
		console.log("Edit  new Vet");
		$("#rxVetDietVetAttribute_1").val("");
		petcoPersonalizationJS.reAssignRxPersonalizedAttr(petcoPersonalizationJS.findPersonalizedAttrForShoppingCart(1,orderItemId));
		petcoCommonJS.showModal('modal-rxmedicineAddvet');
		$("#rxMedicineVetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxMedSubmitVetFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");
	},

		rxVetDietEditVetInfo:function(orderItemId){
		$("#modal-rxVetDietAddvet h3").text("Edit Vet Clinic");
		console.log("Edit Vet Clinic");

				$("#rxVetDietVetName_1").removeClass("hide");
		$("#rxVetDietVetName_1").val("");
		$("#rxVetDietVetCliName_1").val("");
		$("#rxVetDietVetPhNo_1").val("");
		$("#rxVetDietVetFxNo_1").val("");
		$("#rxVetDietVetLastName_1").val("");
		$("#rxVetDietVetAPIId_1").val("");
		$("#rxVetDietVetClinicId_1").val("");
		$("#rxVetDietVetAttribute_1").val("vetsource");
		petcoPersonalizationJS.reAssignRxPersonalizedAttr(petcoPersonalizationJS.findPersonalizedAttrForShoppingCart(1,orderItemId));
		petcoCommonJS.showModal('modal-rxVetDietAddvet');
		$("#rxVetDietVetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxVetDietVetSubmitFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");

		},
	displayPrescriptionSection :function(){
		var isRxSPCModalEnabled = $("#isRxSPCModalEnabled").val();
		var isRxThrottleEnabled = $("#isRxThrottleEnabled").val();
		var displayRxPetVetFormSPC = "";
		if($("#displayRxPetVetFormSPC").length > 0){
			displayRxPetVetFormSPC = $("#displayRxPetVetFormSPC").val();
		}
		if('true' == isRxThrottleEnabled){
			var rxPetVetSource = localStorage.getItem('rxPetVetSource');
			if("" == rxPetVetSource || null == rxPetVetSource){
				var rxPetVetSource = localStorage.getItem('rxPetVetSource');
				var expiresIn = localStorage.getItem('rxPetVetSource_expiresIn');
				var now = Date.now();  
			    if (expiresIn===undefined || expiresIn===null) { 
			    	expiresIn = 0;
			    }
				var updateLocalStorage = false;

								if (rxPetVetSource===undefined || rxPetVetSource===null) {
					var expires = (24*60*60);
					updateLocalStorage = true;
			    }else if(expiresIn < now){
			    	localStorage.removeItem('rxPetVetSource_expiresIn');
			    	localStorage.removeItem('rxPetVetSource');
			    	var expires = (24*60*60);
			    	updateLocalStorage = true;
			    }

				if(updateLocalStorage){
					var schedule = now + expires*1000; 
				    try {
				        localStorage.setItem('rxPetVetSource', $("#rxPetVetSource").val());
				        localStorage.setItem('rxPetVetSource_expiresIn', schedule);
				        var rxPetVetSource = $("#rxPetVetSource").val();
					} catch(e) {
				        console.log('setStorage: Error setting key ['+ key + '] in localStorage: ' + JSON.stringify(e) );
				    }
				}				

			}
			if("pdp" == rxPetVetSource && displayRxPetVetFormSPC == "true" ){
				$("#prescription-information").removeClass("hide");
			}else if("pdp" != rxPetVetSource){
				$("#prescription-information").removeClass("hide");
			}
		}else if(isRxSPCModalEnabled == 'true'){
			$("#prescription-information").removeClass("hide");
		}
	},
	updateRxMethodApprovalSelection :function(id,value){
		var identifier = id.split("_");
		var orderItemId = identifier[identifier.length - 2];
		var catentryId = $("#catentry_"+orderItemId).val();
		if("" ==  value){
			var loopIndex = identifier[identifier.length - 1];
			var petDataContainer = $('#rxMedPetDataSelection_Container_' + orderItemId + '_' + loopIndex);
 			var vetDataContainer = $('#rxMedVetDataSelection_Container_' + orderItemId + '_' + loopIndex);
 			var mailingMsgContainer = $('#mailing_msg_' + orderItemId);
 			petDataContainer.addClass('hide');
			vetDataContainer.addClass('hide');
 			mailingMsgContainer.addClass('hide');
 			if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true'){
 				var mailinMsgContainer = $('#mailin_msg_wrx_' + orderItemId);
 				mailinMsgContainer.addClass('hide');
 			}
		}else{
			var params = {};
			params.orderItemId	= orderItemId;
			params.orderId	= $("#orderId").val();
			params.action	= "updateChannel";
			params.channel	= value;
			params.catEntryId_1	= catentryId;
			params.vetId	= $("#rxMedVetDataSelection_"+orderItemId+"_1").val();
			wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
			cursor_wait();
		}


					},
	rxMedUpdateMandatoryPetInfo:function (orderItemId,petId){
		console.log("Update Mandatory a Pet details");
		$("#modal-rxmedicineAddpet h3").text($("#updateLabel").val());
		$('#rxMedicinePetDetailsSubmit_SPC').text('Save');
		petcoCommonJS.showModal('modal-rxmedicineAddpet');
		petcoPersonalizationJS.reAssignRxPersonalizedAttr(petcoPersonalizationJS.findPersonalizedAttrForShoppingCart(1,orderItemId));
		$("#rxMedicinePetDetailsSubmit_SPC").attr("onclick","petcoPersonalizationJS.rxMedSubmitPetFormSPC(this,'${param.ltlorRxflag}',"+orderItemId+","+1+")");
		petcoPersonalizationJS.loadPetBreedValues("rxMedicinePetType_1","rxMedicinePetBreed_1",$("#modal-rxmedicineAddpet #autoSuggestLoader_1").val(),$("#modal-rxmedicineAddpet #rxMedicinePetType_1").val().toUpperCase());
		petcoPersonalizationJS.rxMedicineValidateRequiredFields('1');
	},
	rxMedCombineItems :function(orderItemId){
		var catentryId = $("#catentry_"+orderItemId).val();
		var params = {};
		params.orderItemId	= orderItemId;
		params.orderId	= $("#orderId").val();
		params.action	= "combineOrderItems";
		params.catEntryId_1	= catentryId;
		params.vetId	= $("#rxMedVetDataSelection_"+orderItemId+"_1").val();
		wc.service.invoke("AjaxUpdateOrderItemRxInfo",params);
		cursor_wait();
	},
	rxMedicineValidateRequiredFields :function(counter){
		var jsonFieldEleContent = $("#fieldGroup").html();
        var jsonField = JSON.parse(jsonFieldEleContent);
        for (var i in jsonField) {
        	var idOfField = $('[id^="' + jsonField[i].Field+"_"+counter + '"]');
            var isFiledRequired = jsonField[i].IsRequired;
            var fieldGroup = jsonField[i].FieldGroup+"_"+counter;
            if (idOfField.length > 0) {
            	var isNumric = false;
                var isTeliphonic = false;
                var isOptionOthersExists = false;
                var isMultiOtherOptionsExists= false;
                var isDateField = false;
                var isRadio = false;
                var isAlpha = false;
                if (idOfField[0].className.indexOf("NUMERIC") > -1) {
                    isNumric = true;
                } else if (idOfField[0].className.indexOf("TELEPHONE") > -1) {
                    isTeliphonic = true;
                }  else if (idOfField[0].className.indexOf("MULTICHECKBOX") > -1) {
                	isMultiOtherOptionsExists = true;
                }else if (idOfField[0].className.indexOf("CHECKBOX") > -1) {
                    isOptionOthersExists = true;
                } else if (idOfField[0].className.indexOf("DATE") > -1) {
                    isDateField = true;
                } else if (idOfField[0].className.indexOf("RADIO") > -1) {
                    isRadio = true;
                } else if (idOfField[0].className.indexOf("ALPHA") > -1) {
                	isAlpha = true;
                }
                if (isFiledRequired == 'Y') {
                    if (!isOptionOthersExists && !isDateField && !isRadio && idOfField[0].value.trim() == "") {
                    	$('#pet'+jsonField[i].Field+'_div').addClass("error-box-rx");
                    } else if (isRadio) {
                    	var checkedRadio = $('input[type="radio"][name="' + jsonField[i].Field+"_"+counter + '"]:checked');
                    	if (checkedRadio.length == 0) {
                    		$('#pet'+jsonField[i].Field+'_div').addClass("error-box-rx");
                    	}
                    } else if (isDateField) {
                            if ($("#" + jsonField[i].Field+"_"+counter + "_MON").length > 0 && $("#" + jsonField[i].Field+"_"+counter + "_MON").val() == "" ||
                                $("#" + jsonField[i].Field+"_"+counter + "_Date").length > 0 && $("#" + jsonField[i].Field+"_"+counter + "_Date").val() == "" ||
                                $("#" + jsonField[i].Field+"_"+counter + "_Year").length > 0 && $("#" + jsonField[i].Field+"_"+counter + "_Year").val() == ""
                            ) {
                            	$('#petDob_div').addClass("error-box-rx");
                            } 
                    }

                                    }
            }
        }    
	},
	resetMandatoryFieldWarnings :function(){
		var jsonFieldEleContent = $("#fieldGroup").html();
        var jsonField = JSON.parse(jsonFieldEleContent);
        for (var i in jsonField) {
        	var idOfField = jsonField[i].Field;
        	if($('#pet'+jsonField[i].Field+'_div').length >0 &&
        			$('#pet'+jsonField[i].Field+'_div').hasClass("error-box-rx")){
        		$('#pet'+jsonField[i].Field+'_div').removeClass("error-box-rx");
        	}else if ($('#petDob_div').length >0 && $('#petDob_div').hasClass("error-box-rx")){
        		$('#pet'+jsonField[i].Field+'_div').removeClass("error-box-rx");
        	}
        }	
	},

	enableSearchButton : function(){
		if($('#searchVetClinicTextBox').val().length >= 3){
			$('#searchVetClinicButton').removeAttr('disabled');
		}else{
			$('#searchVetClinicButton').attr('disabled','disabled');
		}
	},

		searchVetClinic : function(page,clearList){
		var counter = 1;
		if(typeof(clearList) != "undefined" && clearList){
    		var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);
        	if(containerFOrVetClinic.length > 0){
        		containerFOrVetClinic.html("");
        	}
    	}
		if($("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").length == 0){
    		$("#vetDietSearchResults_"+counter).html('<div class="text-center"><img src="/wcsstore/PetcoSAS/images/miniloading.gif" class="text-center vetclinicnamecontainer" border="0"/></div>');
    	}

				var apiUrl = $("#vetSourceApiUrl").val();
    	var apiKey = $("#vetSourceApiKey").val();
    	var searchTerm = $("#searchVetClinicTextBox").val();
    	var searchURL = apiUrl + '/practice/search?api_key=' + apiKey + '&zip_code=' +searchTerm + '&limit=20&radius=10&page='+page+'&vetsource=true';

    			$.ajax({
	 		url : searchURL,
	 		type : 'GET',
	 		cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		data: '',
	 		success: function(data){
	 			var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);

	 				 			if(containerFOrVetClinic.length > 0 ){
	 				var Items = data.items;
	 				var htmlVal = "";

	 					 				if(typeof(Items) != "undefined"){
	 					var count=0;
	 					for( var ixi in Items){
	 						var adrVetClinic = Items[ixi].address;
	 						var countryVetClinic= Items[ixi].country;
	 						var stateVetClinic= Items[ixi].state;
	 						var phone_numberVetClinic= Items[ixi].phone_number;
	 						var zip_codeVetClinic= Items[ixi].zip_code;
	 						var cityVetClinic= Items[ixi].city;
	 						var nameVetClinic= Items[ixi].name;
	 						var idVetClinic= Items[ixi].id;
	 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
	 						var wrxOnlyClinic = Items[ixi].written_rx_only;
	 						var internalHospital = Items[ixi].internal_hospital;
	 						if(idVetClinic == '999999999'){
	 							cityVetClinic = 'Vetco Vaccination Clinics are Petco\'s prevention and wellness clinics. Select Vetco Vaccination Clinic if you have an active prescription from a Vetco Vaccination Clinic veterinarian';
	 							stateVetClinic="";
	 							zip_codeVetClinic="";
	 						}
	 						htmlVal = htmlVal+"<div class='widget-vet-clinic-row-container'>";
	 						htmlVal = htmlVal+"<div class='row vetclinicrow'>";
	 						htmlVal = htmlVal+"<div class='col-2 col-2-sm'>";
	 						htmlVal = htmlVal+"<input type='radio' id='selectClinNameId_"+idVetClinic+"_"+counter+"' name='selectClinName"+counter+"' onclick='petcoPersonalizationJS.openVetClinic("+idVetClinic+","+counter+")'/>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"<div class='col-10 col-10-sm'>";
	 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' >"+nameVetClinic+"</br>"+adrVetClinic+"</br>"+cityVetClinic+" "+stateVetClinic+" "+zip_codeVetClinic+"</br>"+phone_numberVetClinic+"</label>";
	 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='wrxOnlyVetClinic_"+idVetClinic+"_"+counter+"' value='"+wrxOnlyClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='internalHospital_"+idVetClinic+"_"+counter+"' value='"+internalHospital+"'/>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"<div class='row vetClinicresultrow hide margin-bottom-sm' id='vetClinicresultrowId_"+idVetClinic+"_"+counter+"'>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"</div>";
	 						count++;
	 					}
	 					if(count == 0){
	 						$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 					}
	 				}else{
	 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 				}
	 				$("#addNewVet").addClass("show");

	 					 				var currentCount = ((((page*1))*10)+(data.page_items*1))-10;
	 				if(currentCount > (data.total_items*1)){
	 					currentCount = (data.total_items*1);
	 				}
	 				if(typeof(data) != 'undefined' && !isNaN(currentCount) && currentCount != (data.total_items*1) && (data.page_items*1) > 0 ){
	 					$("#RX_VetLoadNextPage_"+counter).remove();
	 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 						htmlVal = htmlVal+"<div class='row'>";
		 					htmlVal = htmlVal+"<div class='col-12 text-center'>";
		 					htmlVal = htmlVal+"<div class='text-center'><a id='loadMoreLink' class='editLink load-more-link' onclick='petcoPersonalizationJS.searchVetClinic("+(page+1)+",true);'>Load More...</a></div>";
	 						htmlVal = htmlVal+"</div></div>";
	 						$(containerFOrVetClinic).append(htmlVal);
	 				}else{
	 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 					$("#RX_VetLoadNextPage_"+counter).remove();
	 					$(containerFOrVetClinic).append(htmlVal);
	 					}
	 			}else{
	 				$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 			}
	 		},
	 		error: function(){
	 			$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 			console.log("SEARCH ERROR");
	 			var containerFOrVetClinic = $("#vetDietSearchResults_1");
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	});
	},
	searchVetClinicV2 : function(page,clearList){
		var counter = 1;
		if(typeof(clearList) != "undefined" && clearList){
    		var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);
        	if(containerFOrVetClinic.length > 0){
        		containerFOrVetClinic.html("");
        	}
    	}
		if($("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").length == 0){
    		$("#vetDietSearchResults_"+counter).html('<div class="text-center"><img src="/wcsstore/PetcoSAS/images/miniloading.gif" class="text-center vetclinicnamecontainer" border="0"/></div>');
    	}

				var apiUrl = $("#vetSourceApiUrl").val();
    	var apiKey = $("#vetSourceApiKey").val();
    	var clinicSearchApiV1Enabled= $("#clinicSearchV1Enabled").val();
    	var clinicSearchApiV1URL = $("#clinicSearchV1URL").val();
    	var clinicSearchApiV1Key = $("#clinicSearchV1Key").val();
    	var searchTerm = $("#searchVetClinicTextBox").val();
        if(clinicSearchApiV1Enabled)
        {
        var searchURL = clinicSearchApiV1URL + 'search?&zip_code=' +searchTerm + '&limit=20&radius=10&page='+page+'&vetsource=true';
		$.ajax({
	 		url : searchURL,
	 		type : 'GET',
	 		cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		headers: {
	 		    'Authorization': 'Token ' +clinicSearchApiV1Key
	 		  },
	 		data: '',
	 		success: function(data){
	 			var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);

	 				 			if(containerFOrVetClinic.length > 0 ){
	 				var Items = data.items;
	 				var htmlVal = "";
	 				var totalVetItems=data.total_items;
	 				if(typeof(totalVetItems) != "undefined")
	 					$("#NoOfVetSearchResult").html("<span class='vet-search-result-count'>"+totalVetItems+" Results </span>")
	 				if(typeof(Items) != "undefined"){
	 					var count=0;
	 					for( var ixi in Items){
	 						var adrVetClinic = Items[ixi].address;
	 						var countryVetClinic= Items[ixi].country;
	 						var stateVetClinic= Items[ixi].state;
	 						var phone_numberVetClinic= Items[ixi].phone_number;
	 						var zip_codeVetClinic= Items[ixi].zip_code;
	 						var cityVetClinic= Items[ixi].city;
	 						var nameVetClinic= Items[ixi].name;
	 						var idVetClinic= Items[ixi].id;
	 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
	 						var wrxOnlyClinic = Items[ixi].written_rx_only;
	 						var internalHospital = Items[ixi].internal_hospital;
	 						if(idVetClinic == '999999999'){
	 							cityVetClinic = 'Vetco Vaccination Clinics are Petco\'s prevention and wellness clinics. Select Vetco Vaccination Clinic if you have an active prescription from a Vetco Vaccination Clinic veterinarian';
	 							stateVetClinic="";
	 							zip_codeVetClinic="";
	 						}
	 						htmlVal = htmlVal+"<div class='widget-vet-clinic-row-container vet-search-result-container' >";
	 						htmlVal = htmlVal+"<div class='row vetclinicrow' style=' background-color: #FFFFFF; '>";
	 						htmlVal = htmlVal+"<div class='col-8 col-8-sm' style='display: flex;'>";
	 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/vetclinic.png' class='vet-search-result-vetclinic-img'>";
	 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' > <strong class='vet-search-result-vetclinic-name'>"+nameVetClinic+"</strong></br><span class='vet-search-result-vetclinic-info'>"+adrVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+cityVetClinic+" "+stateVetClinic+" "+zip_codeVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+phone_numberVetClinic+"</span></label>";
	 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='wrxOnlyVetClinic_"+idVetClinic+"_"+counter+"' value='"+wrxOnlyClinic+"'/>";
	 						htmlVal = htmlVal+"<input type='hidden' id='internalHospital_"+idVetClinic+"_"+counter+"' value='"+internalHospital+"'/>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"<div class='col-4 col-4-sm'>";
	 						htmlVal = htmlVal+"<div class='row vetClinicresultrow margin-bottom-sm' id='vetClinicresultrowId_"+idVetClinic+"_"+counter+"'>";
	 						htmlVal = htmlVal+"<a class='btn btn-primary btn-full' id='RX_MyVetSaveInfo_"+idVetClinic+"_"+counter+"' onclick='petcoPersonalizationJS.saveVetClinicInfoV2("+idVetClinic+","+counter+",this);' href='javascript:void(0)'>SELECT</a>";
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"</div>";
	 						if(wrxOnlyClinic && wrxOnlyClinic == true){
	 						htmlVal = htmlVal+"<div class='col-12 vet-search-result-wrx-vetclinic-div'>";
	 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/mail_icon.png' class='vet-search-result-wrx-vetclinic-mail-img'> Clinic Requires Written Prescription or Authorization ";
	 						htmlVal = htmlVal+"<div class='tooltip-vet'><img src='/wcsstore/PetcoStore/img/info-icon-img.png' class='vet-search-result-wrx-vetclinic-info-img'><span class='tooltiptext-vet vet-search-result-wrx-vetclinic-tooltip'>This Veterinary Clinic has a policy that requires you to provide the original prescription to online pharmacies. We will show you how to do this after your order is placed.</span></div></div>";
	 						}
	 						htmlVal = htmlVal+"</div>";
	 						htmlVal = htmlVal+"</div>";
	 						count++;
	 					}
	 					if(count == 0){
	 						$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 					}
	 				}else{
	 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 				}
	 				$("#addNewVet").addClass("show");

	 					 				var currentCount = ((((page*1))*10)+(data.page_items*1))-10;
	 				if(currentCount > (data.total_items*1)){
	 					currentCount = (data.total_items*1);
	 				}
	 				if(typeof(data) != 'undefined' && !isNaN(currentCount) && currentCount != (data.total_items*1) && (data.page_items*1) > 0 ){
	 					$("#RX_VetLoadNextPage_"+counter).remove();
	 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 						htmlVal = htmlVal+"<div class='row'>";
		 					htmlVal = htmlVal+"<div class='col-12 text-center'>";
		 					htmlVal = htmlVal+"<div class='text-center'><a id='loadMoreLink' class='editLink load-more-link' onclick='petcoPersonalizationJS.searchVetClinicV2("+(page+1)+",true);'>Load More...</a></div>";
	 						htmlVal = htmlVal+"</div></div>";
	 						$(containerFOrVetClinic).append(htmlVal);
	 				}else{
	 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 					$("#RX_VetLoadNextPage_"+counter).remove();
	 					$(containerFOrVetClinic).append(htmlVal);
	 					}
	 			}else{
	 				$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 			}
	 		},
	 		error: function(){
	 			$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
	 			console.log("SEARCH ERROR");
	 			var containerFOrVetClinic = $("#vetDietSearchResults_1");
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	}); }else{
	 		var searchURL = apiUrl + '/practice/search?api_key=' + apiKey + '&zip_code=' +searchTerm + '&limit=20&radius=10&page='+page+'&vetsource=true';
	 		$.ajax({
		 		url : searchURL,
		 		type : 'GET',
		 		cache: true,
		 		contentType: 'application/json',
		 		dataType: 'json',
		 		data: '',
		 		success: function(data){
		 			var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);

		 					 			if(containerFOrVetClinic.length > 0 ){
		 				var Items = data.items;
		 				var htmlVal = "";
		 				var totalVetItems=data.total_items;
		 				if(typeof(totalVetItems) != "undefined")
		 					$("#NoOfVetSearchResult").html("<span class='vet-search-result-count'>"+totalVetItems+" Results </span>")
		 				if(typeof(Items) != "undefined"){
		 					var count=0;
		 					for( var ixi in Items){
		 						var adrVetClinic = Items[ixi].address;
		 						var countryVetClinic= Items[ixi].country;
		 						var stateVetClinic= Items[ixi].state;
		 						var phone_numberVetClinic= Items[ixi].phone_number;
		 						var zip_codeVetClinic= Items[ixi].zip_code;
		 						var cityVetClinic= Items[ixi].city;
		 						var nameVetClinic= Items[ixi].name;
		 						var idVetClinic= Items[ixi].id;
		 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
		 						var wrxOnlyClinic = Items[ixi].written_rx_only;
		 						var internalHospital = Items[ixi].internal_hospital;
		 						if(idVetClinic == '999999999'){
		 							cityVetClinic = 'Vetco Vaccination Clinics are Petco\'s prevention and wellness clinics. Select Vetco Vaccination Clinic if you have an active prescription from a Vetco Vaccination Clinic veterinarian';
		 							stateVetClinic="";
		 							zip_codeVetClinic="";
		 						}
		 						htmlVal = htmlVal+"<div class='widget-vet-clinic-row-container vet-search-result-container' >";
		 						htmlVal = htmlVal+"<div class='row vetclinicrow' style=' background-color: #FFFFFF; '>";
		 						htmlVal = htmlVal+"<div class='col-8 col-8-sm' style='display: flex;'>";
		 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/vetclinic.png' class='vet-search-result-vetclinic-img'>";
		 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' > <strong class='vet-search-result-vetclinic-name'>"+nameVetClinic+"</strong></br><span class='vet-search-result-vetclinic-info'>"+adrVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+cityVetClinic+" "+stateVetClinic+" "+zip_codeVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+phone_numberVetClinic+"</span></label>";
		 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
		 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
		 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
		 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
		 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
		 						htmlVal = htmlVal+"<input type='hidden' id='wrxOnlyVetClinic_"+idVetClinic+"_"+counter+"' value='"+wrxOnlyClinic+"'/>";
		 						htmlVal = htmlVal+"<input type='hidden' id='internalHospital_"+idVetClinic+"_"+counter+"' value='"+internalHospital+"'/>";
		 						htmlVal = htmlVal+"</div>";
		 						htmlVal = htmlVal+"<div class='col-4 col-4-sm'>";
		 						htmlVal = htmlVal+"<div class='row vetClinicresultrow margin-bottom-sm' id='vetClinicresultrowId_"+idVetClinic+"_"+counter+"'>";
		 						htmlVal = htmlVal+"<a class='btn btn-primary btn-full' id='RX_MyVetSaveInfo_"+idVetClinic+"_"+counter+"' onclick='petcoPersonalizationJS.saveVetClinicInfoV2("+idVetClinic+","+counter+",this);' href='javascript:void(0)'>SELECT</a>";
		 						htmlVal = htmlVal+"</div>";
		 						htmlVal = htmlVal+"</div>";
		 						if(wrxOnlyClinic && wrxOnlyClinic == true){
		 						htmlVal = htmlVal+"<div class='col-12 vet-search-result-wrx-vetclinic-div'>";
		 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/mail_icon.png' class='vet-search-result-wrx-vetclinic-mail-img'> Clinic Requires Written Prescription or Authorization ";
		 						htmlVal = htmlVal+"<div class='tooltip-vet'><img src='/wcsstore/PetcoStore/img/info-icon-img.png' class='vet-search-result-wrx-vetclinic-info-img'><span class='tooltiptext-vet vet-search-result-wrx-vetclinic-tooltip'>This Veterinary Clinic has a policy that requires you to provide the original prescription to online pharmacies. We will show you how to do this after your order is placed.</span></div></div>";
		 						}
		 						htmlVal = htmlVal+"</div>";
		 						htmlVal = htmlVal+"</div>";
		 						count++;
		 					}
		 					if(count == 0){
		 						$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
		 					}
		 				}else{
		 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
		 				}
		 				$("#addNewVet").addClass("show");

		 						 				var currentCount = ((((page*1))*10)+(data.page_items*1))-10;
		 				if(currentCount > (data.total_items*1)){
		 					currentCount = (data.total_items*1);
		 				}
		 				if(typeof(data) != 'undefined' && !isNaN(currentCount) && currentCount != (data.total_items*1) && (data.page_items*1) > 0 ){
		 					$("#RX_VetLoadNextPage_"+counter).remove();
		 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
		 						htmlVal = htmlVal+"<div class='row'>";
			 					htmlVal = htmlVal+"<div class='col-12 text-center'>";
			 					htmlVal = htmlVal+"<div class='text-center'><a id='loadMoreLink' class='editLink load-more-link' onclick='petcoPersonalizationJS.searchVetClinicV2("+(page+1)+",true);'>Load More...</a></div>";
		 						htmlVal = htmlVal+"</div></div>";
		 						$(containerFOrVetClinic).append(htmlVal);
		 				}else{
		 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
		 					$("#RX_VetLoadNextPage_"+counter).remove();
		 					$(containerFOrVetClinic).append(htmlVal);
		 					}
		 			}else{
		 				$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
		 			}
		 		},
		 		error: function(){
		 			$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
		 			console.log("SEARCH ERROR");
		 			var containerFOrVetClinic = $("#vetDietSearchResults_1");
		 			if(containerFOrVetClinic.length > 0 ){
		 				$(containerFOrVetClinic).html("");
		 			}
		 		}
		 	});
	 	}
	},
	saveVetClinicInfoV2:function(id,counter,obj){
		if($(obj).hasClass('disabled')){
    		return;
    	}
    	var vFirstName="";
    	var vLastName="";
    	var vClinicName="";
    	var vPhoneNumber = "";
    	var vFaxNo = "";
    	var vClinicId = "";
    	var vVetId = "";


    	    	    	vClinicName = $("#nameVetClinic_"+id+"_"+counter+"").val();
    	vClinicId = id;
    	vVetId = id;
		vPhoneNumber= $("#phone_numberVetClinic_"+id+"_"+counter+"").val();
		vFaxNo= $("#fax_phone_numberVetClinic_"+id+"_"+counter+"").val();

		    	$("#rxVetDietVetName_"+counter).val('Clinic');	
    	$("#rxVetDietVetLastName_"+counter).val('Veterinarian');

    	    	var isWrxOnlyClinc = $("#wrxOnlyVetClinic_"+id+"_"+counter).val();
    	var isInternalHospital = $("#internalHospital_"+id+"_"+counter).val();
    	var vetAttribute = "vetsource";
    	if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true' && isWrxOnlyClinc == 'true') {
			vetAttribute = vetAttribute+",wrx";
		}
    	if($("#internalHospitalFilterEnabled").length >0  && $("#internalHospitalFilterEnabled").val() == 'true' && isInternalHospital == 'true'){
			vetAttribute = vetAttribute+",internal_hospital";
		}
		$("#rxVetDietVetAttribute_"+counter).val(vetAttribute);	

    	    	if(vClinicName != null && vClinicName != 'null' && vClinicName != ''){
    		$("#rxVetDietVetCliName_"+counter).val(vClinicName);
    	}
    	if(vPhoneNumber != null && vPhoneNumber != 'null' ){
    		$("#rxVetDietVetPhNo_"+counter).val(vPhoneNumber);
    	}
    	if(vFaxNo != null && vFaxNo != 'null' && vFaxNo != ''){
    	    $("#rxVetDietVetFxNo_"+counter).val(vFaxNo);
    	}

    	    	$("#rxVetDietVetAPIId_"+counter).val('');
        if(vClinicId != null && vClinicId != 'null' && vClinicId != ''){
        	$("#rxVetDietVetClinicId_"+counter).val(vClinicId);
    	}       

				if($('[id^="rxVetDietVetDetailsSubmit_SPC"]').length > 0){
			$("#rxVetDietVetDetailsSubmit_SPC").click()   		
        }   
	},
	openVetClinic: function(clinicid,counter,name){

				$.each($('[id^="vetClinicresultrowId_"].show'),function(i,obj){
    		$(obj).addClass("hide");
    		$(obj).removeClass("show");
    	});

		    	$("#vetClinicresultrowId_"+clinicid+"_"+counter).removeClass("hide");
    	$("#vetClinicresultrowId_"+clinicid+"_"+counter).addClass("show");
    	var vetinfoLabel = "Save & Review";

    	    	var apiUrl = $("#vetSourceApiUrl").val();
    	var apiKey = $("#vetSourceApiKey").val();

    			$.ajax({
	 		url : apiUrl + '/practice/'+ clinicid + '/experts?api_key=' + apiKey,
	 		type : 'GET',
	 		cache: true,
	 		contentType: 'application/json',
	 		dataType: 'json',
	 		data: '',
	 		success: function(data){
	 			var containerFOrVetClinic = $("#vetClinicresultrowId_"+clinicid+"_"+counter);
	 			if(containerFOrVetClinic.length > 0 ){
	 				var Items = data.items;
	 				var htmlVal = "";
	 				if(typeof(Items) != "undefined"){
	 					if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true'){
	 						var isWrxOnlyClinc = $("#wrxOnlyVetClinic_"+clinicid+"_"+counter).val();
	 						if(isWrxOnlyClinc == 'true') {
	 							htmlVal = htmlVal+"<div class='col-12'>";
		 						htmlVal = htmlVal+"<div class='row wrx-row'>";
		 						htmlVal = htmlVal+"<div class='col-1 col-2-sm'><img class='mail-icon' src='/wcsstore/PetcoStore/img/mail_icon.png' alt='Mail Icon' border='0'></div>";
		 						htmlVal = htmlVal+"<div class='col-11 col-9-sm mail-info-text'>";
		 						if(petcoPersonalizationJS.isRxFood == 'Y'){
		 							htmlVal = htmlVal+ MessageHelper.messages["WRX_VETDIET_MAIL_IN_MSG"];
		 						}else{
		 							htmlVal = htmlVal+MessageHelper.messages["WRX_PHARMA_MAIL_IN_MSG"];
		 						}
		 						htmlVal = htmlVal+"</div>";
		 						htmlVal = htmlVal+"</div>";
		 						htmlVal = htmlVal+"</div>";
	 						}
	 					}
	 					htmlVal = htmlVal+"<div class='row'>";
	 					htmlVal = htmlVal+"<div class='row'>";
	 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div>";
	 					htmlVal = htmlVal+"<div  class='col-6 col-10-sm text-center'>";
	 					htmlVal = htmlVal+"<a class='btn btn-primary btn-full' id='RX_MyVetSaveInfo_"+clinicid+"_"+counter+"'";
	 					htmlVal = htmlVal+" onclick='petcoPersonalizationJS.saveVetClinicInfo("+clinicid+","+counter+",this);' ";
	 					htmlVal = htmlVal+" href='javascript:void(0)'>"+vetinfoLabel+"</a>";
	 					htmlVal = htmlVal+"</div>";
	 					htmlVal = htmlVal+"<div class='col-3 col-1-sm'>&nbsp;</div></div>";
	 				}
	 				$(containerFOrVetClinic).html(htmlVal);
	 			}
	 		},
	 		error: function(){
	 			console.log("VET CLINIC SEARCH ERROR OUT");
	 			var containerFOrVetClinic = $("#vetDietSearchResults_1");
	 			if(containerFOrVetClinic.length > 0 ){
	 				$(containerFOrVetClinic).html("");
	 			}
	 		}
	 	});
	},

		saveVetClinicInfo:function(id,counter,obj){
		if($(obj).hasClass('disabled')){
    		return;
    	}
    	var vFirstName="";
    	var vLastName="";
    	var vClinicName="";
    	var vPhoneNumber = "";
    	var vFaxNo = "";
    	var vClinicId = "";
    	var vVetId = "";


    	    	    	vClinicName = $("#nameVetClinic_"+id+"_"+counter+"").val();
    	vClinicId = id;
    	vVetId = id;
		vPhoneNumber= $("#phone_numberVetClinic_"+id+"_"+counter+"").val();
		vFaxNo= $("#fax_phone_numberVetClinic_"+id+"_"+counter+"").val();

		    	$("#rxVetDietVetName_"+counter).val('Clinic');	
    	$("#rxVetDietVetLastName_"+counter).val('Veterinarian');

    	    	var isWrxOnlyClinc = $("#wrxOnlyVetClinic_"+id+"_"+counter).val();
    	var isInternalHospital = $("#internalHospital_"+id+"_"+counter).val();
    	var vetAttribute = "vetsource";
    	if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true' && isWrxOnlyClinc == 'true') {
			vetAttribute = vetAttribute+",wrx";
		}
    	if($("#internalHospitalFilterEnabled").length >0  && $("#internalHospitalFilterEnabled").val() == 'true' && isInternalHospital == 'true'){
			vetAttribute = vetAttribute+",internal_hospital";
		}
		$("#rxVetDietVetAttribute_"+counter).val(vetAttribute);	

    	    	if(vClinicName != null && vClinicName != 'null' && vClinicName != ''){
    		$("#rxVetDietVetCliName_"+counter).val(vClinicName);
    	}
    	if(vPhoneNumber != null && vPhoneNumber != 'null' ){
    		$("#rxVetDietVetPhNo_"+counter).val(vPhoneNumber);
    	}
    	if(vFaxNo != null && vFaxNo != 'null' && vFaxNo != ''){
    	    $("#rxVetDietVetFxNo_"+counter).val(vFaxNo);
    	}

    	    	$("#rxVetDietVetAPIId_"+counter).val('');
        if(vClinicId != null && vClinicId != 'null' && vClinicId != ''){
        	$("#rxVetDietVetClinicId_"+counter).val(vClinicId);
    	}

            	var editDivId = "#RX_MyVetClinic_" + counter + "_edit";
    	var reviewDivId = "#RX_MyVetClinic_" + counter +"_review";
    	var editLink = "#RX_MyVetClinic_" + counter +"_editLink";

    	    	$(editDivId).addClass("hide");
    	$(editDivId).removeClass("show");
    	$(reviewDivId).removeClass("hide");
    	$(reviewDivId).addClass("show");
    	$(editLink).removeClass("hide");
    	$(editLink).addClass("show");

    	    	$(reviewDivId).html("");  

    			var htmlvet= "<p class='spacer-lg-left'><strong>Clinic Name: </strong>"+vClinicName+"</p>"
		htmlvet = htmlvet+ "<p class='spacer-lg-left spacer-xsm-bottom'><strong>Phone Number: </strong>"+vPhoneNumber+"</p>"
		$(reviewDivId).html(htmlvet);

				if($('[id^="rxVetDietVetDetailsSubmit_SPC"]').length > 0){
        	var spcSubtmitVetButton = $('[id^="rxVetDietVetDetailsSubmit_SPC"]');
        	$(spcSubtmitVetButton).removeClass("disabled");
        	$(spcSubtmitVetButton).addClass("show");
    		$(spcSubtmitVetButton).removeClass("hide");
        }   
	},

		addNewVet:function(){
		$("#modal-rxVetDietAddvet h3").text("Add a Vet Clinic");
		$("#addNewVetClinic").removeClass("hide");
		$("#addNewVetClinic").addClass("show");

				$("#searchVetClinic").removeClass("show");
		$("#searchVetClinic").addClass("hide");
	},

		saveNewVetClinic:function(){
		vetSourceErrors = false;
		petcoPersonalizationJS.validateForm();

				if(!vetSourceErrors){
			var phoneNumber = $("#add-vet-phone").val();
			var faxNumber = $("#add-vet-fax_number").val();
			var apiUrl = $("#vetSourceApiUrl").val();
	    	var apiKey = $("#vetSourceApiKey").val();
			var form = document.getElementById('addNewVetClinicForm');
			var formData = new FormData(form);
			phoneNumber = phoneNumber.replace(/[^\d]/g, "");

						if(phoneNumber.charAt(0) == '1'){
				phoneNumber = phoneNumber.substring(1);
			}

						formData.set('phone_number','+1'+phoneNumber);

						if(faxNumber != ""){
				faxNumber = faxNumber.replace(/[^\d]/g, "");
				if(faxNumber.charAt(0) == '1'){
					faxNumber = faxNumber.substring(1);
				}
				formData.set('fax_number','+1'+faxNumber);
			}
			var clinicSearchApiV1Enabled= $("#clinicSearchV1Enabled").val();
	    	var clinicSearchApiV1URL = $("#clinicSearchV1URL").val();
	    	var clinicSearchApiV1Key = $("#clinicSearchV1Key").val();
	    	if(clinicSearchApiV1Enabled){
	    		$.ajax({
			 		url : clinicSearchApiV1URL + 'add',
			 		type : 'POST',
			 		data: formData,
			 		headers: {
			 		    'Authorization': 'Token ' +clinicSearchApiV1Key
			 		  },
			 		processData: false,
			 	    contentType: false,
			 		success: function(data){
			 			counter=1;
			 			var htmlvet="";
			        	var VetName = 'Clinic';
		 				var VetClinicName = data.name;
		 				var VetPhone = data.phone_number;
		 				var VetLastName = 'Veterinarian';
		 				var VetAttribute = 'vetsource';
		 				var VetFax = data.fax_number;
		 				var vVetId = data.clinic_id;

		 						 				var editDivId = "#RX_MyVetClinic_" + counter + "_edit";
		 	        	var reviewDivId = "#RX_MyVetClinic_" + counter +"_review";
		 	        	var editLink = "#RX_MyVetClinic_" + counter +"_editLink";

		 	        			 	        	$(editDivId).addClass("hide");
		 	        	$(editDivId).removeClass("show");
		 	        	$(reviewDivId).removeClass("hide");
		 	        	$(reviewDivId).addClass("show");
		 	        	$(editLink).removeClass("hide");
		 	        	$(editLink).addClass("show");

		 						 	        	htmlvet = htmlvet+ "<p class='spacer-lg-left'><strong>Clinic Name: </strong>"+VetClinicName+"</p>"
			    		htmlvet = htmlvet+ "<p class='spacer-lg-left spacer-xsm-bottom'><strong>Phone Number: </strong>"+VetPhone+"</p>"
			    		$(reviewDivId).html(htmlvet);

			    					        	$("#rxVetDietVetName_"+counter).val(VetName);	
			        	$("#rxVetDietVetLastName_"+counter).val(VetLastName);
			        	$("#rxVetDietVetCliName_"+counter).val(VetClinicName);
			        	$("#rxVetDietVetPhNo_"+counter).val(VetPhone);
			        	$("#rxVetDietVetFxNo_"+counter).val(VetFax);
			        	$("#rxVetDietVetAPIId_"+counter).val('');
			        	$("#rxVetDietVetClinicId_"+counter).val(vVetId);
			        	$("#rxVetDietVetAttribute_"+counter).val(VetAttribute);

			    					    		if($('[id^="rxVetDietVetDetailsSubmit_SPC"]').length > 0){
			            	var spcSubtmitVetButton = $('[id^="rxVetDietVetDetailsSubmit_SPC"]');
			            	$(spcSubtmitVetButton).removeClass("disabled");
			            	$(spcSubtmitVetButton).addClass("show");
			        		$(spcSubtmitVetButton).removeClass("hide");
			            }
			          },
			 		error: function(data){

			 						 			if(data.responseJSON.__all__ != undefined){
			 				var html = '<span class="error">'+data.responseJSON.__all__+'</span>';
			 				$('#zip_error').html(html);
			 				$('#zip_error').removeClass("hide");
			 				$('#zip_error').addClass("show");
			 				vetSourceErrors = true;
			 			}

			 						 			if((data.responseJSON.phone_number != undefined)){
			 				var html = '<span class="error">'+data.responseJSON.phone_number+'</span>';
							$('#phone_error').html(html);
							$('#phone_error').removeClass("hide");
							$('#phone_error').addClass("show");
							vetSourceErrors = true;
			 			}

			 				 			if(data.responseJSON.fax_number != undefined){
			 				var html = '<span class="error">'+data.responseJSON.fax_number+'</span>';
							$('#fax_error').html(html);
							$('#fax_error').removeClass("hide");
							$('#fax_error').addClass("show");
							vetSourceErrors = true;
			 			}

			 						 		}	
			 	});
	    	}else{
			$.ajax({
		 		url : apiUrl + '/practice/add?api_key='+apiKey,
		 		type : 'POST',
		 		data: formData,
		 		processData: false,
		 	    contentType: false,
		 		success: function(data){
		 			counter=1;
		 			var htmlvet="";
		        	var VetName = 'Clinic';
	 				var VetClinicName = data.name;
	 				var VetPhone = data.phone_number;
	 				var VetLastName = 'Veterinarian';
	 				var VetAttribute = 'vetsource';
	 				var VetFax = data.fax_number;
	 				var vVetId = data.clinic_id;

	 					 				var editDivId = "#RX_MyVetClinic_" + counter + "_edit";
	 	        	var reviewDivId = "#RX_MyVetClinic_" + counter +"_review";
	 	        	var editLink = "#RX_MyVetClinic_" + counter +"_editLink";

	 	        		 	        	$(editDivId).addClass("hide");
	 	        	$(editDivId).removeClass("show");
	 	        	$(reviewDivId).removeClass("hide");
	 	        	$(reviewDivId).addClass("show");
	 	        	$(editLink).removeClass("hide");
	 	        	$(editLink).addClass("show");

	 					 	        	htmlvet = htmlvet+ "<p class='spacer-lg-left'><strong>Clinic Name: </strong>"+VetClinicName+"</p>"
		    		htmlvet = htmlvet+ "<p class='spacer-lg-left spacer-xsm-bottom'><strong>Phone Number: </strong>"+VetPhone+"</p>"
		    		$(reviewDivId).html(htmlvet);

		    				        	$("#rxVetDietVetName_"+counter).val(VetName);	
		        	$("#rxVetDietVetLastName_"+counter).val(VetLastName);
		        	$("#rxVetDietVetCliName_"+counter).val(VetClinicName);
		        	$("#rxVetDietVetPhNo_"+counter).val(VetPhone);
		        	$("#rxVetDietVetFxNo_"+counter).val(VetFax);
		        	$("#rxVetDietVetAPIId_"+counter).val('');
		        	$("#rxVetDietVetClinicId_"+counter).val(vVetId);
		        	$("#rxVetDietVetAttribute_"+counter).val(VetAttribute);

		    				    		if($('[id^="rxVetDietVetDetailsSubmit_SPC"]').length > 0){
		            	var spcSubtmitVetButton = $('[id^="rxVetDietVetDetailsSubmit_SPC"]');
		            	$(spcSubtmitVetButton).removeClass("disabled");
		            	$(spcSubtmitVetButton).addClass("show");
		        		$(spcSubtmitVetButton).removeClass("hide");
		            }
		          },
		 		error: function(data){

		 					 			if(data.responseJSON.__all__ != undefined){
		 				var html = '<span class="error">'+data.responseJSON.__all__+'</span>';
		 				$('#zip_error').html(html);
		 				$('#zip_error').removeClass("hide");
		 				$('#zip_error').addClass("show");
		 				vetSourceErrors = true;
		 			}

		 					 			if((data.responseJSON.phone_number != undefined)){
		 				var html = '<span class="error">'+data.responseJSON.phone_number+'</span>';
						$('#phone_error').html(html);
						$('#phone_error').removeClass("hide");
						$('#phone_error').addClass("show");
						vetSourceErrors = true;
		 			}

		 			 			if(data.responseJSON.fax_number != undefined){
		 				var html = '<span class="error">'+data.responseJSON.fax_number+'</span>';
						$('#fax_error').html(html);
						$('#fax_error').removeClass("hide");
						$('#fax_error').addClass("show");
						vetSourceErrors = true;
		 			}

		 					 		}	
		 	});}
		}
	},

		validateForm : function(){
		var clinicName = $('#add-vet-clinic_name').val();
		var clinicAddress = $("#add-vet-street-address").val();
		var city = $("#add-vet-city").val();
		var state = $("#add-vet-state").val();
		var zip = $("#add-zip").val();
		var phoneNumber = $("#add-vet-phone").val();
		var faxNumber = $("#add-vet-fax_number").val();
		var numeric = new RegExp(/^[0-9]+$/);
		var alphaWithSpace = new RegExp(/^[a-zA-Z]+[\sa-zA-Z]*$/i);
		var phone = new RegExp(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g);
		var phoneRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
		var faxRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
		if(clinicName.length == 0){
			var html = '<span class="error">Clinic\'s name is required</span>';
			$('#clinicname_error').html(html);
			$('#clinicname_error').removeClass("hide");
			$('#clinicname_error').addClass("show");
			vetSourceErrors = true;
		}else{
			$('#clinicname_error').removeClass("show");
			$('#clinicname_error').addClass("hide");
			$('#clinicname_error').html('');
		}
		if(clinicAddress.length == 0){
			var html = '<span class="error">City is required</span>';
			$('#clinicAddress_error').html(html);
			$('#clinicAddress_error').removeClass("hide");
			$('#clinicAddress_error').addClass("show");
			vetSourceErrors = true;
		}else{
			$('#clinicAddress_error').html('');
			$('#clinicAddress_error').removeClass("show");
			$('#clinicAddress_error').addClass("hide");
		}
		if(city.length == 0){
			var html = '<span class="error">Clinic street address is required</span>';
			$('#city_error').html(html);
			$('#city_error').removeClass("hide");
			$('#city_error').addClass("show");
			vetSourceErrors = true;
		}else if(!alphaWithSpace.test(city)) {
			var html = '<span class="error">The City field may only contain alphabetic characters as well as spaces.</span>';
			$('#city_error').html(html);
			$('#city_error').removeClass("hide");
			$('#city_error').addClass("show");
			vetSourceErrors = true;
		}else{
			$('#city_error').html('');
			$('#city_error').removeClass("show");
			$('#city_error').addClass("hide");
		}
		if(state == null){
			var html = '<span class="error">State is required</span>';
			$('#state_error').html(html);
			$('#state_error').removeClass("hide");
			$('#state_error').addClass("show");
			vetSourceErrors = true;
		}else{
			$('#state_error').html('');
			$('#state_error').removeClass("show");
			$('#state_error').addClass("hide");
		}
		if(zip.length == 0){
			var html = '<span class="error">Zip code is required</span>';
			$('#zip_error').html(html);
			$('#zip_error').removeClass("hide");
			$('#zip_error').addClass("show");
			vetSourceErrors = true;
		}else if(zip.length > 5 || !numeric.test(zip)){
			var html = '<span class="error">Please enter a valid 5 digit US zip code</span>';
			$('#zip_error').html(html);
			$('#zip_error').removeClass("hide");
			$('#zip_error').addClass("show");
			vetSourceErrors = true;
		}else {
			$('#zip_error').html('');
			$('#zip_error').removeClass("show");
			$('#zip_error').addClass("hide");
		}
		if(phoneNumber.length == 0){
			var html = '<span class="error">Phone number is required</span>';
			$('#phone_error').html(html);
			$('#phone_error').removeClass("hide");
			$('#phone_error').addClass("show");
			vetSourceErrors = true;
		}else if (!phoneRegex.test(phoneNumber) || phoneNumber[0] == '0'){

						var html = '<span class="error">Please enter a valid US phone number</span>';
			$('#phone_error').html(html);
			$('#phone_error').removeClass("hide");
			$('#phone_error').addClass("show");
			vetSourceErrors = true;
		}
		else{
			$('#phone_error').html('');
			$('#phone_error').removeClass("show");
			$('#phone_error').addClass("hide");
		}
		if(faxNumber.length > 0){
			if((!faxRegex.test(faxNumber)) || faxNumber[0] == '0'){
				var html = '<span class="error">Please enter a valid US fax number</span>';
				$('#fax_error').html(html);
				$('#fax_error').removeClass("hide");
				$('#fax_error').addClass("show");
				vetSourceErrors = true;
			}else{
				$('#fax_error').html('');
				$('#fax_error').removeClass("show");
				$('#fax_error').addClass("hide");
			}
		}else{
			$('#fax_error').html('');
			$('#fax_error').removeClass("show");
			$('#fax_error').addClass("hide");
		}

			},

		gobackToClinicSearch: function(){
		$("#modal-rxVetDietAddvet h3").text("Search For Your Vet Clinic");
		$("#addNewVetClinic").removeClass("show");
		$("#addNewVetClinic").addClass("hide");

				$("#searchVetClinic").removeClass("hide");
		$("#searchVetClinic").addClass("show");
	},
	rxRdChangeVet: function(){
    	$("#modal-rxmedicineAddvet h3").text("Add a Vet");
        	console.log("Add a new Vet Clinic");
			$("#modal-rxVetDietAddvet h3").text("Veterinary Clinic Search");
			petcoCommonJS.showModal('modal-rxVetDietAddvet');
		},	

			searchRDVetClinicV2:function(page,clearList){
				var counter = 1;
				if(typeof(clearList) != "undefined" && clearList){
		    		var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);
		        	if(containerFOrVetClinic.length > 0){
		        		containerFOrVetClinic.html("");
		        	}
		    	}
				if($("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").length == 0){
		    		$("#vetDietSearchResults_"+counter).html('<div class="text-center"><img src="/wcsstore/PetcoSAS/images/miniloading.gif" class="text-center vetclinicnamecontainer" border="0"/></div>');
		    	}

								var apiUrl = $("#vetSourceApiUrl").val();
		    	var apiKey = $("#vetSourceApiKey").val();
		    	var searchTerm = $("#searchVetClinicTextBox").val();
		    	var clinicSearchApiV1Enabled= $("#clinicSearchV1Enabled").val();
		    	var clinicSearchApiV1URL = $("#clinicSearchV1URL").val();
		    	var clinicSearchApiV1Key = $("#clinicSearchV1Key").val();
		    	if(clinicSearchApiV1Enabled){
		    		var searchURL = clinicSearchApiV1URL + 'search?&zip_code=' +searchTerm + '&limit=20&radius=10&page='+page+'&vetsource=true';
		    		$.ajax({
				 		url : searchURL,
				 		type : 'GET',
				 		cache: true,
				 		contentType: 'application/json',
				 		dataType: 'json',
				 		headers: {
				 		    'Authorization': 'Token ' +clinicSearchApiV1Key
				 		  },
				 		data: '',
				 		success: function(data){
				 			var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);

							 				 			if(containerFOrVetClinic.length > 0 ){
				 				var Items = data.items;
				 				var htmlVal = "";
				 				var totalVetItems=data.total_items;
				 				if(typeof(totalVetItems) != "undefined")
				 					$("#NoOfVetSearchResult").html("<span class='vet-search-result-count'>"+totalVetItems+" Results </span>")
				 				if(typeof(Items) != "undefined"){
				 					var count=0;
				 					for( var ixi in Items){
				 						var adrVetClinic = Items[ixi].address;
				 						var countryVetClinic= Items[ixi].country;
				 						var stateVetClinic= Items[ixi].state;
				 						var phone_numberVetClinic= Items[ixi].phone_number;
				 						var zip_codeVetClinic= Items[ixi].zip_code;
				 						var cityVetClinic= Items[ixi].city;
				 						var nameVetClinic= Items[ixi].name;
				 						var idVetClinic= Items[ixi].id;
				 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
				 						var wrxOnlyClinic = Items[ixi].written_rx_only;
				 						var internalHospital = Items[ixi].internal_hospital;
				 						if(idVetClinic == '999999999'){
				 							cityVetClinic = 'Vetco Vaccination Clinics are Petco\'s prevention and wellness clinics. Select Vetco Vaccination Clinic if you have an active prescription from a Vetco Vaccination Clinic veterinarian';
				 							stateVetClinic="";
				 							zip_codeVetClinic="";
				 						}
				 						htmlVal = htmlVal+"<div class='widget-vet-clinic-row-container vet-search-result-container' >";
				 						htmlVal = htmlVal+"<div class='row vetclinicrow' style=' background-color: #FFFFFF; '>";
				 						htmlVal = htmlVal+"<div class='col-8 col-8-sm' style='display: flex;'>";
				 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/vetclinic.png' style='width: 19px;' class='vet-search-result-vetclinic-img'>";
				 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' > <strong class='vet-search-result-vetclinic-name'>"+nameVetClinic+"</strong></br><span class='vet-search-result-vetclinic-info'>"+adrVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+cityVetClinic+" "+stateVetClinic+" "+zip_codeVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+phone_numberVetClinic+"</span></label>";
				 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
				 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
				 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
				 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
				 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
				 						htmlVal = htmlVal+"<input type='hidden' id='wrxOnlyVetClinic_"+idVetClinic+"_"+counter+"' value='"+wrxOnlyClinic+"'/>";
				 						htmlVal = htmlVal+"<input type='hidden' id='internalHospital_"+idVetClinic+"_"+counter+"' value='"+internalHospital+"'/>";
				 						htmlVal = htmlVal+"</div>";
				 						htmlVal = htmlVal+"<div class='col-4 col-4-sm'>";
				 						htmlVal = htmlVal+"<div class='row vetClinicresultrow margin-bottom-sm' id='vetClinicresultrowId_"+idVetClinic+"_"+counter+"'>";
				 						htmlVal = htmlVal+"<a class='btn btn-primary btn-full' id='RX_MyVetSaveInfo_"+idVetClinic+"_"+counter+"' onclick='petcoPersonalizationJS.saveRDVetClinicInfoV2("+idVetClinic+","+counter+",this);' href='javascript:void(0)'>SELECT</a>";
				 						htmlVal = htmlVal+"</div>";
				 						htmlVal = htmlVal+"</div>";
				 						if(wrxOnlyClinic && wrxOnlyClinic == true){
				 						htmlVal = htmlVal+"<div class='col-12 vet-search-result-wrx-vetclinic-div'>";
				 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/mail_icon.png' class='vet-search-result-wrx-vetclinic-mail-img'> Clinic Requires Written Prescription or Authorization ";
				 						htmlVal = htmlVal+"<div class='tooltip-vet'><img src='/wcsstore/PetcoStore/img/info-icon-img.png' class='vet-search-result-wrx-vetclinic-info-img'><span class='tooltiptext-vet vet-search-result-wrx-vetclinic-tooltip'>This Veterinary Clinic has a policy that requires you to provide the original prescription to online pharmacies. We will show you how to do this after your order is placed.</span></div></div>";
				 						}
				 						htmlVal = htmlVal+"</div>";
				 						htmlVal = htmlVal+"</div>";
				 						count++;
				 					}
				 					if(count == 0){
				 						$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
				 					}
				 				}else{
				 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
				 				}


				 											 					 				var currentCount = ((((page*1))*10)+(data.page_items*1))-10;
				 				if(currentCount > (data.total_items*1)){
				 					currentCount = (data.total_items*1);
				 				}
				 				if(typeof(data) != 'undefined' && !isNaN(currentCount) && currentCount != (data.total_items*1) && (data.page_items*1) > 0 ){
				 					$("#RX_VetLoadNextPage_"+counter).remove();
				 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
				 						htmlVal = htmlVal+"<div class='row'>";
					 					htmlVal = htmlVal+"<div class='col-12 text-center'>";
					 					htmlVal = htmlVal+"<div class='text-center'><a id='loadMoreLink' class='editLink load-more-link' onclick='petcoPersonalizationJS.searchRDVetClinicV2("+(page+1)+",true);'>Load More...</a></div>";
				 						htmlVal = htmlVal+"</div></div>";
				 						$(containerFOrVetClinic).append(htmlVal);
				 				}else{
				 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
				 					$("#RX_VetLoadNextPage_"+counter).remove();
				 					$(containerFOrVetClinic).append(htmlVal);
				 					}
				 			}else{
				 				$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
				 			}
				 		},
				 		error: function(){
				 			$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
				 			console.log("SEARCH ERROR");
				 			var containerFOrVetClinic = $("#vetDietSearchResults_1");
				 			if(containerFOrVetClinic.length > 0 ){
				 				$(containerFOrVetClinic).html("");
				 			}
				 		}
				 	});
		    	}else{	    		
		    		var searchURL = apiUrl + '/practice/search?api_key=' + apiKey + '&zip_code=' +searchTerm + '&limit=20&radius=10&page='+page+'&vetsource=true';			    	
		    			$.ajax({
			 		url : searchURL,
			 		type : 'GET',
			 		cache: true,
			 		contentType: 'application/json',
			 		dataType: 'json',
			 		data: '',
			 		success: function(data){
			 			var containerFOrVetClinic = $("#vetDietSearchResults_"+counter);

					 				 			if(containerFOrVetClinic.length > 0 ){
			 				var Items = data.items;
			 				var htmlVal = "";
			 				var totalVetItems=data.total_items;
			 				if(typeof(totalVetItems) != "undefined")
			 					$("#NoOfVetSearchResult").html("<span class='vet-search-result-count'>"+totalVetItems+" Results </span>")
			 				if(typeof(Items) != "undefined"){
			 					var count=0;
			 					for( var ixi in Items){
			 						var adrVetClinic = Items[ixi].address;
			 						var countryVetClinic= Items[ixi].country;
			 						var stateVetClinic= Items[ixi].state;
			 						var phone_numberVetClinic= Items[ixi].phone_number;
			 						var zip_codeVetClinic= Items[ixi].zip_code;
			 						var cityVetClinic= Items[ixi].city;
			 						var nameVetClinic= Items[ixi].name;
			 						var idVetClinic= Items[ixi].id;
			 						var fax_phone_numberVetClinic= Items[ixi].fax_phone_number;
			 						var wrxOnlyClinic = Items[ixi].written_rx_only;
			 						var internalHospital = Items[ixi].internal_hospital;
			 						if(idVetClinic == '999999999'){
			 							cityVetClinic = 'Vetco Vaccination Clinics are Petco\'s prevention and wellness clinics. Select Vetco Vaccination Clinic if you have an active prescription from a Vetco Vaccination Clinic veterinarian';
			 							stateVetClinic="";
			 							zip_codeVetClinic="";
			 						}
			 						htmlVal = htmlVal+"<div class='widget-vet-clinic-row-container vet-search-result-container' >";
			 						htmlVal = htmlVal+"<div class='row vetclinicrow' style=' background-color: #FFFFFF; '>";
			 						htmlVal = htmlVal+"<div class='col-8 col-8-sm' style='display: flex;'>";
			 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/vetclinic.png' style='width: 19px;' class='vet-search-result-vetclinic-img'>";
			 						htmlVal = htmlVal+"<label for='selectClinNameId_"+idVetClinic+"_"+counter+"' > <strong class='vet-search-result-vetclinic-name'>"+nameVetClinic+"</strong></br><span class='vet-search-result-vetclinic-info'>"+adrVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+cityVetClinic+" "+stateVetClinic+" "+zip_codeVetClinic+"</span></br><span class='vet-search-result-vetclinic-info'>"+phone_numberVetClinic+"</span></label>";
			 						htmlVal = htmlVal+"<input type='hidden' id='idVetClinic_"+idVetClinic+"_"+counter+"' value='"+idVetClinic+"'/>";
			 						htmlVal = htmlVal+"<input type='hidden' id='adrVetClinic_"+idVetClinic+"_"+counter+"' value='"+adrVetClinic+"'/>";
			 						htmlVal = htmlVal+"<input type='hidden' id='nameVetClinic_"+idVetClinic+"_"+counter+"' value='"+nameVetClinic+"'/>";
			 						htmlVal = htmlVal+"<input type='hidden' id='phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+phone_numberVetClinic+"'/>";
			 						htmlVal = htmlVal+"<input type='hidden' id='fax_phone_numberVetClinic_"+idVetClinic+"_"+counter+"' value='"+fax_phone_numberVetClinic+"'/>";
			 						htmlVal = htmlVal+"<input type='hidden' id='wrxOnlyVetClinic_"+idVetClinic+"_"+counter+"' value='"+wrxOnlyClinic+"'/>";
			 						htmlVal = htmlVal+"<input type='hidden' id='internalHospital_"+idVetClinic+"_"+counter+"' value='"+internalHospital+"'/>";
			 						htmlVal = htmlVal+"</div>";
			 						htmlVal = htmlVal+"<div class='col-4 col-4-sm'>";
			 						htmlVal = htmlVal+"<div class='row vetClinicresultrow margin-bottom-sm' id='vetClinicresultrowId_"+idVetClinic+"_"+counter+"'>";
			 						htmlVal = htmlVal+"<a class='btn btn-primary btn-full' id='RX_MyVetSaveInfo_"+idVetClinic+"_"+counter+"' onclick='petcoPersonalizationJS.saveRDVetClinicInfoV2("+idVetClinic+","+counter+",this);' href='javascript:void(0)'>SELECT</a>";
			 						htmlVal = htmlVal+"</div>";
			 						htmlVal = htmlVal+"</div>";
			 						if(wrxOnlyClinic && wrxOnlyClinic == true){
			 						htmlVal = htmlVal+"<div class='col-12 vet-search-result-wrx-vetclinic-div'>";
			 						htmlVal = htmlVal+"<img src='/wcsstore/PetcoStore/img/mail_icon.png' class='vet-search-result-wrx-vetclinic-mail-img'> Clinic Requires Written Prescription or Authorization ";
			 						htmlVal = htmlVal+"<div class='tooltip-vet'><img src='/wcsstore/PetcoStore/img/info-icon-img.png' class='vet-search-result-wrx-vetclinic-info-img'><span class='tooltiptext-vet vet-search-result-wrx-vetclinic-tooltip'>This Veterinary Clinic has a policy that requires you to provide the original prescription to online pharmacies. We will show you how to do this after your order is placed.</span></div></div>";
			 						}
			 						htmlVal = htmlVal+"</div>";
			 						htmlVal = htmlVal+"</div>";
			 						count++;
			 					}
			 					if(count == 0){
			 						$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
			 					}
			 				}else{
			 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
			 				}


			 									 					 				var currentCount = ((((page*1))*10)+(data.page_items*1))-10;
			 				if(currentCount > (data.total_items*1)){
			 					currentCount = (data.total_items*1);
			 				}
			 				if(typeof(data) != 'undefined' && !isNaN(currentCount) && currentCount != (data.total_items*1) && (data.page_items*1) > 0 ){
			 					$("#RX_VetLoadNextPage_"+counter).remove();
			 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
			 						htmlVal = htmlVal+"<div class='row'>";
				 					htmlVal = htmlVal+"<div class='col-12 text-center'>";
				 					htmlVal = htmlVal+"<div class='text-center'><a id='loadMoreLink' class='editLink load-more-link' onclick='petcoPersonalizationJS.searchRDVetClinicV2("+(page+1)+",true);'>Load More...</a></div>";
			 						htmlVal = htmlVal+"</div></div>";
			 						$(containerFOrVetClinic).append(htmlVal);
			 				}else{
			 					$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
			 					$("#RX_VetLoadNextPage_"+counter).remove();
			 					$(containerFOrVetClinic).append(htmlVal);
			 					}
			 			}else{
			 				$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
			 			}
			 		},
			 		error: function(){
			 			$("#vetDietSearchResults_"+counter+" div.text-center img.vetclinicnamecontainer").addClass("hide");
			 			console.log("SEARCH ERROR");
			 			var containerFOrVetClinic = $("#vetDietSearchResults_1");
			 			if(containerFOrVetClinic.length > 0 ){
			 				$(containerFOrVetClinic).html("");
			 			}
			 		}
			 	});}
			},
			saveRDVetClinicInfoV2:function(id,counter,obj){
				if($(obj).hasClass('disabled')){
		    		return;
		    	}
		    	var vFirstName="";
		    	var vLastName="";
		    	var vClinicName="";
		    	var vPhoneNumber = "";
		    	var vFaxNo = "";
		    	var vClinicId = "";
		    	var vVetId = "";


		    			    			    	vClinicName = $("#nameVetClinic_"+id+"_"+counter+"").val();
		    	vClinicId = id;
		    	vVetId = id;
				vPhoneNumber= $("#phone_numberVetClinic_"+id+"_"+counter+"").val();
				vFaxNo= $("#fax_phone_numberVetClinic_"+id+"_"+counter+"").val();

						    	$("#rxVetDietVetName_"+counter).val('Clinic');	
		    	$("#rxVetDietVetLastName_"+counter).val('Veterinarian');

		    			    	var isWrxOnlyClinc = $("#wrxOnlyVetClinic_"+id+"_"+counter).val();
		    	var isInternalHospital = $("#internalHospital_"+id+"_"+counter).val();
		    	var vetAttribute = "vetsource";
		    	if( $("#wrxEnabled").length >0  && $("#wrxEnabled").val() == 'true' && isWrxOnlyClinc == 'true') {
					vetAttribute = vetAttribute+",wrx";
				}
		    	if($("#internalHospitalFilterEnabled").length >0  && $("#internalHospitalFilterEnabled").val() == 'true' && isInternalHospital == 'true'){
					vetAttribute = vetAttribute+",internal_hospital";
				}
				$("#rxVetDietVetAttribute_"+counter).val(vetAttribute);	

		    			    	if(vClinicName != null && vClinicName != 'null' && vClinicName != ''){
		    		$("#rxVetDietVetCliName_"+counter).val(vClinicName);
		    	}
		    	if(vPhoneNumber != null && vPhoneNumber != 'null' ){
		    		$("#rxVetDietVetPhNo_"+counter).val(vPhoneNumber);
		    	}
		    	if(vFaxNo != null && vFaxNo != 'null' && vFaxNo != ''){
		    	    $("#rxVetDietVetFxNo_"+counter).val(vFaxNo);
		    	}

		    			    	$("#rxVetDietVetAPIId_"+counter).val('');
		        if(vClinicId != null && vClinicId != 'null' && vClinicId != ''){
		        	$("#rxVetDietVetClinicId_"+counter).val(vClinicId);
		    	}       

								if($('[id^="rxVetDietVetDetailsSubmit_SPC"]').length > 0){
					$("#rxVetDietVetDetailsSubmit_SPC").click()   		
		        }   
			},
			rxVetDietVetSubmitFormRD: function(obj){


												var params = {};
				params.catEntryId_1	=$("#catalogEntryDetailsId_1").val();
				params.orderId	= $("#parentOrderId_1").val();
				params.subscriptionId	= $("#subscriptionId_1").val();
				params.rxMedicineVetName = $("#rxVetDietVetName_1").val();
				params.rxMedicineVetLastName = $("#rxVetDietVetLastName_1").val();
				params.rxMedicineVetCliName = $("#rxVetDietVetCliName_1").val();
				params.rxMedicineVetPhNo = $("#rxVetDietVetPhNo_1").val();
				params.rxMedicineVetFxNo = $("#rxVetDietVetFxNo_1").val();
				params.rxMedicineVetAPIId = $("#rxVetDietVetAPIId_1").val();
				params.rxMedicineVetClinicId = $("#rxVetDietVetClinicId_1").val();
				params.rxVetAttribute = $("#rxVetDietVetAttribute_1").val();
				params.storeId = $("#storeId").val();
				params.catalogId = $("#catalogId").val();
				params.langId = $("#langId").val();
				params.isUpdateVetDetails = "true";
				params.fromRDVetChange = "true";
				params.action="addVet";
				cursor_wait();
				wc.service.invoke("AjaxUpdateOrderItemRxRdInfo",params);
			}

}