

wc.service.declare({
	id:"ShoppingListServiceCreate",
	actionId:"ShoppingListServiceCreate",
	url:"AjaxGiftListServiceCreate",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();
		closeAllDialogs();	

				dojo.topic.publish('ShoppingList_Changed', {listId: serviceResponse.giftListId[0], listName: serviceResponse.giftListName[0], action: 'add'});
	}

	,failureHandler: function(serviceResponse) {
		cursor_clear();
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}

			}			
}),

wc.service.declare({
	id:"ShoppingListServiceUpdate",
	actionId:"ShoppingListServiceUpdate",
	url:"AjaxGiftListServiceUpdateDescription",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();
		MessageHelper.hideAndClearMessage();
	}

	,failureHandler: function(serviceResponse) {
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),

wc.service.declare({
	id:"ShoppingListServiceDelete",
	actionId:"ShoppingListServiceDelete",
	url:"AjaxGiftListServiceDeleteGiftList",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();			
		closeAllDialogs();
		shoppingListJS.showMessageDialog(storeNLS['LIST_DELETED']);

				dojo.topic.publish('ShoppingList_Changed', {listId: serviceResponse.giftListId[0], listName: '', action: 'delete'});
	}

	,failureHandler: function(serviceResponse) {
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),

wc.service.declare({
	id:"ShoppingListServiceAddItem",
	actionId:"ShoppingListServiceAddItem",
	url:"AjaxGiftListServiceAddItem",
	formId:""

	,successHandler: function(serviceResponse) {

		var addListMessage = "<div class=\"alert alert-success\"> <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-hidden=\"true\">&times;</button>This item has been added to your list.</div>";
		$("#addToShoppingList").after(addListMessage);
		wc.render.updateContext('MylistsDisplay_Context',{'startIndex':'0'});	
		if(document.getElementById("purListId") && document.getElementById("purListExtId")){
			wc.render.updateContext('PurchaselistDisplay_Context',{'showTabName':'purchaselist','purStartDate':'','purEndDate':'','purStartDateParsed':'','purEndDateParsed':'','fromGoCmd':false,'startIndex':document.getElementById("startIndexForPurList").value,'listId':document.getElementById("purListId").value,'externalId':document.getElementById("purListExtId").value,'requesttype':'ajax'});
		}else{
			wc.render.updateContext('PurchaselistDisplay_Context',{'showTabName':'purchaselist','purStartDate':'','purEndDate':'','purStartDateParsed':'','purEndDateParsed':'','fromGoCmd':false,'startIndex':'0','listId':'-1','externalId':'-1','requesttype':'ajax'});
		}
		if(!document.getElementById("add-to-controls")){
			$(window).scrollTop(0);
		}	
		var omnitureFlag = document.getElementById('omnitureEnabled');
		var omnitureEnabled = '';
		if(omnitureFlag != null && omnitureFlag != "undefined"){
			var productIdInput = document.getElementById("product_id");
			var productSkuInput = document.getElementById("tel_product_sku");
			var productNameInput = document.getElementById("tel_product_name");
			var productId = '';
			var productSku = '';
			var productName = '';
			if(productIdInput != null && productIdInput != "undefined"){
				productId = productIdInput.value
			}
			if(productSkuInput != null && productSkuInput != "undefined"){
				productSku = productSkuInput.value
			}
			if(productNameInput != null && productNameInput != "undefined"){
				productName = productNameInput.value
			}
			omnitureEnabled = omnitureFlag.value;
			if(omnitureEnabled === 'true'){
				var cartEventDetails = {};
				cartEventDetails["product_page_action"] = "wish_list_add";
				cartEventDetails["product_id"] = productId;
				cartEventDetails["product_sku"] = productSku;
				cartEventDetails["product_name"] = productName;
				if($('#tel_product_id').size()>0){
					cartEventDetails["product_parent_sku"] = $('#tel_product_id').val();
				}
				cartEventDetails["event_name"] = "add_to_wishlist";
				cartEventDetails["event_status"] = "end"; 
				pushEvent(cartEventDetails); 
			}
		}
		cursor_clear();
	}

	,failureHandler: function(serviceResponse) {
		if(serviceResponse.errorMessageKey == '_ERR_WISHLIST_ITEM_DUPLICATE'){
			petcoCommonJS.showModalNotificationAlert(MessageHelper.messages[serviceResponse.errorMessageKey]);
			$(window).scrollTop(0);
		}
		else if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),
wc.service.declare({
	id:"PlaceOrderShoppingListServiceAddItem",
	actionId:"PlaceOrderShoppingListServiceAddItem",
	url:"AjaxGiftListServiceAddItem",
	formId:""

	,successHandler: function(serviceResponse) {
		var shipmentTypeId = CheckoutHelperJS.getShipmentTypeId();
		resetRequest();
		document.location.href = "OrderShippingBillingConfirmationView?storeId=" + ServicesDeclarationJS.storeId + "&catalogId=" + ServicesDeclarationJS.catalogId + "&langId=" + ServicesDeclarationJS.langId + "&orderId=" + document.getElementById("orderIdForPurcList").value + "&shipmentTypeId=" + shipmentTypeId;
		console.debug('Purchase List Add successful');
	}

	,failureHandler: function(serviceResponse) {
		var shipmentTypeId = CheckoutHelperJS.getShipmentTypeId();
		resetRequest();
		document.location.href = "OrderShippingBillingConfirmationView?storeId=" + ServicesDeclarationJS.storeId + "&catalogId=" + ServicesDeclarationJS.catalogId + "&langId=" + ServicesDeclarationJS.langId + "&orderId=" + document.getElementById("orderIdForPurcList").value + "&shipmentTypeId=" + shipmentTypeId;
		console.debug('Purchase List Add failed');
	}			
}),

wc.service.declare({
	id:"ShoppingListServiceRemoveItem",
	actionId:"ShoppingListServiceRemoveItem",
	url:"AjaxGiftListServiceUpdateItem",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();		
		MessageHelper.hideAndClearMessage();
		if(document.getElementById("startIndexForWishList")){
			wc.render.updateContext('MylistsDisplay_Context',{'startIndex':document.getElementById("startIndexForWishList").value});
		}else{
			wc.render.updateContext('MylistsDisplay_Context',{'startIndex':'0'});
		}

				if(document.getElementById("purListId") && document.getElementById("purListExtId")){
			wc.render.updateContext('PurchaselistDisplay_Context',{'showTabName':'purchaselist','purStartDate':'','purEndDate':'','purStartDateParsed':'','purEndDateParsed':'','fromGoCmd':false,'startIndex':document.getElementById("startIndexForPurList").value,'listId':document.getElementById("purListId").value,'externalId':document.getElementById("purListExtId").value,'requesttype':'ajax'});
		}else{
			wc.render.updateContext('PurchaselistDisplay_Context',{'showTabName':'purchaselist','purStartDate':'','purEndDate':'','purStartDateParsed':'','purEndDateParsed':'','fromGoCmd':false,'startIndex':'0','listId':'-1','externalId':'-1','requesttype':'ajax'});
		}
	}

	,failureHandler: function(serviceResponse) {
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),

wc.service.declare({
	id:"ShoppingListServiceUpdateItem",
	actionId:"ShoppingListServiceUpdateItem",
	url:"AjaxGiftListServiceUpdateItem",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();		
		MessageHelper.hideAndClearMessage();

				if(document.getElementById("startIndexForWishList")){
			wc.render.updateContext('MylistsDisplay_Context',{'startIndex':document.getElementById("startIndexForWishList").value});
		}else{
			wc.render.updateContext('MylistsDisplay_Context',{'startIndex':'0'});
		}
		if(document.getElementById("purListId") && document.getElementById("purListExtId")){
			wc.render.updateContext('PurchaselistDisplay_Context',{'showTabName':'purchaselist','purStartDate':'','purEndDate':'','purStartDateParsed':'','purEndDateParsed':'','fromGoCmd':false,'startIndex':document.getElementById("startIndexForPurList").value,'listId':document.getElementById("purListId").value,'externalId':document.getElementById("purListExtId").value,'requesttype':'ajax'});
		}else{
			wc.render.updateContext('PurchaselistDisplay_Context',{'showTabName':'purchaselist','purStartDate':'','purEndDate':'','purStartDateParsed':'','purEndDateParsed':'','fromGoCmd':false,'startIndex':'0','listId':'-1','externalId':'-1','requesttype':'ajax'});
		}

			}

	,failureHandler: function(serviceResponse) {
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),

wc.service.declare({
	id:"ShoppingListServiceAddItemAndRemoveFromCart",
	actionId:"ShoppingListServiceAddItemAndRemoveFromCart",
	url:"AjaxGiftListServiceAddItem",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();
		dojo.topic.publish("ShoppingListItem_Added");
	}

	,failureHandler: function(serviceResponse) {
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),

wc.service.declare({
	id:"AjaxGiftListServiceChangeGiftListStatus",
	actionId:"AjaxGiftListServiceChangeGiftListStatus",
	url:"AjaxGiftListServiceChangeGiftListStatus",
	formId:""

	,successHandler: function(serviceResponse) {
		cursor_clear();			
		MessageHelper.hideAndClearMessage();

		MultipleWishLists.updateDefaultListName('multipleWishListButton',serviceResponse.giftListName);		
		MultipleWishLists.updateDefaultListName('addToMultipleWishListLink',serviceResponse.giftListName);
		MultipleWishLists.setDefaultListId(serviceResponse.giftListId);
		MultipleWishLists.updateContextPostSwitch(serviceResponse.giftListId);
	}

	,failureHandler: function(serviceResponse) {
		if (serviceResponse.errorMessage) {
			MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
		} 
		else {
			 if (serviceResponse.errorMessageKey) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
			 }
		}
		cursor_clear();
	}			
}),

	wc.service.declare({
		id: "AjaxGiftListAnnouncement",
		actionId: "AjaxGiftListAnnouncement",
		url: getAbsoluteURL() + "AjaxGiftListServiceAnnounceGiftList",
		formId: ""

		,successHandler: function(serviceResponse) {
			cursor_clear();			
			MessageHelper.hideAndClearMessage();
			shoppingListJS.showMessageDialog(storeNLS['WISHLIST_EMAIL_SENT']);
		}
		,failureHandler: function(serviceResponse) {

			if (serviceResponse.errorMessage) {
				MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
			} 
			else {
				 if (serviceResponse.errorMessageKey) {
					MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
				 }
			}
			cursor_clear();
		}

	})
