
function ShoppingListJS(storeParams, catEntryParams, shoppingListNames, jsObjectName) {

		this.storeParams = storeParams;

		this.catEntryParams = catEntryParams;

	this.catEntryParams.quantity = 1;

		this.shoppingListNames = shoppingListNames;

		this.addItemAfterCreate = false;

		this.jsObjectName = jsObjectName;

		this.dropDownVisible = false;

	this.dropDownInFocus = false;

	this.dropDownOpen = false;

	this.exceptionFlag = false;

	this.mouseOnArrow = false;

		this.pageName = "";

		this.itemId = -1;

		var eventName = "";

	this.nameToDelete = "";

	this.orderItemId = "";

	this.actionBeingPerformed = "";

		this.isRxFoodCollectEnabled = true;

		if(jsObjectName != 'shoppingListJS'){
		this.pageName = jsObjectName.replace('shoppingListJS', '');
		eventName = this.pageName + "_";
	}

	this.setCatEntryQuantity = function(catEntryQuantity){
		var catEntryQuantity = dojo.fromJson(catEntryQuantity);
		if(dojo.isObject(catEntryQuantity)){
			var component = this.catEntryParams.components[catEntryQuantity.baseItemId];
			if(component.id != catEntryQuantity.baseItemId && catEntryQuantity.id !=0){
				component.id = catEntryQuantity.id;
			}
			component.quantity = catEntryQuantity.quantity;
		} else {
			this.catEntryParams.quantity = catEntryQuantity;
		}
	};

	this.setCatEntryAttributes = function(catEntryAttributes){
		this.catEntryParams.attributes = dojo.fromJson(catEntryAttributes);
	};

	this.setItemId = function(catEntryId){
		this.itemId = catEntryId;
	};

	this.hideDropDown = function() {
		var dropDown = dojo.byId(this.pageName + 'shoppingListDropDown');
		if (dropDown) {
			dropDown.style.display = "none";
			dojo.query("#" + this.pageName + "addToShoppingListBtn .drop")[0].focus();
			this.dropDownVisible = false;
			this.dropDownInFocus = false;
			this.dropDownOpen = false;

						var contentRightBorder = dojo.query(".widget_quick_info_popup .content_right_border")[0];
			if (contentRightBorder != undefined) {
				dojo.style(contentRightBorder, 'height', 'auto');
			}
		}
	};

	this.showDropDown = function() {
		if(this.dropDownOpen == false){
			var contentRightBorder = dojo.query(".widget_quick_info_popup .content_right_border")[0];
			var ocontentRightBorderH = dojo.position(contentRightBorder).h;
			var dropDown = dojo.byId(this.pageName + 'shoppingListDropDown');
			dropDown.style.display = "";
			this.dropDownVisible = true;

						dojo.query("#" + this.pageName + "shoppingListDropDown.dropdown_list div").removeClass("focused");

						if(dojo.byId("quickInfoRefreshArea") && dojo.byId("QuickInfoshoppingListDropDown") ) {  
				var quickInfoRefreshAreaH = dojo.position(dojo.byId("quickInfoRefreshArea")).h;		
				var shoppingListDropDownH = dojo.position(dojo.byId("QuickInfoshoppingListDropDown")).h;
				var contentRightBorderH = dojo.position(contentRightBorder).h;

								if((quickInfoRefreshAreaH + shoppingListDropDownH) > contentRightBorderH) {
					var quickInfoPopup = dijit.byId('quickInfoPopup');
					dojo.style(contentRightBorder, 'height', contentRightBorderH + shoppingListDropDownH + 'px');					
					quickInfoPopup.resize();
					if (ocontentRightBorderH < contentRightBorderH){
						dojo.style(contentRightBorder, 'height', ocontentRightBorderH + shoppingListDropDownH + 'px');
						quickInfoPopup.resize();
					}
				}
			}
			this.dropDownOpen = true;
		}
		else{
			this.hideDropDown();
		}
	};

	this.showPopup = function(action){
		this.hideDropDown();
		this.clearPopupText();
		var popup = dijit.byId(this.pageName + action + "ShoppingListPopup");
		this.hideErrorMessage();
		this.hideEditErrorMessage();
		if (popup !=null) {			
			popup.closeButtonNode.style.display='none';
			closeAllDialogs(); 
			popup.show();
			if (action == 'create') {
				dojo.byId(this.pageName + "newListName").focus();
			} else if (action == 'edit') {
				dojo.byId("editListName").focus();
			}
		}else {
			console.debug(action+"ShoppingListPopup"+" does not exist");
		}
	};

	 this.showSuccessDialog = function(){
		var popup = dijit.byId(this.pageName + "shoppingListCreateSuccessPopup");
		if (popup !=null && this.actionBeingPerformed != "") {	
			dojo.byId(this.pageName + "successMessageAreaText").innerHTML = storeNLS['LIST_CREATED'];
			popup.closeButtonNode.style.display='none';
			popup.show();
			this.actionBeingPerformed = "";
		}
	 };

	 this.showMessageDialog = function(message){
		var popup = dijit.byId(this.pageName + "shoppingListCreateSuccessPopup");

				if (popup !=null) {	
			dojo.byId(this.pageName + "successMessageAreaText").innerHTML = message;
			popup.closeButtonNode.style.display='none';
			popup.show();
		}
	 };	 

	this.showErrorMessage = function(msg){
		if(document.getElementById(this.pageName + "shoppingListErrorMessageArea") && document.getElementById(this.pageName + "shoppingListErrorMessageText")){
			document.getElementById(this.pageName + "shoppingListErrorMessageText").innerHTML = msg;
			document.getElementById(this.pageName + "shoppingListErrorMessageArea").style.display = "block";
		}
	};

	this.hideErrorMessage = function(){
		if(document.getElementById(this.pageName + "shoppingListErrorMessageArea") && document.getElementById(this.pageName + "shoppingListErrorMessageText")){
			document.getElementById(this.pageName + "shoppingListErrorMessageText").innerHTML = "";
			document.getElementById(this.pageName + "shoppingListErrorMessageArea").style.display = "none";
		}
	};

	this.showEditErrorMessage = function(msg){
		if(document.getElementById("editShoppingListErrorMessageArea") && document.getElementById("editShoppingListErrorMessageText")){
			document.getElementById("editShoppingListErrorMessageText").innerHTML = msg;
			document.getElementById("editShoppingListErrorMessageArea").style.display = "block";
		}
	};

	this.hideEditErrorMessage = function(){
		if(document.getElementById("editShoppingListErrorMessageArea") && document.getElementById("editShoppingListErrorMessageText")){
			document.getElementById("editShoppingListErrorMessageText").innerHTML = "";
			document.getElementById("editShoppingListErrorMessageArea").style.display = "none";
		}
	};

	this.create = function(){
		var name = trim(dojo.byId(this.pageName + "newListName").value);
		var maxlength = dojo.byId(this.pageName + "newListName").maxLength;
		var defaultName = storeNLS['DEFAULT_WISH_LIST_NAME'];

				if (this.empty(name)) {
			this.showErrorMessage(storeNLS['ERR_NAME_EMPTY']);
		} else if(!MessageHelper.isValidUTF8length(name, maxlength)){
			this.showErrorMessage(storeNLS['ERR_NAME_TOOLONG']);
		} else if(name == defaultName) {
			this.showErrorMessage(storeNLS['ERR_NAME_SHOPPING_LIST']);
		} else if(this.isDuplicate(name)){
			this.showErrorMessage(storeNLS['ERR_NAME_DUPLICATE']);
		} else if(!this.validateWishName(name)){
			this.showErrorMessage(storeNLS['INVALID_NAME_SHOPPING_LIST']);
		} else {
			var params = this.setCommonParams();
			params.name = name;

						var popup = dijit.byId(this.pageName + "createShoppingListPopup");
			if (popup !=null) {	
				popup.hide();
			}
			if(!submitRequest()){
				return;
			}
			cursor_wait();
			wc.service.invoke('ShoppingListServiceCreate',params);
			this.actionBeingPerformed = "create";
		}
	};

	this.createDefaultListAndAddItem = function(listId, orderItemId){

				if(($("#vetWidgetVendorScriptUrl").length >0 && $("#vetWidgetAppScriptUrl").length >0 && !isVetJsLoaded )){
			var vetScript1 = document.createElement("script");
			vetScript1.type= "text/javascript";
			vetScript1.src= $("#vetWidgetVendorScriptUrl").val();
			vetScript1.async=true
			var headTag=document.getElementsByTagName("head")[0];
			headTag.appendChild(vetScript1);
			var vetScript2 = document.createElement("script");
			vetScript2.type= "text/javascript";
			vetScript2.src= $("#vetWidgetAppScriptUrl").val();
			vetScript2.async=true
			var headTag=document.getElementsByTagName("head")[0];
			headTag.appendChild(vetScript2);
			setTimeout(function(){eval($("script#wetApiWidgetAfterAjax").html())},500);
			isVetJsLoaded = true;
		}




										if($('div[data-pageType="product-detail-page"]').length > 0){
			var isRXMedicine = false;
			if($("#rxMedicine").length >0 && $("#rxMedicine").val() == 'true' ){
				isRXMedicine = true;
			}
			if(isRXMedicine){
				if(petcoPersonalizationJS.petandvetInfoPulated){
					if (!submitRequest()) {
						return;
					}
						cursor_wait();
						petcoPersonalizationJS.loadPetAndVetInfoInModel();
				}
				if("R" != dojo.cookie("WC_UserType")){
					petcoPersonalizationJS.loadVetClicnicInfos(1,1,true);
				}
			}
		}


						if(document.getElementById("defaultShoppingListId_pdp") != null){
			if(document.getElementById("defaultShoppingListId_pdp").value != "" && document.getElementById("defaultShoppingListId_pdp").value != listId){
				listId = document.getElementById("defaultShoppingListId_pdp").value;
			}
		}
		if (orderItemId && orderItemId != "") {
			this.orderItemId = orderItemId;
		}
		var purhaselist = $("#purchaseListId");


						if($("#isRXFoodIndicator").length >0 && $("#isRXFoodIndicator").val() == 'true' ){
			if($("#isRxFoodCollectPdpEnabled").length >0 && $("#isRxFoodCollectPdpEnabled").val() == 'false'){
				this.isRxFoodCollectEnabled = false;
			}
		}

		if((petcoPersonalizationJS.isPersonalizedItem()&& !petcoPersonalizationJS.isRxMedicineItem() && this.isRxFoodCollectEnabled && !$('#personilaztionDisplay').hasClass("active"))
		|| (petcoPersonalizationJS.isRxMedicineItem() && petcoPersonalizationJS.isPersonalizedItem()&&
				!$('#rxPersonilaztionDisplay').hasClass("active"))		
		){
			dojo.forEach(dojo.query('div[id="cart-submit-button"]'),function(obj){
				obj.className = "col-8 col-center hide";
			})
			dojo.forEach(dojo.query('div[id="pdp-submit-button"]'),function(obj){
					obj.className = "col-8 col-center hide";
			})
			dojo.forEach(dojo.query('div[id="wishList-submit-button"]'),function(obj){
				obj.className = "col-8 col-center show";
			})
			if(!petcoPersonalizationJS.isRxMedicineItem()){
				petcoCommonJS.showModal('Personalization'); 
				$('#Personalization_1').show();
				$('#personilaztionDisplay').addClass('active');
				if(0 < $("#isPersonalizationMandate").length){
					var isPersonalizationMandate = $("#isPersonalizationMandate").val();
					if("false" == isPersonalizationMandate){
						$(".personalizationMandatory").removeClass('hide');
						$(".personalizationMandatory").addClass('show');
					}
				}
			}else{
				$('#rxPersonilaztionDisplay').addClass("active")
				$('[id^="wishList-submit-button"]').addClass("show");
				$('[id^="wishList-submit-button"]').removeClass("hide");
				$('[id^="pdp-submit-button"]').removeClass("show");
				$('[id^="pdp-submit-button"]').addClass("hide");				
				petcoCommonJS.showModal('modal-rxmedicine'); 
			}

		}else{
			if(purhaselist.length > 0 && purhaselist.val() == "false"){
				var params = {};	
				params.storeId		= this.storeParams.storeId;
				params.catalogId	= this.storeParams.catalogId;
				params.langId		= this.storeParams.langId;
				params.name = 'PURCHASELIST';
				wc.service.invoke('ShoppingListServiceCreate',params);
				var thisObj = this;
				dojo.topic.subscribe("ShoppingList_Changed", function(serviceResponse) {
					if(serviceResponse.listName == "PURCHASELIST" && serviceResponse.action=="add"){
						if("-1" == listId){
							var params = thisObj.setCommonParams();
								params.name = storeNLS['DEFAULT_WISH_LIST_NAME'];

							if(!submitRequest()){
								return;
							}
							cursor_wait();
							thisObj.addItemAfterCreate = true;
							wc.service.invoke('ShoppingListServiceCreate',params);
						} else {
							if (orderItemId && orderItemId != "") {
								thisObj.addToListAndDelete(listId, orderItemId);
							} else {
								thisObj.addToList(listId);
							}
						}
					}
					});

								}else{

										if("-1" == listId){
						var params = this.setCommonParams();
						var cookieVal = dojo.cookie('pcustData');
							params.name = storeNLS['DEFAULT_WISH_LIST_NAME'];

						if(!submitRequest()){
							return;
						}
						cursor_wait();
						this.addItemAfterCreate = true;
						wc.service.invoke('ShoppingListServiceCreate',params);




																												} else {
						if (orderItemId && orderItemId != "") {
							this.addToListAndDelete(listId, orderItemId);
						} else {
							this.addToList(listId);
						}
					}
			}
		}
		try{
			var omnitureEnabled
			if(dojo.byId("omnitureEnabled") != undefined && dojo.byId("omnitureEnabled") != null)
				omnitureEnabled = dojo.byId("omnitureEnabled").value;
			if(omnitureEnabled != "undefined" && null != omnitureEnabled && omnitureEnabled !='' && omnitureEnabled == 'true'){
				var productId = productDisplayJS.bopusShowStoreDetailsCatEntryId;
				if(productId != undefined && null != productId){
					var catEntry = productDisplayJS.itemPriceJsonOject[productId].catalogEntry;
					if(catEntry != undefined && catEntry != null){
						var cartOrderId = dojo.cookie("WC_CartOrderId_10151");
						var cartEventDetails = {};
						cartEventDetails["cart_event"] = "remove";
						cartEventDetails["event_name"] = "add_to_wishlist";
						cartEventDetails["cart_action_save"] = "yes";
						if(cartOrderId != null && cartOrderId != "")
							cartEventDetails["cart_id"] = cartOrderId;
						cartEventDetails["product_id"] = productId;
						cartEventDetails["product_sku"] = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
						cartEventDetails["product_name"] = catEntry.description[0].name;
						if($('#tel_product_id').size()>0){
							cartEventDetails["product_parent_sku"] = $('#tel_product_id').val();
						}

												pushEvent(cartEventDetails);
					}

									}
			}
		}catch(err){
			console.log(err);
		}
	};

	this.createDefaultListAndAddItemFrmPurList = function(listId, orderItemId,quantity,fromFlow,listItemId){

				if (orderItemId && orderItemId != "") {
			this.orderItemId = orderItemId;
		}
		if($('#defaultWishList').length >0 ){
			listId = $('#defaultWishList').val();
		}
		if("-1" == listId){
			var params = this.setCommonParams();
				params.name = storeNLS['DEFAULT_WISH_LIST_NAME'];

			if(!submitRequest()){
				return;
			}
			cursor_wait();
			this.addItemAfterCreate = true;
			wc.service.invoke('ShoppingListServiceCreate',params);
		} else {
			if (orderItemId && orderItemId != "") {
				this.addToListAndDelete(listId, orderItemId);
			} else {
				var catentryId = this.catEntryParams.id;
				this.addToListFrmPurList(listId,catentryId,quantity,fromFlow,listItemId);
			}
		}
	};
	this.addToList = function(listId){
		var omnitureFlag = document.getElementById('omnitureEnabled');
		var omnitureEnabled = '';
		if(omnitureFlag != null && omnitureFlag != "undefined"){
			omnitureEnabled = omnitureFlag.value;
			if(omnitureEnabled === 'true'){
				var eventStatus = {"event_status" : "start", "event_action" : "", "event_name" : "add_to_wishlist","event_type" : ""};
				eval(pushEvent(eventStatus));
			}
		}
		this.hideDropDown();

				var params = this.setCommonParams();
		params.giftListId = listId;
		params.name = storeNLS['DEFAULT_WISH_LIST_NAME'];

				var catEntryId = this.catEntryParams.id;
		if(this.catEntryParams.type.toLowerCase() == 'itembean'
			|| this.catEntryParams.type.toLowerCase() == 'packagebean'
			|| this.catEntryParams.type.toLowerCase() == 'dynamickitbean'){
			updateParamObject(params,"catEntryId",this.catEntryParams.id,false,-1);
			updateParamObject(params,"quantity",this.catEntryParams.quantity,false,-1);
		} else if(this.catEntryParams.type.toLowerCase() =='bundlebean'){

			for(baseItemId in this.catEntryParams.components){
				updateParamObject(params,"catEntryId",this.catEntryParams.components[baseItemId].id,false,-1);
				updateParamObject(params,"quantity",this.catEntryParams.components[baseItemId].quantity,false,-1);
			}
		} else {

			var sku = this.itemId;
			if (sku == -1){
				sku = this.resolveSKU();
			}
			if(-1 == sku){
				MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
				return;
			} else {
				catEntryId = sku;
				updateParamObject(params,"catEntryId",sku,false,-1);
				updateParamObject(params,"quantity",this.catEntryParams.quantity,false,-1);
			}
		}
		if(this.jsObjectName != 'shoppingListJS'){
		}

		if (petcoPersonalizationJS.isPersonalizedItem() && !petcoPersonalizationJS.isRxMedicineItem() && this.isRxFoodCollectEnabled) {
			var customParams = true;
		    var invalidChars = petcoPersonalizationJS.findInvalidCharSetForUserInput(this.catEntryParams.quantity, customParams);
		    if (invalidChars != "") {
		        return false;
		    }
		    if (!petcoPersonalizationJS.validatePersonalizedRequiredField(this.catEntryParams.quantity, customParams)) {
		        return false;
		    }
		    if (!petcoPersonalizationJS.validatePersonalizedTextInputType(this.catEntryParams.quantity, customParams)) {
		        return false;
		    }

		    var isPersonalizeItem = document.getElementById("personalization");
		    var personalizationList = document.getElementById("personalizationAttrNames");
		    var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");
		    var pFlag = false;
		    var pName = personalizationList.value;
		    pName = pName + "_1";
		    if (document.getElementById(pName) != null)
		        pFlag = true;

		    if (isPersonalizeItem != 'undefined' && isPersonalizeItem != null) {
		        if (isPersonalizeItem.value == 'true' || pFlag) {
		            for (var i = 0; i < this.catEntryParams.quantity; i++) {
		                if (isSameValueForAllQty != 'undefined' && isSameValueForAllQty != null && isSameValueForAllQty.checked == true) {
		                    if (i == 1) {
		                        break;
		                    }
		                }
		                var personalizationName = personalizationList.value.split(',');
				                for (var j = 0; j < (personalizationName.length); j++) {
				                    var nameAttr = personalizationName[j] + '_' + (1);
				                    var personalizedName = document.getElementById(nameAttr);
				                    if (personalizedName != undefined || personalizedName != null) {		
				                		if (personalizedName.value.length < 64) {
				                			updateParamObject(params, "attrName", personalizationName[j], false, -1);
		                			        updateParamObject(params, "attrVal", personalizedName.value, false, -1);
		                			        console.log("ShoppingList attrName-" + personalizationName[j] + "-attrVal-" + personalizedName.value);		

				                									                       } else {
				                		var myarray = (personalizedName.value).match(/.{1,63}/g);
				                        for (var i = 0; i < myarray.length; i++) {
				                			var finalAttrName= personalizationName[j]+ "_"+ i;
				                			 updateParamObject(params, "attrName", finalAttrName, false, -1);
				                			 updateParamObject(params, "attrVal", myarray[i], false, -1);
				                		     console.log("ShoppingList attrName-" + personalizationName[j]+ "_"+ i + "-attrVal-" + myarray[i]);
				                		}  

						                    }
				                  }
				                }
		                }

		            }
		        }
		   }else if (petcoPersonalizationJS.isPersonalizedItem() && petcoPersonalizationJS.isRxMedicineItem()) {
			    var customParams = true;
			    var isPersonalizeItem = document.getElementById("personalization");
			    var isRxMedicinePersonalizeItem = document.getElementById("rxMedicine");
			    var isSameValueForAllQty = document.getElementById("PersonalizationCheckBox");
			    if (isPersonalizeItem != 'undefined' && isPersonalizeItem != null && isPersonalizeItem.value == 'true' &&
			        isRxMedicinePersonalizeItem != 'undefined' && isRxMedicinePersonalizeItem != null && isRxMedicinePersonalizeItem.value == 'true') {
			        var jsonFieldEleContent = $("#fieldGroup").html();
			        var jsonField = JSON.parse(jsonFieldEleContent);
			        for (var i = 0; i < this.catEntryParams.quantity; i++) {
			            if (isSameValueForAllQty != 'undefined' && isSameValueForAllQty != null && isSameValueForAllQty.checked == true) {
			                if (i == 1) {
			                    break;
			                }
			            }
			            for (var j in jsonField) {
			                var isValidForSubmit = true;
			                var idOfField = $('[id^="' + jsonField[j].Field + "_" + ((i * 1) + 1) + '"]');
			                var LabelRx = jsonField[j].Label;
			                var uniqueIdentifier = jsonField[j].Field;
			                var OrderOfLoad = jsonField[j].Order;
			                var isRequired = jsonField[j].IsRequired;
			                var isRequiredInCart = jsonField[j].IsRequiredInCart;
			                if (idOfField.length > 0) {
			                    var isNumric = false;
			                    var isTeliphonic = false;
			                    var isOptionOthersExists = false;
			                    var isDateField = false;
			                    var isRadio = false;
			                    var isOptionOthersMultipleExists = false;
			                    if (idOfField[0].className.indexOf("NUMERIC") > -1) {
			                        isNumric = true;
			                    } else if (idOfField[0].className.indexOf("TELEPHONE") > -1) {
			                        isTeliphonic = true;
			                    } else if(idOfField[0].className.indexOf("MULTICHECKBOX") > -1){
				                	isOptionOthersMultipleExists = true;
				                }else if (idOfField[0].className.indexOf("CHECKBOX") > -1) {
				                    isOptionOthersExists = true;
				                }else if (idOfField[0].className.indexOf("DATE") > -1) {
			                        isDateField = true;
			                    } else if (idOfField[0].className.indexOf("RADIO") > -1) {
			                        isRadio = true;
			                    }
			                    if (!isOptionOthersMultipleExists && !isOptionOthersExists && !isDateField && !isRadio) {
			                    	if(idOfField[0].value.trim().length > 0){
			                        if (idOfField[0].value.trim().length < 64) {
			                            updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
			                            updateParamObject(params, "attrVal", idOfField[0].value.trim(), false, -1);

			                        } else {
			                            var myarray = (idOfField[0].value.trim()).match(/.{1,63}/g);
			                            for (var ix = 0; ix < myarray.length; ix++) {
			                                var finalAttrName = "RXMED-"+uniqueIdentifier + "_" + ix;
			                                updateParamObject(params, "attrName", finalAttrName, false, -1);
			                                updateParamObject(params, "attrVal", myarray[ix], false, -1);
			                            }
			                        }
			                    	}
			                    } else if (isRadio) {
			                        var checkedRadio = $('input[type="radio"][name="' + jsonField[j].Field + "_" + ((i * 1) + 1) + '"]:checked');
			                        if (checkedRadio.length != 0) {
			                            if ($(checkedRadio).val().trim().length < 64) {
			                                updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
			                                updateParamObject(params, "attrVal", $(checkedRadio).val().trim(), false, -1);

			                            } else {
			                                var myarray = ($(checkedRadio).val().trim()).match(/.{1,63}/g);
			                                for (var ix = 0; ix < myarray.length; ix++) {
			                                    var finalAttrName = "RXMED-"+uniqueIdentifier + "_" + ix;
			                                    updateParamObject(params, "attrName", finalAttrName, false, -1);
			                                    updateParamObject(params, "attrVal", myarray[ix], false, -1);
			                                }
			                            }
			                        }
			                    } else if(isOptionOthersMultipleExists){

			                    				                    	  var checkedRadioOthers = $('input[type="checkbox"][name="' + jsonField[j].Field + "_" + ((i * 1) + 1) + '"]:checked');
				                        if (checkedRadioOthers.length == 0) {
				                            updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
				                            updateParamObject(params, "attrVal", 'No', false, -1);
				                        } else if (checkedRadioOthers.length > 0 &&  $('[id^="'+jsonField[j].Field+'_'+((i*1)+1)+'-_options_-"]:checked').length > 0) {
				                        	var filedsList = "";
			                            	$.each($('[id^="'+jsonField[j].Field+'_'+((i*1)+1)+'-_options_-"]:checked'),function(x,obj){
			                            		if(x == 0){
			                            			filedsList = $(obj).val();
			                            		}else{
			                            			filedsList=filedsList+","+$(obj).val();
			                            		}
			                            	})
			                            	var othercehckboxvalue = $("#"+jsonField[j].Field+"_"+((i*1)+1)+"_Others").val();
			                            	if(filedsList==""){
			                            		filedsList=othercehckboxvalue;				                            		
			                            	}else{
			                            		filedsList=filedsList+","+othercehckboxvalue;
			                            	}
				                            if (filedsList.length < 64) {
				                                updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
				                                updateParamObject(params, "attrVal", filedsList, false, -1);

				                            } else {
				                                var myarray = (filedsList).match(/.{1,63}/g);
				                                for (var ix = 0; ix < myarray.length; ix++) {
				                                    var finalAttrName = "RXMED-"+uniqueIdentifier + "_" + ix;
				                                    updateParamObject(params, "attrName", finalAttrName, false, -1);
				                                    updateParamObject(params, "attrVal", myarray[ix], false, -1);
				                                }
				                            }

				                        }





			                    				                    				                    				                    				                    				                    }else if (isOptionOthersExists) {
			                        var checkedRadioOthers = $('input[type="checkbox"][id="' + jsonField[j].Field + "_" + ((i * 1) + 1) + '"]:checked');
			                        if (checkedRadioOthers.length == 0) {
			                            updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
			                            updateParamObject(params, "attrVal", 'No', false, -1);
			                        } else if (checkedRadioOthers.length != 0 && $(checkedRadioOthers).val() == "Yes" && $('[id^="'+jsonField[j].Field+'_'+((i*1)+1)+'_optionContainer_"]').length > 0) {
			                                 	var filedsList = "";
				                            	$.each($('[id^="'+jsonField[j].Field+'_'+((i*1)+1)+'_optionContainer_"]'),function(x,obj){
				                            		if(x == 0){
				                            			filedsList = $(obj).val();
				                            		}else{
				                            			filedsList=filedsList+","+$(obj).val();
				                            		}
				                            	})
				                            	var othercehckboxvalue = $("#"+jsonField[j].Field+"_"+((i*1)+1)+"_Others").val();
				                            	if(filedsList==""){
				                            		filedsList=othercehckboxvalue;				                            		
				                            	}else{
				                            		filedsList=filedsList+","+othercehckboxvalue;
				                            	}
			                            if (filedsList.length < 64) {
			                                updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
			                                updateParamObject(params, "attrVal", filedsList, false, -1);

			                            } else {
			                                var myarray = (filedsList).match(/.{1,63}/g);
			                                for (var ix = 0; ix < myarray.length; ix++) {
			                                    var finalAttrName = "RXMED-"+uniqueIdentifier + "_" + ix;
			                                    updateParamObject(params, "attrName", finalAttrName, false, -1);
			                                    updateParamObject(params, "attrVal", myarray[ix], false, -1);
			                                }
			                            }

			                        }
			                    } else if (isDateField) {
			                        var dateVal = "";
			                        if ($("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_MON").length > 0 && $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_MON").val() != "" &&
			                            $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_Date").length > 0 && $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_Date").val() != "" &&
			                            $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_Year").length > 0 && $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_Year").val() != ""
			                        ) {
			                            var date = $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_Date").val();
			                            var month = $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_MON").val();
			                            var year = $("#" + jsonField[j].Field + "_" + ((i * 1) + 1) + "_Year").val();
			                            var datetoValidate = new Date();
			                            datetoValidate.setFullYear(year, (month - 1), date);
			                            if (datetoValidate.getDate() == date && datetoValidate.getMonth() == (month - 1)) {
			                                dateVal = month + "-" + date + "-" + year;
			                                updateParamObject(params, "attrName", "RXMED-"+uniqueIdentifier, false, -1);
			                                updateParamObject(params, "attrVal", dateVal, false, -1);
			                            }

			                        }
			                    }
			                }
			            }
			        }
			    }

			}
		ShoppingListDialogJS.setDialogParams(this.storeParams, {catEntryId:catEntryId, thumbnail: 'imgPath'});
		if(!submitRequest()){
			return;
		}

				cursor_wait();
		 if(dojo.byId('modal-Personalization') != null){
			 petcoCommonJS.hideModal('modal-Personalization');
		 }
		  if(dojo.byId('personilaztionDisplay') != null){
			 $('#personilaztionDisplay').removeClass('active');
		 }
		  dojo.forEach(dojo.query('div[id^="Personalization_"]'),function(obj){
			  obj.style.display = 'none';
			  })
		 if(dojo.byId('modal-rxmedicine') != null){
			 petcoCommonJS.hideModal('modal-rxmedicine');
		 }
		  if(dojo.byId('rxPersonilaztionDisplay') != null){
			  $('#rxPersonilaztionDisplay').removeClass('active');
		 }
		  dojo.forEach(dojo.query('div[id^="rxmedicineContent_"]'),function(obj){
		  })  


			  			  		wc.service.invoke('ShoppingListServiceAddItem',params);

	};
	this.addToListFrmPurList = function(listId,catentryId,quantity,fromFlow,listItemId){
		this.hideDropDown();
		var params = this.setCommonParams();
		params.giftListId = listId;
		params.name = 'Wish List';

				var catEntryId = catentryId;
		if(this.catEntryParams.type.toLowerCase() == 'itembean'
			|| this.catEntryParams.type.toLowerCase() == 'packagebean'
			|| this.catEntryParams.type.toLowerCase() == 'dynamickitbean'){
			updateParamObject(params,"catEntryId",this.catEntryParams.id,false,-1);
			updateParamObject(params,"quantity",quantity,false,-1);
		} else if(this.catEntryParams.type.toLowerCase() =='bundlebean'){
			for(baseItemId in this.catEntryParams.components){
				updateParamObject(params,"catEntryId",this.catEntryParams.components[baseItemId].id,false,-1);
				updateParamObject(params,"quantity",this.catEntryParams.components[baseItemId].quantity,false,-1);
			}
		} else {
			var sku = this.itemId;
			if (sku == -1){
				sku = this.resolveSKU();
			}
			if(-1 == sku){
				MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
				return;
			} else {
				catEntryId = sku;
				if(quantity == 0) {
					quantity = this.catEntryParams.quantity;
				}
				updateParamObject(params,"catEntryId",sku,false,-1);
				updateParamObject(params,"quantity",quantity,false,-1);
			}
		}
		var jsFileName;
		if(fromFlow == 'purchaseList'){
			jsFileName = 'shoppingListJS'+listItemId;
			var purListId = document.getElementById('purchaselistId').value;
		}else{
			jsFileName = 'shoppingListJS'+catentryId;
		}

				if(this.jsObjectName != 'shoppingListJS' && this.jsObjectName != jsFileName){
			QuickInfoJS.close();
		}

		if(!submitRequest()){
			return;
		}
		cursor_wait();
		ShoppingListDialogJS.setDialogParams(this.storeParams, {catEntryId:catEntryId, thumbnail: 'imgPath'});
		wc.service.invoke('ShoppingListServiceAddItem',params);
	};
	this.updateQuantityFromList = function(listId,catEntryId,quantity,event){
		if(event.keyCode == dojo.keys.TAB)return;
		this.hideDropDown();
		if(quantity <= 0)
		{
			return false;
		}
		else if(quantity > 999)
		{
			alert("Quantity should be less than 1000");
			return false;
		}
		var params = this.setCommonParams();
		params.giftListId = listId;
		updateParamObject(params,"catEntryId",catEntryId,false,-1);
		updateParamObject(params,"quantity",quantity,false,-1);
		wc.service.invoke('ShoppingListServiceUpdateItem',params);
	};
	this.isDuplicate = function(listName) {
		var listName = this.escapeXml(listName, true);
		return (this.shoppingListNames[listName.toUpperCase()] == 1);
	};

	this.updateDefaultListId = function(listId){
		this.shoppingListNames[storeNLS['DEFAULT_WISH_LIST_NAME']] = 1;
		if (dojo.byId(this.pageName+'addToShoppingList')) {
			dojo.byId(this.pageName+'addToShoppingList').href = "javascript:" + this.jsObjectName + ".createDefaultListAndAddItem(" + listId + ");";
		}
		if($('#defaultWishList').length >0 && $('#defaultWishList').val()==-1){
			$('#defaultWishList').val(listId);
		}
		if (dojo.byId('defaultShoppingListId_pdp') != null) {
			dojo.byId('defaultShoppingListId_pdp').value = listId;
		}

				$('div#wishList-submit-button a.personalizationSubmit_WishList').each(function(ind,obj){

						obj.href = "javascript:cmManuallyTriggerEventTrack(this);" + this.jsObjectName + ".createDefaultListAndAddItem(" + listId + ");";

					})

			};

	this.updateShoppingList = function(listId, listName, action){
		var listNameEsc = this.escapeXml(listName, false);
		this.shoppingListNames[listNameEsc.toUpperCase()] = 1;

				if (action && (action == 'edit' || action == 'delete')) {
			this.shoppingListNames[this.nameToDelete.toUpperCase()] = -1;
		}

				var dropdownWidgetNode = dojo.byId(this.pageName+'ShoppingListDivider');
		if (dropdownWidgetNode) {
			var eventHandlerString = "javascript: this.className = 'created_list';";

			if (this.pageName.indexOf("OI") == -1) {
				dojo.place('<div role="menuitem" id="' + this.pageName + 'ShoppingList_' + listId + '" class="created_list" onfocus="javascript:' + jsObjectName + '.focusList(\'' + listId +
						'\'); "  onblur="'+ eventHandlerString +'" onclick="javascript:' + jsObjectName + '.addToList(\'' + listId + 
						'\');"><a role="menuitem" id="' + this.pageName + 'ShoppingListLink_' + listId + '" href="javascript:' + jsObjectName + '.addToList(\'' + listId + 
						'\');" onfocus="javascript:' + jsObjectName + '.focusListLink(\'' + listId + '\');">' + listName + 
						'</a></div>', this.pageName + 'ShoppingListDivider', 'before');
			} else {
				var oiId = this.pageName.replace("OI", "");
				dojo.place('<div role="menuitem" id="' + this.pageName + 'ShoppingList_' + listId + '" class="created_list" onfocus="javascript:' + jsObjectName + '.focusList(\'' + listId +
						'\'); "  onblur="'+ eventHandlerString +'" onclick="javascript:' + jsObjectName + '.addToListAndDelete(\'' + listId + 
						'\',\'' + oiId + '\');"><a role="menuitem" id="' + this.pageName + 'ShoppingListLink_' + listId + '" href="javascript:' + jsObjectName + '.addToListAndDelete(\'' + listId + 
						'\',\'' + oiId + '\');" onfocus="javascript:' + jsObjectName + '.focusListLink(\'' + listId + '\');">' + listName + 
						'</a></div>', this.pageName + 'ShoppingListDivider', 'before');
			}
		}
	};

	this.clearPopupText = function(){
		dojo.byId(this.pageName + "newListName").value = "";
	};

	this.escapeXml = function(str, fullConversion){
		if(fullConversion){
			str = str.replace(/&/gm, "&amp;").replace(/</gm, "&lt;").replace(/>/gm, "&gt;");
		}
		str = str.replace(/"/gm, "&#034;").replace(/'/gm, "&#039;");
		return str;
	};

	 this.resolveSKU = function() {
		if(this.catEntryParams.skus.length == 1){
			return this.catEntryParams.skus[0].id;
		}
		for (idx = 0; idx < this.catEntryParams.skus.length; idx++) {
			var matches = 0;
			var attributeCount = 0;
			for (attribute in this.catEntryParams.skus[idx].attributes) {
				attributeCount++;
				if (this.catEntryParams.attributes
						&& this.catEntryParams.skus[idx].attributes[attribute] == this.catEntryParams.attributes[attribute]) {
					matches++;
				} else {
					break;
				}
			}
			if (0 != matches && matches == attributeCount) {
				return this.catEntryParams.skus[idx].id;
			}
		}
		return -1;
	};

	this.setCommonParams = function(){
		var params = {};
		params.storeId		= this.storeParams.storeId;
		params.catalogId	= this.storeParams.catalogId;
		params.langId		= this.storeParams.langId;
		return params;
	};

	this.empty = function(str) {
		return (str == null || str == undefined || str == "");
	};

	this.redirectToSignOn = function() {

			var currentURL = location.href;
		if(true==isGuest){
			currentURL = getAbsoluteURL() + "OrderItemMove?continue=1&createIfEmpty=1&updatePrices=0&deleteIfEmpty=*&fromOrderId=*&toOrderId=.&page=&calculationUsageId=-1&URL="+encodeURIComponent("OrderCalculate?URL="+encodeURIComponent(currentURL));
		}

				document.location.href = getAbsoluteURL() + "LogonForm?myAcctMain=1&storeId="
				+ this.storeParams.storeId + "&catalogId=" + this.storeParams.catalogId
				+ "&langId=" + this.storeParams.langId + "&URL=" + encodeURIComponent(currentURL);
	};

	this.focusList = function(listId){
		this.focusListByElementId(this.pageName + "ShoppingList_" +listId);
	};

	this.focusListByElementId = function(elementId){
		if(dojo.hasClass(elementId,"focused")) {
			return;
		}
		dojo.byId(elementId.replace("ShoppingList","ShoppingListLink")).focus();

			};

	this.focusListLink = function(listId){
		dojo.query("#" + this.pageName + "shoppingListDropDown.dropdown_list div").removeClass("focused");
		dojo.addClass(this.pageName + "ShoppingList_" +listId,"focused");
	};

		this.updateShoppingListAndAddItem = function(serviceResponse){
		if(serviceResponse.listName == storeNLS['DEFAULT_WISH_LIST_NAME']){
			this.updateDefaultListId(serviceResponse.listId);
		} else {
			this.updateShoppingList(serviceResponse.listId, serviceResponse.listName, serviceResponse.action);
		}

				if(this.addItemAfterCreate){
			this.addItemAfterCreate = false;
			if (this.orderItemId != "") {
				this.addToListAndDelete(serviceResponse.listId, this.orderItemId);
			} else {
				this.addToList(serviceResponse.listId);
			}
		} else {
			if (serviceResponse.action == 'add') {
				this.showSuccessDialog();
			}
		}
	};

		this.navigateDropDown = function(event){
		var shoppingListObj = this;
		if(event.keyCode == dojo.keys.UP_ARROW) {
			dojo.stopEvent(event);

						var focusChanged = false;
			dojo.query("#" + shoppingListObj.pageName + "shoppingListDropDown.dropdown_list div.created_list").forEach(function(node, index, arr){
				if(!focusChanged && (dojo.hasClass(node,"focused"))){
					if(0 == index){
						shoppingListObj.focusListByElementId(arr[arr.length-1].id);
					} else {
						shoppingListObj.focusListByElementId(arr[index-1].id);
					}
					focusChanged = true;
				}
			});
		} else if(event.keyCode == dojo.keys.DOWN_ARROW){
			dojo.stopEvent(event);

						var focusChanged = false;
			dojo.query("#" + shoppingListObj.pageName + "shoppingListDropDown.dropdown_list div.created_list").forEach(function(node, index, arr){
				if(!focusChanged && (dojo.hasClass(node,"focused"))){
					if(arr.length-1 == index){
						shoppingListObj.focusListByElementId(arr[0].id);
					} else {
						shoppingListObj.focusListByElementId(arr[index+1].id);
					}
					focusChanged = true;
				}
			});
		} else if(event.keyCode == dojo.keys.ESCAPE || event.keyCode == dojo.keys.TAB) {
			dojo.stopEvent(event);
			this.hideDropDown();
		}
	};

		this.hideIfNoFocus = function(){
		if(this.dropDownVisible && !this.dropDownInFocus && !this.mouseOnArrow){
			this.hideDropDown();
		}
	};

		this.hasFocus = function(event){
		if(dojo.mouseButtons.isRight(event)){
			this.dropDownInFocus = false;
		} else {
			this.dropDownInFocus = true;
		}

			};


	this.editLink = function(){
		 $("#wishlistName-EditName").removeClass("hide");
		 $("#wishlistName-StaticName").addClass("hide");
		 if($("#editListName").val() == storeNLS['DEFAULT_WISH_LIST_NAME'])
		 {
			 $("#editListName").val($(userName).val());
		 }

			  	};
	this.editSave = function(){
		$("#wishlistName-EditName").addClass("hide");
	    $("#wishlistName-StaticName").removeClass("hide");			  
	};
	this.edit = function(editType){
		var name = trim(dojo.byId("editListName").value);
		var maxlength = name.maxLength;
		var defaultName = storeNLS['DEFAULT_WISH_LIST_NAME'];

				if (this.empty(name)) {
			alert("" +storeNLS['ERR_NAME_EMPTY']);
			this.showEditErrorMessage(storeNLS['ERR_NAME_EMPTY']);
		} else if(!MessageHelper.isValidUTF8length(name, maxlength)){
			alert("" +storeNLS['ERR_NAME_TOOLONG']);
			this.showEditErrorMessage(storeNLS['ERR_NAME_TOOLONG']);
		} else if(!this.validateWishName(name)){
			alert("" +storeNLS['INVALID_NAME_SHOPPING_LIST']);
			this.showErrorMessage(storeNLS['INVALID_NAME_SHOPPING_LIST']);
		} else {
			var params = this.setCommonParams();
			if(editType == 'name'){
				params.name = name;
			}
			if(editType == 'private'){
				$("#share-links").addClass("hide");
				params["guestFlag"] = "private";				 
			}
			if(editType == 'public'){
				$("#share-links").removeClass("hide");
				params["guestFlag"] = "public";				
			}
			var dropdown = dojo.byId('multipleWishlistController_select');
			if((dropdown != null && dropdown != 'undefined') && dropdown.value != 0){
				params["giftListId"] = dojo.byId("multipleWishlistController_select").value;
				this.nameToDelete = defaultName;
			}

						var popup = dijit.byId("editShoppingListPopup");
			if (popup !=null) {	
				popup.hide();
			}
			if(!submitRequest()){
				return;
			}
			cursor_wait();
			wc.service.invoke('ShoppingListServiceUpdate',params);
		}
	};

	this.deleteList = function(){
		var params = this.setCommonParams();
		var dropdown = dojo.byId('multipleWishlistController_select');
		if((dropdown != null && dropdown != 'undefined') && dropdown.value != 0){
			params["giftListId"] = dojo.byId("multipleWishlistController_select").value;
			this.nameToDelete = dojo.byId('multipleWishlistController_select').options[dropdown.selectedIndex].text
		}

					var popup = dijit.byId("deleteShoppingListPopup");
		if (popup !=null) {	
			popup.hide();
		}
		if(!submitRequest()){
			return;
		}
		cursor_wait();
		wc.service.invoke('ShoppingListServiceDelete',params);
	};

	this.refreshLinkState = function() {
		var dropdown = dojo.byId('multipleWishlistController_select');
		if (dropdown != null) {
			var wName=dojo.byId('multipleWishlistController_select').options[dropdown.selectedIndex].text;
			var defaultName = storeNLS['DEFAULT_WISH_LIST_NAME'];
			if (wName == defaultName) {
				dojo.byId('editDivider').style.display='none';
				dojo.byId('edit_popup_link').style.display='none';
				dojo.byId('deleteDivider').style.display='none';
				dojo.byId('delete_popup_link').style.display='none';
			} else {
				dojo.byId('editDivider').style.display='block';
				dojo.byId('edit_popup_link').style.display='block';
				dojo.byId('deleteDivider').style.display='block';
				dojo.byId('delete_popup_link').style.display='block';
			}
		}
	};

	this.addToListAndDelete = function(listId, inOrderItemId) {
		this.orderItemId = inOrderItemId;

				dojo.publish("modelChanged/AnalyticsConversionEvent");

		this.hideDropDown();

				var params = this.setCommonParams();
		params.giftListId = listId;
		params["catEntryId"] = this.catEntryParams.id;
		params["quantity"] = 1;

		if(!submitRequest()){
			return;
		}		
		cursor_wait();
		ShoppingListDialogJS.setDialogParams(this.storeParams, {catEntryId:this.catEntryParams.id, name:this.catEntryParams.name, image:this.catEntryParams.image, thumbnail: 'imgPath'});		
		wc.service.invoke('ShoppingListServiceAddItemAndRemoveFromCart',params);
	};

	this.deleteItemFromCart = function() {
		if (this.orderItemId != "") {
			var test = this.orderItemId;
			this.orderItemId = "";
			if(test != ""){
				CheckoutHelperJS.deleteFromCart(test, true);
			}
		}
	};
	this.validateWishName = function(wishName) {
	    var invalidChars = "~!@#$%^&*()+=[]{};:,<>?/|`"; 
	    invalidChars += "\t\'\"\\\/"; 

	    for (var i=0; i<wishName.length; i++) {
	      if (invalidChars.indexOf(wishName.substring(i, i+1)) >= 0) {
	        return false;
	      }
	    }
	    return true;
	}

		dojo.connect(document.documentElement, "onmousedown", this, "hideIfNoFocus");
}

if(typeof(ShoppingListDialogJS) == "undefined" || ShoppingListDialogJS == null || !ShoppingListDialogJS) {

		ShoppingListDialogJS = {
		storeParams: null,
		dialogParams: null,

				setDialogParams: function(storeParams, dialogParams){
			this.storeParams = storeParams;
			this.dialogParams = dialogParams;
			if(this.dialogParams.image == null || this.dialogParams.image == ''){
				this.fetchAddedItem();
			} else {
				this.displayItemAddedWithoutFetching();
			}
		},

				fetchAddedItem: function(){
			var params = this.setCommonParams();
			params.productId = this.dialogParams.catEntryId;
			params.catalogEntryId = this.dialogParams.catEntryId;

			dojo.xhrPost({
				url: getAbsoluteURL() + "GetCatalogEntryDetailsByIDView",				
				handleAs: "json-comment-filtered",
				content: params,
				service: this,
				load: ShoppingListDialogJS.displayItemAddedDialog,
				error: function(errObj,ioArgs) {
					console.debug("QuickInfoJS.selectItem: Unexpected error occurred during an xhrPost request.");
				}
			});
		},

				displayItemAddedDialog: function(serviceResponse, ioArgs) {
			var itemAddedPopup = dijit.byId("shoppingListItemAddedPopup");
			if(itemAddedPopup != null){
				dojo.byId("shoppingListItemAddedImg").src = serviceResponse.catalogEntry.description[0].thumbnail.replace("160x160", "105x105");
				dojo.byId("shoppingListItemAddedImg").alt = serviceResponse.catalogEntry.description[0].name;
				dojo.byId("shoppingListItemAddedName").innerHTML = serviceResponse.catalogEntry.description[0].name;
			} else {
				console.debug("shoppingListItemAddedPopup does not exist");
			}
		},

		displayItemAddedWithoutFetching: function() {
			var itemAddedPopup = dijit.byId("shoppingListItemAddedPopup");
			if(itemAddedPopup != null){
				dojo.byId("shoppingListItemAddedImg").src = this.dialogParams.image;
				dojo.byId("shoppingListItemAddedImg").alt = this.dialogParams.name;
				dojo.byId("shoppingListItemAddedName").innerHTML = this.dialogParams.name;
			} else {
				console.debug("shoppingListItemAddedPopup does not exist");
			}
		},

		showDialog: function(){
			var itemAddedPopup = dijit.byId("shoppingListItemAddedPopup");
			if(itemAddedPopup != null){
				itemAddedPopup.closeButtonNode.style.display='none';
				itemAddedPopup.show();
			} else {
				console.debug("shoppingListItemAddedPopup does not exist");
			}
		},

				setCommonParams: function(){
			var params = new Object();
			params.storeId		= this.storeParams.storeId;
			params.catalogId	= this.storeParams.catalogId;
			params.langId		= this.storeParams.langId;
			return params;
		},

				close: function(){
			dijit.byId("shoppingListItemAddedPopup").hide();
		}
	}
	require(['dojo/topic'], function(topic) {
		topic.subscribe("ShoppingListItem_Added", ShoppingListDialogJS.showDialog);
	});
}