dojo.addOnLoad(function() {
	if (dojo.byId('MiniShopCartContents')) {
		setMiniShopCartControllerURL(getAbsoluteURL()+'MiniShopCartDisplayView?storeId='+WCParamJS.storeId+'&catalogId='+WCParamJS.catalogId+'&langId='+WCParamJS.langId+'&getCartData=false');
		if(wc.render.getRefreshControllerById("MiniShopCartContentsController") != null){
			wc.render.getRefreshControllerById("MiniShopCartContentsController").url = getAbsoluteURL()+'MiniShopCartDisplayView?storeId='+WCParamJS.storeId+'&catalogId='+WCParamJS.catalogId+'&langId='+WCParamJS.langId+'&page_view=dropdown';
		}
		if(dojo.byId('MiniShoppingCart') != null){
			loadMiniCart(WCParamJS.currencyCode,WCParamJS.langId);
		}

				if(checkDeleteCartCookie()) {
			updateCartCookie();
			reinitializeCartCounts();
			resetDeleteCartCookie();
		}
	}
});