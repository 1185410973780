
if(typeof(QuickInfoJS) == "undefined" || QuickInfoJS == null || !QuickInfoJS) {

		QuickInfoJS = {
		productImgDimensions: "1000x1000",
		quickInfoImgDimensions: "330x330",
		catEntryParams: {},
		selectedAttributes: new Object(),
		catEntryQuantity: 1,
		ltlorRXflag: false,
		itemDetailsArr: new Object(),
		params: null,
		isRepeatDeliveryProduct: false,
		replaceOrderItemId: '', 
		itemId: '', 
		selectedSkuIndex: null, 
		selectedThumbnailIndex: null, 

		setReplaceOrderItemId: function(replaceOrderItemId){
			this.replaceOrderItemId = replaceOrderItemId;
		},

		setValues: function(){
			if(dojo.byId('catEntryParamsForJS')){
				this.catEntryParams = dojo.fromJson(dojo.byId('catEntryParamsForJS').value);
			}

						this.catEntryParams.attributes = this.selectedAttributes;
		},

		setCatEntryQuantity: function(catEntryQuantity){

			this.catEntryQuantity = catEntryQuantity;
		},

		showDetails: function(productId, params, itemId, quantity){
			if(params){
				this.params = params;
			} else {
				this.params = null;
			}
			this.selectedAttributes = new Object();
			this.selectedSkuIndex = null; 
			this.selectedThumbnailIndex = null; 

			this.close();

			var contextValues = this.setCommonParams();
			if(itemId){
				this.itemId = itemId;
				contextValues.updateAttributes = "true";
			} else {
				this.itemId = '';
				contextValues.updateAttributes = "false";
			}

						if(quantity){
				this.catEntryQuantity = quantity;
			}

						contextValues.productId = productId;
			wc.render.updateContext('QuickInfoContext', contextValues);
			if(document.getElementById('productIdQuickInfo')){
				document.getElementById('productIdQuickInfo').innerHTML = productId;
			}
		},

		changeAttributes: function(orderItemId, productId, itemId, quantity){
			this.setReplaceOrderItemId(orderItemId);
			this.showDetails(productId, null, itemId, quantity);
		},

		changeImage: function(elementId, imgSrc){
			this.selectedThumbnailIndex = elementId-1;
			dojo.byId("quickInfoMainImage").src = this.getQuickInfoImage(this.catEntryParams.skus[this.selectedSkuIndex].fullImageAttachments[this.selectedThumbnailIndex].path);
			dojo.query(".widget_quick_info_popup .other_views li").removeClass("selected");
			dojo.addClass("quickInfoThumbnail" + elementId,"selected");
		},

		setSelectedAttribute : function(selectedAttributeName , selectedAttributeValue){
			this.selectedAttributes[selectedAttributeName] = selectedAttributeValue;
			if(selectedAttributeValue == '' || selectedAttributeValue == null || selectedAttributeValue == undefined){
				document.getElementById("WC_QuickInfo_Link_addtocart").style.cursor = "default";
				document.getElementById("WC_QuickInfo_Link_addtocart").style.pointerEvents  = "none";
				document.getElementById("WC_QuickInfo_Link_addtocart").style.opacity = "0.65";  
			}
		},

		notifyAttributeChange: function(productId,catEntryId){
			this.setValues();

						dojo.query('script[id^="inventoryScript_"]').forEach(function(node, index, nodelist){dojo.eval(node.innerHTML);});

			dojo.publish('DefiningAttributes_Resolved_'+productId, catEntryId, productId);

			dojo.publish('QuickInfo_attributesChanged', [dojo.toJson(this.selectedAttributes)]);
			if( document.getElementById("notifyCatentry")!=null){
				document.getElementById("notifyCatentry").value=catEntryId;
			}
		},

		selectItem: function(productId,displayPriceRange,attrName,attrValue,updateItemImageOnly){
			this.displayPriceRange = displayPriceRange;
			this.setValues();
			var catEntryId = this.resolveSKU();

			if(catEntryId == -1 && updateItemImageOnly){
				var skuId = -1;
				for(idx=0;idx<this.catEntryParams.skus.length;idx++){
					for(attribute in this.catEntryParams.skus[idx].attributes){
						if(this.catEntryParams.attributes && this.catEntryParams.skus[idx].attributes[attribute] == this.catEntryParams.attributes[attribute]){
							skuId = this.catEntryParams.skus[idx].id;
							break;
						}
					}
				}
				if(skuId != -1){
					catEntryId = skuId;
					this.updateItemImageOnly = updateItemImageOnly;
				}		
			}
			var temp="";
			var tempCatentryId=null;
			var counter=0;
			for(x in this.catEntryParams.skus)
			{
				var Attributes = this.catEntryParams.skus[x].attributes;

								for(y in Attributes){
					var attrName1 = y;
					attrName1 = attrName1.replace(/ /g,"_");
					var attrValue1 = Attributes[y];
					catentry_id = this.catEntryParams.skus[x].id;
					if(attrName == attrName1 && attrValue==attrValue1){
						temp=temp+x+",";
						if(catEntryId==catentry_id){
							tempCatentryId=catEntryId;
						}else if(catEntryId == -1){
							if(counter==0){
								tempCatentryId=catentry_id;
								counter++;
							}
						}

											}
				}	
			}
			var temp1 = temp.split(',');
			var temp2= temp1;
			var attrNameDisp="";
			var attrValueDisp="";
			var selectedAttr="";
			var i="";
			var tempAttrName;
			var j="";
			var counterSelection=0;
			for(index in temp2){
				j=temp2[index];
				if(j!=""){
					if(this.catEntryParams.skus[j]!=undefined){
						var Attributes = this.catEntryParams.skus[j].attributes;
						for(y in Attributes){
							attrNameDisp=y;
							attrNameDisp = attrNameDisp.replace(/ /g,"_");
							if(attrNameDisp!=attrName ){
								attrValueDisp=Attributes[y];
								attrNameDisp = y;
								attrNameDisp = attrNameDisp.replace(/ /g,"_");
								tempAttrName ="#quickInfoAttrValue_"+attrNameDisp;
								$(tempAttrName+" option[value='"+attrValueDisp+"']").remove();
								$(tempAttrName).append("<option  selected='selected' value='"+attrValueDisp+"'>"+attrValueDisp+"</option>");
								attrNameSelected = attrNameDisp.replace(/_/g," ");
								this.selectedAttributes[attrNameSelected] = attrValueDisp;
							}

													}
					}


														}	

									}

						if(catEntryId == -1 || catEntryId == null){
				catEntryId = tempCatentryId;
			}
			if(catEntryId != -1 || catEntryId != null){
				this.notifyAttributeChange(productId,catEntryId);
				if(this.itemDetailsArr[catEntryId] != null && this.itemDetailsArr[catEntryId] != 'undefined'){
					this.displayItemDetails(this.itemDetailsArr[catEntryId]);
				}
				else{
					var params = this.setCommonParams();
					params.catalogEntryId = catEntryId;

					dojo.xhrPost({
						url: getAbsoluteURL() + "GetCatalogEntryDetailsByIDView",				
						handleAs: "json-comment-filtered",
						content: params,
						service: this,
						load: QuickInfoJS.setItemDetails,
						error: function(errObj,ioArgs) {
							console.debug("QuickInfoJS.selectItem: Unexpected error occurred during an xhrPost request.");
						}
					});
				}
				for(idx=0;idx<this.catEntryParams.skus.length;idx++){

										var catentry_id = this.catEntryParams.skus[idx].id;
					var rdPrice = "";
					var rdFlag = "";
					if(catentry_id == catEntryId)
					{
						var inStoreSku = false;
						var inStore = this.catEntryParams.skus[idx].InStoreOnly;
						if(inStore == 'true'){
							inStoreSku = true;
						}
						if(inStoreSku){
							var priceDiv = dojo.byId("priceDiv");
							if(null != priceDiv){
								var requireds = dojo.query(".col-7", priceDiv);
								 dojo.forEach(requireds, function(required){
									 if(null != dojo.getAttr(required,"id") && dojo.getAttr(required,"id") == "InStoreMessage"){
										 dojo.style(required, "display", "");
									 }
									 else{
										 dojo.style(required, "display", "none");
									 }
								    });
							}
							if(null != dojo.byId("add-to-controls"))
								dojo.style("add-to-controls","display","none");
							if(null != dojo.byId("notifyMe"))
								dojo.style("notifyMe","display","none");
							if(null != dojo.byId("qv_atc"))
								dojo.style("qv_atc","display","none");

													}
						else{
							var priceDiv = dojo.byId("priceDiv");
							if(null != priceDiv){
								var requireds = dojo.query(".col-7", priceDiv);
								 dojo.forEach(requireds, function(required){
									 if(null != dojo.getAttr(required,"id") && dojo.getAttr(required,"id") == "InStoreMessage"){
										 dojo.style(required, "display", "none");
									 }
									 else{
										 dojo.style(required, "display", "");
									 }
								    });
							}
							if(null != dojo.byId("add-to-controls"))
								dojo.style("add-to-controls","display","block");
							if(null != dojo.byId("notifyMe"))
								dojo.style("notifyMe","display","block");
							if(null != dojo.byId("qv_atc"))
								dojo.style("qv_atc","display","block");
						}

													rdPrice = this.catEntryParams.skus[idx].RepeatDeliveryPrice;
						rdFlag = this.catEntryParams.skus[idx].RepeatDeliveryFlag;
						console.debug('rdFlag in QuickInfo.selectItem == ' + rdFlag);
						if(rdFlag!=undefined)	{
						if(rdFlag != null && rdFlag != undefined && rdFlag == '0')
						{
							var rdPriceDiv =  dojo.byId('product-price-rd');
							var savings = 0;
							if(document.getElementById('rdPriceDiv') != null && document.getElementById('rdPriceDiv') != undefined)
							{
								rdPriceDiv.setAttribute("class","product-price");
								if(rdPrice != null && rdPrice != undefined && rdPrice != 'Price pending')
								{
									var rdPriceNumber = rdPrice.replace('$', '');
									rdPriceNumber = parseFloat(rdPriceNumber.trim());
									try{

																			var listPriceNumber = QuickInfoJS.itemDetailsArr[catentry_id].listPrice;
									}catch(e){
										listPriceNumber = "";
									}
									listPriceNumber = listPriceNumber.replace('$','');
									listPriceNumber = parseFloat(listPriceNumber.trim())
									if(listPriceNumber != undefined && listPriceNumber != null && listPriceNumber != ""){
										savings = ((listPriceNumber-rdPriceNumber)*100)/listPriceNumber;
										savings = Math.round(savings);
									}

																		if(savings != undefined && savings != null && savings != "" && !isNaN(savings)){
										var savingsStr = savings + "%";
									}else{
										document.getElementById('rdPriceDiv').innerHTML="<span class='product-price-rddetail'>" +rdPrice +"</span>";
									}
								}
								else
								{
									document.getElementById('rdPriceDiv').innerHTML="<span class='form-required'>Price pending</span>";
								}
							}
						}
						else
						{	
							var rdPriceDiv =  dojo.byId('product-price-rd');
							if(document.getElementById('rdPriceDiv') != null && document.getElementById('rdPriceDiv') != undefined)
								rdPriceDiv.setAttribute("class","product-price hide");

													}
						}
					}
				}

							}
		},

		selectSwatch: function(selectedAttributeName, selectedAttributeValue, swatchId, swatchGrp) {
			if(dojo.hasClass("quickInfoSwatch_" + selectedAttributeName + "_" + selectedAttributeValue, "color_swatch_disabled")){
				return false;
			}
			this.setValues();
			var swatchNames = dojo.byId("WC_QuickInfo_SwatchNames").value.split("_");
			var currentSwatchPos = -1;
			for(var i=0; i<swatchNames.length;i++){
				if(swatchNames[i] == selectedAttributeName){
					currentSwatchPos = i;
					break;
				}
			}
			var currentSwatchkey = "quickInfoSwatch_" + selectedAttributeName + "_" +selectedAttributeValue;
			var currentSwatchkeyLink = "WC_QuickInfo_Swatch_" + selectedAttributeName + "_" +selectedAttributeValue;
			var validSwatchArr = new Array();
			for(idx in this.catEntryParams.skus){
				var validItem = false;
				var entitledItem = this.catEntryParams.skus[idx];
				for(attribute in entitledItem.attributes){

										if(selectedAttributeName == attribute && selectedAttributeValue == entitledItem.attributes[attribute] && entitledItem.buyable){
						validItem = true;
						break;
					}
				}
				if(validItem){
					for(attribute in entitledItem.attributes){
						var attributePos = -1;
						for(var i=0; i<swatchNames.length;i++){
							if(swatchNames[i] == attribute){
								attributePos = i;
								break;
							}
						}						
						if(attributePos > currentSwatchPos){
							validSwatchArr.push(attribute + "_" + entitledItem.attributes[attribute]);
						}
					}
				}
			}

						var swatchesDisabled = new Array();
			var selectedSwatches = new Array();
			for(idx in this.catEntryParams.skus){
				var entitledItem = this.catEntryParams.skus[idx];
				for(attribute in entitledItem.attributes){
					var attributePos = -1;
					for(var i=0; i<swatchNames.length;i++){
						if(swatchNames[i] == attribute){
							attributePos = i;
							break;
						}
					}											
					if(attributePos > currentSwatchPos){
						var swatchSelection = attribute + "_" + entitledItem.attributes[attribute];
						var swatchId = "quickInfoSwatch_" + swatchSelection;
						var swatchLinkId = "WC_QuickInfo_Swatch_" + swatchSelection;
						var validSwatchArrPos = -1;
						for(var i=0; i<validSwatchArr.length;i++){
							if(validSwatchArr[i] == swatchSelection){
								validSwatchArrPos = i;
								break;
							}
						}								
						var swatchesDisabledPos = -1;
						for(var i=0; i<swatchesDisabled.length;i++){
							if(swatchesDisabled[i] == swatchSelection){
								swatchesDisabledPos = i;
								break;
							}
						}								
						if(validSwatchArrPos > -1){
							if(!dojo.hasClass(swatchId,"color_swatch_selected")){
								dojo.byId(swatchId).className = "color_swatch";
								dojo.byId(swatchId).src = dojo.byId(swatchId).src.replace("_disabled.png","_enabled.png");
								document.getElementById(swatchLinkId).setAttribute("aria-disabled", "false");
							}
						} 
						else if(swatchesDisabledPos == -1){
							swatchesDisabled.push(swatchSelection);
							if(dojo.hasClass(swatchId,"color_swatch_selected")){
								selectedSwatches.push(swatchId);
							}
							dojo.byId(swatchId).className = "color_swatch_disabled";
							dojo.byId(swatchId).src = dojo.byId(swatchId).src.replace("_enabled.png","_disabled.png");
							document.getElementById(swatchLinkId).setAttribute("aria-disabled", "true");
						}
					}
					if (document.getElementById("WC_QuickInfo_Swatch_" + attribute + "_" + entitledItem.attributes[attribute]) != null) {
						document.getElementById("WC_QuickInfo_Swatch_" + attribute + "_" + entitledItem.attributes[attribute]).setAttribute("aria-checked", "false");
					}
				}
			}

			for(idx in selectedSwatches){
				var selectedSwatch = selectedSwatches[idx];
				var idSelector = "img[id^='" + selectedSwatch.substring(0, selectedSwatch.lastIndexOf("_")) + "']";
				var swatchSelected = false;
				dojo.query(idSelector).forEach(function(node, index, arr){
					if(!swatchSelected && dojo.hasClass(node,"color_swatch")){
						var values = node.id.split("_");
						QuickInfoJS.selectSwatch(values[1],values[2], "quickInfoSwatch_" + values[1] + "_" + values[2],"quickInfoSwatch_"+values[1]+"_");
						QuickInfoJS.selectItem(true);
						swatchSelected = true;
					}
				});
			}

						selector = "img[id^='"+swatchGrp+"']";

						dojo.query(selector).forEach(function(node, index, arr){
			    if(!dojo.hasClass(node,"color_swatch_disabled")){
			    	dojo.addClass(node,"color_swatch");
			    	if(dojo.hasClass(node,"color_swatch_selected")){
				    	dojo.removeClass(node,"color_swatch_selected");
				    }
			    }
			});

						dojo.byId(currentSwatchkey).className = "color_swatch_selected";
			dojo.byId("quickinfo_swatch_selection_"+selectedAttributeName).innerHTML = selectedAttributeValue;
			document.getElementById(currentSwatchkeyLink).setAttribute("aria-checked", "true");
			this.setSelectedAttribute(selectedAttributeName, selectedAttributeValue);
		},

		setCommonParams: function(){
			var params = new Object();
			params.storeId		= WCParamJS.storeId;
			params.catalogId	= WCParamJS.catalogId;
			params.langId		= WCParamJS.langId;
			return params;
		},

		setItemDetails: function(serviceResponse, ioArgs) {
			QuickInfoJS.itemDetailsArr[serviceResponse.catalogEntry.catalogEntryIdentifier.uniqueID] = serviceResponse.catalogEntry;
			QuickInfoJS.displayItemDetails(serviceResponse.catalogEntry);
		},

				displayItemDetails: function(catalogEntry) {
			if(null != this.catEntryParams){
				for(idx in this.catEntryParams.skus){
					if(null != this.catEntryParams.skus[idx] && (this.catEntryParams.skus[idx].id == catalogEntry.catalogEntryIdentifier.uniqueID)){
						var angleImageAreaList = dojo.query("div[id^='quickinfoAngleImagesArea']");
						if(angleImageAreaList != null){
							var angleImageArea = angleImageAreaList[0];
						}
						if(this.catEntryParams.skus[idx].thumbnailAttachments != null && this.catEntryParams.skus[idx].thumbnailAttachments.length > 0){
							var thumbnailAreaList = dojo.query("ul[id^='quickInfoAngleImagesAreaList']");
							if(thumbnailAreaList != null){
								var thumbnailArea = thumbnailAreaList[0];
								if(angleImageArea != null){
									angleImageArea.style.display = "block";
								}
								for(idx2 = 1; idx2 <= this.catEntryParams.skus[idx].thumbnailAttachments.length; idx2++){
									var angleThumbnail = document.createElement("li");						
									var angleThumbnailLink = document.createElement("a");
									var angleThumbnailImg = document.createElement("img");

																		angleThumbnail.id = "quickInfoThumbnail" + idx2;

																		angleThumbnailLink.href = "javaScript:QuickInfoJS.changeImage(" + idx2 + ",'" + this.catEntryParams.skus[idx].fullImageAttachments[idx2-1].path + "');";
									angleThumbnailLink.id = "WC_QuickInfo_Link_thumbnail_" + idx2;
									angleThumbnailLink.className = "tlignore";
									if(this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].shortDesc != 'undefined' && this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].shortDesc != null){
										angleThumbnailLink.title = this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].shortDesc;
									}


																											angleThumbnailImg.src = this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].path;
									if(this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].shortDesc != 'undefined' && this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].shortDesc != null){
										angleThumbnailImg.alt = this.catEntryParams.skus[idx].thumbnailAttachments[idx2-1].shortDesc;
									}
									if(idx2 == 1){
										dojo.empty(thumbnailArea);							
									}						
									angleThumbnailLink.appendChild(angleThumbnailImg);
									angleThumbnail.appendChild(angleThumbnailLink);
									thumbnailArea.appendChild(angleThumbnail);
								}
							}
						} else {
							if(angleImageArea != null){
								angleImageArea.style.display = "none";
							}
						}
						if(null == this.selectedThumbnailIndex){
							this.selectedThumbnailIndex = 0;
						}
						this.selectedSkuIndex = idx;


						var imagePresetSrc = catalogEntry.description[0].fullImage+"?$ProductList-QuickBuy$";
						if (isCloudniaryEnabled()) {
							imagePresetSrc  = catalogEntry.description[0].fullImage.replace('f_auto,q_auto', 'f_auto,q_auto,t_ProductList-QuickBuy');
						}
						var imagePath = this.getQuickInfoImage(imagePresetSrc);
						if (null != imagePath && imagePath.length != 0){
							dojo.byId("quickInfoMainImage").src = imagePath;
						}
						if(document.getElementById("ProductInfoImage_"+this.catEntryParams.id) != null){
							document.getElementById("ProductInfoImage_"+this.catEntryParams.id).value = imagePath;
						}
					}
				}
			}

						if(this.updateItemImageOnly){	
				this.updateItemImageOnly = false;
				return;
			}
			var product_name = catalogEntry.description[0].name;
			if(product_name != null){
				dojo.byId("quickInfoMainName").innerHTML = product_name;
				dojo.byId("quickInfoMainImage").alt = product_name; 
			}
			if(document.getElementById("ProductInfoName_"+this.catEntryParams.id) != null){
				document.getElementById("ProductInfoName_"+this.catEntryParams.id).value = catalogEntry.description[0].name;
			}

						if(catalogEntry.listPriced && catalogEntry.listPrice > catalogEntry.offerPrice){
				var savings = 0;
				var rdSavings = 0;
				var strikedpriceStr = catalogEntry.listPrice;
				if(strikedpriceStr != null && strikedpriceStr != "" && strikedpriceStr != undefined){
					strikedpriceStr = strikedpriceStr.substring(1, strikedpriceStr.length);
					var offerPriceStr = catalogEntry.offerPrice;
					offerPriceStr = offerPriceStr.replace("$", "");
					var listPrice = parseFloat(strikedpriceStr.trim());
					var offerPriceFloat = parseFloat(offerPriceStr.trim());
					if(offerPriceFloat < listPrice)
					savings = ((listPrice-offerPriceFloat)*100)/listPrice;
					savings = Math.round(savings);

									}
				if(null != dojo.query(".product-price-crossout")[0]){
					dojo.html.set(dojo.query(".product-price-crossout")[0], catalogEntry.listPrice);
				} else {
					var oldPriceTag = "<span class='product-price-crossout'>" + catalogEntry.listPrice + "</span> ";
					var priceTag = dojo.query(".product-price-promo")[0];
					dojo.place(oldPriceTag, priceTag, "before");
				}
				if(!isNaN(savings) && savings > 0){
					var savingsStr = "save " +savings + "%";
					var rdSavingsTag = dojo.query(".product-price-save")[0];
					if(rdSavingsTag)
					dojo.html.set(dojo.query(".product-price-save")[0], savingsStr);
					else{
						var rdtag = "<span class='product-price-save'>" + savingsStr + "</span>";
						dojo.place(rdtag, priceTag, "after");
					}
				}


				var rdDetailsObject = this.catEntryParams.skus.find(function (v){ return v.id===catalogEntry.catalogEntryIdentifier.uniqueID; });
				var rdPriceWithDollar = rdDetailsObject.RepeatDeliveryPrice;
				var rdPriceNoDollar = rdPriceWithDollar.replace('$','');
				var rdPriceFloat = parseFloat(rdPriceNoDollar.trim());
				var rdsavings;
				if(rdPriceFloat < listPrice)
				rdsavings = ((listPrice-rdPriceFloat)*100)/listPrice;
				rdsavings = Math.round(rdsavings);

								if(!isNaN(rdsavings) && rdsavings > 0){
					var rdSavingsStr = rdsavings + "%";
					document.getElementById('rdPriceDiv').innerHTML="<span class='product-price-rddetail'>" +rdPriceWithDollar +"</span><span class='product-price-banner'><img src='/wcsstore/PetcoSAS/images/arrow-white.png' alt='&nbsp;'/> save " +rdSavingsStr +"</span>";
				}else{
					document.getElementById('rdPriceDiv').innerHTML="<span class='product-price-rddetail'>" +rdPriceWithDollar +"</span>";
				}

								} else if(null != dojo.query(".product-price-crossout")[0]){
				dojo.query(".product-price-crossout").orphan();
				dojo.query(".product-price-save").orphan();
				dojo.query(".product-price-banner").orphan();
			}
			dojo.html.set(dojo.query(".product-price-promo")[0], catalogEntry.offerPrice);
			dojo.html.set(dojo.query(".product-sku")[0], storeNLS['SKU'] + " " + catalogEntry.catalogEntryIdentifier.externalIdentifier.partNumber);
			document.getElementById('rd_sku').value = catalogEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
			if(document.getElementById("ProductInfoPrice_"+this.catEntryParams.id) != null){
				document.getElementById("ProductInfoPrice_"+this.catEntryParams.id).value = catalogEntry.offerPrice;
				var itemId = this.catEntryParams.id;
				if(dojo.byId("ProductInfoPrice_"+itemId).value == ""){
					if(dojo.byId("offerPrice_"+itemId) != null){
						dojo.byId("offerPrice_"+itemId).innerHTML='Price pending';
						dojo.query(".product-price-crossout").orphan();
						dojo.query(".product-price-save").orphan();
					}
				}
			}
			var priceEnabled = document.getElementById('quickInfoPriceEnabled_'+this.catEntryParams.id);
			if(priceEnabled!='undefined' && priceEnabled!=null){
				if(priceEnabled.value == "true"){
					if(document.getElementById('product-price_'+this.catEntryParams.id) != null && document.getElementById('product-price_'+this.catEntryParams.id) != undefined)
						document.getElementById('product-price_'+this.catEntryParams.id).innerHTML = "See price in cart";
				}
			}
		},

				validate: function(){
			if(this.catEntryParams.type =='ProductBean' && 
					(null == this.catEntryParams.attributes || "undefined" == this.catEntryParams.attributes)) {
				MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
				return false;
			} else if(!isPositiveInteger(this.catEntryQuantity)){
				MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
				return false;
			}
			return true;
		},

		add2ShopCart: function(catEntryIdentifier,quantity,customParams){
			var catEntrySku = this.resolveSKU();
			this.setValues();
			if(!this.validate()){
				return;
			}
			if(null == this.params){
				this.params = this.setCommonParams();
				this.params.orderId		= ".";
				this.params.calculationUsage = "-1,-2,-5,-6,-7";
				this.params.inventoryValidation = "true";
			}

						if(!isPositiveInteger(quantity)){
				MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
				return;
			}
			if(petcoPersonalizationJS.isPersonalizedItem()){ 
				var invalidChars = petcoPersonalizationJS.findInvalidCharSetForUserInput(quantity,customParams);
				if(invalidChars!=""){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedRequiredField(quantity,customParams)){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedTextInputType(quantity,customParams)){
					return false;
				}
				this.params = petcoPersonalizationJS.addPersonalizationAttr(catEntrySku,quantity,this.params);
			}


						var productId = '';
			if(!petcoPersonalizationJS.isPersonalizedItem()){ 
			if(this.catEntryParams.type.toLowerCase() == 'itembean'
				|| this.catEntryParams.type.toLowerCase() == 'packagebean'
				|| this.catEntryParams.type.toLowerCase() == 'dynamickitbean'){
				updateParamObject(this.params,"catEntryId",this.catEntryParams.id,false,-1);
				updateParamObject(this.params,"quantity",this.catEntryQuantity,false,-1);
				productId = this.catEntryParams.id;
			} else {
				var sku = this.resolveSKU();
				if(-1 == sku){
					MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
					return;
				} else {
					updateParamObject(this.params,"catEntryId",sku,false,-1);
					updateParamObject(this.params,"quantity",this.catEntryQuantity,false,-1);
				}
				productId = sku;
			}
			}
			else
			{
				productId = catEntrySku;
			}
			var repeatDeliveryOption = document.getElementById("repeat-delivery-radio");
			var oneTimeDeliveryOption = document.getElementById("one-time-delivery-radio");

						if(repeatDeliveryOption != null && repeatDeliveryOption != undefined && repeatDeliveryOption.checked)
			{
				this.isRepeatDeliveryProduct = true;
			}
			else
			{
				this.isRepeatDeliveryProduct = false;
			}

						if(this.isRepeatDeliveryProduct)
			{
				this.params.rdFrequency = document.getElementById('repeat-delivery-freq-options').value;
			}

						var shopCartService = "AddOrderItem";

			if(customParams != null && customParams != 'undefined'){
				for(i in customParams){
					this.params[i] = customParams[i];
				}
				if (customParams['catalogEntryType'] == 'dynamicKit' ) {
					shopCartService = "AddPreConfigurationToCart";
				}
				else if (customParams['catalogEntryType'] == 'repeatDeliveryAddOn') {
					shopCartService = 'RepeatDeliveryOrderAdd';	
					this.params.orderId = '**'; 
				}
			}
			if(this.params['catalogEntryType'] == 'dynamicKit' ){
				shopCartService = "AddPreConfigurationToCart";
			}

							shoppingActionsJS.saveAddedProductInfo(this.catEntryQuantity, this.catEntryParams.id, productId, this.selectedAttributes);

						this.close();

			if(!submitRequest()){
				return;
			}
		    cursor_wait();
			if (this.isRepeatDeliveryProduct) {
				var rd_schedule = document.getElementById('repeat-delivery-freq-options').value;
				var rd_sku = document.getElementById('rd_sku').value;
				if (rd_schedule != null && rd_schedule != "undefined" && rd_sku != null && rd_sku != "undefined") {
					var productRDJSON = {"conversion_event_id" : "New Repeat Delivery", "conversion_category_id" : "Repeat Delivery", "conversion_action_type" : "1", "rd_sku" : rd_sku, "rd_schedule" :  rd_schedule};
					pushEvent(productRDJSON);

									}
			}
		    var catalogEntID = $("#catalog_EntryID").val();
			var prd_id = $("#prd_id_"+catalogEntID).val();
			wc.service.invoke(shopCartService, this.params);
		},

				add2RecurringOrder: function(customParams) {

			var subscriptionIdField = null;
			var subscriptionId = null;

			subscriptionIdField = dojo.query('input[type=hidden][name=subscriptionId]');
			if (subscriptionIdField.length == 0) {
				subscriptionIdField = dojo.query('input[type=radio][name=subscriptionId]:checked');
			}
			subscriptionId = subscriptionIdField[0].value;
			console.log('subscriptionId == ' + subscriptionId);
			var rdSkuPartNumber = document.getElementById('rd_sku').value;
			if (customParams == null || (customParams != null && customParams == ''))
				customParams = {};	
			customParams['catalogEntryType'] = 'repeatDeliveryAddOn';
			customParams['subscriptionId'] = subscriptionId;
			customParams['isAddOn'] = 'Y';
			if (rdSkuPartNumber != null && rdSkuPartNumber != "undefined") {
				customParams['rdSkuPartNumber'] = rdSkuPartNumber;
			}
			var catEntryIdent = 'entitledItem_'+this.resolveSKU();
			this.add2ShopCart(catEntryIdent,this.catEntryQuantity,customParams);
		},

		resolveSKU: function() {
			var ptealium = getCookie("pcustData");
			if(ptealium != undefined && ptealium != null && ptealium != ""){
		    	var ptealiumArray = ptealium.split("|");
		    	if(document.getElementById('NotifyName')!=null){
		    		document.getElementById('NotifyName').value= ptealiumArray[0]+" "+ptealiumArray[1];
		    	} 
		    	if(document.getElementById('NotifyEmail')!=null){
		    		document.getElementById('NotifyEmail').value= ptealiumArray[2];
		    	}
		    } 
			if(this.catEntryParams.skus.length == 1){
				return this.catEntryParams.skus[0].id;
			}
			for(idx=0;idx<this.catEntryParams.skus.length;idx++){
				var matches = 0;
				var attributeCount = 0;
				for(attribute in this.catEntryParams.skus[idx].attributes){
					attributeCount++;
					if(this.catEntryParams.attributes && this.catEntryParams.skus[idx].attributes[attribute] == this.catEntryParams.attributes[attribute]){
						matches++;
					} else {
						break;
					}
				}
				if(0 != matches && matches == attributeCount){
					return this.catEntryParams.skus[idx].id;
				}
			}
			return -1;
		},

		showQuickInfoButton: function(id){
			var quickInfoBtn = dojo.byId(id);
			if(quickInfoBtn!=null && quickInfoBtn!='undefined'){
				quickInfoBtn.style.visibility="visible";
			}
		},

		hideQuickInfoButton: function(id){
			var quickInfoBtn = dojo.byId(id);
			if(quickInfoBtn!=null && quickInfoBtn!='undefined'){
				quickInfoBtn.style.visibility="";
			}
		},

		shiftTabHideQuickInfoButton: function(id, event){
			if ((event.shiftKey) && (event.keyCode == dojo.keys.TAB)){
				this.hideQuickInfoButton(id);
			} 
		},

		close: function(focusElement){
			$('#quickbuy').modal('hide');
			if(focusElement !=null && focusElement !='undefined' && document.getElementById(focusElement)){
				document.getElementById(focusElement).focus();
			}
		},


		setFocus: function(event){
			if(event.keyCode == dojo.keys.ESCAPE && dojo.byId('catEntryParamsForJS')){
				var catEntryParams = dojo.byId("catEntryParamsForJS").value;
				var catEntryId = dojo.fromJson(catEntryParams).id;
				if(document.getElementById('catalogEntry_img' + catEntryId)){
					document.getElementById('catalogEntry_img' + catEntryId).focus();
				}
			}
		},


		selectDefaultSwatch: function(){
			var swatchElement = dojo.query("a[id^='WC_QuickInfo_Swatch_']")[0];
			if(swatchElement){
				eval(dojo.attr(swatchElement,"href"));
			}
		},

		replaceCartItem : function(){
			this.setValues();
			if(!this.validate()){
				return;
			}

			var catalogEntryId = this.resolveSKU();
			if(-1 == catalogEntryId){
				MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
				return;
			}
			this.close();

						var addressId = "";
			var shipModeId = "";
			var physicalStoreId = "";
			var typeId = dojo.byId("shipmentTypeId");

						if(null != dojo.byId("addressId_all") && null != dojo.byId("shipModeId_all")){
				if(null == typeId || typeId.value != "1"){
					addressId = dojo.byId("addressId_all").value;
				} else if(null != dojo.byId("physicalStoreId")){
					physicalStoreId = dojo.byId("physicalStoreId").value;
				}
				shipModeId = dojo.byId("shipModeId_all").value;
			} else if (null != dojo.byId("MS_ShipmentAddress_"+this.replaceOrderItemId) && null != dojo.byId("MS_ShippingMode_"+this.replaceOrderItemId)){
				addressId = dojo.byId("MS_ShipmentAddress_"+this.replaceOrderItemId).value;
				shipModeId = dojo.byId("MS_ShippingMode_"+this.replaceOrderItemId).value;
			}

								if(this.replaceOrderItemId != "" && categoryDisplayJS){
				shoppingActionsJS.replaceItemAjaxHelper(catalogEntryId,this.catEntryQuantity,this.replaceOrderItemId,addressId,shipModeId,physicalStoreId);
			} else {
				console.error("categoryDisplayJS not defined");
			}
		},

		selectCurrentAttributes: function(){
			for(idx=0;idx<this.catEntryParams.skus.length;idx++){
				var selectedSKU = this.catEntryParams.skus[idx];
				if(selectedSKU.id == this.itemId){
					for(attribute in selectedSKU.attributes){
						var selectNode = dojo.query("select[alt='" + attribute + "']")[0];
						if(selectNode){
							selectNode.value = selectedSKU.attributes[attribute];
							this.setSelectedAttribute(attribute,selectNode.value);
						} else {
							var attrValue = selectedSKU.attributes[attribute];
							this.selectSwatch(attribute, attrValue, "quickInfoSwatch_" + attribute + "_" + attrValue, "quickInfoSwatch_" + attribute + "_");
						}
					}

										this.notifyAttributeChange();
					this.selectItem(true);

				  					  	dojo.byId("WC_QuickInfo_input_quantity").disabled = true;
				  	dojo.byId("WC_QuickInfo_input_quantity").value = this.catEntryQuantity;
					return;
				}
			}

					},

		getQuickInfoImage:function(fullImage){
			if(fullImage == "") return fullImage;
			return fullImage.replace(this.productImgDimensions, this.quickInfoImgDimensions);

		},

		showRDFrequencyOptions: function(optionVal) {
			if (optionVal == 'repeat-delivery') {
				dojo.query('#repeat-delivery-dropdown').removeClass('hide');						
			}
			else {
				dojo.query('#repeat-delivery-dropdown').addClass('hide');			
			}
		},

		selectRepeatDelivery:function(signInUrl,quantity,customParams) {
			var userType = dojo.cookie('WC_UserType');
			this.catEntryQuantity = quantity;
			this.ltlorRXflag = customParams;
			this.catalogEntryId = document.getElementById("catalog_EntryID").value;
			if(petcoPersonalizationJS.isPersonalizedItem()){ 
				var invalidChars = petcoPersonalizationJS.findInvalidCharSetForUserInput(quantity,customParams);
				if(invalidChars!=""){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedRequiredField(quantity,customParams)){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedTextInputType(quantity,customParams)){
					return false;
				}
		}
			if(userType != 'R'){
				var urlParams = window.location.href;
				var rdSignInUrl = "";
				if (signInUrl != null && signInUrl != 'undefined') {
					rdSignInUrl = signInUrl;
					rdSignInUrl = rdSignInUrl+'&URL='+urlParams;
					var catentryId = document.getElementById("catalog_EntryID").value;
					dojo.cookie('oneTimeAddOnRDQuickView', 'true', {path:'/'});
					dojo.cookie('oneTimeAddOnRDQuickViewCatentryId', catentryId, {path:'/'});
					document.location.href = rdSignInUrl;
				}
			} else {
				$('#quickbuy').modal('hide');
				PetcoRepeatDeliveryAddOnJS.updateContextForRDAddOn();	
			}	
		},
		  add2ShopCartforNoAddOnRd:function(){
                        var catEntryIdentforNoRD = 'entitledItem_'+this.catalogEntryId;
                        this.add2ShopCart(catEntryIdentforNoRD,this.catEntryQuantity,this.ltlorRXflag);
                },
		preSelectRadioButtonOnQuickInfo:function() {
			var oneTimeAddOnRDFlag = dojo.cookie('oneTimeAddOnRDQuickView'); 
			if (oneTimeAddOnRDFlag != null && oneTimeAddOnRDFlag != '' && oneTimeAddOnRDFlag == 'true') {
				var userType = dojo.cookie('WC_UserType');
				if(userType == 'R'){
					if(document.getElementById("repeat-delivery-add-on")!=null && document.getElementById("repeat-delivery-add-on")!='undefined'){
						document.getElementById("repeat-delivery-add-on").click();
					}
					if(document.getElementById("add2RDOrderBtn")!=null && document.getElementById("add2RDOrderBtn")!='undefined'){
						document.getElementById("add2RDOrderBtn").click();
					}
						dojo.cookie("oneTimeAddOnRDQuickView", null, {expires: -1, path:'/'});
						dojo.cookie("oneTimeAddOnRDQuickViewCatentryId", null, {expires: -1, path:'/'});
				} else {
					document.getElementById("one-time-delivery").click();
					dojo.cookie("oneTimeAddOnRDQuickView", null, {expires: -1, path:'/'});
					dojo.cookie("oneTimeAddOnRDQuickViewCatentryId", null, {expires: -1, path:'/'});
				}
			} else {
				if(document.getElementById("one-time-delivery")!=null && document.getElementById("one-time-delivery")!='undefined'){
					document.getElementById("one-time-delivery").click();
				}
			}
		},


		refreshActionButton:function(optionVal) {
			if (optionVal == 'repeat-delivery-add-on') {
				dojo.query('#WC_QuickInfo_Link_addtocart').addClass('hide');
				dojo.query('#add2RDOrderBtn').removeClass('hide');
				var rd_sku = document.getElementById('rd_sku').value;
				if (rd_sku != null && rd_sku != "undefined") {
					var productRDJSON = {"conversion_event_id" : "New Product Existing RD Order", "conversion_category_id" : "Repeat Delivery", "conversion_action_type" : "1", "rd_sku" : rd_sku};
					pushEvent(productRDJSON);
				}
			}
			else {
				dojo.query('#WC_QuickInfo_Link_addtocart').removeClass('hide');
				dojo.query('#add2RDOrderBtn').addClass('hide');			
			}
		}		
	}

}