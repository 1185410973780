dojo.require("dojox.xml.DomParser");


if(typeof(NotifyInventoryJS) == "undefined" || NotifyInventoryJS == null || !NotifyInventoryJS) {

		NotifyInventoryJS = {


					validateNotify:function(catentry,prodname) {

						reWhiteSpace = new RegExp(/^\s+$/);

					var catentryId= catentry;
			var prodname = prodname;
			var email = document.getElementById('NotifyEmail');
			var name = document.getElementById('NotifyName').value;
			var formPage = "";
			if(document.getElementById('formPage')!=null)
				formPage = document.getElementById('formPage').value;

						var flag = false;
			if(name == "" ||  name == " "){
				document.getElementById("NotifyName").placeholder = MessageHelper.messages["ERROR_NotifyName"];
				document.getElementById("NotifyName").className = "invalid";
				document.querySelectorAll("label[for='NotifyName']")[0].className = "invalid";
			}
			else if(email!=null){
				var emailValue = trim(email.value);
				var captchaResp = " ";
				if(typeof(notifyWidget) != "undefined"){
					captchaResp = grecaptcha.getResponse(notifyWidget);
				}
				if(emailValue == "" || reWhiteSpace.test(emailValue)){					
					document.getElementById("NotifyName").className = "valid";
					document.querySelectorAll("label[for='NotifyName']")[0].className = "valid";
					document.getElementById("NotifyEmail").placeholder = MessageHelper.messages["ERROR_EmailEmpty"];
					document.getElementById("NotifyEmail").className = "invalid";
					document.querySelectorAll("label[for='NotifyEmail']")[0].className = "invalid";

										return flag;
				}
				else if(!NotifyInventoryJS.emailValidation(emailValue)){					
					document.getElementById("NotifyName").className = "valid";
					document.querySelectorAll("label[for='NotifyName']")[0].className = "valid";
					document.getElementById("NotifyEmail").placeholder = MessageHelper.messages["ERROR_INVALIDEMAILFORMAT"];
					document.getElementById("NotifyEmail").className = "invalid";
					document.querySelectorAll("label[for='NotifyEmail']")[0].className = "invalid";

										return flag;
				}else if(!MessageHelper.isValidUTF8length(emailValue, 256)){
					document.getElementById("NotifyName").className = "valid";
					document.querySelectorAll("label[for='NotifyName']")[0].className = "valid";
					document.getElementById("NotifyEmail").placeholder = MessageHelper.messages["ERROR_EmailTooLong"];
					document.getElementById("NotifyEmail").className = "invalid";
					document.querySelectorAll("label[for='NotifyEmail']")[0].className = "invalid";

										return flag;
				}
				else if(name == "" || name == null){
					document.getElementById("NotifyName").placeholder = MessageHelper.messages["ERROR_NotifyName"];
					document.getElementById("NotifyName").className = "invalid";
					document.querySelectorAll("label[for='NotifyName']")[0].className = "invalid";
					document.getElementById("NotifyEmail").className = "valid";
					document.querySelectorAll("label[for='NotifyEmail']")[0].className = "valid";

										return flag;
				}
				else if(document.getElementById("notifyCaptcha") != null && captchaResp != null && captchaResp == ''){
					dojo.style("notifyCaptchaError","display","block");
					dojo.byId('notifyCaptchaError').innerHTML = '<span class="help-block"> Please select the captcha. </span>';

										document.getElementById("NotifyEmail").className = "valid";
					document.querySelectorAll("label[for='NotifyEmail']")[0].className = "valid";

										return flag;
				}
				else{

										this.notifyInventory(catentry,formPage,prodname);					
				}
			}
			else{
				return false;
			}


									return flag;

					},

				notifyMeOnclickTracking:function(conversion_event_id,conversion_category_id,conversion_action_type){
			try{
				var sku = document.getElementById('primarySku').value;
				if (sku == null || sku == "")
					sku = document.getElementById('tel_product_sku').value;					
			}catch(e){
				console.log("Error in NotifyInventoryJS.notifyMeOnclickTracking. "+e);
				return true;
			}
		},
		setCommonParams: function(){
			var params = new Object();
			params.storeId		= WCParamJS.storeId;
			params.catalogId	= WCParamJS.catalogId;
			params.langId		= WCParamJS.langId;
			return params;
		},

		notifyInventory: function(catalogEntryID,formPage,prodname) {

						var catentryId = null;
			catentryId=catalogEntryID;
			var prodname = prodname;
			var emailNotify = document.getElementById('NotifyEmail').value;
			var name = document.getElementById('NotifyName').value;

						var notifyParams = new Object();
			notifyParams.emailNotify = emailNotify;
			notifyParams.catEntryId = catentryId;
			if(document.getElementById("notifyCaptcha") != null){
				var captchaResp = grecaptcha.getResponse(notifyWidget);
				notifyParams.captcha = captchaResp;
			}

						var contextValues = notifyParams;		
			contextValues.name = name;
			contextValues.formPage =formPage;
			contextValues.prodname = prodname;
			contextValues.storeId = WCParamJS.storeId;
			contextValues.catalogId	= WCParamJS.catalogId;
			contextValues.langId = WCParamJS.langId;
			if(emailNotify == "") {
				alert("Please enter the Email Id  to send the notification");
				return;
			}
			wc.service.invoke("AjaxInventoryNotification", contextValues);

					},
		emailValidation:function(email){
			var lastindex = email.lastIndexOf('.');
			if(lastindex < 0){
				return false;
			}
			var result= email.substring(lastindex  + 1);
			if(result.length > 25){
				return false;
			}
			else{
				var validEmail = new RegExp(/^\w+([\.!#$%&'*([)+\/<>=?`{|};:,~^-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,25})+$/);
				return validEmail.test(email);
			}
		},
		openNotifyModal:function(){
			document.getElementById("notifyMe").style.display = "block";
			document.querySelectorAll("body")[0].classList.add("no-scroll");
		},
		closeNotifyModal:function(){
			document.getElementById("notifyMe").style.display = "none";
			document.querySelectorAll("body")[0].classList.remove("no-scroll");
		},
		closeNotifySuccessModal:function(){
			document.getElementById("notifyMeSuccess").style.display = "none";
			document.querySelectorAll("body")[0].classList.remove("no-scroll");
		},
		validateSubmitButtonEnabled:function() {
			var email = document.getElementById('NotifyEmail').value;
			var name = document.getElementById('NotifyName').value;
			if(name == "" ||  name == " " || email == "" ||  email == " " ){
				document.getElementById("notifyMeSubmitBtn").disabled = true;
			} else{
				document.getElementById("notifyMeSubmitBtn").disabled = false;
			}
		}
	}
    wc.service.declare({
		id: "AjaxInventoryNotification",
		actionId: "AjaxInventoryNotification",
		url: getAbsoluteURL() + "AjaxPetcoNotifyMe",
		formId: "",

    		successHandler: function(serviceResponse) {	  
    		if(serviceResponse.message != undefined){

    			    			if(document.getElementById("notifyOOSbtn")!=null){
    				document.getElementById("oos-notify-mewishlist"+serviceResponse.catentryId).className = 'modal';
    				$('#oos-notify-me'+serviceResponse.formPage+''+serviceResponse.catentryId).modal('hide');
    				document.getElementById("notifyMeSuccess").className = 'row';
    				$("#notifyOOSbtn").remove();
    			} else if (document.getElementById("notifyMe")!=null){
    				dojo.style("notifyMeSuccess","display","block");
    				dojo.style("notifyMe","display","none");
    			} else{
    				$('#oos-notify-me'+serviceResponse.formPage+''+serviceResponse.catentryId).modal('hide');
    				$('#oos-notify-mepurchaselist'+''+serviceResponse.catentryId).modal('hide');
    				document.getElementById("product-name").innerHTML =serviceResponse.prodname; 
    				petcoCommonJS.showModal('ListSuccessMsg'); 
        			$('#ListSuccessMsg_1').show();
    			}
			}		
	    },

			failureHandler: function(serviceResponse) {

	    		    	if (serviceResponse.errorMessage) {
			 	if(serviceResponse.errorMessageKey == "_ERR_FINDER_EXCEPTION"){
			 		MessageHelper.displayErrorMessage("This notification is not sent for the specififed email id");
					} else {
						MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
					}
			} 
			else {
				 if (serviceResponse.errorMessageKey) {
					MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
				 }
			}
			cursor_clear();
		} 
	})


	}

