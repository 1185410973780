wc.service.declare({
    id: "AjaxPetcoPalsRewardsPointsSubscription",
    actionId: "AjaxPetcoPalsRewardsPointsSubscription",
    url: getAbsoluteURL()+"AjaxPetcoPalsRewardsPointsSubscription",
    formId: ""

    ,successHandler: function(serviceResponse) {
        var palsRewardsPoints = serviceResponse.palsRewardsPoints,
            subscriptionDate = serviceResponse.subscriptionDate,
            palsPointCookie = serviceResponse.palsPointCookie,
            nextRepeatedDeliveryDateCookie = serviceResponse.nextRepeatedDeliveryDateCookie,
            updateCookies = serviceResponse.updateCookies,
            isSubscriptionPaused=serviceResponse.isPendingOrder;
	        var hasIos = false;
	        require(["dojo/has", "dojo/sniff"], function(has){
	        	var iosVal = has("ios");
	              if(typeof(iosVal)!= "undefined" && iosVal != null && iosVal){
	        		hasIos=true;
	        	  }else{
	        		hasIos=false;
	        	  }
	        });
            if(updateCookies && typeof(localStorageHelper) != "undefined"){

                        	localStorageHelper.set(palsPointCookie,palsRewardsPoints,1,hasIos);
				if(subscriptionDate != null){
					subscriptionDate = subscriptionDate + " PDT"
					var date = new Date(subscriptionDate.replace(",", " "));
					localStorageHelper.set(nextRepeatedDeliveryDateCookie,date.toISOString().substring(0, 10),1,hasIos);
				}else{
					if(isSubscriptionPaused){
						localStorageHelper.set(nextRepeatedDeliveryDateCookie,"paused",1,hasIos);
					}else{
						localStorageHelper.set(nextRepeatedDeliveryDateCookie,"none",1,hasIos);
					}

									}
			}

                    	petcoPalsRewardsRepeatDelivery.renderPalsRewardTile();
        	petcoPalsRewardsRepeatDelivery.renderRepeatDeliveryTile();
        	if(undefined != document.getElementById('rd-next-date') ){
        		var nextRepeatedDeliveryDateCookie = "NEXT_REPEAT_DELIVERY_DATE";
				var nextRepeatedDeliveryDateCookieValue;
				if(typeof(localStorageHelper) != "undefined"){
					nextRepeatedDeliveryDateCookieValue = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);
				}

    							if (null != nextRepeatedDeliveryDateCookieValue
						&& nextRepeatedDeliveryDateCookieValue 
						&& 	nextRepeatedDeliveryDateCookieValue != "" 
	    				&&  nextRepeatedDeliveryDateCookieValue != 'none' 
	    				&& 	nextRepeatedDeliveryDateCookieValue != 'paused') {
					var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
					var nextDate = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);
					var dateString = nextDate.replace(/-/g, '/')+" 00:00:00";
					var nextDateVal =  new Date(dateString);
					var isnotify = false;
					if(dojo.byId("notifybutton") != null){
						isnotify = dojo.hasClass(dojo.byId("notifybutton"),'show');
					}
					if($('#rd-add-on').length >0){
						if(!isnotify){
							document.getElementById("rd-add-on").className = "radio rd-option show";
						}else{
							document.getElementById("rd-add-on").className = "radio rd-option hide";
						}
					}	

												var innerHTML = months[nextDateVal.getMonth()]+" "+nextDateVal.getDate()+", "+ nextDateVal.getFullYear();
						if(document.getElementById('rd-next-date') != null &&  typeof(months[nextDateVal.getMonth()]) != "undefined"){
							document.getElementById("rd-next-date").innerHTML = innerHTML;
						}
				}
        	}
            if (typeof(productDisplayJS) != 'undefined') {
                productDisplayJS.toggleAddonButton();
            }
    }
    ,failureHandler: function(serviceResponse) {
    }

})

var petcoPalsRewardsRepeatDelivery = (function(){
	'use strict';

    var palsPointCookie = "PALS_POINTS",
        nextRepeatedDeliveryDateCookie = "NEXT_REPEAT_DELIVERY_DATE",
        tilePalsPointsRegistered     = 'PALS_POINTS_REGISTERED_TILE',
        tilePalsPointsGuest          = 'PALS_POINTS_GUEST_TILE',
        tileRepeatDeliveryGuest      = 'REPEAT_DELIVERY_GUEST_TILE',
        tileRepeatDeliveryRegistered = 'REPEAT_DELIVERY_REGISTERED_TILE',
        tileRepeatDeliveryRegisteredToday = 'REPEAT_DELIVERY_REGISTERED_TODAY_TILE',
        tileRepeatDeliveryPaused = 'REPEAT_DELIVERY_PAUSED_TILE';

    function init(bUpdateCookieValues, sUserType){


    	    	        var hasIos = false;
        require(["dojo/has", "dojo/sniff"], function(has){
        	var iosVal = has("ios");
              if(typeof(iosVal)!= "undefined" && iosVal != null && iosVal){
        		hasIos=true;
        	  }else{
        		hasIos=false;
        	  }
        });
          var aParams = [],
            bUpdateCookies = bUpdateCookieValues || false,
            palsPointCookieValue = localStorageHelper.get(palsPointCookie,hasIos),
            nextRepeatedDeliveryDateCookieValue = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);

        sUserType = sUserType || "G";

                if (sUserType === 'R') {
            aParams.langId = WCParamJS.langId;
            aParams.storeId = WCParamJS.storeId;
            aParams.catalogId = WCParamJS.catalogId;
            aParams.palsPointCookie = palsPointCookie;
            aParams.nextRepeatedDeliveryDateCookie = nextRepeatedDeliveryDateCookie;

            if(bUpdateCookies || palsPointCookieValue == null || nextRepeatedDeliveryDateCookieValue == null){    
                aParams.updateCookies = true;

                               wc.service.invoke("AjaxPetcoPalsRewardsPointsSubscription",aParams);


					                            }
        }else{
        	petcoPalsRewardsRepeatDelivery.renderPalsRewardTile();
        	petcoPalsRewardsRepeatDelivery.renderRepeatDeliveryTile();
        }
    }

    function getFragment(key, params) {
        var container = dojo.byId(key);
        if (!container) {
        	return '';
        }
        var template = container.innerHTML;
        if (params) {
        	var zeroParmRegex = new RegExp('\\{0\\}', 'gi');
        	template = template.replace(zeroParmRegex, params[0]);
        }
        return template;
    }

        function renderPalsRewardTile() {    	
    	 var hasIos = false;
         require(["dojo/has", "dojo/sniff"], function(has){
         	var iosVal = has("ios");
               if(typeof(iosVal)!= "undefined" && iosVal != null && iosVal){
         		hasIos=true;
         	  }else{
         		hasIos=false;
         	  }
         });    	
    	require(['dojo/query', 'dojo/NodeList-traverse', 'dojo/domReady!'], function(query) {
    		query('#pals-rewards-tile2').forEach(function (tile) {
    	    	if (getUserTypeCookie() === 'G') {
    	    		var frag = getFragment(tilePalsPointsGuest);
    	    		tile.innerHTML = frag;
    	    	}
    	    	else {
    				var points = null;
    				if(typeof(localStorageHelper) != "undefined"){
    					points = localStorageHelper.get(palsPointCookie,hasIos);
    				}
    				if (points !== null) {
    					tile.innerHTML = getFragment(tilePalsPointsRegistered, [points | 0]);
    					var plasrewardurl = document.getElementById("palsrewardsurl_id");
						if(plasrewardurl != null){
							query('a',tile)[0].href=plasrewardurl.value;						
						}
    				}
    				else {
    					tile.innerHTML = getFragment(tilePalsPointsGuest);
    				}
    	    	}
    		});
    	});
    }


        function renderRepeatDeliveryTile() {
    	 var hasIos = false;
         require(["dojo/has", "dojo/sniff"], function(has){
         	var iosVal = has("ios");
               if(typeof(iosVal)!= "undefined" && iosVal != null && iosVal){
         		hasIos=true;
         	  }else{
         		hasIos=false;
         	  }
         });    	
    	require(['dojo/query', 'dojo/NodeList-traverse', 'dojo/domReady!'], function(query) {
	    	query('#repeat-delivery-tile2').forEach(function (tile) {
	    		if (getUserTypeCookie() === 'G') {
	    			tile.innerHTML = getFragment(tileRepeatDeliveryGuest);
	    		}
	    		else {
	    			var nextDate = 'none';
    				if(typeof(localStorageHelper) != "undefined"){
    					nextDate = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);
    				}
	    			if (!nextDate || nextDate === 'none') {
		    			tile.innerHTML = getFragment(tileRepeatDeliveryGuest);
	    			}else if(nextDate === 'paused'){
	    				tile.innerHTML = getFragment(tileRepeatDeliveryPaused);
	    			}
	    			else if(nextDate != "") {
	    				var dummyDate = new Date();
	    				var today = new Date(dummyDate.getFullYear(),dummyDate.getMonth(),dummyDate.getDate(),0, 0, 0, 0);
	    				var dateString = nextDate.replace(/-/g, '/')+" 00:00:00";
						var nextDay =  new Date(dateString);
	    				var days = nextDay.getTime() - today.getTime();
	    				days = Math.ceil(days / (1000 * 60 * 60 * 24));
	    				if(days < 1){
	    					tile.innerHTML = getFragment(tileRepeatDeliveryRegisteredToday);
	    				}else if(!isNaN(days)){
	    					tile.innerHTML = getFragment(tileRepeatDeliveryRegistered, [days]);
	    				}else{	    					
	    					tile.innerHTML = getFragment(tileRepeatDeliveryGuest);
	    				}
	    			}else{
	    				tile.innerHTML = getFragment(tileRepeatDeliveryGuest);
	    			}
	    			var repeatDevliveryurl = document.getElementById("repeatDevliveryurl_id");
					if(repeatDevliveryurl != null){
						query('a',tile)[0].href=repeatDevliveryurl.value;						
					}
	    		}
	    	});
    	});
    } 

        return {
        init: init,
        renderPalsRewardTile: renderPalsRewardTile,
        renderRepeatDeliveryTile: renderRepeatDeliveryTile,

            	palsPointCookie: palsPointCookie,
    	nextRepeatedDeliveryDateCookie: nextRepeatedDeliveryDateCookie
	};
})();