var tns = (function (){
    if (!Object.keys) {
        Object.keys = function (object) {
            var keys = [];
            for (var name in object) {
                if (Object.prototype.hasOwnProperty.call(object, name)) {
                    keys.push(name);
                }
            }
            return keys;
        };
    }

    (function () {
      "use strict";

          if(!("remove" in Element.prototype)){
        Element.prototype.remove = function(){
          if(this.parentNode) {
            this.parentNode.removeChild(this);
          }
        };
      }
    })();




        if (!Date.now)
        Date.now = function() { return new Date().getTime(); };

        (function() {
        'use strict';

                var vendors = ['webkit', 'moz'];
        for (var i = 0; i < vendors.length && !window.requestAnimationFrame; ++i) {
            var vp = vendors[i];
            window.requestAnimationFrame = window[vp+'RequestAnimationFrame'];
            window.cancelAnimationFrame = (window[vp+'CancelAnimationFrame']
                                       || window[vp+'CancelRequestAnimationFrame']);
        }
        if (/iP(ad|hone|od).*OS 6/.test(window.navigator.userAgent) 
            || !window.requestAnimationFrame || !window.cancelAnimationFrame) {
            var lastTime = 0;
            window.requestAnimationFrame = function(callback) {
                var now = Date.now();
                var nextTime = Math.max(lastTime + 16, now);
                return setTimeout(function() { callback(lastTime = nextTime); },
                                  nextTime - now);
            };
            window.cancelAnimationFrame = clearTimeout;
        }
    }());

    (function(){
        "use strict";

        var UNDEF,
        WIN   = window,
        DOC   = document,
        OBJ   = Object,
        NULL  = null,
        TRUE  = true,
        FALSE = false,

        SPACE           = " ",
        ELEMENT         = "Element",
        CREATE_ELEMENT  = "create"+ELEMENT,
        DOM_TOKEN_LIST  = "DOMTokenList",
        DEFINE_GETTER   = "__defineGetter__",
        DEFINE_PROPERTY = "defineProperty",
        CLASS_          = "class",
        LIST            = "List",
        CLASS_LIST      = CLASS_+LIST,
        REL             = "rel",
        REL_LIST        = REL+LIST,
        DIV             = "div",
        LENGTH          = "length",
        CONTAINS        = "contains",
        APPLY           = "apply",
        HTML_           = "HTML",
        METHODS         = ("item "+CONTAINS+" add remove toggle toString toLocaleString").split(SPACE),
        ADD             = METHODS[2],
        REMOVE          = METHODS[3],
        TOGGLE          = METHODS[4],
        PROTOTYPE       = "prototype",



        dpSupport       = DEFINE_PROPERTY in OBJ || DEFINE_GETTER in OBJ[ PROTOTYPE ] || NULL,


        defineGetter    = function(object, name, fn, configurable){
            if(OBJ[ DEFINE_PROPERTY ])
                OBJ[ DEFINE_PROPERTY ](object, name, {
                    configurable: FALSE === dpSupport ? TRUE : !!configurable,
                    get:          fn
                });

                        else object[ DEFINE_GETTER ](name, fn);
        },




        DOMTokenList = function(el, prop){
            var THIS    = this,

            tokens      = [],
            tokenMap    = {},
            length      = 0,
            maxLength   = 0,


                                    reindex     = function(){

                if(length >= maxLength)
                    for(; maxLength < length; ++maxLength) (function(i){

                                                defineGetter(THIS, i, function(){
                            preop();
                            return tokens[i];
                        }, FALSE);

                                            })(maxLength);
            },



            preop = function(){
                var error, i,
                args    = arguments,
                rSpace  = /\s+/;

                if(args[ LENGTH ])
                    for(i = 0; i < args[ LENGTH ]; ++i)
                        if(rSpace.test(args[i])){
                            error       = new SyntaxError('String "' + args[i] + '" ' + CONTAINS + ' an invalid character');
                            error.code  = 5;
                            error.name  = "InvalidCharacterError";
                            throw error;
                        }


                tokens = ("" + el[prop]).replace(/^\s+|\s+$/g, "").split(rSpace);

                if("" === tokens[0]) tokens = [];

                tokenMap = {};
                for(i = 0; i < tokens[ LENGTH ]; ++i)
                    tokenMap[tokens[i]] = TRUE;
                length = tokens[ LENGTH ];
                reindex();
            };



            preop();



            defineGetter(THIS, LENGTH, function(){
                preop();
                return length;
            });


            THIS[ METHODS[6]  ] =
            THIS[ METHODS[5]  ] = function(){
                preop();
                return tokens.join(SPACE);
            };



            THIS.item = function(idx){
                preop();
                return tokens[idx];
            };


            THIS[ CONTAINS ] = function(token){
                preop();
                return !!tokenMap[token];
            };



            THIS[ADD] = function(){
                preop[APPLY](THIS, args = arguments);

                    for(var args, token, i = 0, l = args[ LENGTH ]; i < l; ++i){
                    token = args[i];
                    if(!tokenMap[token]){
                        tokens.push(token);
                        tokenMap[token] = TRUE;
                    }
                }

                if(length  !== tokens[ LENGTH ]){
                    length   = tokens[ LENGTH ] >>> 0;
                    el[prop] = tokens.join(SPACE);
                    reindex();
                }
            };



            THIS[ REMOVE ] = function(){
                preop[APPLY](THIS, args = arguments);

                for(var args, ignore = {}, i = 0, t = []; i < args[ LENGTH ]; ++i){
                    ignore[args[i]] = TRUE;
                    delete tokenMap[args[i]];
                }

                for(i = 0; i < tokens[ LENGTH ]; ++i)
                    if(!ignore[tokens[i]]) t.push(tokens[i]);

                                tokens   = t;
                length   = t[ LENGTH ] >>> 0;

                el[prop] = tokens.join(SPACE);
                reindex();
            };



            THIS[TOGGLE] = function(token, force){
                preop[APPLY](THIS, [token]);

                if(UNDEF !== force){
                    if(force) { THIS[ADD](token);     return TRUE;  }
                    else      { THIS[REMOVE](token);  return FALSE; }
                }

                if(tokenMap[token]){
                    THIS[ REMOVE ](token);
                    return FALSE;
                }

                THIS[ADD](token);
                return TRUE;
            };


            (function(o, defineProperty){
                if(defineProperty)
                    for(var i = 0; i < 7; ++i)
                        defineProperty(o, METHODS[i], {enumerable: FALSE});
            }(THIS, OBJ[ DEFINE_PROPERTY ]));

                        return THIS;
        },



        addProp = function(o, name, attr){

                        defineGetter(o[PROTOTYPE], name, function(){
                var tokenList,
                THIS = this,

                gibberishProperty           = DEFINE_GETTER + DEFINE_PROPERTY + name;
                if(THIS[gibberishProperty]) return tokenList;
                THIS[gibberishProperty]     = TRUE;


                if(FALSE === dpSupport){

                                        var visage,
                    mirror      = addProp.mirror = addProp.mirror || DOC[ CREATE_ELEMENT ](DIV),
                    reflections = mirror.childNodes,

                    l = reflections[ LENGTH ],
                    i = 0;

                                        for(; i < l; ++i)
                        if(reflections[i]._R === THIS){
                            visage = reflections[i];
                            break;
                        }

                    visage || (visage = mirror.appendChild(DOC[ CREATE_ELEMENT ](DIV)));

                                        tokenList = DOMTokenList.call(visage, THIS, attr);
                }

                                else tokenList = new DOMTokenList(THIS, attr);


                                                defineGetter(THIS, name, function(){ return tokenList; });
                delete THIS[gibberishProperty];

                                return tokenList;
            }, TRUE);
        },

        testList,
        nativeAdd,
        nativeRemove;




        if(!WIN[ DOM_TOKEN_LIST ]){

            if(dpSupport)
                try{ defineGetter({}, "support"); }
                catch(e){ dpSupport = FALSE; }


                                    DOMTokenList.polyfill   = TRUE;
            WIN[ DOM_TOKEN_LIST ]   = DOMTokenList;

                        addProp( WIN[ ELEMENT ], CLASS_LIST, CLASS_ + "Name");      
            addProp( WIN[ HTML_+ "Link"   + ELEMENT ], REL_LIST, REL);  
            addProp( WIN[ HTML_+ "Anchor" + ELEMENT ], REL_LIST, REL);  
            addProp( WIN[ HTML_+ "Area"   + ELEMENT ], REL_LIST, REL);  
        }


        else{
            testList = DOC[ CREATE_ELEMENT ](DIV)[CLASS_LIST];

            PROTOTYPE = WIN[DOM_TOKEN_LIST][PROTOTYPE];


            testList[ADD][APPLY](testList, METHODS);
            if(2 > testList[LENGTH]){
                nativeAdd      = PROTOTYPE[ADD];
                nativeRemove   = PROTOTYPE[REMOVE];

                                PROTOTYPE[ADD] = function(){
                    for(var i = 0, args = arguments; i < args[LENGTH]; ++i)
                        nativeAdd.call(this, args[i]);
                };

                                PROTOTYPE[REMOVE] = function(){
                    for(var i = 0, args = arguments; i < args[LENGTH]; ++i)
                        nativeRemove.call(this, args[i]);
                };
            }


            if(testList[TOGGLE](LIST, FALSE))
                PROTOTYPE[TOGGLE] = function(token, force){
                    var THIS = this;
                    THIS[(force = UNDEF === force ? !THIS[CONTAINS](token) : force) ? ADD : REMOVE](token);
                    return !!force;
                };
        }
    }());

        function extend() {
      var obj, name, copy,
          target = arguments[0] || {},
          i = 1,
          length = arguments.length;

          for (; i < length; i++) {
        if ((obj = arguments[i]) !== null) {
          for (name in obj) {
            copy = obj[name];

                if (target === copy) {
              continue;
            } else if (copy !== undefined) {
              target[name] = copy;
            }
          }
        }
      }
      return target;
    }

        function indexOf(array, item) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] === item) { return i; }
      }
      return -1;
    }

        function getSupportedProp(proparray){
      var root = document.documentElement;
      for (var i=0; i<proparray.length; i++){
        if (proparray[i] in root.style){
          return proparray[i];
        }
      }
    }


        function isNodeList(el) {
      return typeof el.item !== "undefined"; 
    }

        function append(els, data) {
      var els_new = (isNodeList(els)) ? els : [els], i;

          if (typeof data.nodeType !== "undefined" && data.nodeType === 1) {
        for (i = els_new.length; i--;) {
          els_new[i].appendChild(data);
        }
      } else if (typeof data === "string") {
        for (i = els_new.length; i--;) {
          els_new[i].insertAdjacentHTML("beforeend", data);
        }
      } else if (isNodeList(data)) {
        var fragment = document.createDocumentFragment();
        for (i = data.length; i--;) {
          fragment.insertBefore(data[i], fragment.firstChild);
        }
        for (var j = els_new.length; j--;) {
          els_new[j].appendChild(fragment);
        }
      }
    }

        function wrap(els, obj) {
      var elsNew = (isNodeList(els)) ? els : [els];
      for (var i = elsNew.length; i--;) {
        var wrapper = (i > 0) ? obj.cloneNode(true) : obj,
          el = elsNew[i];

        var parent = el.parentNode,
          sibling = el.nextSibling;

        wrapper.appendChild(el);

        if (sibling) {
          parent.insertBefore(wrapper, sibling);
        } else {
          parent.appendChild(wrapper);
        }
      }
    }

        function unwrap(els) {
      var elsNew = (isNodeList(els)) ? els : [els];
      for (var i = elsNew.length; i--;) {
        var el = elsNew[i];

        var parent = el.parentNode;

        while (el.firstChild) { 
          parent.insertBefore(el.firstChild, el); 
        }

        parent.removeChild(el);
      }
    }

        function getSlideId() {
      if (window.tnsId === undefined) {
        window.tnsId = 1;
      } else {
        window.tnsId++;
      }
      return 'tns' + window.tnsId;
    }

        function toDegree (y, x) {
      return Math.atan2(y, x) * (180 / Math.PI);
    }

        function getTouchDirection(angle, range) {
      if ( Math.abs(90 - Math.abs(angle)) >= (90 - range) ) {
        return 'horizontal';
      } else if ( Math.abs(90 - Math.abs(angle)) <= range ) {
        return 'vertical';
      } else {
        return false;
      }
    }

        function hasAttr(el, attr) {
      return el.hasAttribute(attr);
    }

        function getAttr(el, attr) {
      return el.getAttribute(attr);
    }

        function isNodeList$1(el) {
      return typeof el.item !== "undefined"; 
    }

        function setAttrs(els, attrs) {
      els = (isNodeList$1(els) || els instanceof Array) ? els : [els];
      if (Object.prototype.toString.call(attrs) !== '[object Object]') { return; }

          for (var i = els.length; i--;) {
        for(var key in attrs) {
          els[i].setAttribute(key, attrs[key]);
        }
      }
    }

        function removeAttrs(els, attrs) {
      els = (isNodeList$1(els) || els instanceof Array) ? els : [els];
      attrs = (attrs instanceof Array) ? attrs : [attrs];

          var attrLength = attrs.length;
      for (var i = els.length; i--;) {
        for (var j = attrLength; j--;) {
          els[i].removeAttribute(attrs[j]);
        }
      }
    }

        function removeEventsByClone(el) {
      var elClone = el.cloneNode(true), parent = el.parentNode;
      parent.insertBefore(elClone, el);
      el.remove();
      el = null;
    }

        function hideElement(el) {
      if (!hasAttr(el, 'hidden')) {
        setAttrs(el, {'hidden': ''});
      }
    }

        function showElement(el) {
      if (hasAttr(el, 'hidden')) {
        removeAttrs(el, 'hidden');
      }
    }


        function imageLoaded(img) {
      if (typeof img.complete === 'boolean') {
        return img.complete;
      } else if (typeof img.naturalWidth === 'number') {
        return img.naturalWidth !== 0;
      }
    }

        function whichProperty(obj){
      var t, el = document.createElement('fakeelement');
      for(t in obj){
        if( el.style[t] !== undefined ){
          return [t, obj[t][0], obj[t][1]];
        }
      }

          return false; 
    }

    var supportsPassive = false;
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function() {
          supportsPassive = true;
        }
      });
      window.addEventListener("test", null, opts);
    } catch (e) {}
    var passiveOption = supportsPassive ? { passive: true } : false;

        function addEvents(el, obj) {
      for (var prop in obj) {
        var option = (prop === 'touchstart' || prop === 'touchmove') ? passiveOption : false;
        el.addEventListener(prop, obj[prop], option);
      }
    }

        function removeEvents(el, obj) {
      for (var prop in obj) {
        var option = (prop === 'touchstart' || prop === 'touchmove') ? passiveOption : false;
        el.removeEventListener(prop, obj[prop], option);
      }
    }

        function Events() {
      return {
        topics: {},
        on: function (eventName, fn) {
          this.topics[eventName] = this.topics[eventName] || [];
          this.topics[eventName].push(fn);
        },
        off: function(eventName, fn) {
          if (this.topics[eventName]) {
            for (var i = 0; i < this.topics[eventName].length; i++) {
              if (this.topics[eventName][i] === fn) {
                this.topics[eventName].splice(i, 1);
                break;
              }
            }
          }
        },
        emit: function (eventName, data) {
          if (this.topics[eventName]) {
            this.topics[eventName].forEach(function(fn) {
              fn(data);
            });
          }
        }
      };
    }

        function jsTransform(element, attr, prefix, postfix, to, duration, callback) {
      var tick = Math.min(duration, 10),
          from = Number(element.style[attr].slice(prefix.length, - (postfix.length + 2))),
          positionTick = (to - from) / duration * tick,
          running;

          setTimeout(moveElement, tick);
      function moveElement() {
        duration -= tick;
        from += positionTick;
        element.style[attr] = prefix + from + 'px' + postfix;
        if (duration > 0) { 
          setTimeout(moveElement, tick); 
        } else {
          callback();
        }
      }
    }


    var TRANSFORM = getSupportedProp([
          'transform', 
          'WebkitTransform', 
          'MozTransform', 
          'msTransform',
          'OTransform'
        ]);
    var transitions = {
          'transitionDuration': ['transitionDelay', 'transitionend'],
          'WebkitTransitionDuration': ['WebkitTransitionDelay', 'webkitTransitionEnd'],
          'MozTransitionDuration': ['MozTransitionDelay', 'transitionend'],
          'OTransitionDuration': ['OTransitionDelay', 'oTransitionEnd']
        };
    var animations = {
          'animationDuration': ['animationDelay', 'animationend'],
          'WebkitAnimationDuration': ['WebkitAnimationDelay', 'webkitAnimationEnd'],
          'MozAnimationDuration': ['MozAnimationDelay', 'animationend'],
          'OAnimationDuration': ['OAnimationDelay', 'oAnimationEnd']
        };
    var TRANSITIONDURATION = whichProperty(transitions)[0];
    var TRANSITIONDELAY = whichProperty(transitions)[1];
    var TRANSITIONEND = whichProperty(transitions)[2];
    var ANIMATIONDURATION = whichProperty(animations)[0];
    var ANIMATIONDELAY = whichProperty(animations)[1];
    var ANIMATIONEND = whichProperty(animations)[2];
    var KEY = {
          ENTER: 13,
          SPACE: 32,
          PAGEUP: 33,
          PAGEDOWN: 34,
          END: 35,
          HOME: 36,
          LEFT: 37,
          UP: 38,
          RIGHT: 39,
          DOWN: 40
        };

        var tns = function(options) {
      options = extend({
        container: document.querySelector('.slider'),
        mode: 'carousel',
        axis: 'horizontal',
        items: 1,
        gutter: 0,
        edgePadding: 0,
        fixedWidth: false,
        slideBy: 1,
        controls: true,
        controlsText: ['prev', 'next'],
        controlsContainer: false,
        nav: true,
        navContainer: false,
        arrowKeys: false,
        speed: 300,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayDirection: 'forward',
        autoplayText: ['start', 'stop'],
        autoplayHoverPause: false,
        autoplayButton: false,
        autoplayResetOnVisibility: true,
        animateIn: 'tns-fadeIn',
        animateOut: 'tns-fadeOut',
        animateNormal: 'tns-normal',
        animateDelay: false,
        loop: true,
        rewind: false,
        autoHeight: false,
        responsive: false,
        lazyload: false,
        touch: true,
        mouseDrag: false,
        nested: false,
        onInit: false
      }, options || {});

      var nodes = ['container', 'controlsContainer', 'navContainer', 'autoplayButton'];
      for (var i = 4; i--;) {
        var el = options[nodes[i]];
        if (typeof el === 'string') { options[nodes[i]] = document.querySelector(el); }
      }

      if (!options.container || !options.container.nodeName || options.container.children.length < 2) { return; }

      var mode = options.mode,
          axis = options.axis,
          wrapper = document.createElement('div'),
          contentWrapper = document.createElement('div'),
          container = options.container,
          slideItems = container.children,
          slideCount = slideItems.length,
          items = options.items,
          slideBy = getSlideBy(),
          nested = options.nested,
          gutter = options.gutter,
          edgePadding = (mode === 'gallery') ? false : options.edgePadding,
          fixedWidth = options.fixedWidth,
          arrowKeys = options.arrowKeys,
          speed = options.speed,
          rewind = options.rewind,
          loop = (mode === 'gallery')? true: (options.rewind)? false : options.loop,
          autoHeight = (mode === 'gallery') ? true : options.autoHeight,
          responsive = (fixedWidth) ? false : options.responsive,
          lazyload = options.lazyload,
          slideWidth = (fixedWidth)? fixedWidth + gutter : 0,
          slideEdges, 
          slideItemsOut = [],
          cloneCount = (loop) ? slideCount * 2 : (edgePadding) ? 1 : 0,
          slideCountNew = (mode === 'gallery') ? slideCount + cloneCount : slideCount + cloneCount * 2,
          hasRightDeadZone = (fixedWidth && !loop && !edgePadding)? true : false,
          checkIndexBeforeTransform = (mode === 'gallery' || !loop)? true : false,
          transformDir = (axis === 'horizontal')? 'X' : 'Y',
          transformAttrLegacy = (axis === 'horizontal')? 'left' : 'top', 
          transformAttr = transformAttrLegacy,
          transformType = 'translate',
          transformPrefix = '',
          transformPostfix = '',
          index = (mode === 'gallery') ? 0 : cloneCount,
          indexCached = index,
          indexAdjust = (edgePadding) ? 1 : 0,
          indexMin = indexAdjust,
          indexMax,
          vw,
          resizeTimer,
          touchedOrDraged,
          running = false,
          onInit = options.onInit,
          events = new Events(),
          sliderFrozen = false,

          containerIdCached = container.id,
          containerClassCached = container.className,
          slideId = container.id || getSlideId();


      if (options.controls) {
        var controls = options.controls,
            controlsText = options.controlsText,
            controlsContainer = options.controlsContainer,
            prevButton,
            nextButton;
      }

      if (options.nav) {
        var nav = options.nav,
            navContainer = options.navContainer,
            navItems,
            navCountVisible,
            navCountVisibleCached = slideCount,
            visibleNavIndexes = [],
            visibleNavIndexesCached = visibleNavIndexes,
            navClicked = -1,
            navCurrent = 0,
            navCurrentCached = 0;
      }

      if (options.autoplay) {
        var autoplay = options.autoplay,
            autoplayTimeout = options.autoplayTimeout,
            autoplayDirection = (options.autoplayDirection === 'forward') ? 1 : -1,
            autoplayText = options.autoplayText,
            autoplayHoverPause = options.autoplayHoverPause,
            autoplayTimer,
            autoplayButton = options.autoplayButton,
            animating = false,
            autoplayHoverStopped = false,
            autoplayHtmlString = '<span hidden>Stop Animation</span>',
            autoplayResetOnVisibility = options.autoplayResetOnVisibility,
            autoplayResetVisibilityState = false;
      }

      if (options.touch) {
        var touch = options.touch,
            startX = null,
            startY = null,
            translateInit,
            disX,
            disY;
      }

      if (options.mouseDrag) {
        var mouseDrag = options.mouseDrag,
            mousePressed = false,
            isDragEvent = false;
      }

      if (options.mode === 'gallery') {
        var animateIn = (ANIMATIONDURATION) ? options.animateIn : 'tns-fadeIn',
            animateOut = (ANIMATIONDURATION) ? options.animateOut : 'tns-fadeOut',
            animateNormal = (ANIMATIONDURATION) ? options.animateNormal : 'tns-normal',
            animateDelay = (ANIMATIONDURATION) ? options.animateDelay : false;
      }

          if (TRANSFORM) {
        transformAttr = TRANSFORM;
        transformPrefix = transformType + transformDir + '(';
        transformPostfix = ')';
      }

      function getSlideBy () {
        return (mode === 'gallery' || options.slideBy === 'page') ? items : options.slideBy;
      }

          var getItems = (function () {
        if (!fixedWidth) {
          return function () {
            var itemsTem = options.items,
                bpKeys = (typeof responsive === 'object') ? Object.keys(responsive) : false;

                if (bpKeys) {
              bpKeys.forEach(function (key) {
                if (vw >= key) { itemsTem = responsive[key]; }
              });
            }
            return Math.max(1, Math.min(slideCount, itemsTem));
          };

            } else {
          return function () { return Math.max(1, Math.min(slideCount, Math.floor(vw / fixedWidth))); };
        }
      })();

          function getSlideWidth() {
        return (vw + gutter) / items;
      }

          var getVisibleNavCount = (function () {
        if (options.navContainer) {
          return function () { return slideCount; };
        } else {
          return function () { return Math.ceil(slideCount / items); };
        }
      })();

          var getViewWidth = (function () {
        if (axis === 'horizontal' && !fixedWidth && edgePadding) { 
          return function () { return wrapper.clientWidth - (edgePadding + gutter) * 2; };
        } else {
          return function () { return wrapper.clientWidth; };
        }
      })();

      function wrapperInit() {
        setAttrs(wrapper, {'data-tns-role': 'wrapper'});
        setAttrs(contentWrapper, {'data-tns-role': 'content-wrapper'});
        if (axis === 'vertical') { 
          setAttrs(contentWrapper, {'data-tns-hidden': 'y'}); 
        } else {
          setAttrs(wrapper, {'data-tns-hidden': 'x'}); 
        }

            if (mode === 'carousel') {
          var gap = (fixedWidth && edgePadding) ? getFixedWidthEdgePadding() : (edgePadding) ? edgePadding + gutter : 0;
          contentWrapper.style.cssText = (axis === 'horizontal') ? 'margin: 0 ' + gap + 'px;' : 'padding: ' + gap + 'px 0 ' + edgePadding + 'px; height: ' + getVerticalWrapperHeight() + 'px;'; 
        }
      }

      function getVariables() {
        vw = getViewWidth();
        items = getItems();
        indexMax = slideCountNew - items - indexAdjust;

            if (axis === 'horizontal' && !fixedWidth) { slideWidth = getSlideWidth(); }
        navCountVisible = getVisibleNavCount();
        slideBy = getSlideBy();
      }

          function containerInit() {
        if (container.id === '') { container.id = slideId; }
        setAttrs(container, {
          'data-tns-role': 'content', 
          'data-tns-mode': mode, 
          'data-tns-axis': axis
        });

            if (axis === 'horizontal') {
          container.style.width = (slideWidth + 1) * slideCountNew + 'px';
        }
      }

          function containerInitStyle() {
        if (mode === 'carousel') {
          if (autoHeight) { setAttrs(container, {'data-tns-hidden': 'y'}); }
          container.style[transformAttr] = transformPrefix + Math.round(-slideEdges[index]) + 'px' + transformPostfix;
        }
      }

      function msInit() {
        if (navigator.msMaxTouchPoints) {
          wrapper.classList.add('ms-touch');
          addEvents(wrapper, {'scroll': ie10Scroll});
        }
      }

          function slideItemsInit() {
        for (var x = 0; x < slideCount; x++) {
          var item = slideItems[x];

          item.id = slideId + '-item' + x;

          if (mode === 'gallery' && animateNormal) { item.classList.add(animateNormal); }

          setAttrs(item, {
            'aria-hidden': 'true',
            'tabindex': '-1'
          });

          var gutterPosition = (axis === 'horizontal') ? 'right' : 'bottom', 
              styles = '';
          if (mode === 'carousel') { styles = 'margin-' + gutterPosition + ': ' + gutter + 'px;'; }
          if (axis === 'horizontal') { styles = 'width: ' + (slideWidth - gutter) + 'px; ' + styles; }
          item.style.cssText += styles;
        }

        if (loop || edgePadding) {
          var fragmentBefore = document.createDocumentFragment(), 
              fragmentAfter = document.createDocumentFragment();

              for (var j = cloneCount; j--;) {
            var num = j%slideCount,
                cloneFirst = slideItems[num].cloneNode(true);
            removeAttrs(cloneFirst, 'id');
            fragmentAfter.insertBefore(cloneFirst, fragmentAfter.firstChild);

                if (mode === 'carousel') {
              var cloneLast = slideItems[slideCount - 1 - num].cloneNode(true);
              removeAttrs(cloneLast, 'id');
              fragmentBefore.appendChild(cloneLast);
            }
          }

              container.insertBefore(fragmentBefore, container.firstChild);
          container.appendChild(fragmentAfter);
          slideItems = container.children;
        }
      }

          function controlsInit() {
        if (controls) {
          if (controlsContainer) {
            prevButton = controlsContainer.children[0];
            nextButton = controlsContainer.children[1];
            setAttrs(controlsContainer, {
              'aria-label': 'Carousel Navigation',
              'tabindex': '0'
            });
            setAttrs(prevButton, {'data-controls' : 'prev'});
            setAttrs(nextButton, {'data-controls' : 'next'});
            setAttrs(controlsContainer.children, {
              'aria-controls': slideId,
              'tabindex': '-1',
            });
          } else {
            append(wrapper, '<div data-tns-role="controls" aria-label="Carousel Navigation" tabindex="0"><button data-controls="prev" tabindex="-1" aria-controls="' + slideId +'" type="button">' + controlsText[0] + '</button><button data-controls="next" tabindex="-1" aria-controls="' + slideId +'" type="button">' + controlsText[1] + '</button></div>');

                [].forEach.call(wrapper.children, function (el) {
              if (el.getAttribute('data-tns-role') === 'controls') { controlsContainer = el; }
            });
            prevButton = controlsContainer.children[0];
            nextButton = controlsContainer.children[1];
          }
        }
      }

          function navInit() {
        if (nav) {
          if (options.navContainer) {
            setAttrs(navContainer, {'aria-label': 'Carousel Pagination'});
            navItems = navContainer.children;
            [].forEach.call(navItems, function (item, index) {
              setAttrs(item, {
                'data-nav': index,
                'tabindex': '-1',
                'aria-selected': 'false',
                'aria-controls': slideId + '-item' + index,
              });
            });

          } else {
            var navHtml = '';
            for (var i = 0; i < slideCount; i++) {
              navHtml += '<button data-nav="' + i +'" tabindex="-1" aria-selected="false" aria-controls="' + slideId + '-item' + i +'" hidden type="button"></button>';
            }
            navHtml = '<div data-tns-role="nav" aria-label="Carousel Pagination">' + navHtml + '</div>';
            append(wrapper, navHtml);

                [].forEach.call(wrapper.children, function (el) {
              if (el.getAttribute('data-tns-role') === 'nav') { navContainer = el; }
            });
            navItems = navContainer.children;

                updateNavVisibility();
          }
        }
      }

          function autoplayInit() {
        if (autoplay) {
          if (autoplayButton) {
            setAttrs(autoplayButton, {'data-action': 'stop'});
          } else {
            if (!navContainer) {
              append(wrapper, '<div data-tns-role="nav" aria-label="Carousel Pagination"></div>');
              navContainer = wrapper.querySelector('[data-tns-role="nav"]');
            }

                append(navContainer, '<button data-action="stop" type="button">' + autoplayHtmlString + autoplayText[0] + '</button>');
            autoplayButton = navContainer.querySelector('[data-action]');
          }

          startAction();
        }
      }

          function activateSlider() {
        for (var i = index; i < index + items; i++) {
          var item = slideItems[i];
          setAttrs(item, {'aria-hidden': 'false'});
          removeAttrs(item, ['tabindex']);
          if (mode === 'gallery') { 
            item.style.left = slideWidth * (i - index) + 'px'; 
            item.classList.remove(animateNormal);
            item.classList.add(animateIn);
          }
        }
        if (controls) {
          if (index === indexMin && !loop || rewind) {
            prevButton.disabled = true;
          }
        }
        if (nav) {
          setAttrs(navItems[0], {'tabindex': '0', 'aria-selected': 'true'});
        }
      }

          function addSliderEvents() {
        if (mode === 'carousel' && TRANSITIONEND) {
          var eve = {};
          eve[TRANSITIONEND] = onTransitionEnd;
          addEvents(container, eve);
        }

            if (nav) {
          for (var y = 0; y < slideCount; y++) {
            addEvents(navItems[y],{
              'click': onClickNav,
              'keydown': onKeydownNav
            });
          }
        }

            if (controls) {
          addEvents(controlsContainer, {'keydown': onKeydownControl});
          addEvents(prevButton,{'click': onClickPrev});
          addEvents(nextButton,{'click': onClickNext});
        }




            if (nested === 'inner') {
          events.on('outerResized', function () {
            resizeTasks();
            events.emit('innerLoaded', info());
          });
        } else {
          addEvents(window, {'resize': onResize});
          if (nested === 'outer') {
            events.on('innerLoaded', runAutoHeight);
          }
        }
      }

          function checkSlideCount(isInitializing) {
        if (!sliderFrozen && slideCount <= items) { 
          toggleSliderEvents(isInitializing, true);
          if (animating) { stopAction(); }

          index = (mode !== 'carousel') ? 0 : cloneCount;

              if (nav) { hideElement(navContainer); }
          if (controls) { hideElement(controlsContainer); }
          if (autoplay) { hideElement(autoplayButton); }

              sliderFrozen = true;

        } else {
          toggleSliderEvents(isInitializing, false);
          if (autoplay && !animating) { startAction(); }

              if (nav) { showElement(navContainer); }
          if (controls) { showElement(controlsContainer); }
          if (autoplay) { showElement(autoplayButton); }

              sliderFrozen = false;
        }
      }

          function toggleSliderEvents(isInitializing, freeze) {
        var remove = !isInitializing && freeze,
            add = !freeze;

        if (mode === 'carousel') {
          var touchEvents = {
                'touchstart': onTouchOrMouseStart,
                'touchmove': onTouchOrMouseMove,
                'touchend': onTouchOrMouseEnd,
                'touchcancel': onTouchOrMouseEnd
              }, dragEvents = {
                'mousedown': onTouchOrMouseStart,
                'mousemove': onTouchOrMouseMove,
                'mouseup': onTouchOrMouseEnd,
                'mouseleave': onTouchOrMouseEnd
              };

              if (remove) {
            if (touch) { removeEvents(container, touchEvents); }
            if (mouseDrag) { removeEvents(container, dragEvents); }
          }

              if (add) {
            if (touch) { addEvents(container, touchEvents); }
            if (mouseDrag) { addEvents(container, dragEvents); }
          }
        }

        var autoplayEvent = {'click': toggleAnimation},
            hoverEvents = {
              'mouseover': mouseoverPause,
              'mouseout': mouseoutRestart
            },
            visibilityEvent = {'visibilitychange': onVisibilityChange},
            docmentKeydownEvent = {'keydown': onKeydownDocument};

            if (remove) {
          if (autoplay) {
            removeEvents(autoplayButton, autoplayEvent);
            if (autoplayHoverPause) { removeEvents(container, hoverEvents); }
            if (autoplayResetOnVisibility) { removeEvents(document, visibilityEvent); }
          }

              if (arrowKeys) { removeEvents(document, docmentKeydownEvent); }
        } 

            if (add) {
          if (autoplay) {
            addEvents(autoplayButton, autoplayEvent);
            if (autoplayHoverPause) { addEvents(container, hoverEvents); }
            if (autoplayResetOnVisibility) { addEvents(document, visibilityEvent); }
          }

              if (arrowKeys) { addEvents(document, docmentKeydownEvent); }
        }
      }

          function mouseoverPause() {
        if (animating) { 
          stopAction(); 
          autoplayHoverStopped = true;
        }
      }

          function mouseoutRestart() {
        if (!animating && autoplayHoverStopped) { 
          startAction(); 
          autoplayHoverStopped = false;
        }
      }

      function lazyLoad() {
        if (lazyload) {
          var i = index, 
              len = index + items;

                        if (edgePadding) {
            i -=1;
            len +=1;
          }

              for(; i < len; i++) {
            [].forEach.call(slideItems[i].querySelectorAll('[data-tns-role="lazy-img"]'), function (img) {
              var eve = {};
              eve[TRANSITIONEND] = function (e) { e.stopPropagation(); };
              addEvents(img, eve);

                  if (!img.classList.contains('loaded')) {
                img.src = getAttr(img, 'data-src');
                img.classList.add('loaded');
              }
            });
          }
        }
      }

      function runAutoHeight() {
        if (autoHeight) {
          var images = [];

              for (var i = index; i < index + items; i++) {
            [].forEach.call(slideItems[i].querySelectorAll('img'), function (img) {
              images.push(img);
            });
          }

              if (images.length === 0) {
            updateContainerHeight(); 
          } else {
            checkImagesLoaded(images);
          }
        }
      }

          function checkImagesLoaded(images) {
        images.forEach(function (img, index) {
          if (imageLoaded(img)) { images.splice(index, 1); }
        });

            if (images.length === 0) {
          updateContainerHeight();
        } else {
          setTimeout(function () { 
            checkImagesLoaded(images); 
          }, 16);
        }
      } 

          function sliderInit() {
        wrap(container, contentWrapper);
        wrap(contentWrapper, wrapper);

            getVariables();
        containerInit();
        slideItemsInit();
        getSlideEdges();

            wrapperInit();
        containerInitStyle();
        msInit();
        controlsInit();
        navInit();
        autoplayInit();

            activateSlider();
        addSliderEvents();
        checkSlideCount(true);

            lazyLoad();
        runAutoHeight();

            if (typeof onInit === 'function') {
          onInit(info());
        }

            if (nested === 'inner') { 
          events.emit('innerLoaded', info()); 
        }
      }
      sliderInit();

      function getFixedWidthEdgePadding() {
        return (vw%slideWidth + gutter) / 2;
      }

      function updateContainerHeight() {
        var heights = [], maxHeight;
        for (var i = index; i < index + items; i++) {
          heights.push(slideItems[i].offsetHeight);
        }
        maxHeight = Math.max.apply(null, heights);

            if (container.style.height !== maxHeight) {
          if (TRANSITIONDURATION) { setDurations(speed); }
          container.style.height = maxHeight + 'px';
        }
      }

      function getSlideEdges() {
        slideEdges = [0];
        var topFirst = slideItems[0].getBoundingClientRect()[transformAttrLegacy], attr;
        for (var i = 1; i < slideCountNew; i++) {
          attr = slideItems[i].getBoundingClientRect()[transformAttrLegacy];
          slideEdges.push(attr - topFirst);
        }
      }

      function getVerticalWrapperHeight() {
        return slideEdges[index + items] - slideEdges[index];
      }

      function setSnapInterval() {
        wrapper.style.msScrollSnapPointsX = 'snapInterval(0%, ' + slideWidth + ')';
      }

      function updateSlideStatus() {
        var h1, h2, v1, v2;
        if (index !== indexCached) {
          if (index > indexCached) {
            h1 = indexCached;
            h2 = Math.min(indexCached + items, index);
            v1 = Math.max(indexCached + items, index);
            v2 = index + items;
          } else {
            h1 = Math.max(index + items, indexCached);
            h2 = indexCached + items;
            v1 = index;
            v2 = Math.min(index + items, indexCached);
          }
        }

            if (slideBy%1 !== 0) {
          h1 = Math.round(h1);
          h2 = Math.round(h2);
          v1 = Math.round(v1);
          v2 = Math.round(v2);
        }

            for (var i = h1; i < h2; i++) {
          setAttrs(slideItems[i], {
            'aria-hidden': 'true',
            'tabindex': '-1'
          });
        }
        for (var j = v1; j < v2; j++) {
          setAttrs(slideItems[j], {'aria-hidden': 'false'});
          removeAttrs(slideItems[j], ['tabindex']);
        }
      }

      function updateNavStatus() {
        if (nav) {
          navCurrent = (navClicked !== -1) ? navClicked : index%slideCount;
          navClicked = -1;

              if (navCurrent !== navCurrentCached) {
            setAttrs(navItems[navCurrentCached], {
              'tabindex': '-1',
              'aria-selected': 'false'
            });

                setAttrs(navItems[navCurrent], {
              'tabindex': '0',
              'aria-selected': 'true'
            });
            navCurrentCached = navCurrent;
          }
        }
      }

      function updateControlsStatus() {
        if (controls && !loop) {
          var disable = [], active = [];
          if (index === indexMin) {
            disable.push(prevButton);
            active.push(nextButton);
            changeFocus(prevButton, nextButton);
          } else if (!rewind && index === indexMax) {
            disable.push(nextButton);
            active.push(prevButton);
            changeFocus(nextButton, prevButton);
          } else {
            active.push(prevButton, nextButton);
          }

              if (disable.length > 0) {
            disable.forEach(function (button) {
              if (!button.disabled) {
                button.disabled = true;
              }
            });
          }

              if (active.length > 0) {
            active.forEach(function (button) {
              if (button.disabled) {
                button.disabled = false;
              }
            });
          }
        }
      }

      function setDurations (duration, target) {
        duration = (!duration)? '' : duration / 1000 + 's';
        target = target || container;
        target.style[TRANSITIONDURATION] = duration;

            if (mode === 'gallery') {
          target.style[ANIMATIONDURATION] = duration;
        }
        if (axis === 'vertical') {
          contentWrapper.style[TRANSITIONDURATION] = duration;
        }
      }

      var transformCore = (function () {
        if (mode === 'carousel') {
          return function (duration, distance) {
            if (!distance) { distance = -slideEdges[index]; }
            if (hasRightDeadZone && index === indexMax) {
              distance = Math.max(distance, -slideCountNew * slideWidth + vw + gutter);
            }

                if (TRANSITIONDURATION || !duration) {
              container.style[transformAttr] = transformPrefix + Math.round(distance) + 'px' + transformPostfix;
            } else {
              jsTransform(container, transformAttr, transformPrefix, transformPostfix, distance, speed, onTransitionEnd);
            }

                if (axis === 'vertical') { contentWrapper.style.height = getVerticalWrapperHeight() + 'px'; }
          };
        } else {
          return function () {
            slideItemsOut = [];

                var eve = {};
            eve[TRANSITIONEND] = eve[ANIMATIONEND] = onTransitionEnd;
            removeEvents(slideItems[indexCached], eve);
            addEvents(slideItems[index], eve);

                (function () {
              for (var i = indexCached, l = indexCached + items; i < l; i++) {
                var item = slideItems[i];
                if (TRANSITIONDURATION) { setDurations(speed, item); }
                if (animateDelay && TRANSITIONDELAY) {
                  var d = animateDelay * (i - indexCached) / 1000; 
                  item.style[TRANSITIONDELAY] = d + 's'; 
                  item.style[ANIMATIONDELAY] = d + 's'; 
                }
                item.classList.remove(animateIn);
                item.classList.add(animateOut);
                slideItemsOut.push(item);
              }
            })();

                (function () {
              for (var i = index, l = index + items; i < l; i++) {
                var item = slideItems[i];
                if (TRANSITIONDURATION) { setDurations(speed, item); }
                if (animateDelay && TRANSITIONDELAY) {
                  var d = animateDelay * (i - index) / 1000; 
                  item.style[TRANSITIONDELAY] = d + 's'; 
                  item.style[ANIMATIONDELAY] = d + 's'; 
                }
                item.classList.remove(animateNormal);
                item.classList.add(animateIn);
                if (i > index) { item.style.left = (i - index) * slideWidth + 'px'; }
              }
            })();

                if (!TRANSITIONEND) {
              setTimeout(onTransitionEnd, speed);
            }
          };
        }
      })();

          function doTransform (duration, distance) {
        if (duration === undefined) { duration = speed; }
        if (TRANSITIONDURATION) { setDurations(duration); }
        transformCore(duration, distance);
      }

      var checkIndex = (function () {
        if (loop) {
          return function () {
            var leftEdge = (mode === 'carousel')? slideBy + indexMin : indexMin, 
                rightEdge = (mode === 'carousel')? indexMax - slideBy : indexMax;

                if (fixedWidth && vw%slideWidth !== 0) { rightEdge -= 1; }

                if (index > rightEdge) {
              while(index >= leftEdge + slideCount) { index -= slideCount; }
            } else if(index < leftEdge) {
              while(index <= rightEdge - slideCount) { index += slideCount; }
            }
          };
        } else {
          return function () {
            index = Math.max(indexMin, Math.min(indexMax, index));
          };
        }
      })();

          function render() {
        running = true;
        if (checkIndexBeforeTransform) { checkIndex(); }

        if (index%slideCount !== indexCached%slideCount) { events.emit('indexChanged', info()); }
        events.emit('transitionStart', info());

            doTransform();
      }

      function onTransitionEnd(event) {
        events.emit('transitionEnd', info(event));

            if (mode === 'gallery' && slideItemsOut.length > 0) {
          for (var i = 0; i < items; i++) {
            var item = slideItemsOut[i];
            if (TRANSITIONDURATION) { setDurations(0, item); }
            if (animateDelay && TRANSITIONDELAY) { 
              item.style[TRANSITIONDELAY] = item.style[ANIMATIONDELAY] = '';
            }
            item.classList.remove(animateOut);
            item.classList.add(animateNormal);
            item.style.left = '';
          }
        }

        function strTrans(str) {
          return str.toLowerCase().replace(/-/g, '');
        }

        if (!event || 
            mode === 'gallery' && event.target.parentNode === container || 
            event.target === container && strTrans(event.propertyName) === strTrans(transformAttr)) {

              if (!checkIndexBeforeTransform) { 
            var indexTem = index;
            checkIndex();
            if (index !== indexTem) { 
              doTransform(0); 
              events.emit('indexChanged', info());
            }
          } 

              updateSlideStatus();

          if (nav && !loop || 
              nav && loop && visibleNavIndexes.indexOf(index%slideCount) === -1) { 
            updateNavVisibility(); 
          }
          updateNavStatus();
          updateControlsStatus();
          lazyLoad();
          runAutoHeight();

              if (nested === 'inner') { 
            events.emit('innerLoaded', info()); 
          } 
          running = false;
          updateIndexCache();
        }

          }

          function updateIndexCache() {
        indexCached = index;
      }

      function onClickControl(dir) {
        if (!running) {
          index = index + dir * slideBy;

              render();
        }
      }

          function onClickPrev() {
        onClickControl(-1);
      }

          function onClickNext() {
        if(rewind && index === indexMax){
          onClickControl(-(indexMax - indexMin) / slideBy);
        }else{
          onClickControl(1);
        }
      }

      function onClickNav(e) {
        if (!running) {
          var clickTarget = e.target || e.srcElement,
              navIndex,
              indexAdjust,
              targetIndex;

          while (indexOf(navItems, clickTarget) === -1) {
            clickTarget = clickTarget.parentNode;
          }

              navIndex = navClicked = indexOf(navItems, clickTarget);
          indexAdjust = (mode === 'gallery')? 0 : cloneCount;
          targetIndex = navIndex + indexAdjust;

              goTo(targetIndex);
        }
      }

          function startAction() {
        resetActionTimer();
        setAttrs(autoplayButton, {'data-action': 'stop'});
        autoplayButton.innerHTML = autoplayHtmlString + autoplayText[1];

            animating = true;
      }

          function stopAction() {
        pauseActionTimer();
        setAttrs(autoplayButton, {'data-action': 'start'});
        autoplayButton.innerHTML = autoplayHtmlString.replace('Stop', 'Start') + autoplayText[0];

            animating = false;
      }

          function pauseActionTimer() {
        animating = 'paused';
        clearInterval(autoplayTimer);
      }

          function resetActionTimer() {
        if (animating === true) { return; }
        clearInterval(autoplayTimer);
        autoplayTimer = setInterval(function () {
          onClickControl(autoplayDirection);
        }, autoplayTimeout);
      }

          function toggleAnimation() {
        if (animating) {
          stopAction();
        } else {
          startAction();
        }
      }

          function onVisibilityChange() {
        if (autoplayResetVisibilityState != document.hidden && animating !== false) {
          document.hidden ? pauseActionTimer() : resetActionTimer();
        }
        autoplayResetVisibilityState = document.hidden;
      }

      function onKeydownDocument(e) {
        e = e || window.event;
        switch(e.keyCode) {
          case KEY.LEFT:
            onClickPrev();
            break;
          case KEY.RIGHT:
            onClickNext();
        }
      }

      function changeFocus(blur, focus) {
        if (typeof blur === 'object' && 
            typeof focus === 'object' && 
            blur === document.activeElement) {
          blur.blur();
          focus.focus();
        }
      }

      function onKeydownControl(e) {
        e = e || window.event;
        var code = e.keyCode,
            curElement = document.activeElement;

            switch (code) {
          case KEY.LEFT:
          case KEY.UP:
          case KEY.PAGEUP:
              if (!prevButton.disabled) {
                onClickPrev();
              }
              break;
          case KEY.RIGHT:
          case KEY.DOWN:
          case KEY.PAGEDOWN:
              if (!nextButton.disabled) {
                onClickNext();
              }
              break;
          case KEY.HOME:
            goTo(0);
            break;
          case KEY.END:
            goTo(slideCount - 1);
            break;
        }
      }

      function onKeydownNav(e) {
        e = e || window.event;
        var code = e.keyCode,
            curElement = document.activeElement,
            dataSlide = getAttr(curElement, 'data-nav');

            switch(code) {
          case KEY.LEFT:
          case KEY.PAGEUP:
            if (dataSlide > 0) { changeFocus(curElement, curElement.previousElementSibling); }
            break;
          case KEY.UP:
          case KEY.HOME:
            if (dataSlide !== 0) { changeFocus(curElement, navItems[0]); }
            break;
          case KEY.RIGHT:
          case KEY.PAGEDOWN:
            if (dataSlide < navCountVisible - 1) { changeFocus(curElement, curElement.nextElementSibling); }
            break;
          case KEY.DOWN:
          case KEY.END:
            if (dataSlide < navCountVisible - 1) { changeFocus(curElement, navItems[navCountVisible - 1]); }
            break;
          case KEY.ENTER:
          case KEY.SPACE:
            onClickNav(e);
            break;
        }
      }

      function ie10Scroll() {
        doTransform(0, container.scrollLeft());
        updateIndexCache();
      }

          function getTarget(e) {
        return e.target || e.srcElement;
      }

          function isLinkElement(el) {
        return el.tagName.toLowerCase() === 'a';
      }

          function preventDefaultBehavior(e) {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
      }

          function onTouchOrMouseStart(e) {
        e = e || window.event;
        if (isLinkElement(getTarget(e)) && e.type !== 'touchstart') { preventDefaultBehavior(e); }

            var ev = (e.type === 'touchstart') ? e.changedTouches[0] : e;
        startX = parseInt(ev.clientX);
        startY = parseInt(ev.clientY);
        var slices = (TRANSFORM)? [11, -3] : [0, -2];
        translateInit = Number(container.style[transformAttr].slice(slices[0], slices[1]));

            if (e.type === 'touchstart') {
          events.emit('touchStart', info(e));
        } else {
          events.emit('dragStart', info(e));
          mousePressed = true;
        }
      }

          function onTouchOrMouseMove(e) {
        e = e || window.event;

        if (mousePressed && e.type === 'mousemove' && !isDragEvent) {
          isDragEvent = true;
        }

        if (startX !== null) {
          if (isLinkElement(getTarget(e)) && e.type !== 'touchmove') { preventDefaultBehavior(e); }

              var ev = (e.type === 'touchmove') ? e.changedTouches[0] : e;
          disX = parseInt(ev.clientX) - startX;
          disY = parseInt(ev.clientY) - startY;

              if (getTouchDirection(toDegree(disY, disX), 15) === axis) { 
            touchedOrDraged = true;

                if (e.type === 'touchmove') {
              events.emit('touchMove', info(e));
            } else {
              events.emit('dragMove', info(e));
            }

                var x = (axis === 'horizontal')? (translateInit + disX) : (translateInit + disY);
                x += 'px';

                if (TRANSFORM) {
              x = 'translate' + transformDir + '(' + x + ')';
              setDurations(0);
            }
            container.style[transformAttr] = x;
          }
        }
      }

          function onTouchOrMouseEnd(e) {
        e = e || window.event;

        if (mousePressed) { mousePressed = false; }

            if (touchedOrDraged) {
          touchedOrDraged = false;

              var ev = (e.type.indexOf('touch') === 0) ? e.changedTouches[0] : e;
          disX = parseInt(ev.clientX) - startX;
          disY = parseInt(ev.clientY) - startY;

          startX = startY = null;

              if (axis === 'horizontal') {
            index = - (translateInit + disX) / slideWidth;
            index = (disX > 0) ? Math.floor(index) : Math.ceil(index);
          } else {
            var moved = - (translateInit + disY);
            if (moved <= 0) {
              index = indexMin;
            } else if (moved >= slideEdges[slideEdges.length - 1]) {
              index = indexMax;
            } else {
              var i = 0;
              do {
                i++;
                index = (disY < 0) ? i + 1 : i;
              } while (i < slideCountNew && moved >= Math.round(slideEdges[i + 1]));
            }
          }

                    if (e.type.indexOf('touch') === 0) {
            events.emit('touchEnd', info(e));
          } else {
            events.emit('dragEnd', info(e));
          }

              render();
        }

        if (isDragEvent) { 
          isDragEvent = false;

          var target = getTarget(e);
          if (isLinkElement(target)) {
            addEvents(target, {'click': function preventClick(e) {
              preventDefaultBehavior(e);
              removeEvents(target, {'click': preventClick});
            }}); 
          }
        } 
      }

      function updateSlideWidth() {
        container.style.width = (slideWidth + 1) * slideCountNew + 'px'; 
        for (var i = slideCountNew; i--;) {
          slideItems[i].style.width = (slideWidth - gutter) + 'px';
        }
      }

      function updateSlidePosition() {
        for (var i = index + 1, len = index + items; i < len; i++) {
          slideItems[i].style.left = slideWidth * (i - index) + 'px';
        }
      }

      function updateFixedWidthEdgePadding() {
        contentWrapper.style.cssText = 'margin: 0px ' + getFixedWidthEdgePadding() + 'px';
      }

      function updateContentWrapperHeight() {
        contentWrapper.style.height = getVerticalWrapperHeight() + 'px';
      }

      function getVisibleNavIndex() {
        visibleNavIndexes = [];

            var absIndexMin = index%slideCount%items;
        while (absIndexMin < slideCount) {
          if (!loop && absIndexMin + items > slideCount) { absIndexMin = slideCount - items; }
          visibleNavIndexes.push(absIndexMin);
          absIndexMin += items;
        }

        if (loop && visibleNavIndexes.length * items < slideCount ||
            !loop && visibleNavIndexes[0] > 0) {
          visibleNavIndexes.unshift(0);
        }
      }

      function updateNavVisibility() {
        if (nav && !options.navContainer) {
          getVisibleNavIndex();

              if (visibleNavIndexes !== visibleNavIndexesCached) {
            if (visibleNavIndexesCached.length > 0) {
              visibleNavIndexesCached.forEach(function (ind) {
                setAttrs(navItems[ind], {'hidden': ''});
              });
            }

            if (visibleNavIndexes.length > 0) {
              visibleNavIndexes.forEach(function (ind) {
                removeAttrs(navItems[ind], 'hidden');
              });
            }

            visibleNavIndexesCached = visibleNavIndexes;
          }
        }
      }

          function info(e) {
        return {
          container: container,
          slideItems: slideItems,
          navItems: navItems,
          prevButton: prevButton,
          nextButton: nextButton,
          items: items,
          index: index,
          indexCached: indexCached,
          navCurrent: navCurrent,
          navCurrentCached: navCurrentCached,
          slideCount: slideCount,
          cloneCount: cloneCount,
          slideCountNew: slideCountNew,
          event: e || {},
        };
      }

          function goTo (targetIndex) {
        var absIndex = index%slideCount, 
            indexGap;

            if (absIndex < 0) { absIndex += slideCount; }

            switch(targetIndex) {
          case 'next':
            indexGap = 1;
            break;
          case 'prev':
          case 'previous':
            indexGap = -1;
            break;
          case 'first':
            indexGap = - absIndex;
            break;
          case 'last':
            indexGap = (slideCount - 1) - absIndex;
            break;
          default:
            if (typeof targetIndex === 'number') {
              var absTargetIndex = targetIndex%slideCount;
              if (absTargetIndex < 0) { absTargetIndex += slideCount; }
              indexGap = absTargetIndex - absIndex;
            }
        }

            index += indexGap;

        if (index%slideCount !== indexCached%slideCount) {
          checkIndex();
          render();
        }

          }

          function resizeTasks() {
        var indexTem = index,
            itemsTem = items;
        getVariables();
        checkIndex();
        checkSlideCount();

            if (axis === 'horizontal') {
          if (fixedWidth && edgePadding) {
            updateFixedWidthEdgePadding();
          } else {
            updateSlideWidth();

                if (mode === 'gallery') {
              updateSlidePosition(); 
            }
          }
          getSlideEdges();
        } else {
          getSlideEdges();
          updateContentWrapperHeight();
        }

            if (index !== indexTem || mode === 'carousel' && !fixedWidth) {
          doTransform(0); 
        }

                if (index !== indexTem || items !== itemsTem) {
          lazyLoad(); 
          updateNavVisibility();
          updateNavStatus();
        }

            runAutoHeight(); 

            if (index !== indexTem) { 
          events.emit('indexChanged', info());
          updateSlideStatus();
          updateControlsStatus();
        }


                if (navigator.msMaxTouchPoints) { setSnapInterval(); }
      }

          function onResize(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          if (vw !== getViewWidth()) {
            resizeTasks();
            if (nested === 'outer') { 
              events.emit('outerResized', info(e)); 
            }
          }
        }, 100); 
      }

          return {
        getInfo: info,
        events: events,
        goTo: goTo,

            destroy: function () {
          unwrap(wrapper);
          unwrap(contentWrapper);
          wrapper = contentWrapper = null;

          container.id = containerIdCached || '';
          container.className = containerClassCached || '';
          removeAttrs(container, ['style', 'data-tns-role', 'data-tns-features']);

          if (loop) {
            for (var j = cloneCount; j--;) {
              slideItems[0].remove();
              slideItems[slideItems.length - 1].remove();
            }
          }

          removeAttrs(slideItems, ['id', 'style', 'aria-hidden', 'tabindex']);
          slideId = slideCount = null;

          if (controls) {
            if (options.controlsContainer) {
              removeAttrs(controlsContainer, ['aria-label', 'tabindex']);
              removeAttrs(controlsContainer.children, ['aria-controls', 'tabindex']);
              removeEventsByClone(controlsContainer);
            } else {
              controlsContainer.remove();
              controlsContainer = prevButton = nextButton = null;
            }
          }

          if (nav) {
            if (options.navContainer) {
              removeAttrs(navContainer, ['aria-label']);
              removeAttrs(navItems, ['aria-selected', 'aria-controls', 'tabindex']);
              removeEventsByClone(navContainer);
            } else {
              navContainer.remove();
              navContainer = null;
            }
            navItems = null;
          }

          if (autoplay) {
            if (!options.navContainer && navContainer !== null) {
              navContainer.remove();
              navContainer = null;
            } else {
              removeEventsByClone(autoplayButton);
            }
            removeEvents(document, {'visibilitychange': onVisibilityChange});
          }

          removeEventsByClone(container);

          if (arrowKeys) {
            removeEvents(document, {'keydown': onKeydownDocument});
          }

          removeEvents(window, {'resize': onResize});
        },

      };
    };

        return tns;
    })();

