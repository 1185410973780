productDisplayJS={

	langId:  WCParamJS.langId,

	storeId: WCParamJS.storeId,

	catalogId:WCParamJS.catalogId,

	userType:"",

	baseItemAddedToCart:false,

	entitledItems:[],

    entitledItemJsonObject: null,

	selectedAttributesList: new Object(),

	selectedAttributeValues: new Object(),

	registeredAttributeIds: new Object(),

	moreInfoUrl :"",

	isPopup : false,

	displayPriceRange : true,

	itemPriceJsonOject : [],
	allItemPricesOject : [],

	allSwatchesArrayList : new Object(),

	allDropdownsList : new Object(),

	skuImageId:"",

	cookieKeyPrefix: "CompareItems_",

	cookieDelimiter: ";",

	maxNumberProductsAllowedToCompare: 4,

	minNumberProductsAllowedToCompare: 2,

	baseCatalogEntryId: 0,

	selectedCatalogEntryId: 0,

	selectedProducts: new Object(),

	productList: new Object(),

	currencySymbol: "",

	compareReturnName: "",
	searchTerm: "",

		search01: "'",


	isRepeatDeliveryProduct: false,

	search02: '"',

		replaceStr01: /\\\'/g,

		replaceStr02: /\\\"/g,

		ampersandChar: /&/g,

		ampersandEntityName: "&amp;" ,

		singleSKUProductWithoutDefiningAttribute: false,

	replaceStr001: "&#039;",
	replaceStr002: "&#034;",

		canSwapProductPriceDiv: true,

		holidayPreCutOff: false,

		holidayMsgCallCompleted: false,

		hasOrdLevHollidayMsg: false,

		enableAllButton: false,

		idAdobeCallDone: false,

		adobeRDVarValue: "",

		isRxFoodCollectPDPEnabled : true,

	setCommonParameters:function(langId,storeId,catalogId,userType,currencySymbol){
		productDisplayJS.langId = langId;
		productDisplayJS.storeId = storeId;
		productDisplayJS.catalogId = catalogId;
		productDisplayJS.userType = userType;
		productDisplayJS.currencySymbol = currencySymbol;
	},

		setEntitledItems : function(entitledItemArray){
		productDisplayJS.entitledItems = entitledItemArray;
	},

		getCatalogEntryId : function(entitledItemId){
		var attributeArray = [];
		var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];
		for(attribute in selectedAttributes){
			attributeArray.push(attribute + "_" + selectedAttributes[attribute]);
		}
		if (selectedAttributes == null && this.entitledItems == null) {
			return entitledItemId.substring(entitledItemId.indexOf("_") + 1);
		}
		return productDisplayJS.resolveSKU(attributeArray);
	},
	postCallCatentryDetailsById: function(parameters){
		var jsonVal = {};
		var jsonObject = catentryJson;
		for( var i in jsonObject){
		 for(var j in jsonObject[i]){
			if(j == parameters.catalogEntryId){
			jsonVal = jsonObject[i][j]

						}
		 }
		}
		var priceObj = globalPriceMap[parameters.catalogEntryId];
		var bopusprice_USD =  priceObj["bopusprice_USD"];
		var listprice_USD =  priceObj["listprice_USD"];
		var price_USD  =  priceObj["price_USD"];
		var rd_price_USD   =  priceObj["rd_price_USD"]; 
		var listprice_USDTemp = 0;
		var price_USDTemp = 0 ;
		var listPriced = "false";
		if(typeof(listprice_USD) != "undefined" && listprice_USD != null){
			listprice_USDTemp = listprice_USD.replace("$","")*1;
		}
		if(typeof(price_USD) != "undefined" && price_USD != null){
			price_USDTemp = price_USD.replace("$","")*1;
		}
		if(listprice_USDTemp > price_USDTemp){
			listPriced = "true";
		}

		jsonVal.catalogEntry.offerPrice = price_USD;
		jsonVal.catalogEntry.listPrice = listprice_USD;
		jsonVal.catalogEntry.bopusPrice = bopusprice_USD;
		jsonVal.catalogEntry.listPriced = listPriced;
		jsonVal.catalogEntry.rdPrice = rd_price_USD;
		if(listprice_USDTemp == price_USDTemp){
			jsonVal.catalogEntry.listPrice = "";
		}
		productDisplayJS.publishAttributeResolvedEventServiceResponse(jsonVal,parameters);
	},
	showAddToCartSubmit: function(){
		dojo.forEach(dojo.query('div[id="cart-submit-button"]'),function(obj){
			obj.className = "col-8 col-center hide";
		})
		dojo.forEach(dojo.query('div[id="pdp-submit-button"]'),function(obj){
				obj.className = "col-8 col-center show";
		})
		dojo.forEach(dojo.query('div[id="wishList-submit-button"]'),function(obj){
			obj.className = "col-8 col-center hide";
		})
		var isRXMedicine = false;
		if($("#rxMedicine").length >0 && $("#rxMedicine").val() == 'true' ){
			isRXMedicine = true;
		}

		var isRXFood = false;
		if($("#isRXFoodIndicator").length >0 && $("#isRXFoodIndicator").val() == 'true' ){
			isRXFood = true;
		}

		if(isRXMedicine && $("#isRxSPCModalEnabled").length >0 && $("#isRxSPCModalEnabled").val() == 'true' ){
			var isRxThrottleEnabled = $("#isRxThrottleEnabled").val();
			if(isRxThrottleEnabled == 'true'){
				var rxPetVetSource = localStorage.getItem('rxPetVetSource');
				var expiresIn = localStorage.getItem('rxPetVetSource_expiresIn');
				var now = Date.now();  
			    if (expiresIn===undefined || expiresIn===null) { 
			    	expiresIn = 0;
			    }
				var updateLocalStorage = false;

								if (rxPetVetSource===undefined || rxPetVetSource===null) {
					var expires = (24*60*60);
					updateLocalStorage = true;
			    }else if(expiresIn < now){
			    	localStorage.removeItem('rxPetVetSource_expiresIn');
			    	localStorage.removeItem('rxPetVetSource');
			    	var expires = (24*60*60);
			    	updateLocalStorage = true;
			    }

				if(updateLocalStorage){
					var schedule = now + expires*1000; 
				    try {
				        localStorage.setItem('rxPetVetSource', $("#rxPetVetSource").val());
				        localStorage.setItem('rxPetVetSource_expiresIn', schedule);
				        var rxPetVetSource = $("#rxPetVetSource").val();
					} catch(e) {
				        console.log('setStorage: Error setting key ['+ key + '] in localStorage: ' + JSON.stringify(e) );
				    }
				}				
				if('pdp' != rxPetVetSource){
					isRXMedicine = false;
					this.Add2ShopCartAjax("entitledItem_"+productDisplayJS.bopusShowStoreDetailsProductId, $("#quantity_"+productDisplayJS.bopusShowStoreDetailsProductId).val(), false, false);
					return;
				}
			}else{
				isRXMedicine = false;
				this.Add2ShopCartAjax("entitledItem_"+productDisplayJS.bopusShowStoreDetailsProductId, $("#quantity_"+productDisplayJS.bopusShowStoreDetailsProductId).val(), false, false);
				return;
			}
		}else if( $("#isRxSPCModalEnabled").val() == 'false'){
			localStorage.removeItem('rxPetVetSource_expiresIn');
	    	localStorage.removeItem('rxPetVetSource');

	    			}
		if(isRXMedicine){
			$('[id^="wishList-submit-button"]').addClass("hide");
			$('[id^="wishList-submit-button"]').removeClass("show");
			$('[id^="pdp-submit-button"]').removeClass("hide");
			$('[id^="pdp-submit-button"]').addClass("show");
			petcoCommonJS.showModal('rxmedicine');
			petcoPersonalizationJS.rxGoToPage("1");
			$('#rxPersonilaztionDisplay').addClass('active');
			if(petcoPersonalizationJS.petandvetInfoPulated){
			     if (!submitRequest()) {
		             return;
		         }
				cursor_wait();
				petcoPersonalizationJS.loadPetAndVetInfoInModel();

							}

						if("R" != dojo.cookie("WC_UserType")  && $("#isVetWidgetEnabled").val() != 'true'){
					petcoPersonalizationJS.loadVetClicnicInfos(1,1,true);
			}

				}else if(isRXFood && $("#isRxFoodCollectPdpEnabled").length >0 && $("#isRxFoodCollectPdpEnabled").val() == 'false'){ 
			this.isRxFoodCollectPDPEnabled = false;
			this.Add2ShopCartAjax("entitledItem_"+productDisplayJS.bopusShowStoreDetailsProductId, $("#quantity_"+productDisplayJS.bopusShowStoreDetailsProductId).val(), false, false);
			return;

					}else {
			petcoCommonJS.showModal('Personalization');
			$('#Personalization_1').show();
			$('#personilaztionDisplay').addClass('active');
			if(0 < $("#isPersonalizationMandate").length){
				var isPersonalizationMandate = $("#isPersonalizationMandate").val();
				if("false" == isPersonalizationMandate){ 
					$(".personalizationMandatory").removeClass('hide');
					$(".personalizationMandatory").addClass('show');
				}
			}
		}


							},	
	getCatalogEntryIdforProduct : function(selectedAttributes){
		var attributeArray = [];
		for(attribute in selectedAttributes){
			attributeArray.push(attribute + "_" + selectedAttributes[attribute]);
		}
		return productDisplayJS.resolveSKU(attributeArray);
	},

    getEntitledItemJsonObject: function () {
    	return productDisplayJS.entitledItemJsonObject;
    },

	resolveSKU : function(attributeArray){
		var catentry_id = "";
		var attributeArrayCount = attributeArray.length;
		if(this.entitledItems.length == 1){
			return this.entitledItems[0].catentry_id;
		}
		for(x in this.entitledItems){
			var catentry_id = this.entitledItems[x].catentry_id;
			var Attributes = this.entitledItems[x].Attributes;
			var attributeCount = 0;
			for(index in Attributes){
				attributeCount ++;
			}
			if (attributeArrayCount == 0 && attributeCount == 0){
				return catentry_id;
			}
			if(attributeCount != 0 && attributeArrayCount >= attributeCount){
				var matchedAttributeCount = 0;

				for(attributeName in attributeArray){
					var attributeValue = attributeArray[attributeName];
					if(attributeValue in Attributes){
						matchedAttributeCount ++;
					}
				}

								if(attributeCount == matchedAttributeCount){
					console.debug("CatEntryId:" + catentry_id + " for Attribute: " + attributeArray);
					this.disableBuyButtonforUnbuyable(x);
					return catentry_id;
				}
			}
		}
		return null;
	},

	disableBuyButtonforUnbuyable :function(entitledItemIndex){
		var buyableFlag = this.entitledItems[entitledItemIndex].buyable;
		var btn = document.getElementById("add2CartBtn");
		if(buyableFlag != null && btn != null){
			if(buyableFlag == 'false'){
				btn.className += " add2CartButtonDisabled";
			}
			else{  
				btn.className = btn.className.replace(" add2CartButtonDisabled", "");
			}
		}
	},

	registerAttributeIds: function(attributeName, entitledItemId, ids) {
		var attributeIds = productDisplayJS.registeredAttributeIds[entitledItemId];
		if (attributeIds == null) {
			attributeIds = new Object();
			productDisplayJS.registeredAttributeIds[entitledItemId] = attributeIds;
		}
		attributeIds[productDisplayJS.removeQuotes(attributeName)] = ids;
	},

	getAttributeIds: function(attributeName, entitledItemId) {
		var ids = null;
		var attributeIds = productDisplayJS.registeredAttributeIds[entitledItemId];
		if (attributeIds != null) {
			ids = attributeIds[productDisplayJS.removeQuotes(attributeName)];
		}
		return ids;
	},

	setSelectedAttribute : function(selectedAttributeName , selectedAttributeValue, entitledItemId, skuImageId, imageField, selectedAttributeDisplayValue){ 		
		var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];
		if(selectedAttributes == null){
			selectedAttributes = new Object();
		}
		selectedAttributeValue = selectedAttributeValue.replace(productDisplayJS.replaceStr001, productDisplayJS.search01);
		selectedAttributeValue = selectedAttributeValue.replace(productDisplayJS.replaceStr002, productDisplayJS.search02);
		if(selectedAttributeValue == ""){
			if(dojo.byId("notifyMe")!=null)
				dojo.style("notifyMe","display","none");
		}
		if(dojo.byId("notifyMeSuccess")!=null)
			dojo.style("notifyMeSuccess","display","none");

		if(dojo.byId("splDlvFlag")!=null)
			dojo.byId("splDelvMsg").innerHTML=dojo.byId("splDlvFlag").value;
		selectedAttributeValue = selectedAttributeValue.replace(productDisplayJS.replaceStr01, productDisplayJS.search01);
		selectedAttributeValue = selectedAttributeValue.replace(productDisplayJS.replaceStr02, productDisplayJS.search02);
		if(!selectedAttributeValue.includes(productDisplayJS.ampersandEntityName)){
			selectedAttributeValue = selectedAttributeValue.replace(productDisplayJS.ampersandChar, productDisplayJS.ampersandEntityName);
		}
		selectedAttributes[selectedAttributeName] = selectedAttributeValue;
		productDisplayJS.moreInfoUrl=productDisplayJS.moreInfoUrl+'&'+selectedAttributeName+'='+selectedAttributeValue;
		productDisplayJS.selectedAttributesList[entitledItemId] = selectedAttributes;
		if(skuImageId != undefined){
			productDisplayJS.setSKUImageId(skuImageId);
		}
		var entitledItemJSON;
		if (dojo.byId(entitledItemId)!=null && !productDisplayJS.isPopup) {
			 entitledItemJSON = eval('('+ dojo.byId(entitledItemId).innerHTML +')');
		}else{
			entitledItemJSON = productDisplayJS.getEntitledItemJsonObject(); 
		}
		productDisplayJS.setEntitledItems(entitledItemJSON);
		var attributeIds = productDisplayJS.getAttributeIds(selectedAttributeName, entitledItemId);
		if (attributeIds != null) {
			var usedFilterValue = dojo.byId(attributeIds.usedFilterValueId);
			if (usedFilterValue != null) {
				if (selectedAttributeDisplayValue) {
					usedFilterValue.innerHTML = selectedAttributeDisplayValue;
				} else {
					usedFilterValue.innerHTML = selectedAttributeValue;
				}
			}
			if (selectedAttributeValue == "") {
				dojo.removeClass(attributeIds.usedFilterId, "visible");
				var hideCurrentUsedFilters = true;
				var dropdownList = this.allDropdownsList[entitledItemId];
				for (var i in dropdownList) {
					if (selectedAttributes[dropdownList[i].name] != "") {
						hideCurrentUsedFilters = false;
						break;
					}
				}
				if (hideCurrentUsedFilters) {
					dojo.addClass("currentUsedFilters_" + entitledItemId, "hidden");
				}
			}
			else {
				dojo.addClass(attributeIds.usedFilterId, "visible");
				dojo.removeClass("currentUsedFilters_" + entitledItemId, "hidden");
				var selectedAttributeNameId = selectedAttributeName.replace(productDisplayJS.search01, "\\\'").replace(productDisplayJS.search02,'\\\"');
				dojo.addClass("attr_" + entitledItemId + '_' + selectedAttributeNameId, "hidden");
			}

						productDisplayJS.makeDropdownSelection(selectedAttributeName, selectedAttributeValue, entitledItemId);
		}
	},
	 AddtoShopCartAjax: function(entitledItemId, quantity, prdId, isPopup, customParams) {
	         var entitledItemJSON;
	         var isRDAddOn = false;
	         if (customParams != null && customParams != 'undefined') {
	             if (customParams['catalogEntryType'] == 'repeatDeliveryAddOn') {
	                 isRDAddOn = true; 
	             }
	         }

	         var catalogEntryId = entitledItemId;
	         var repeatDeliveryOption = document.getElementById("RDScheduleFrqValue").value;

	         if (repeatDeliveryOption != null || repeatDeliveryOption != "") {
	             selectedProductId = catalogEntryId;
	         }

	         if (catalogEntryId != null) {
	             var productId = prdId; 
	             if (isRDAddOn) {
	                 this.AddItem2RecurringOrderAjax(catalogEntryId, quantity, customParams, productId);
	             } else {
	                 this.AddItemToShopCartAjax(catalogEntryId, quantity, customParams, productId);
	                 this.baseItemAddedToCart = true;
	             }
	         }
	     },

	     AddItemToShopCartAjax: function(catEntryIdentifier, quantity, customParams, productId) {
	         var params = [];
	         var isNewEASession = dojo.cookie('isNewEASession');
	         if (isNewEASession == "yes" ) {
	        	 params.storeId = document.getElementById('PStoreId').value;	
	         } else {
	        	 params.storeId = document.getElementById('storeId').value;
	         }	 
	         params.catalogId = document.getElementById('catalogId').value;
	         params.langId = "-1";
	         params.orderId = ".";
	         params.calculationUsage = "-1,-2,-5,-6,-7";
			 params.callToODM = true;
	         params.inventoryValidation = "true";

	       	         	         var isRDAddOn = false;
	         var isRxProd = false;
	         if($("#isRxProd").length >0 && $("#isRxProd").val() == 'true'){
	 			isRxProd = true;
	 			if(petcoPersonalizationJS.isRxMedicineItemSPCEnabled()){
	 					params.isRxMedicineFieldUpdate		= isRxProd;	
	 			}
	 		}

				if($("#isBopusDelete").length > 0 && $("#physicalSToreId").length > 0 && $("#bopusShipModeId").length > 0 && $("#isBopusDelete").val() == "true"){
					params.physicalStoreId		= $("#physicalSToreId").val();
					params.shipModeId		=  $("#bopusShipModeId").val();
					params.fromBopusPage = true;				
				}else{
					if($("#defaultShipMode").length > 0 && $("#defaultFFMCenter").length > 0 && $("#defaultShipMode").val() !="" && $("#defaultFFMCenter").val() != ""){
						params.shipModeId		= $("#defaultShipMode").val();
						params.fulfillmentCenterId = $("#defaultFFMCenter").val();
					}
					params.fromBopusPage = false;
				}

								if($("#isBopusDelete").length > 0 && $("#physicalSToreId").length > 0 && $("#bopusShipModeId").length > 0 
				&& $("#defaultShipMode").length > 0 && $("#defaultFFMCenter").length > 0){
									$("#isBopusDelete").val("false");
									$("#physicalSToreId").val("");
									$("#bopusShipModeId").val("");
									$("#defaultShipMode").val("");
									$("#defaultFFMCenter").val("");																
				}
	         var donationOtion = document.getElementById("RemovedDonation").value;
	         if (donationOtion != "") {
	             params.catalogEntryType = "donation";
	         }

	         var repeatDeliveryOption = document.getElementById("RDScheduleFrqValue").value;
	         if (repeatDeliveryOption != null && repeatDeliveryOption != "") {
	             this.isRepeatDeliveryProduct = true;
	         } else {
	             this.isRepeatDeliveryProduct = false;
	         }

	         if (this.isRepeatDeliveryProduct) {
	             params.rdFrequency = document.getElementById('RDScheduleFrqValue').value;
	         }
	         var ajaxShopCartService = "AjaxAddOrderItem_shopCart";

	         shoppingActionsJS.productAddedList = new Object();
	         if (dojo.isArray(catEntryIdentifier) && dojo.isArray(quantity)) {
	             for (var i = 0; i < catEntryIdentifier.length; i++) {
	                 if (!isPositiveInteger(quantity[i])) {
	                     MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
	                     return;
	                 }
	                 params["catEntryId_" + (i + 1)] = catEntryIdentifier[i];
	                 params["quantity_" + (i + 1)] = quantity[i];
	             }
	         } else {
	             if (!isPositiveInteger(quantity)) {
	                 MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
	                 return;
	             }
	             if (document.getElementById("removedAttrNames").value != "") {
	                 customParams = true;
	                 var i = 0;
	                 params["isPersonalized_" + (i + 1)] = true;
	                 params["catEntryId_" + (i + 1)] = catEntryIdentifier;
	                 params["quantity_" + (i + 1)] = 1;
	                 var AttrNames = document.getElementById("removedAttrNames").value
	                 var personalizationName = AttrNames.split('|');
	                 if(document.getElementById("isRxProd").value != "" && document.getElementById("isRxProd").value == "true"){
	                	 params["isRxPersonalized_"+((i*1)+1)] = true;
	                	 params["isTemplatePersonalizedOrder_"+((i*1)+1)] = true;
	                	 var rxProdParameters = $("#rxProdParameters");
	                	 if(rxProdParameters.length > 0 ){


	                     		                		 var rxProdParametersArr = $(rxProdParameters).html().split("||^||");
	                		 for(var j = 0 ; j < rxProdParametersArr.length ; j++){
	                			 if(rxProdParametersArr[j] == 'isRefillRxPersonalized'){
	                				 params["isRefillRxPersonalized_"+(i+1)] = "true";
	                			 }
	                			 else{
		                			params["pAttrName"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[0].replace("pAttr","");
		                			params["pAttrId"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[6]; 
		 							params["pAttrValue"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[1]; 
		 							params["pAttrOrder"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[4];
		 							params["pAttrReq"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[5]; 
		 							params["pAttrFieldId"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[2];
		 							params["pAttrReqInCart"+((j*1)+1)+'_'+((i*1)+1)] = rxProdParametersArr[j].split("||*||")[3];
	                			 }
	                		 }
	                	 }
	                 }else{
	                 for (var j = 0; j < (personalizationName.length); j++) {
	                     var nameAttr = personalizationName[j] + '_' + (i + 1);
	                     params["pAttrId" + (j + 1) + '_' + (i + 1)] = personalizationName[j];
	                 }

	                 if (document.getElementById("removedAttrvalue").value != "") {
	                     var AttrValue = document.getElementById("removedAttrvalue").value
	                     var personalizationValue = AttrValue.split(',');

	                     for (var k = 0; k < (personalizationValue.length); k++) {
	                         var nameAttrvalue = personalizationValue[k] + '_' + (i + 1);
	                         params["pAttrValue" + (k + 1) + '_' + (i + 1)] = personalizationValue[k];
	                     }
	                 }
	                 }

	             } else {
	                 params.catEntryId = catEntryIdentifier;
	                 params.quantity = quantity;
	             }

	         }
	         if (customParams != null && customParams != 'undefined') {
	             for (i in customParams) {
	                 params[i] = customParams[i];
	             }
	             if (customParams['catalogEntryType'] == 'dynamicKit') {
	                 ajaxShopCartService = "AddPreConfigurationToCart";
	             } else if (customParams['catalogEntryType'] == 'repeatDeliveryAddOn') {
	                 isRDAddOn = true; 
	                 ajaxShopCartService = 'RepeatDeliveryOrderAdd';
	                 params.orderId = '**';
	             }
	         }
	         if (!submitRequest()) {
	             return;
	         }
	         cursor_wait();

	         wc.service.invoke(ajaxShopCartService, params);
	         if (!isRDAddOn) {
	             productDisplayJS.baseItemAddedToCart = true;
	         }
	     },

	Add2ShopCartAjax : function(entitledItemId,quantity,isPopup,customParams)
	{	
		var entitledItemJSON;
		var isRDAddOn = false;
		var isAddToExistingRD = false;		
		if (dojo.byId(entitledItemId)!=null) {
			 entitledItemJSON = eval('('+ dojo.byId(entitledItemId).innerHTML +')');
		}else{
			entitledItemJSON = this.getEntitledItemJsonObject(); 
		}
		if (customParams != null && customParams != 'undefined') {
			if (customParams['catalogEntryType'] == 'repeatDeliveryAddOn') {
				isRDAddOn = true;	
			}
		}
		if (customParams != null && customParams != 'undefined') {
			if (customParams['catalogEntryType'] == 'addToExistingRepeatDeliveryOrder') {
				isAddToExistingRD = true;	
			}
		}
		productDisplayJS.setEntitledItems(entitledItemJSON);
		var catalogEntryId = productDisplayJS.getCatalogEntryId(entitledItemId);
		var repeatDeliveryOption = document.getElementById("repeat-delivery-radio");
		{
			if(repeatDeliveryOption!=null || repeatDeliveryOption!=undefined)
			{
    		   if(repeatDeliveryOption && repeatDeliveryOption.checked){

    			   selectedProductId = catalogEntryId;
    		   }
    	   }
		 if(null != document.getElementById("isWHMemberShipSKU")){			 
				var  customParams = {};			 
				customParams['isWHMembershipSKU'] =true;
			}
		if(catalogEntryId!=null){
			var productId = entitledItemId.substring(entitledItemId.indexOf("_")+1);
			if (isRDAddOn || isAddToExistingRD) {
				try {
					if(isRDAddOn) {
						PetcoDataLayer.product.product_delivery_type="one time";
					} else {
						PetcoDataLayer.product.product_delivery_type="repeat";
					}
				} catch(e) {
			        console.log("Error setting product delivery type");
			    }


												this.AddItem2RecurringOrderAjax(catalogEntryId , quantity, customParams, productId);
			}
			else {
			productDisplayJS.AddItem2ShopCartAjax(catalogEntryId , quantity,customParams, productId);
			productDisplayJS.baseItemAddedToCart=true;
			}
			if(dijit.byId('second_level_category_popup') != null){
				hidePopup('second_level_category_popup');
			}
		}
		else if (isPopup == true){
			dojo.byId('second_level_category_popup').style.zIndex = '1';
			MessageHelper.formErrorHandleClient('addToCartLinkAjax', storeNLS['ERR_RESOLVING_SKU']);			
		} else{
			MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
			productDisplayJS.baseItemAddedToCart=false;
		}
		}
	},


	Add2RecurringOrderAjax : function(entitledItemId, quantity, isPopup, customParams) {

		var subscriptionIdField = null;
		var subscriptionId = null;
		var isLTLDeliveryRequired = null;

		subscriptionIdField = dojo.query('input[type=hidden][name=subscriptionId]');
		if (subscriptionIdField.length == 0) {
			subscriptionIdField = dojo.query('input[type=radio][name=subscriptionId]:checked');
		}
		subscriptionId = subscriptionIdField[0].value;
		console.log('subscriptionId == ' + subscriptionId);
		if (customParams == null || (customParams != null && customParams == ''))
			customParams = {};	
		else {
			isLTLDeliveryRequired = customParams;
			customParams = {};
			customParams['isLTLDeliveryRequired'] = isLTLDeliveryRequired;
		}
		var entitledItemJSON;
		if (dojo.byId(entitledItemId)!=null) {
		 entitledItemJSON = eval('('+ dojo.byId(entitledItemId).innerHTML +')');
		}else{
		entitledItemJSON = this.getEntitledItemJsonObject();
		}
		productDisplayJS.setEntitledItems(entitledItemJSON);
		var catalogEntryId = productDisplayJS.getCatalogEntryId(entitledItemId);
		var catEntry = productDisplayJS.itemPriceJsonOject[catalogEntryId].catalogEntry;
		var rdSkuPartNumber = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
		customParams['catalogEntryType'] = 'repeatDeliveryAddOn';
		customParams['subscriptionId'] = subscriptionId;
		customParams['isAddOn'] = 'Y';
		customParams['rdSkuPartNumber'] = rdSkuPartNumber;
		this.Add2ShopCartAjax(entitledItemId, quantity, isPopup, customParams);
	},

	Add2ExistingRDOrderAjax : function(entitledItemId, quantity, isPopup, customParams) {

		var subscriptionIdField = null;
		var subscriptionId = null;
		var isLTLDeliveryRequired = null;
		var selectRDDrop=  $("#addToRDDropDown" ).val();
		subscriptionIdField = dojo.query('input[type=hidden][name=subscriptionId]');
		if (subscriptionIdField.length == 0) {
			subscriptionIdField = dojo.query('input[type=radio][name=subscriptionId]:checked');
		}
		subscriptionId = subscriptionIdField[0].value;
		console.log('subscriptionId == ' + subscriptionId);
		if (customParams == null || (customParams != null && customParams == ''))
			customParams = {};	
		else {
			isLTLDeliveryRequired = customParams;
			customParams = {};
			customParams['isLTLDeliveryRequired'] = isLTLDeliveryRequired;
		}
		var entitledItemJSON;
		if (dojo.byId(entitledItemId)!=null) {
		 entitledItemJSON = eval('('+ dojo.byId(entitledItemId).innerHTML +')');
		}else{
		entitledItemJSON = this.getEntitledItemJsonObject();
		}
		productDisplayJS.setEntitledItems(entitledItemJSON);
		var catalogEntryId = productDisplayJS.getCatalogEntryId(entitledItemId);
		var catEntry = productDisplayJS.itemPriceJsonOject[catalogEntryId].catalogEntry;
		var rdSkuPartNumber = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
		if (selectRDDrop !=null && selectRDDrop == 'ADDON') {
			customParams['catalogEntryType'] = 'repeatDeliveryAddOn';
			customParams['isAddOn'] = 'Y';
			customParams['isAddOnFromINC'] = 'Y';
		}else{
			customParams['catalogEntryType'] = 'addToExistingRepeatDeliveryOrder';
			customParams['isInclusive'] = 'Y';
		}	
		customParams['subscriptionId'] = subscriptionId;
		customParams['rdSkuPartNumber'] = rdSkuPartNumber;
		this.Add2ShopCartAjax(entitledItemId, quantity, isPopup, customParams);
	},

		AddItem2ShopCartAjax : function(catEntryIdentifier, quantity, customParams, productId)
	{
		var params = {};
		params.storeId		= this.storeId;
		params.catalogId	= this.catalogId;
		params.langId		= this.langId;
		params.orderId		= ".";


								if($("#rxMedicine").length >0 && $("#rxMedicine").val() == 'true' ){
			isRXMedicine = true;
			if(petcoPersonalizationJS.isRxMedicineItemSPCEnabled()){
 				params.isRxMedicineFieldUpdate		= isRXMedicine;	
 			}
		}

		var isRXFood = false;
		if($("#isRXFoodIndicator").length >0 && $("#isRXFoodIndicator").val() == 'true' ){
			isRXFood = true;
			if($("#isRxFoodCollectPdpEnabled").length >0 && $("#isRxFoodCollectPdpEnabled").val() == 'false'){
 				params.isRxMedicineFieldUpdate		= isRXFood;	
 			}
		}

		params.inventoryValidation = "true";
		var defaultShipMode= productDisplayJS.getShipModeIdForDefault();
		var defaultFFMCenter = productDisplayJS.getFfmCenterDefault();
		var samedaySelected = (document.getElementById('sameday-selected')!= null &&  document.getElementById('sameday-selected') != undefined) ? document.getElementById('sameday-selected').value : "false";
		if(samedaySelected == "true"){
			params.shipModeId = (document.getElementById('samedayShipmodId')!= null &&  document.getElementById('samedayShipmodId') != undefined) ? document.getElementById('samedayShipmodId').value : '';
			params.fulfillmentCenterId = defaultFFMCenter;	
			params.SameDayOpted = true;
		}else if($("div#bopusSelectEnabledBopusAttrOn.show").length > 0  &&
				$("#bopusSelectStails").length > 0 && $("#bopusSelectStails").val() == "true"){
			if($("div#bopusSelectEnabledBopusAttrOn input#store-pickup:checked").length > 0){
				var storeId = productDisplayJS.getStoreIdFromPhyicalSToreIdCookie();
				var bopusShipMode = productDisplayJS.getShipModeIdForBOPUS();
				if(storeId != "" && storeId != "NA" && bopusShipMode != ""){
					params.physicalStoreId		= storeId;
					params.shipModeId		= bopusShipMode;
					params.fromBopusPage = true;
				}else if(defaultShipMode != "" && defaultFFMCenter != ""){
					params.shipModeId		= defaultShipMode;
					params.fulfillmentCenterId = defaultFFMCenter;
					params.fromBopusPage = false;
				}
			}else{
				if(defaultShipMode != "" && defaultFFMCenter != ""){
					params.shipModeId		= defaultShipMode;
					params.fulfillmentCenterId = defaultFFMCenter;
					params.fromBopusPage = false;
				}
			}

					}

				var isRDAddOn = false;

		var repeatDeliveryOption = document.getElementById("repeat-delivery-radio");
		var oneTimeDeliveryOption = document.getElementById("one-time-delivery-radio");

		if(repeatDeliveryOption != null && repeatDeliveryOption != undefined && repeatDeliveryOption.checked)
		{
			this.isRepeatDeliveryProduct = true;
		}
		else
		{
			this.isRepeatDeliveryProduct = false;
		}

		if(this.isRepeatDeliveryProduct)
		{
			if ($('#repeat-delivery-dropdown').hasClass('collapsible') && !$('#repeat-delivery-dropdown').hasClass('open')) {
				if(document.getElementById("repeat-delivery-first-ship-options") != undefined && document.getElementById("repeat-delivery-first-ship-options") != null){
					var rdInitialFirstOrderValue = document.getElementById('repeat-delivery-first-ship-options').value;
					if(rdInitialFirstOrderValue != null && rdInitialFirstOrderValue != undefined) {
						if (rdInitialFirstOrderValue == "now") {
							params.rdFrequency = document.getElementById('repeat-delivery-freq-options_future_order').value;
						} else {
							params.rdFrequency = document.getElementById('repeat-delivery-freq-options_future_order').value;
							params.rdInitialFirstOrder = rdInitialFirstOrderValue;
						}
					}
				}
			} else {
				params.rdFrequency = document.getElementById('repeat-delivery-freq-options').value;
			}
		}
		var ajaxShopCartService = "AddOrderItem";

				shoppingActionsJS.productAddedList = new Object();
		if(dojo.isArray(catEntryIdentifier) && dojo.isArray(quantity)){
			for(var i=0; i<catEntryIdentifier.length; i++){
				if(!isPositiveInteger(quantity[i])){
					MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
					return;
				}
				params["catEntryId_" + (i+1)] = catEntryIdentifier[i];
				params["quantity_" + (i+1)]	= quantity[i];
			}
		}
		else{
			if(!isPositiveInteger(quantity)){
				MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
				return;
			}
			if(petcoPersonalizationJS.isPersonalizedItem() && !petcoPersonalizationJS.isRxMedicineItem() && this.isRxFoodCollectPDPEnabled){ 
				var invalidChars = petcoPersonalizationJS.findInvalidCharSetForUserInput(quantity,customParams);
				if(invalidChars!=""){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedRequiredField(quantity,customParams)){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedTextInputType(quantity,customParams)){
					return false;
				}
				params = petcoPersonalizationJS.addPersonalizationAttr(catEntryIdentifier,quantity,params)
			}else if(petcoPersonalizationJS.isRxMedicineItem() && !petcoPersonalizationJS.isRxMedicineItemSPCEnabled()){		
				params = petcoPersonalizationJS.addRxMedicinePersonalizationAttr(catEntryIdentifier,quantity,params);				
			}else
			{
				params.catEntryId	= catEntryIdentifier;
				params.quantity		= quantity;
			}
			var selectedAttrList = new Object();
			for(attr in productDisplayJS.selectedAttributesList['entitledItem_'+productId]){
				selectedAttrList[attr] = productDisplayJS.selectedAttributesList['entitledItem_'+productId][attr];
			}			

						if(productId == undefined){
				shoppingActionsJS.saveAddedProductInfo(quantity, catEntryIdentifier, catEntryIdentifier, selectedAttrList);
			} else {
				shoppingActionsJS.saveAddedProductInfo(quantity, productId, catEntryIdentifier, selectedAttrList);
			}
		}		

		if(customParams != null && customParams != 'undefined'){
			for(i in customParams){
				params[i] = customParams[i];				
			}						
			if(customParams['catalogEntryType'] == 'dynamicKit' ){
				ajaxShopCartService = "AddPreConfigurationToCart";
			}
			else if (customParams['catalogEntryType'] == 'repeatDeliveryAddOn' || customParams['catalogEntryType'] == 'addToExistingRepeatDeliveryOrder') {
				isRDAddOn = true;	
				ajaxShopCartService = 'RepeatDeliveryOrderAdd';	
				params.orderId = '**'; 
			}
		}

		var contractIdElements = document.getElementsByName('contractSelectForm_contractId');
		if (contractIdElements != null && contractIdElements != "undefined") {
			for (i=0; i<contractIdElements.length; i++) {
				if (contractIdElements[i].checked) {
					params.contractId = contractIdElements[i].value;
					break;
				}
			}
		}

				if(!submitRequest()){
			return;
		}   

						cursor_wait();	
		if (this.isRepeatDeliveryProduct) {
			var rd_schedule = document.getElementById('repeat-delivery-freq-options').value;
			var catEntry = productDisplayJS.itemPriceJsonOject[catEntryIdentifier].catalogEntry;
			var rd_sku = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
			try{
				var name = catEntry.description[0].name;
				var productName = name.replace(/["']/g, "");
				if (rd_sku != null && rd_sku != "undefined") { 
					var cartEventRDDetails = {};
					cartEventRDDetails["product_id"] = productId;
					cartEventRDDetails["product_sku"] = rd_sku;
					cartEventRDDetails["product_name"] = productName;
					if($('#tel_product_id').size()>0){
						cartEventRDDetails["product_parent_sku"] = $('#tel_product_id').val();
					}
					cartEventRDDetails["conversion_action_type"] = "1";
					cartEventRDDetails["conversion_category_id"] = "Repeat Delivery";
					cartEventRDDetails["conversion_event_id"] = "New Repeat Delivery";
					cartEventRDDetails["event_name"] = "new_repeat_delivery";               

					pushEvent(cartEventRDDetails);
				}
			}catch(err){
				console.log(err);
			}
		}

				wc.service.invoke(ajaxShopCartService, params);
		if (!isRDAddOn) {
			productDisplayJS.baseItemAddedToCart=true;
		}
	},

	AddItem2RecurringOrderAjax : function(catEntryIdentifier, quantity, customParams, productId) {
		this.AddItem2ShopCartAjax(catEntryIdentifier, quantity, customParams, productId);
	},
	setSKUImageId:function(skuImageId){
		productDisplayJS.skuImageId = skuImageId;
	},

	getImageForSKU : function(entitledItemId, imageField){
		var attributeArray = [];
		var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];
		for(attribute in selectedAttributes){
			attributeArray.push(attribute + "_|_" + selectedAttributes[attribute]);
		}
		return productDisplayJS.resolveImageForSKU(attributeArray, imageField);
	},

	resolveImageForSKU : function(attributeArray, imageField){		
		var imagePath = "";
		var attributeArrayCount = attributeArray.length;

				for(x in this.entitledItems){
			if(null != imageField){
				var imagePath = this.entitledItems[x][imageField];
			} else {
			var imagePath = this.entitledItems[x].ItemImage467;
			}

						var Attributes = this.entitledItems[x].Attributes;
			var attributeCount = 0;
			for(index in Attributes){
				attributeCount ++;
			}

			if (attributeArrayCount == 0 && attributeCount == 0){
				return imagePath;
			}
			if(attributeCount != 0 && attributeArrayCount >= attributeCount){
				var matchedAttributeCount = 0;

				for(attributeName in attributeArray){
					var attributeValue = attributeArray[attributeName];
					if(attributeValue in Attributes){
						matchedAttributeCount ++;
					}
				}

								if(attributeCount == matchedAttributeCount){
					console.debug("ItemImage:" + imagePath + " for Attribute: " + attributeArray);
					var imageArray = [];
					imageArray.push(imagePath);
					imageArray.push(this.entitledItems[x].ItemThumbnailImage);
					if(this.entitledItems[x].ItemAngleThumbnail != null && this.entitledItems[x].ItemAngleThumbnail != undefined){
						imageArray.push(this.entitledItems[x].ItemAngleThumbnail);
						imageArray.push(this.entitledItems[x].ItemAngleFullImage);
						imageArray.push(this.entitledItems[x].ItemAngleThumbnailShortDesc);
					}
					return imageArray;
				}
			}
		}
		return null;
	},


	changeViewImages : function(itemAngleThumbnail, itemAngleFullImage, itemAngleThumbnailShortDesc){	
		var imageCount = 0;
		for (x in itemAngleThumbnail) {
			var prodAngleCount = imageCount;
			imageCount++;

						var thumbnailWidgets = dojo.query("ul[id^='ProductAngleImagesAreaList']");
			if(thumbnailWidgets != null){
				for(var i = 0; i<thumbnailWidgets.length; i++){			
					if(null != thumbnailWidgets[i]){
						var angleThumbnail = document.createElement("li");						
						var angleThumbnailLink = document.createElement("a");
						var angleThumbnailImg = document.createElement("img");

												angleThumbnail.id = "productAngleLi" + prodAngleCount;

												angleThumbnailLink.href = "JavaScript:changeThumbNail('productAngleLi" + prodAngleCount + "','" + itemAngleFullImage[x] + "');";
						angleThumbnailLink.id = "WC_CachedProductOnlyDisplay_links_1_" + imageCount ;
						if(itemAngleThumbnailShortDesc != 'undefined' && itemAngleThumbnailShortDesc != null){
							angleThumbnailLink.title = itemAngleThumbnailShortDesc[x];
						}

												angleThumbnailImg.src = itemAngleThumbnail[x];
						angleThumbnailImg.id = "WC_CachedProductOnlyDisplay_images_1_" + imageCount;
						if(itemAngleThumbnailShortDesc != 'undefined' && itemAngleThumbnailShortDesc != null){
							angleThumbnailImg.alt = itemAngleThumbnailShortDesc[x];
						}

												if(prodAngleCount == 0){
							dojo.empty(thumbnailWidgets[i]);						
						}						

												angleThumbnailLink.appendChild(angleThumbnailImg);
						angleThumbnail.appendChild(angleThumbnailLink);
						thumbnailWidgets[i].appendChild(angleThumbnail);
					}
				}				
			}			
		}

				var displayImageArea = "";
		if(imageCount > 0){
			displayImageArea = 'block';
		} else {
			displayImageArea = 'none';
		}
		var angleImageArea = dojo.query("div[id^='ProductAngleImagesArea']");
		if(angleImageArea != null){
			for(var i = 0; i<angleImageArea.length; i++){			
				if(null != angleImageArea[i]){
					angleImageArea[i].style.display = displayImageArea;
				}
			}
		}		
	},

	selectSwatch: function(selectedAttributeName, selectedAttributeValue, entitledItemId, doNotDisable, selectedAttributeDisplayValue, skuImageId, imageField) {
		if(dojo.hasClass("swatch_" + entitledItemId + "_" + selectedAttributeValue, "color_swatch_disabled")){
			return;
		}
	},
	makeDropdownSelection: function(selectedAttrName, selectedAttrValue, entitledItemId) {
		if (!Array.prototype.indexOf) {
	        Array.prototype.indexOf = function(obj, start) {
	            for (var i = (start || 0), j = this.length; i < j; i++) {
	                if (this[i] === obj) {
	                    return i;
	                }
	            }
	            return -1;
	        };
	    }

				var dropdownsToUpdate = [];
		var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];
		var selectedAttrValues = selectedAttributes[selectedAttrName];
		var dropdownList = productDisplayJS.allDropdownsList[entitledItemId];
		for (var i=0; i<dropdownList.length; i++) {
			if (productDisplayJS.removeQuotes(dropdownList[i].name) != productDisplayJS.removeQuotes(selectedAttrName)) {
				dropdownsToUpdate.push(dropdownList[i]);
			}
		}
		var attributesToEnable = new Object();
		for (var x in productDisplayJS.entitledItems) {
			var Attributes = productDisplayJS.entitledItems[x].Attributes;
			var attrList = new Object();
			for (var y in Attributes) {
				var index = y.indexOf("_|_");
				var entitledDropdownName = y.substring(0, index);
				var entitledDropdownValue = y.substring(index + 3);
				attrList[entitledDropdownName] = entitledDropdownValue;
			}

						for (var attrName in attrList) {
				if (productDisplayJS.removeQuotes(attrName) == productDisplayJS.removeQuotes(selectedAttrName)
						&& (attrList[attrName] == selectedAttrValue || selectedAttrValue == '')) {
					for (var attrName2 in attrList) {
						var attrName2NQ = productDisplayJS.removeQuotes(attrName2);
						if (productDisplayJS.removeQuotes(attrName) != attrName2NQ){ 
							var matchSelectedAttributes = true;
							for (var selected in selectedAttributes) {
								if (productDisplayJS.removeQuotes(selected) != attrName2NQ) {
									if (selectedAttributes[selected] && selectedAttributes[selected] !== attrList[selected]) {
										matchSelectedAttributes = false;
									}
								}				
							}

														if (matchSelectedAttributes && attrList[attrName2]) {
								if (!attributesToEnable[attrName2NQ]) {
									attributesToEnable[attrName2NQ] = [];
								} 
								if (attributesToEnable[attrName2NQ].indexOf(attrList[attrName2].replace(/^\s+|\s+$/g, '')) == -1) {
									attributesToEnable[attrName2NQ].push(attrList[attrName2].replace(/^\s+|\s+$/g, ''));
								}
							}							
						}
					}
				}
			}
		}				

				for (var i in dropdownsToUpdate) {
			var attrValues = attributesToEnable[productDisplayJS.removeQuotes(dropdownsToUpdate[i].name)];
			if (attrValues) {
				for (var j = 0; j < dropdownsToUpdate[i].options.length; j++) {										
					var dropdownToUpdateOption = dropdownsToUpdate[i].options[j];
					if (attrValues.indexOf(dropdownToUpdateOption.value.replace(/^\s+|\s+$/g, '')) != -1 || dropdownToUpdateOption.value == '') {
						dropdownToUpdateOption.enabled = true;
					}
				}
			}
		}

					for (var i in dropdownsToUpdate) {
			if (dropdownsToUpdate[i].options) {
				for (var j = 0; j < dropdownsToUpdate[i].options.length; j++) {
					var dropdownToUpdateOption = dropdownsToUpdate[i].options[j];

										if (dropdownToUpdateOption.enabled) {
						dropdownToUpdateOption.disabled = false;
					} else {
						dropdownToUpdateOption.disabled = true;
					}

										delete dropdownToUpdateOption.enabled;
				}

								var dropdown = dijit.byNode(dropdownsToUpdate[i].node);
				dropdown.startup();				
			}
		}
	},

	addToAllSwatchsArray: function(swatchName, swatchValue, swatchImg1, entitledItemId, swatchDisplayValue) {
		var swatchList = this.allSwatchesArrayList[entitledItemId];
	},

	existInAllDropdownsArray: function(dropdownName, dropdownId, dropdownsList) {
		for(var i=0; i<dropdownsList.length; i++) {
			if (dropdownsList[i].name == dropdownName && dropdownsList[i].id == dropdownId) {
				return true;
			}
		}
		return false;
	},

	makeDefaultSwatchSelection: function(entitledItemId, doNotDisable) {
		if (this.entitledItems.length == 0) {
			if (dojo.byId(entitledItemId)!=null) {
				 entitledItemJSON = eval('('+ dojo.byId(entitledItemId).innerHTML +')');
			}
			productDisplayJS.setEntitledItems(entitledItemJSON);
		}

		for(x in this.entitledItems){
			var Attributes = this.entitledItems[x].Attributes;
			for(y in Attributes){
				var index = y.indexOf("_|_");
				var swatchName = y.substring(0, index);
				var swatchValue = y.substring(index+3);
				this.makeSwatchSelection(swatchName, swatchValue, entitledItemId, doNotDisable, null, imageField);
			}
			break;
		}
	},
	isRdProduct : false,
	isAddOnToggleCallMade : false,	
	displayRDPrice : function(catentry_id, productId, rdFlag, rdPrice, rdSavings){
			var innerHTML = "";
			if(rdFlag.value != null && rdFlag.value != undefined && rdFlag.value == '0')
			{
				var rdPriceDiv =  dojo.byId('product-price-rd');
				if(document.getElementById('rdPriceDiv') != null && document.getElementById('rdPriceDiv') != undefined)
				{
					rdPriceDiv.setAttribute("class","product-price");
					if(rdPrice.price != null && rdPrice.price != undefined && rdPrice.price != 'Price pending')
					{
						var itemRDClass = '';
						if(document.getElementById('itemOrProd') != null && document.getElementById('itemOrProd') != undefined){
							var itemOrProd = document.getElementById('itemOrProd').value;
							itemRDClass = (itemOrProd == 'Item') ? 'item-rd-price' : '';
						}
						document.getElementById('rdPriceDiv').innerHTML="<span class='product-price-rddetail "+itemRDClass+"'>"+rdPrice.price+"</span>"
						+ "<span class='rd_savings_message' id='RDSavingsMessage'></span>"
						+ "<span class='rd-logo'>"+ MessageHelper.messages["REPEAT_DELIVERY_CART"] +"</span> <span>"+ productDisplayJS.allItemPricesOject[catentry_id].savingsOnRDPrice +"</span>";

											if(rdSavings != ""){
							document.getElementById('rdPriceDiv').innerHTML = document.getElementById('rdPriceDiv').innerHTML + " <span class='product-price-banner'><img src='/wcsstore/PetcoSAS/img/r-d-logo.png' alt='Repeat Delivery'/></span>";
						}
					}
					else
					{
						document.getElementById('rdPriceDiv').innerHTML="<span class='form-required'>Price pending</span>";
					}

										if($(".delivery-option-price .product-price-rddetail") != null && $(".delivery-option-price .product-price-rddetail") != undefined){
						$(".delivery-option-price .product-price-rddetail").removeClass("hide");
						$(".delivery-option-price .product-price-rd-info").removeClass("hide");
						$(".delivery-option-price .product-price-rddetail").addClass("");
						$(".delivery-option-price .product-price-rd-info").addClass("");
						$("#rdBreak").removeClass("hide");
						$("#rdBreak").addClass("show");
					}

									}
				productDisplayJS.isRdProduct = true;
				if(dojo.byId("rd-option-container")!= null){
					dojo.removeClass("rd-option-container","hide");
					dojo.addClass("rd-option-container","show");
				}


							}
			else
			{
				productDisplayJS.isRdProduct = false;
				if(dojo.byId("rd-option-container")!= null){
					dojo.addClass("rd-option-container","hide");
					dojo.removeClass("rd-option-container","show");

										if($('#repeat-delivery-radio:checked.rd-option-input').length > 0){
						if($("input#store-pickup:checked").length == 0 && $("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 && $("#repeat-delivery-select-btn.paramStore-deliveryBox").length == 0 ){
							document.getElementById("one-time-delivery").click();
						}

											}

									}
				var rdPriceDiv =  dojo.byId('product-price-rd');
				if(document.getElementById('rdPriceDiv') != null && document.getElementById('rdPriceDiv') != undefined)
					rdPriceDiv.setAttribute("class","product-price hide");

				if($(".delivery-option-price .product-price-rddetail") != null && $(".delivery-option-price .product-price-rddetail") != undefined){
					$(".delivery-option-price .product-price-rddetail").removeClass("");
					$(".delivery-option-price .product-price-rd-info").removeClass("");
					$(".delivery-option-price .product-price-rddetail").addClass("hide");
					$(".delivery-option-price .product-price-rd-info").addClass("hide");
					$("#rdBreak").removeClass("show");
					$("#rdBreak").addClass("hide");
				}


							}

					if(undefined != document.getElementById('rd-item-price') ){
				var catEntry = productDisplayJS.itemPriceJsonOject[catentry_id].catalogEntry;
				if("" !=catEntry.listPrice ){
					var ltPrice = catEntry.listPrice.replace ('\$', '');
					var bestPrice = rdPrice.price.replace ('\$', '');
					if(parseFloat(bestPrice) < parseFloat(ltPrice) && document.getElementById("best-price") != null){
						document.getElementById("best-price").className = "best-price show";
					}
				}else if ("" != catEntry.offerPrice){
					var ofrPrice = catEntry.offerPrice.replace ('\$', '');
					var bestPrice = rdPrice.price.replace ('\$', '');
					if(parseFloat(bestPrice) < parseFloat(ofrPrice) && document.getElementById("best-price") != null){
						document.getElementById("best-price").className = "best-price show";
					}
				}
				if(rdPrice.price != null && rdPrice.price != undefined && rdPrice.price != 'Price pending'){
					innerHTML = '<span class="product-price-rdradio">'+ rdPrice.price+'</span>';
				}
				document.getElementById("rd-item-price").innerHTML = innerHTML;
			}
			if($('#rd-next-item-price .product-price-rdradio').length != 0){
				if(rdPrice.price != null && rdPrice.price != undefined && rdPrice.price != 'Price pending'){
					$('#rd-next-item-price .product-price-rdradio')[0].innerHTML = rdPrice.price;
				}
			}
			if(document.getElementById('itemOrProd') != null && document.getElementById('itemOrProd') != undefined){
				var itemOrProd = document.getElementById('itemOrProd').value;
				if(itemOrProd == 'Prod' && productDisplayJS.canSwapProductPriceDiv){
					var productPriceNormal = $('#pdp-product-info .product-price-normal');
					var productPriceRD = $('#pdp-product-info #product-price-rd');

										if(productPriceNormal != null && productPriceNormal != undefined && productPriceRD != null && productPriceRD != undefined){
						var productPriceNormalCopy = productPriceNormal.clone();
						var productPriceRDCopy = productPriceRD.clone();
							productPriceNormal.replaceWith(productPriceRDCopy);
						    productPriceRD.replaceWith(productPriceNormalCopy);
					}
				}
			}

						productDisplayJS.showRdSavings(catentry_id, productId);


							},
	showRdSavings:function(catentry_id, productId){
		if(typeof($("#offerPrice_"+catentry_id)) != "undefined" && typeof($("span#rdPriceDiv span.product-price-rddetail")) != "undefined" &&
				$("#offerPrice_"+catentry_id).length > 0 && $("span#rdPriceDiv span.product-price-rddetail").length > 0){
			var offerPrice = $("#offerPrice_"+catentry_id).html().replace("$","").replace(",","")*1;
			var rdPrice = $("span#rdPriceDiv span.product-price-rddetail").html().replace("$","").replace(",","")*1;
			var savingsPer = ((offerPrice-rdPrice)/offerPrice)*100;
			if(savingsPer > 5){
				var rdPriceDiv =  $('div#product-price-rd span#rdPriceDiv span#RDSavingsMessage');
				rdPriceDiv.html(MessageHelper.messages["RD_SAVINGS_MESSAGES"]);

			}
		}

			},
	palsRewardsPointsSubscriptionFired:false,
	toggleAddonButton : function(){
		if("R" == dojo.cookie("WC_UserType") ){
			var hasIos = false;
			require(["dojo/has", "dojo/sniff"], function(has){
				var iosVal = has("ios");
				  if(typeof(iosVal)!= "undefined" && iosVal != null && iosVal){
					hasIos=true;
				  }else{
					hasIos=false;
				  }
			});
			var nextRepeatedDeliveryDateCookie = "NEXT_REPEAT_DELIVERY_DATE";
			var  palsPointCookie = "PALS_POINTS";
			var nextRepeatedDeliveryDateCookieValue;
			if(typeof(localStorageHelper) != "undefined"){
				nextRepeatedDeliveryDateCookieValue = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);
			}
			palsPointCookieValue = localStorageHelper.get(palsPointCookie,hasIos);
			if (null != nextRepeatedDeliveryDateCookieValue
				&& nextRepeatedDeliveryDateCookieValue
				&& 	nextRepeatedDeliveryDateCookieValue != ""
				&&  nextRepeatedDeliveryDateCookieValue != 'none'
				&& 	nextRepeatedDeliveryDateCookieValue != 'paused') {
				var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
				var nextDate = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);
				var dateString = nextDate.replace(/-/g, '/')+" 00:00:00";
				var nextDateVal =  new Date(dateString);
				var isnotify = false;
				if(dojo.byId("notifybutton") != null){
					isnotify = dojo.hasClass(dojo.byId("notifybutton"),'show');
				}

								if($('#rd-add-on').length >0){
					if(!isnotify){
						document.getElementById("rd-add-on").className = "radio rd-option show";
					}else{
						document.getElementById("rd-add-on").className = "radio rd-option hide";
					}
				}else{
					if(!isnotify){
						if(!productDisplayJS.idAdobeCallDone){
							productDisplayJS.idAdobeCallDone=true;
							console.log("PDP RD AT = true");
							var adobeRDKey;
							var defaultAdobeValue="var_B"
							var isOBOSession = dojo.cookie('isOBOSession');
							var isEASession = dojo.cookie('isNewEASession');
							if(document.getElementById('atPDPRDModal')){
								adobeRDKey=document.getElementById('atPDPRDModal').value;
							}
							if(adobeRDKey !="" && adobeRDKey != "NA" && isOBOSession != "yes" && isEASession != "yes"){
								var adobeTargetCmdUrl =  "PetcoAdobeABTesting?storeId="+WCParamJS.storeId+"&catalogId="+WCParamJS.catalogId+"&langId="+WCParamJS.langId;
				            	var params={};
								params.targetTest=adobeRDKey;
								console.log("PDP RD AT........ key:"+adobeRDKey);
			            			$.ajax({
			                            url: adobeTargetCmdUrl,
			                            type: 'POST',
			                            cache: false,
			                            dataType: "text",
			                            data: params,
			                            success: function(data) {
			                            	console.log("PDP RD Success for key:"+adobeRDKey);
			                            	var filterData;
			                            	var resJson;
			                            	 try {
			                            		   var filterData = petcoCommonJS.dataFilter(data);
					                               var resJson = jQuery.parseJSON(filterData); 
			                            	    } catch(e) {
			                            	        console.log("PDP RD exception in parsing JSON setting default value"); 
				                					productDisplayJS.adobeRDVarValue=defaultAdobeValue;
				                					productDisplayJS.handleRDButtons(productDisplayJS.adobeRDVarValue);
			                            	    }

			                            				                        		if(resJson.adobeTargetResult != null){   
			                        			var option = resJson.adobeTargetResult.option;
			                        			if(typeof(option) == undefined || option == ""){
			                        				option=defaultAdobeValue;
			                        			}
			                        			productDisplayJS.adobeRDVarValue=option;
			                        			productDisplayJS.handleRDButtons(productDisplayJS.adobeRDVarValue);
			                            		Visitor.getInstance(resJson.adobeTargetResult.organizationId, {serverState: resJson.adobeTargetResult.visitorState});
			                				}else{
			                					productDisplayJS.adobeRDVarValue=defaultAdobeValue;
			                					productDisplayJS.handleRDButtons(productDisplayJS.adobeRDVarValue);
			                				}	 
			                            },
			                            error: function() {
			                            	productDisplayJS.adobeRDVarValue=defaultAdobeValue;
			                            	console.log("PDP RD Error response recived,setting value to : "+productDisplayJS.adobeRDVarValue);
			                            	productDisplayJS.handleRDButtons(productDisplayJS.adobeRDVarValue);
			                            } 
			            			});
						}else{
							console.log("PDP RD AT call not executed because of empty storeconf or cookie");
							productDisplayJS.adobeRDVarValue=defaultAdobeValue;
							productDisplayJS.handleRDButtons(productDisplayJS.adobeRDVarValue);
						}
					}
				else{
					console.log("PDP RD AT adobeRDVarValue = "+productDisplayJS.adobeRDVarValue);
					if(productDisplayJS.adobeRDVarValue != ""){
						productDisplayJS.handleRDButtons(productDisplayJS.adobeRDVarValue);
					}
				}

									}

							}
		var innerHTML = months[nextDateVal.getMonth()]+" "+nextDateVal.getDate()+", "+ nextDateVal.getFullYear();
		if(!isnotify && document.getElementById('rd-next-date') != null && typeof(months[nextDateVal.getMonth()]) != "undefined"){
			document.getElementById("rd-next-date").innerHTML = innerHTML;
		}
	}else{
		var aParams = [],
		bUpdateCookies = false,
		nextRepeatedDeliveryDateCookieValue = localStorageHelper.get(nextRepeatedDeliveryDateCookie,hasIos);
		if (dojo.cookie("WC_UserType")  === 'R') {
			aParams.langId = WCParamJS.langId;
			aParams.storeId = WCParamJS.storeId;
			aParams.catalogId = WCParamJS.catalogId;
			aParams.palsPointCookie = palsPointCookie;
			aParams.nextRepeatedDeliveryDateCookie = nextRepeatedDeliveryDateCookie;
			if(bUpdateCookies || palsPointCookieValue == null || nextRepeatedDeliveryDateCookieValue == null){
				aParams.updateCookies = true;
				if(!productDisplayJS.palsRewardsPointsSubscriptionFired){
					wc.service.invoke("AjaxPetcoPalsRewardsPointsSubscription",aParams);
					productDisplayJS.palsRewardsPointsSubscriptionFired = true;
				}
			}
		}
	}
	}
},


			handleRDButtons: function(AT_PDP_ADDTOCARTMODAL){
		var rdFlag = "";
		if (this.entitledItems != null && this.entitledItems != undefined) {
			for(x in this.entitledItems)
			{
				var catentry_id = this.entitledItems[x].catentry_id;
				if(catentry_id == this.selectedCatalogEntryId)
				{
					rdFlag = this.entitledItems[x].RepeatDeliveryFlag;
			 }
			}
		}
		var isRDSwitchFlag ;
		var isRDDisplay = false;
		if(undefined != document.getElementById('isRDSwitchFlag')){
			isRDSwitchFlag = document.getElementById("isRDSwitchFlag").value;
		}
		if(rdFlag.value != null && rdFlag.value != undefined && rdFlag.value == '0')
		{
			isRDDisplay = true;
		}
		var testFlow = AT_PDP_ADDTOCARTMODAL ? AT_PDP_ADDTOCARTMODAL : "var_B";
		console.log("PDP RD Switch:"+isRDSwitchFlag+" catID:"+this.selectedCatalogEntryId+" rdFlag:"+rdFlag.value+" adobeFlag:"+testFlow);
		if(testFlow == "var_A" || isRDSwitchFlag == "N" || !isRDDisplay){
			if($("#selectRDOrderBtnCpy").length > 0 ){
				$("#selectRDOrderBtnCpy").removeClass("hide");
			}
			if($(".noRdPdpDesign").length > 0 && $("#selectRDOrderBtnCpy").length > 0 && 
					!$("#selectRDOrderBtnCpy").hasClass("hide") && $("#selectRDOrderBtnCpy").css("display") != 'none'){
				if($("#petcoWishListForReg").length > 0 ){
					$("#petcoWishListForReg").addClass("hide");
				}
			}
			if($(".noRdPdpDesign").length > 0 && $("#selectRDOrderBtnAddTo").length > 0 && 
					!$("#selectRDOrderBtnAddTo").hasClass("hide") && $("#selectRDOrderBtnAddTo").css("display") != 'none'){
					$("#selectRDOrderBtnAddTo").addClass("hide");
			}
		} else {
			if($("#selectRDOrderBtnAddTo").length > 0 ){
				$("#selectRDOrderBtnAddTo").removeClass("hide");
			}
			if($(".noRdPdpDesign").length > 0 && $("#selectRDOrderBtnAddTo").length > 0 && 
					!$("#selectRDOrderBtnAddTo").hasClass("hide") && $("#selectRDOrderBtnAddTo").css("display") != 'none'){
				if($("#petcoWishListForReg").length > 0 ){
				}
			}
			if($(".noRdPdpDesign").length > 0 && $("#selectRDOrderBtnCpy").length > 0 && 
					!$("#selectRDOrderBtnCpy").hasClass("hide") && $("#selectRDOrderBtnCpy").css("display") != 'none'){
			}
		}
		buildAddonProductDataLayerVariable();

			},

	calculateSavings: function(catEntryId, productId){

		var rdPriceObject = productDisplayJS.entitledItems;
		var entitledItemIdsObject = document.getElementById("entitledItem_"+productId);
		var entitledItemIds = eval('('+entitledItemIdsObject.innerHTML +')');
		rdPriceObject = entitledItemIds;
		productDisplayJS.allItemPricesOject[catEntryId] = [];
		productDisplayJS.allItemPricesOject[catEntryId].catalogEntryIdentifier = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry.catalogEntryIdentifier;
		productDisplayJS.allItemPricesOject[catEntryId].listPrice = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry.listPrice;
		productDisplayJS.allItemPricesOject[catEntryId].offerPrice = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry.offerPrice;
		productDisplayJS.allItemPricesOject[catEntryId].listPriced = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry.listPriced;
		for(var i=0; i<rdPriceObject.length; i++){
			if(rdPriceObject[i].catentry_id == catEntryId){
				productDisplayJS.allItemPricesOject[catEntryId].RepeatDeliveryPrice = rdPriceObject[i].RepeatDeliveryPrice;
				productDisplayJS.allItemPricesOject[catEntryId].RepeatDeliveryFlag = rdPriceObject[i].RepeatDeliveryFlag;
			}
		}


		if(productDisplayJS.allItemPricesOject[catEntryId].listPriced
				&& productDisplayJS.allItemPricesOject[catEntryId].listPrice != ""){

			var listPrice = productDisplayJS.allItemPricesOject[catEntryId].listPrice.replace('$','');
			listPrice = parseFloat(listPrice.split(",").join(""));
			var offerPrice = productDisplayJS.allItemPricesOject[catEntryId].offerPrice.replace('$','');
			offerPrice = parseFloat(offerPrice.split(",").join(""));

			var savings = ((listPrice-offerPrice)*100)/listPrice;

			if(savings > 0 && savings < 100)
				productDisplayJS.allItemPricesOject[catEntryId].savingsOnOfferPrice = "save " +Math.round(savings) + "%";
			else
				productDisplayJS.allItemPricesOject[catEntryId].savingsOnOfferPrice = "";

		}else{
			productDisplayJS.allItemPricesOject[catEntryId].savingsOnOfferPrice = "";
		}

		if(productDisplayJS.allItemPricesOject[catEntryId].RepeatDeliveryPrice.price != ""
			&& productDisplayJS.allItemPricesOject[catEntryId].listPrice != ""
				){

			var listPrice = productDisplayJS.allItemPricesOject[catEntryId].listPrice.replace('$','');
			listPrice = parseFloat(listPrice.split(",").join(""));
			var rdPrice = productDisplayJS.allItemPricesOject[catEntryId].RepeatDeliveryPrice.price.replace('$','');
			rdPrice = parseFloat(rdPrice.split(",").join(""));

			var rdSavings = ((listPrice-rdPrice)*100)/listPrice;

			if(rdSavings > 0 && rdSavings < 100)
				productDisplayJS.allItemPricesOject[catEntryId].savingsOnRDPrice = "save " +Math.round(rdSavings) + "%";
			else
				productDisplayJS.allItemPricesOject[catEntryId].savingsOnRDPrice = "";

		}else{
			productDisplayJS.allItemPricesOject[catEntryId].savingsOnRDPrice = "";
		}

		console.log(productDisplayJS.allItemPricesOject);
	},

	displayPrice : function(catEntryId, productId){
		$('div#product-price-rd span#rdPriceDiv span#RDSavingsMessage').html("");
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
	var isBopusProduct = catEntry.isBopusProduct;
	var isFrozenProduct = catEntry.isFrozenProduct;
	var isSamedayProduct = catEntry.isSamedayProduct;
		var tempString;
		var popup = productDisplayJS.isPopup;
		var priceEnabled = document.getElementById('priceEnabled_'+productId);

		var inStoreSku = false;
		for (x in productDisplayJS.entitledItems) {
			var catId = productDisplayJS.entitledItems[x].catentry_id;
			if(catId == catEntryId){
				var inStore = productDisplayJS.entitledItems[x].InStoreOnly;
				if(inStore == 'true'){
					inStoreSku = true;
				}
			}
		}
		var omnitureFlag = document.getElementById('omnitureEnabled');
		var omnitureEnabled = '';
		if(omnitureFlag != null && omnitureFlag != "undefined"){
		 omnitureEnabled = omnitureFlag.value; }
		var deliveryMsgWrapperBopus = dojo.byId("splDelvMsgWrapperBopus");
		var priceDiv = dojo.byId("price_display_"+productId);
		var pdpProductAttributes = dojo.byId("pdp-product-attributes_id");
		var pdpProductFeatures = dojo.byId("pdp-product-features_id");
		var repeatDeliverySelect = dojo.byId("repeat-delivery-select-btn");
		var priceMatchGuarantee = dojo.byId("PriceMatchGuarantee");
		var pricestackpromo2 = dojo.byId("pricestack-promo-2");
		var pricestackpromo1 = dojo.byId("pricestack-promo-1");
		var atcControls = dojo.byId("add-to-controls");
		var inStoreNoticeBox = dojo.byId("inStoreNoticeBox");
		$("#repeat-delivery-select-btn").removeClass("instore-deliveryBox");
		$("#repeat-delivery-select-btn").removeClass("paramStore-deliveryBox");
		if(isSamedayProduct== 'yes'){
			$("span.selectableSamedaySection").addClass("show");
			$("span.selectableSamedaySection").removeClass("hide");
		}else{
			$("span.selectableSamedaySection").addClass("hide");
			$("span.selectableSamedaySection").removeClass("show");
		}

				if(!inStoreSku || (inStoreSku && isBopusProduct== 'yes' )){
			dojo.removeClass(dojo.byId("repeat-delivery-select-btn"),'hide');
			dojo.addClass(dojo.byId("repeat-delivery-select-btn"),'show');
			$("div#pdp_atc").addClass("show");
			$("div#pdp_atc").removeClass("hide");
			$(atcControls).css("display","block");
			if(inStoreSku && isBopusProduct== 'yes' && isFrozenProduct == 'yes'){
				$("#frozenFood").addClass("show");
				$("#frozenFood").removeClass("hide");
				$("span.selectableBopusSection").addClass("hide");
				$("span.selectableBopusSection").removeClass("show");
				$("div#pdp_atc").removeClass("show");
				$("div#pdp_atc").addClass("hide");
				$(atcControls).css("display","none");
			}else if(isBopusProduct== 'yes' && isFrozenProduct == 'no'){
				$("#frozenFood").addClass("hide");
				$("#frozenFood").removeClass("show");
				$("span.selectableBopusSection").addClass("show");
				$("span.selectableBopusSection").removeClass("hide");
			}
			if(pdpProductAttributes != null){
				dojo.removeClass(pdpProductAttributes,"hide");
				dojo.addClass(pdpProductAttributes,"show");
			}
			if(pdpProductFeatures != null){
				dojo.removeClass(pdpProductFeatures,"hide");
				dojo.addClass(pdpProductFeatures,"show");
			}
			if(priceMatchGuarantee != null && null != dojo.byId("pdp-persistent-container") && !dojo.hasClass("pdp-persistent-container","fixed")){
				dojo.addClass(priceMatchGuarantee,"show");
				dojo.removeClass(priceMatchGuarantee,"hide");
 			}

						if(pricestackpromo2 != null && null != dojo.byId("pdp-persistent-container") && !dojo.hasClass("pdp-persistent-container","fixed")){
				dojo.addClass(pricestackpromo2,"show");
				dojo.removeClass(pricestackpromo2,"hide");
 			}

						if(pricestackpromo1 != null && null != dojo.byId("pdp-persistent-container") && !dojo.hasClass("pdp-persistent-container","fixed")){
				dojo.addClass(pricestackpromo1,"show");
				dojo.removeClass(pricestackpromo1,"hide");
 			}


									if(inStoreNoticeBox != null){
				dojo.addClass(inStoreNoticeBox,"hide");
				dojo.removeClass(inStoreNoticeBox,"show");
			}
			if(null != atcControls){
				 dojo.style(atcControls, "display", "block");
			}
			if(null != priceDiv){
				 dojo.style(priceDiv, "display", "block");
			}
			 if(null != dojo.byId("inStoreMessaging")){
				 dojo.style("inStoreMessaging", "display", "none");
			 }

			 			 if(null != dojo.byId("inStoreMessagingforfixedscroll")){
				 dojo.removeClass(dojo.byId("inStoreMessagingforfixedscroll"),'showonscrollforinstock');
				 if(null != dojo.byId("pdp-persistent-container") && dojo.hasClass("pdp-persistent-container","fixed")){
						document.getElementById("inStoreMessagingforfixedscroll").classList.add("hide");
						document.getElementById("inStoreMessagingforfixedscroll").classList.remove("show");
					}
			 }


			if(dojo.byId("inStoreMessaging") != null && dojo.byId("repeat-delivery-select-btn") != null){
				dojo.place(dojo.byId("inStoreMessaging"),dojo.byId("repeat-delivery-select-btn"),'before');
				dojo.addClass(dojo.byId("repeat-delivery-select-btn"),'show');
				dojo.removeClass(dojo.byId("repeat-delivery-select-btn"),'hide');
			}


									if(popup == true){
				document.getElementById('productPrice').innerHTML = catEntry.offerPrice;				
			}
			if(popup == false){
				if(priceEnabled!='undefined' && priceEnabled!=null){
					if(priceEnabled.value == "true"){
						if(document.getElementById('product-price_'+productId) != null && document.getElementById('product-price_'+productId) != undefined)
							document.getElementById('product-price_'+productId).innerHTML = "See price in cart";
						return;
					}
				}
				var innerHTML = "";
				var listPrice = dojo.currency.parse(catEntry.listPrice,{symbol: productDisplayJS.currencySymbol});
				var offerPrice = dojo.currency.parse(catEntry.offerPrice,{symbol: productDisplayJS.currencySymbol});
				if(!catEntry.listPriced || listPrice <= offerPrice){

										innerHTML = "<span id='offerPrice_" + catEntry.catalogEntryIdentifier.uniqueID + "' class='product-price-promo'>" + catEntry.offerPrice + "</span>";
				}
				else{
					productDisplayJS.calculateSavings(catEntryId, productId);					
					if(catEntry.listPrice != ""){
						innerHTML = "<span id='offerPrice_" + catEntry.catalogEntryIdentifier.uniqueID + "' class='product-price-promo'>" + catEntry.offerPrice + "</span>"
						+ "&nbsp;<span id='listPrice_" + catEntry.catalogEntryIdentifier.uniqueID + "' class='product-price-crossout'>" + "was " + catEntry.listPrice + "</span>"
						+ "&nbsp;<span id='savings_" +catEntry.catalogEntryIdentifier.uniqueID + "' class='product-price-save'>&nbsp;"
						+productDisplayJS.allItemPricesOject[catEntry.catalogEntryIdentifier.uniqueID].savingsOnOfferPrice +"</span>";
						$("#PriceStack-RedPrice").removeClass("hide");
					}else{

												innerHTML = "<span id='offerPrice_" + catEntry.catalogEntryIdentifier.uniqueID + "' class='product-price-promo'>" + catEntry.offerPrice + "</span>"
						+ "&nbsp;<span id='savings_" +catEntry.catalogEntryIdentifier.uniqueID + "' class='product-price-save'>"
						+productDisplayJS.allItemPricesOject[catEntry.catalogEntryIdentifier.uniqueID].savingsOnOfferPrice +"</span>";
					}


														}
				if(document.getElementById('product-price_'+productId) != null && document.getElementById('product-price_'+productId) != undefined){

										document.getElementById('product-price_'+productId).innerHTML = innerHTML
					+ "<input type='hidden' id='ProductInfoPrice_" + catEntry.catalogEntryIdentifier.uniqueID + "' value='" + catEntry.offerPrice.replace(/"/g, "&#034;").replace(/'/g, "&#039;") + "'/>";

									}
				innerHTML = "";
				productDisplayJS.allItemPricesOject[catEntry.catalogEntryIdentifier.uniqueID].RepeatDeliveryPrice.price = catEntry.rdPrice;

								productDisplayJS.displayRDPrice(catEntry.catalogEntryIdentifier.uniqueID,productId,
						productDisplayJS.allItemPricesOject[catEntry.catalogEntryIdentifier.uniqueID].RepeatDeliveryFlag,
						productDisplayJS.allItemPricesOject[catEntry.catalogEntryIdentifier.uniqueID].RepeatDeliveryPrice,
						productDisplayJS.allItemPricesOject[catEntry.catalogEntryIdentifier.uniqueID].savingsOnRDPrice);

				if(productDisplayJS.displayPriceRange == true){
					for(var i in catEntry.priceRange){
						if(catEntry.priceRange[i].endingNumberOfUnits == catEntry.priceRange[i].startingNumberOfUnits){
							tempString = storeNLS['PQ_PRICE_X'];
							innerHTML = innerHTML + "<p>" + dojo.string.substitute(tempString,{0: catEntry.priceRange[i].startingNumberOfUnits});
						}
						else if(catEntry.priceRange[i].endingNumberOfUnits != 'null'){
							tempString = storeNLS['PQ_PRICE_X_TO_Y'];
							innerHTML = innerHTML + "<p>" + dojo.string.substitute(tempString,{0: catEntry.priceRange[i].startingNumberOfUnits,
								1: catEntry.priceRange[i].endingNumberOfUnits});
						}
						else{
							tempString = storeNLS['PQ_PRICE_X_OR_MORE'];
							innerHTML = innerHTML + "<p>" + dojo.string.substitute(tempString,{0: catEntry.priceRange[i].startingNumberOfUnits});
						}
						innerHTML = innerHTML + " <span class='price'>" + catEntry.priceRange[i].localizedPrice + "</span></p>";
					}
				}
				if(dojo.byId("ProductInfoPrice_"+catEntry.catalogEntryIdentifier.uniqueID)!=null){
					var itemId=catEntry.catalogEntryIdentifier.uniqueID;
					if(dojo.byId("ProductInfoPrice_"+itemId).value==''){
						if(dojo.byId("offerPrice_"+itemId)!=null)
							dojo.byId("offerPrice_"+itemId).innerHTML='Price pending';
					}
				}
				var quantityDiscount = dojo.byId("productLevelPriceRange_"+productId);
				var itemQuantityDiscount = dojo.byId("itemLevelPriceRange_"+productId);

				if(null != quantityDiscount && null == itemQuantityDiscount){
					dojo.style(quantityDiscount, "display", ""); 
				}
				else if("" != innerHTML && null != itemQuantityDiscount){
					innerHTML = storeNLS['PQ_PURCHASE'] + innerHTML;
					itemQuantityDiscount.innerHTML = innerHTML;
					dojo.style(itemQuantityDiscount, "display", "");
					if(null != quantityDiscount){
						dojo.style(quantityDiscount, "display", "none");
					}
				}
				else if("" == innerHTML){
					if(null != itemQuantityDiscount){
						dojo.style(itemQuantityDiscount, "display", "none"); 
					}
					if(null != quantityDiscount){
						dojo.style(quantityDiscount, "display", ""); 
					}
				}
			}
			 if(null != dojo.byId("deliveryMsgWrapperBopus")){
				 dojo.style("deliveryMsgWrapperBopus","display","block");
			 }
			 if(null != dojo.byId("shipRestrictionMsg")){
					document.getElementById("shipRestrictionMsg").classList.add("show");
					document.getElementById("shipRestrictionMsg").classList.remove("hide");
				}

						if(inStoreSku){
				if(isSamedayProduct != 'yes'){
					$("#repeat-delivery-select-btn").addClass("instore-deliveryBox");
				}
				if(null != dojo.byId("deliveryDateMsg")){
					dojo.style("deliveryDateMsg","display","none");
				}
				if(null != dojo.byId("ShippingMessage")){
					dojo.style("ShippingMessage","display","none");
				}
				if(null != dojo.byId("shipRestrictionMsg")){
					document.getElementById("shipRestrictionMsg").classList.add("hide");
					document.getElementById("shipRestrictionMsg").classList.remove("show");
				}
				if($("#store-pickup").length > 0){
					$("#store-pickup")[0].click();
					if(null != atcControls){
						 dojo.style(atcControls, "display", "block");
					}
				}else{
					if(null != atcControls){
						 dojo.style(atcControls, "display", "none");
				}
				}
				if(null != priceDiv){
					if(inStoreSku && isBopusProduct=='yes'){
						if(dojo.byId("product-price-rd") != null)
							dojo.addClass(dojo.byId("product-price-rd"),'hide');
					}else if(inStoreSku)
						dojo.style(priceDiv, "display", "none");
				}
				if(dojo.byId("inStoreMessaging") != null && dojo.byId("repeat-delivery-select-btn") != null){
					dojo.place(dojo.byId("inStoreMessaging"),dojo.byId("repeat-delivery-select-btn"),'before');
					if(dojo.byId("pdp-product-features_id")!= null && dojo.query("div#inStoreMessaging p.notice-box").length > 0){
						dojo.place(dojo.query("div#inStoreMessaging p.notice-box")[0],dojo.byId("pdp-promo-1"),"before");
					}
				}


												 if(null != dojo.byId("notifyMe"))
					 dojo.style("notifyMe","display","none");
				 if(null != dojo.byId("splDelvMsg"))
					 dojo.style("splDelvMsg","display","none");
				 if(null != dojo.byId("deliveryMsgWrapperBopus"))
					 dojo.style("deliveryMsgWrapperBopus","display","none");



				 				 				 			} 	
		}
		else{ 
			if(dojo.byId("inStoreMessaging") != null && dojo.byId("repeat-delivery-select-btn") != null){

								dojo.place(dojo.byId("inStoreMessaging"),dojo.byId("repeat-delivery-select-btn"),'before');
				dojo.style("inStoreMessaging", "display", "block");
				if(dojo.byId("pdp-product-features_id")!= null && dojo.query("div#inStoreMessaging p.notice-box").length > 0){
					dojo.place(dojo.query("div#inStoreMessaging p.notice-box")[0],dojo.byId("pdp-promo-1"),"before");
				}	
				dojo.addClass(dojo.byId("repeat-delivery-select-btn"),'hide');
				dojo.removeClass(dojo.byId("repeat-delivery-select-btn"),'show');
			}
			if(pdpProductAttributes != null){
				dojo.removeClass(pdpProductAttributes,"hide");
				dojo.addClass(pdpProductAttributes,"show");
			}
			if(pdpProductFeatures != null){
				dojo.removeClass(pdpProductFeatures,"hide");
				dojo.addClass(pdpProductFeatures,"show");
			}
			if(null != priceDiv){
				 dojo.style(priceDiv, "display", "none");
			}
			if(priceMatchGuarantee != null){
				dojo.addClass(priceMatchGuarantee,"hide");
				dojo.removeClass(priceMatchGuarantee,"show");
			}

						if(pricestackpromo2 != null){
				dojo.addClass(pricestackpromo2,"hide");
				dojo.removeClass(pricestackpromo2,"show");
			}

						if(pricestackpromo1 != null){
				dojo.addClass(pricestackpromo1,"hide");
				dojo.removeClass(pricestackpromo1,"show");
			}

						if(null != atcControls){
				 dojo.style(atcControls, "display", "none");
			}
			if(null != dojo.byId("shipRestrictionMsg")){
				document.getElementById("shipRestrictionMsg").classList.add("hide");
				document.getElementById("shipRestrictionMsg").classList.remove("show");
			}


						if(dojo.byId("pdp-persistent-container") != null && !dojo.hasClass("pdp-persistent-container","fixed") && inStoreNoticeBox != null){
				dojo.addClass(inStoreNoticeBox,"show");
				dojo.removeClass(inStoreNoticeBox,"hide");
			}

						var productAvailabilityDivs = dojo.query(".product-availability");
			if(productAvailabilityDivs.length > 0) {
				var productAvailabilityDiv = productAvailabilityDivs[0];
				dojo.style(productAvailabilityDiv, "display", "none");
			}
			var attributeInputDivs = dojo.query(".pdp-product-attributes");
			if(attributeInputDivs.length > 0) {
				var attributeInputDiv = attributeInputDivs[0];
				dojo.style(attributeInputDiv, "display", "none");
			}

						 if(null != dojo.byId("inStoreMessagingforfixedscroll")){
				 dojo.addClass(dojo.byId("inStoreMessagingforfixedscroll"),'showonscrollforinstock');
				 if(null != dojo.byId("pdp-persistent-container") && dojo.hasClass("pdp-persistent-container","fixed")){
						document.getElementById("inStoreMessagingforfixedscroll").classList.add("show");
						document.getElementById("inStoreMessagingforfixedscroll").classList.remove("hide");
					}
			 }


			 if(null != dojo.byId("add-to-controls"))
				 dojo.style("add-to-controls","display","none");
			 if(null != dojo.byId("notifyMe"))
				 dojo.style("notifyMe","display","none");
			 if(null != dojo.byId("pdp_atc"))
				 dojo.style("pdp_atc","display","none");
			 if(null != dojo.byId("splDelvMsg"))
				 dojo.style("splDelvMsg","display","none");

			 		}
		if(typeof(petcoCommonJS) != 'undefined' ){petcoCommonJS.clearPromotionLessThanZero()}

		if(undefined != document.getElementById('item-price') ){
			var catEntryOfferPrice = catEntry.offerPrice;
			if(catEntryOfferPrice.indexOf("$") < 0){
				catEntryOfferPrice = "$"+catEntryOfferPrice;
			}
			if(catEntry.listPrice != ""){
				innerHTML = "<strong>"+ catEntryOfferPrice+"</strong>";
			}else{
				innerHTML = "<strong>"+ catEntryOfferPrice+"</strong>";
			}
			document.getElementById("item-price").innerHTML = innerHTML;


								}
		if(undefined != document.getElementById('item-price-sameday') ){
			var catEntryOfferPrice = catEntry.offerPrice;
			if(catEntryOfferPrice.indexOf("$") < 0){
				catEntryOfferPrice = "$"+catEntryOfferPrice;
			} 
			innerHTML = "<strong>"+ catEntryOfferPrice+"</strong>";
			document.getElementById("item-price-sameday").innerHTML = innerHTML;


								}

				if(undefined != document.getElementById('item-price-bopus') ){

						innerHTML = "<strong>"+ catEntry.bopusPrice +"</strong>";
			document.getElementById("item-price-bopus").innerHTML = innerHTML;
		}
		productDisplayJS.selectedCatalogEntryId = catEntry.catalogEntryIdentifier.uniqueID;
	},updateTaggingVal:function(catEntryId, productId){
		try{
			var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
			var partnumber  = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
			$("#customerReviewPannel").attr("manual_cm_sp","pdp-_-replacementsku-_-rating-_-"+partnumber);
			$("#customerReviewPannelStar").attr("manual_cm_sp","pdp-_-replacementsku-_-rating-_-"+partnumber);
			$("#notifybutton").attr("manual_cm_sp","pdp-_-replacementsku-_-notifyme-_-"+partnumber);
			$("#add2CartBtn_cpy1").attr("manual_cm_sp","pdp-_-replacementsku-_-addtocart-_-"+partnumber);
			$("#viewDetailsReplacement").attr("manual_cm_sp","pdp-_-replacementsku-_-viewdetails-_-"+partnumber);
		}catch(err){
			console.log(err);
		}


					},updateViewDetailsLink:function(catEntryId, productId){
		var viewDetailLink = '#catEntryDisplayUrl_'+ catEntryId;
		$("#viewDetailsReplacement").attr("href",$(viewDetailLink).val());
	},addBOPUSDetailsForInstock : function(stateVal){
		if($("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 && $("#repeat-delivery-select-btn.paramStore-deliveryBox").length == 0){
			return;
		}
		var catEntryId = productDisplayJS.bopusShowStoreDetailsCatEntryId;
		var productId = 	productDisplayJS.bopusShowStoreDetailsProductId;
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
		var isBopusProduct = catEntry.isBopusProduct;

				var isBOPUSAreaRestictionItem = catEntry.isBOPUSAreaExcludedItem;
		var isBOPUSAreaRestictionEnabeled = false;
		$("#PDP-Promo5-bopus").addClass("hide");
		if($("#isBOPUSAreaRestictionEnabeled").length > 0 && $("#isBOPUSAreaRestictionEnabeled").val() == 'true'
			&& isBOPUSAreaRestictionItem.toLowerCase() == 'yes' && isBopusProduct.toLowerCase() == 'yes'){
			var states = "";
			if($("#BOPUSAreaRestictionState").length > 0){
				states = $("#BOPUSAreaRestictionState").val();
				var stateArray = states.split(",");
				for(var ix = 0 ; ix < stateArray.length ; ix++){
					if(stateArray[ix] == stateVal){
						hidePersistentAddToCart();
						isBOPUSAreaRestictionEnabeled = true;
						$("#BOPUSDeliveryDetailsConatiner").addClass("hide");
						$("#BOPUSDeliveryDetailsConatiner").removeClass("show");
						$("#PDP-Promo5-bopus").removeClass("hide");
						if($('#add2CartBtn_bopus').length > 0){
							$('#add2CartBtn_bopus')[0].innerHTML="Not Available";
							$('#add2CartBtn_bopus')[0].disabled = true;
							if($("#store-pickup").length > 0){
								$("#store-pickup")[0].click();
							}
						}

						}
				}
			}

					}


						var tempString;

			var popup = productDisplayJS.isPopup;
		var priceEnabled = document.getElementById('priceEnabled_'+productId);

		var inStoreSku = false;
		for (x in productDisplayJS.entitledItems) {
			var catId = productDisplayJS.entitledItems[x].catentry_id;
			if(catId == catEntryId){
				var inStore = productDisplayJS.entitledItems[x].InStoreOnly;
				if(inStore == 'true'){
					inStoreSku = true;
				}
			}
		}
		var omnitureFlag = document.getElementById('omnitureEnabled');
		var omnitureEnabled = '';
		if(omnitureFlag != null && omnitureFlag != "undefined"){
		 omnitureEnabled = omnitureFlag.value; }

					var priceDiv = dojo.byId("price_display_"+productId);
		var pdpProductAttributes = dojo.byId("pdp-product-attributes_id");
		var pdpProductFeatures = dojo.byId("pdp-product-features_id");
		var repeatDeliverySelect = dojo.byId("repeat-delivery-select-btn");
		var priceMatchGuarantee = dojo.byId("PriceMatchGuarantee");
		var pricestackpromo2 = dojo.byId("pricestack-promo-2");
		var pricestackpromo1 = dojo.byId("pricestack-promo-1");
		var atcControls = dojo.byId("add-to-controls");
		var inStoreNoticeBox = dojo.byId("inStoreNoticeBox");
			if(dojo.byId("inStoreMessaging") != null && dojo.byId("repeat-delivery-select-btn") != null){

								dojo.place(dojo.byId("inStoreMessaging"),dojo.byId("repeat-delivery-select-btn"),'before');
				dojo.style("inStoreMessaging", "display", "block");
				if(dojo.byId("pdp-product-features_id")!= null && dojo.query("div#inStoreMessaging p.notice-box").length > 0){
					dojo.place(dojo.query("div#inStoreMessaging p.notice-box")[0],dojo.byId("pdp-promo-1"),"before");
				}				
			}
			if(pdpProductAttributes != null){
				dojo.removeClass(pdpProductAttributes,"hide");
				dojo.addClass(pdpProductAttributes,"show");
			}
			if(pdpProductFeatures != null){
				dojo.removeClass(pdpProductFeatures,"hide");
				dojo.addClass(pdpProductFeatures,"show");
			}
			if(null != priceDiv){
				 dojo.style(priceDiv, "display", "none");
			}
			if(priceMatchGuarantee != null){
				dojo.addClass(priceMatchGuarantee,"hide");
				dojo.removeClass(priceMatchGuarantee,"show");
			}

						if(pricestackpromo1 != null){
				dojo.addClass(pricestackpromo1,"hide");
				dojo.removeClass(pricestackpromo1,"show");
			}

						if(pricestackpromo2 != null){
				dojo.addClass(pricestackpromo2,"hide");
				dojo.removeClass(pricestackpromo2,"show");
			}


								if(null != atcControls){
				 dojo.style(atcControls, "display", "none");
			}


									if(dojo.byId("pdp-persistent-container") != null && !dojo.hasClass("pdp-persistent-container","fixed") && inStoreNoticeBox != null){

				if(inStoreSku && isBopusProduct.toLowerCase() != 'yes' ){
					dojo.addClass(inStoreNoticeBox,"show");
					dojo.removeClass(inStoreNoticeBox,"hide");
				}				
			}

						var productAvailabilityDivs = dojo.query(".product-availability");
			if(productAvailabilityDivs.length > 0) {
				var productAvailabilityDiv = productAvailabilityDivs[0];
				dojo.style(productAvailabilityDiv, "display", "none");
			}
			var attributeInputDivs = dojo.query(".pdp-product-attributes");
			if(attributeInputDivs.length > 0) {
				var attributeInputDiv = attributeInputDivs[0];
				dojo.style(attributeInputDiv, "display", "none");
			}

						 if(null != dojo.byId("inStoreMessagingforfixedscroll")){
				 dojo.addClass(dojo.byId("inStoreMessagingforfixedscroll"),'showonscrollforinstock');
				 if(null != dojo.byId("pdp-persistent-container") && dojo.hasClass("pdp-persistent-container","fixed")){
						document.getElementById("inStoreMessagingforfixedscroll").classList.add("show");
						document.getElementById("inStoreMessagingforfixedscroll").classList.remove("hide");
					}
			 }




			 if(null != dojo.byId("add-to-controls"))
				 dojo.style("add-to-controls","display","none");
			 if(null != dojo.byId("notifyMe"))
				 dojo.style("notifyMe","display","none");
			 if(null != dojo.byId("pdp_atc"))
				 dojo.style("pdp_atc","display","none");
			 if(null != dojo.byId("splDelvMsg"))
				 dojo.style("splDelvMsg","display","none");

			},
	bopusShowStoreDetailsCatEntryId:"",
	bopusShowStoreDetailsProductId:"",
	definngaAttributeResolved:false,
	bopusShowStoreDetails:function(catEntryId, productId){
		productDisplayJS.bopusShowStoreDetailsCatEntryId = catEntryId;
		productDisplayJS.bopusShowStoreDetailsProductId = productId;
		if(dojo.byId("catEntryIdForBOPUSModal") != null){
			dojo.byId("catEntryIdForBOPUSModal").value = catEntryId;
		}
		productDisplayJS.definngaAttributeResolved = true;
		var inStoreSku = false;
		for (x in productDisplayJS.entitledItems) {
			var catId = productDisplayJS.entitledItems[x].catentry_id;
			if(catId == catEntryId){
				var inStore = productDisplayJS.entitledItems[x].InStoreOnly;
				if(inStore == 'true'){
					inStoreSku = true;
				}
			}
		}

					var BOPUSDeliveryDetailsConatiner = dojo.byId("BOPUSDeliveryDetailsConatiner");
			var isBOPUSHideEle = dojo.byId("isBOPUSHide");
			var isBOPUSHide = true;
			if(isBOPUSHideEle != null && isBOPUSHideEle.value == "no"){
				isBOPUSHide = false;
			}			
			if(productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry.isBopusProduct == 'yes' && !isBOPUSHide){
				if(BOPUSDeliveryDetailsConatiner !=null){
						dojo.removeClass(BOPUSDeliveryDetailsConatiner,"hide");
						dojo.addClass(BOPUSDeliveryDetailsConatiner,"show");
						if(window.location.search.toLowerCase().indexOf("lia") !== -1){
							$("#BOPUSDeliveryDetailsConatiner").insertBefore($(".noRdPdpDesign:not(.store-pickup)"));
						}
				}
			}else{
				if(!inStoreSku){
				if(BOPUSDeliveryDetailsConatiner !=null){
						dojo.removeClass(BOPUSDeliveryDetailsConatiner,"show");
						dojo.addClass(BOPUSDeliveryDetailsConatiner,"hide");
						if($('#store-pickup:checked.store-pickup-option-input').length > 0){
							document.getElementById("one-time-delivery").click();
						}
				}				
			}
		}
			var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
			var isBOPUSAreaRestictionItem = catEntry.isBOPUSAreaExcludedItem;
			var isBopusProductTemp =  catEntry.isBopusProduct;
			var isBOPUSAreaRestictionEnabeled = false;
			var priceDiv = $("#price_display_"+productId);
			if(priceDiv.length > 0 && $(priceDiv).attr("bopusrestrictedhide") == 'true' && $(priceDiv).css("display") == 'none'){
				$(priceDiv).css("display","block");
				$(priceDiv).removeAttr("bopusrestrictedhide");
			}
			var stateVal = "";
			if($('#CurrentBOPUSState').length > 0){
				stateVal = $('#CurrentBOPUSState').val();
			}
			$("#PDP-Promo5-bopus").addClass("hide");
			if($("#isBOPUSAreaRestictionEnabeled").length > 0 && $("#isBOPUSAreaRestictionEnabeled").val() == 'true'
				&& isBOPUSAreaRestictionItem.toLowerCase() == 'yes' && isBopusProductTemp.toLowerCase() == 'yes'){
				$("#BOPUSAreaRestictionEnabeledItem").val('yes');
				var states = "";
				if($("#BOPUSAreaRestictionState").length > 0){
					states = $("#BOPUSAreaRestictionState").val();
					var stateArray = states.split(",");
					for(var ix = 0 ; ix < stateArray.length ; ix++){
						if(stateArray[ix] == stateVal){
							isBOPUSAreaRestictionEnabeled = true;
							if(priceDiv.length > 0){
								$(priceDiv).css("display","none");
								$(priceDiv).attr("bopusrestrictedhide","true");
							}
							$("#BOPUSDeliveryDetailsConatiner").addClass("hide");
							$("#BOPUSDeliveryDetailsConatiner").removeClass("show");
							$("#PDP-Promo5-bopus").removeClass("hide");
							if($('#add2CartBtn_bopus').length > 0){
								$('#add2CartBtn_bopus')[0].innerHTML="Not Available";
								$('#add2CartBtn_bopus')[0].disabled = true;
								if($("#store-pickup").length > 0){
									$("#store-pickup")[0].click();
								}

															}


																}
					}
				}

							}
	},

	updateProductName: function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;	 

	 		if(productDisplayJS.isPopup == true){
			document.getElementById('productName').innerHTML = catEntry.description[0].name;
		} else {	 
			if(dojo.query(".top > div[id^='PageHeading_']") != null){
				dojo.query(".top > div[id^='PageHeading_']").forEach(function(node){
					if(node.childNodes != null && node.childNodes.length == 3){
						node.childNodes[1].innerHTML = catEntry.description[0].name;
					}
				});		
			}
			if($("#replacementProductName_"+productId).length > 0 ){
				$("#replacementProductName_"+productId).html(catEntry.description[0].name);
			}
			if($("#fixed-product-name_"+productId).length > 0 ){
				$("#fixed-product-name_"+productId).html(catEntry.description[0].name);
			}
			var productInfoWidgets = dojo.query("input[id^='ProductInfoName_"+productId+"']");
			if(productInfoWidgets != null){
				for(var i = 0; i<productInfoWidgets.length; i++){				
					if(productInfoWidgets[i] != null){
						productInfoWidgets[i].value = catEntry.description[0].name;
					}
				}
			}
			if(dojo.query("div[id^='PageHeading_']") != null){
				dojo.query("div[id^='PageHeading_']").forEach(function(node){
					if(node.childNodes != null && node.childNodes.length == 3){
						node.childNodes[1].innerHTML = catEntry.description[0].name;
					}
				});
			}
		}
	},

	updateProductPartNumber: function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;	 
		var attrCount =0;
		var ptealium = getCookie("ptealiumData");
		if(ptealium != undefined && ptealium != null && ptealium != ""){
	    	var ptealiumArray = ptealium.split("|");
	    	if(ptealiumArray.length == 1){
	    		ptealium = decodeURIComponent(getCookie("pcustData"));
	    		ptealiumArray = ptealium.split("|");
	    	}
	    	if(document.getElementById('NotifyName')!=null){
	    		document.getElementById('NotifyName').value= ptealiumArray[0]+" "+ptealiumArray[1];
	    	}
	    	if(document.getElementById('NotifyEmail')!=null){
	    		document.getElementById('NotifyEmail').value= ptealiumArray[2];
	    	}
	    	if(document.getElementById('sender-name')!=null){
	    		document.getElementById('sender-name').value= ptealiumArray[0]+" "+ptealiumArray[1];
	    	}
	    	if(document.getElementById('sender-email')!=null){
	    		document.getElementById('sender-email').value= ptealiumArray[2];
	    	}
	    }
		if(productDisplayJS.isPopup == true){
			document.getElementById('productSKUValue').innerHTML = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;

		} else {
			var partnumWidgets = dojo.query("div[id^='product_SKU_"+productId+"']");

			var tel_product_sku = new Array();
			var product_sku ="";
			if(partnumWidgets != null){
				for(var i = 0; i<partnumWidgets.length; i++){
					if(partnumWidgets[i]){
						partnumWidgets[i].innerHTML = storeNLS['SKU'] + " " + catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
						product_sku = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;						
					}
				}
			}
		}

			 },

	 	 updateLtlItemOncHange:function(catEntryId, productId){
		 var iscallMade = false;
		 var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;	
		 if(catEntry.isPersonalysedProduct == 'yes'){

								if($("#isLTLDeliveryRequired").length > 0){
					$("#isLTLDeliveryRequired").addClass("show");
					$("#isLTLDeliveryRequired").removeClass("hide");
					$("#personalization").val("true");
					$("#ltlproduct").val(catEntryId);
					$("#isltlproduct").val("true");
					var ltlorRxflagCatentryId="";
					if( $("#ltlproduct").length > 0){
	                	ltlorRxflagCatentryId = $("#ltlproduct").val();
	                }
					CheckoutHelperJS.isshowpersonalizationPopup = false;
					console.log("After Context catEntryId-1-dc-5630"+catEntryId);
				  wc.render.updateContext('personilaztionDisplayContext',
			                    {'quantity':$("#quantity_"+productId).val(),
			                    'persAttr':petcoPersonalizationJS.findPersonalizedAttr($("#quantity_"+productId).val()),
			                    'maxUserInputQuantity':petcoPersonalizationJS.findMaxUserInputQuantity(),
								"ltlproduct":ltlorRxflagCatentryId,
								"catentryId":catEntryId,
								"catentryIdParent":productId}
			                );
				  iscallMade = true;

									}

							}else if(catEntry.isPersonalysedProduct == 'no'){
				if($("#isLTLDeliveryRequired").length > 0){
					$("#isLTLDeliveryRequired").addClass("hide");
					$("#isLTLDeliveryRequired").removeClass("show");
					$("#personalization").val("false");
					$("#ltlproduct").val("");
					$("#isltlproduct").val("false");
				}
			}
		 var personalization = $("#personalization");
		 var rxmedicine = $("#rxMedicine");
		 if(!iscallMade && personalization.length > 0 && rxmedicine.length > 0 && rxmedicine.val() == "true" && personalization.val() == "true"){
				setTimeout(function(){
					$("#quantity_"+productId).trigger("change");
			},300);
		 }



		 		 		 	 },
	 displayAttributeInfo: function(catEntryId, productId){
	   if(document.getElementById("product_attributes_"+catEntryId)!=null) {
		   var attrLists = document.querySelectorAll(".product-attributes-table");
		   for (var i=0; i<attrLists.length; i++) {
			   attrLists[i].classList.add("hide");
		   }
		   document.getElementById("product_attributes_"+catEntryId).classList.remove("hide");
	   }
	   if(document.getElementById('attrCount')!=null){
				attrCount=document.getElementById('attrCount').value;
				for(var i=1;i<=attrCount;i++){
					if(document.getElementById('catId_'+i)!=null){
						var fagValue = document.getElementById('catId_'+i).value;
						if(fagValue == catEntryId){
							if(document.getElementById("attrGroupSpecFlag_"+catEntryId)!=null)
								document.getElementById("attrGroupSpec_"+catEntryId).classList.add("active");
							if(document.getElementById("directionsFlag_"+fagValue)!=null)
								document.getElementById("directionsDisp_"+fagValue).classList.add("active");
							if(document.getElementById("warrantyFlag_"+fagValue)!=null)
								document.getElementById("warrantyDisp_"+fagValue).classList.add("active");

							if(document.getElementById("ingredientsDispFlag_"+fagValue)!=null)
								document.getElementById("ingredientsDisp_"+fagValue).classList.add("active");

							if(document.getElementById("guaranteDispFlag_"+fagValue)!=null)
								document.getElementById("guaranteDispFlag_"+fagValue).classList.add("active");

							if(document.getElementById("featuredCntntFlag_"+fagValue)!=null)
								document.getElementById("featuredDisp_"+fagValue).classList.add("active");

							if(document.getElementById("shippingReturns_"+fagValue)!=null)
								document.getElementById("shippingReturns_"+fagValue).classList.add("active");

						}
						else{
							if(document.getElementById("attrGroupSpec_"+fagValue)!=null)
								document.getElementById("attrGroupSpec_"+fagValue).classList.remove("active");
							if(document.getElementById("directionsDisp_"+fagValue)!=null)
								document.getElementById("directionsDisp_"+fagValue).classList.remove("active");
							if(document.getElementById("directionsFlag_"+fagValue)!=null)
								document.getElementById("directionsDisp_"+fagValue).classList.remove("active");
							if(document.getElementById("warrantyFlag_"+fagValue)!=null)
								document.getElementById("warrantyDisp_"+fagValue).classList.remove("active");

							if(document.getElementById("ingredientsDispFlag_"+fagValue)!=null)
								document.getElementById("ingredientsDisp_"+fagValue).classList.remove("active");

							if(document.getElementById("guaranteDispFlag_"+fagValue)!=null)
								document.getElementById("guaranteDispFlag_"+fagValue).classList.remove("active");

							if(document.getElementById("featuredCntntFlag_"+fagValue)!=null)
								document.getElementById("featuredDisp_"+fagValue).classList.remove("active");

							if(document.getElementById("shippingReturns_"+fagValue)!=null)
								document.getElementById("shippingReturns_"+fagValue).classList.remove("active");
						}


					}
				}

				if (document.querySelectorAll(".panel-heading.active")) {
					$(".panel-heading").removeClass("selected");
					document.querySelectorAll(".panel-heading.active")[0].classList.add("selected");

					var allPanels = document.querySelectorAll(".panel-heading"),
					 openID = document.querySelectorAll(".panel-heading.active.selected")[0].id,
					 openPanel = document.querySelectorAll("." + openID);


					for (var j=0; j < allPanels.length; j++){
						var current = allPanels[j];
						if (current && current.id) {
							$('.' + current.id).removeClass('open');
						}
						if (current.classList.contains('active')) {

							current.onclick = function(e){
								var isSelected = this.classList.contains("selected");
								var isMobile = window.matchMedia( "(max-width: 768px)" ).matches;

								if (!isSelected) {
									var selectedHeadings = document.querySelectorAll(".panel-heading.selected");
									if (selectedHeadings.length > 0) {
										selectedHeadings[0].classList.remove("selected");
										document.querySelectorAll(".panel.open")[0].classList.remove("open");
									}
									this.classList.add("selected");
									document.querySelectorAll("."+this.id)[0].classList.add("open");

									if (isMobile) {
										$('html, body').animate({
											scrollTop: $("#"+this.id).offset().top - $('#header').height()
										}, 300);
									}
								} else {
									if (isMobile && isSelected) {
										this.classList.remove("selected");
										document.querySelectorAll("."+this.id)[0].classList.remove("open");
									}
								}
							}
						}
					}
					openPanel[0].classList.add("open");

					$(window).on("orientationchange",function(event){
						var isMobile = window.matchMedia( "(max-width: 768px)" ).matches;
						if (!isMobile) {
							var selectedHeadings = document.querySelectorAll(".panel-heading.selected");
							if (selectedHeadings.length === 0) {
								document.querySelectorAll(".panel-heading.active")[0].classList.add("selected");
								var openID = document.querySelectorAll(".panel-heading.active.selected")[0].id,
								 openPanel = document.querySelectorAll("." + openID);
								openPanel[0].classList.add("open");
							}
						}
					});

				}
			}


	 },
	 initializeImages: function(catEntryId) {
		var images = $('#imagesFor_' + catEntryId).find('input[type="hidden"]');
		var productName="";
		if ($(".product-name-full.desktop-only h1")) {
			productName = $(".product-name-full.desktop-only h1").text();
		}
		if(document.getElementById("tel_product_name")!=null)
			productName = document.getElementById("tel_product_name").value;
		productName = productName.replace(/'/g, '');
		var that = this;
		images.each(function(i) {
			var imgLink = this.value;
			var imgId = this.id;
			var idparts = /^img_(.*)_(\d+)$/.exec(this.id);
			if (idparts) {
				var sku = idparts[1];
				var imgIndex = idparts[2];
				var flareImg = 1;
				if (imgIndex == 1) {
					if(document.getElementById("flare_" + sku) != null) {
						flareImg = document.getElementById("flare_" + sku).value;
					}
				}
				var imgSource = imgLink + "?$Thumbnail$";
				if (isCloudniaryEnabled()) {
					imgSource  = imgLink.replace('f_auto,q_auto', 'f_auto,q_auto,t_Thumbnail');
				} 
				that.createAltImageContentPane(i + 1, imgLink, flareImg, productName, imgSource, sku);
			}
		});
	 },
	 initializeImages_Mobile: function(catEntryId, sku) {
		var images = $('#imagesFor_' + catEntryId).find('input[type="hidden"]');
		var that = this;
		var to_insert = [];
		images.each(function(i) {
			var imgLink = this.value;
			var imgId = this.id;
			var idparts = /^img_(.*)_(\d+)$/.exec(this.id);
			if (idparts) {
				var imgSku = idparts[1];
				var imgIndex = idparts[2];
				if (sku == imgSku) {
					to_insert.push(imgLink);
				}
			}
		});
		var firstLoadImage = false;
		for(var i = to_insert.length - 1; i >= 0; i--) {
			imgLink = to_insert[i];
			var imagePresetSrc = imgLink + '?$ProductDetail-large$';
			var imagePresetLargeSrc = imgLink + '?$ProductDetail-xlarge$';
			if (isCloudniaryEnabled()) {
				imagePresetSrc = imgLink.replace('f_auto,q_auto', 'f_auto,q_auto,t_ProductDetail-large');
				imagePresetLargeSrc  = imgLink.replace('f_auto,q_auto', 'f_auto,q_auto,t_ProductDetail-xlarge');
			} 
			$("#mobile-slider").prepend("<div class=\"imgContainer\"><img class=\"image-pane\" src=\""+imagePresetSrc+"\" data-largesrc=\""+imagePresetLargeSrc+"\" /></div>");
			if(!firstLoadImage){
				var maxProdHeight = document.querySelector("#mobile-slider .imgContainer img").clientHeight;
				if(maxProdHeight < 475){
					maxProdHeight = 475;
				}
				maxProdHeight = maxProdHeight*1.25;
				document.querySelector("#product-image_div #mobile-slider").style.maxHeight = maxProdHeight+"px";
				firstLoadImage = true;
			}

					}
	 },
	 displayAEMContent : function(catEntryId, partNumb,isProd){
		if(document.getElementById('inStoreOnly')!=null){
			if(document.getElementById('inStoreOnlyMsg')!=null)
				document.getElementById('inStoreOnlyMsg').innerHTML =document.getElementById('inStoreOnly').value;
		 }
		if(document.getElementById('itemRelatedMessaging_'+partNumb)!=null){
			document.getElementById('itemRelatedMsg').innerHTML =document.getElementById('itemRelatedMessaging_'+partNumb).value;
		 }
		 if(document.getElementById('didYouKnow')!=null){
				if(document.getElementById('didYouKnowMsg')!=null){
					var imgMsg="<img alt='Did ou know?' src='https://www.petco.com/assets/shop/didYouKnow.gif'><br>";
					document.getElementById('didYouKnowMsg').innerHTML =imgMsg+""+document.getElementById('didYouKnow').value;
				}
		}
		 if(typeof(isProd) == "undefined"){
			 var partnumbReal = "";
			 if($("#selectedPartNumber").length > 0){
				 partnumbReal = $("#selectedPartNumber").val();
			 }
			 if(partnumbReal!=""){
				 partNumb = partnumbReal;
			 }
		 }
		 productDisplayJS.updateDisplayedImages(catEntryId, partNumb);
	 },
	 updateDisplayedImages: function(catEntryId, partNumb) {
		destroyMobileImageSlider();

		var mq = window.matchMedia( "(max-width: 768px)" );
		if (!mq.matches) {
			$('.pdpthumb').hide();
			$('.pdpthumb.sku_' + partNumb).show();
			$('.pdpthumb.videothumb').show();
			this.createAltImageCarousel();
		if( $('.pdpthumb:visible').length > 0){
			if ($('#imgZoom:visible').length > 0) {
				zoomThisData($('.pdpthumb:visible')[0].childNodes[0]);
			} else {
				$('.pdpthumb:visible')[0].click();
			}
			}
		} else {
			var that = this;
			$(function() {
				var mobileImagesToDelete = $(".imgContainer");
				mobileImagesToDelete.remove();
				that.initializeImages_Mobile(catEntryId, partNumb);
				var imgPanes = $(".image-pane"),
					slider = $("[data-tns-role]");

				if (imgPanes.length > 1 && slider.length < 1){
					var imagesToLoad = imgPanes.length;
					var imagesLoaded = 0;
					$(".image-pane").each(function() {
						var incLoad = function() {
							imagesLoaded += 1;
							if (imagesToLoad == imagesLoaded) {
								if (!globalSliderRef)
									initializeMobileImageSlider();
							}
						}
						if (this.complete) {
							incLoad();
						} else {
							$(this).load(incLoad);
						}
					})
				}
			});
		}
	 },
	 createAltImageContentPane: function(index,imgLink,flareImg,productName,imgSource,sku) {
		 var that = this;
		 $(function() {
			 var pane = null;
			 if(!document.getElementById('alt'+index)) {
				 pane = new dijit.layout.ContentPane({
					 content: "<div class=\"pdpthumb sku_" + sku + "\"  id=\"alt"+index+"\"><a href='JavaScript:Void(0);' manual_cm_sp=\"PDP-_-Image_"+index+"\" data-baseImageUrl='" + imgLink + "' data-altImageId='" + flareImg + "' onclick=\"zoomThisData(this);cmManuallyTriggerEventTrack(this);\"><img   alt= '"+productName+"-Thumbnail-"+index+"'  src="+imgSource+" class=''></a></div>",
					 postCreate: function() { dojo.addClass(this.domNode,"imgContainer");}
				 }).placeAt("imageRow", index);
			 }
		 });
	 },

	 createAltImageCarousel: function(){
		 var tileContainer = document.getElementById("imageRow"),
		 	fullSlider = document.getElementById("thumbnail-slider"),
		 	mq = window.matchMedia( "(min-width: 768px)" ),
			arrowPrev = document.querySelectorAll(".page-prev")[0],
			arrowNext = document.querySelectorAll(".page-next")[0],

			visibleTiles = $('.pdpthumb:visible');
		 arrowPrev.classList.add("disable");
		 tileContainer.removeAttribute("style");

		 if (visibleTiles.length > 4 && mq.matches){

			 fullSlider.classList.remove("inactive");
			 arrowNext.classList.remove("disable");

			var tile = $(".pdpthumb:visible")[0],
				tileHeight = tile.offsetHeight,
				tileStyle = getComputedStyle(tile),
				tileHeightMargin = parseInt(tileStyle.marginTop) + parseInt(tileStyle.marginBottom),
				tileTotalHeight = tileHeight + tileHeightMargin,
				tileWidth = tile.offsetWidth,
				tileWidthMargin = parseInt(tileStyle.marginLeft) + parseInt(tileStyle.marginRight),
				tileTotalWidth = tileWidth + tileWidthMargin;


				tileContainer.style.width = (tileTotalWidth * visibleTiles.length) +"px";


		    arrowNext.onclick = function(event){
		    	var tileContainerDirection= "left",
		    		tileContainerPosition = parseInt(getComputedStyle(tileContainer).left, 10),
		    		tileContainerSize = parseInt(tileContainer.offsetWidth, 10),
		    		tileSize = tileTotalWidth;

		    	event.preventDefault();

		    	arrowPrev.classList.remove("disable");

		    	posChange = tileContainerPosition - (tileSize * 3);

		    	if ( posChange <= -(tileContainerSize - (tileSize * 4)) ){
		    		tileContainer.style.setProperty(tileContainerDirection, -(tileContainerSize - (tileSize * 4))+"px");
		    		arrowNext.classList.add("disable");
		    	} else {
		    		tileContainer.style.setProperty(tileContainerDirection, posChange + "px");
		    	}

		    };

		    arrowPrev.onclick = function(event){
		    	var tileContainerDirection = "left",
		    		tileContainerPosition = parseInt(getComputedStyle(tileContainer).left, 10),
		    		tileSize = tileTotalWidth;

		    	event.preventDefault();

		    	arrowNext.classList.remove("disable");

		    	if (parseInt(tileContainerPosition, 10) === 0){
		    		return; 
		    	} else {
		    		var posChange = tileContainerPosition + (tileSize * 3);

		    		if (posChange >= 0) {
		    			posChange = 0;
		    			arrowPrev.classList.add("disable");
		    		}

		    		tileContainer.style.setProperty(tileContainerDirection, posChange +"px");

		    	}
		    };

		 }
	 },
	 changeDescIcon: function(){
		var icon_sign_desc = document.getElementById('description_span');
		if(icon_sign_desc != null){
			if(icon_sign_desc.className == "glyphicon glyphicon-chevron-right")
				icon_sign_desc.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_desc.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeDirIcon: function(skuId){
		var icon_sign_dir = document.getElementById('directions_span_'+skuId);
		if(icon_sign_dir != null){
			if(icon_sign_dir.className == "glyphicon glyphicon-chevron-right")
				icon_sign_dir.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_dir.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeAttrIcon: function(skuId){
		var icon_sign_attr = document.getElementById('attributes_span_'+skuId);
		if(icon_sign_attr != null){
			if(icon_sign_attr.className == "glyphicon glyphicon-chevron-right")
				icon_sign_attr.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_attr.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeIngIcon: function(skuId){
		var icon_sign_ing = document.getElementById('ingredients_span_'+skuId);
		if(icon_sign_ing != null){
			if(icon_sign_ing.className == "glyphicon glyphicon-chevron-right")
				icon_sign_ing.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_ing.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeWrtIcon: function(skuId){
		var icon_sign_wrt = document.getElementById('warranty_span_'+skuId);
		if(icon_sign_wrt != null){
			if(icon_sign_wrt.className == "glyphicon glyphicon-chevron-right")
				icon_sign_wrt.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_wrt.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeShrIcon: function(skuId){
		var icon_sign_ftr = document.getElementById('shipping_span_'+skuId);
		if(icon_sign_ftr != null){
			if(icon_sign_ftr.className == "glyphicon glyphicon-chevron-right")
				icon_sign_ftr.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_ftr.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeFtrIcon: function(skuId){
		var icon_sign_ftr = document.getElementById('featured_span_'+skuId);
		if(icon_sign_ftr != null){
			if(icon_sign_ftr.className == "glyphicon glyphicon-chevron-right")
				icon_sign_ftr.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_ftr.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeCmtIcon: function(){
		var icon_sign_cmt = document.getElementById('comments_span');
		if(icon_sign_cmt != null){
			if(icon_sign_cmt.className == "glyphicon glyphicon-chevron-right")
				icon_sign_cmt.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_cmt.className = "glyphicon glyphicon-chevron-right";
		}
	 },
	 changeSRIcon: function(id){
		var icon_sign_sr = document.getElementById('headerTitle_'+id);
		if(icon_sign_sr != null){
			if(icon_sign_sr.className == "glyphicon glyphicon-chevron-right")
				icon_sign_sr.className = "glyphicon glyphicon-chevron-down";
			else
				icon_sign_sr.className = "glyphicon glyphicon-chevron-right";
		}
	 },

	updateProductShortDescription: function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;	 

				var shortDescWidgets = dojo.query("p[id^='product_shortdescription_"+productId+"']");
		if(shortDescWidgets != null){
			for(var i = 0; i<shortDescWidgets.length; i++){		
				if(shortDescWidgets[i]) {
					shortDescWidgets[i].innerHTML = catEntry.description[0].shortDescription;
				}
			}
		}
	},	 

	updateProductLongDescription: function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;	 

				var longDescWidgets = dojo.query("p[id^='product_longdescription_"+productId+"']");
		if(longDescWidgets != null){
			for(var i = 0; i<longDescWidgets.length; i++){		
				if(longDescWidgets[i]) {
					longDescWidgets[i].innerHTML = catEntry.description[0].longDescription;
				}
			}
		}
	},	 

	updateProductDiscount: function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;

				var newHtml = '';
		if(typeof catEntry.discounts != 'undefined'){
			for(var i=0; i<catEntry.discounts.length; i++){
				if(i > 0){
					newHtml += '<div class="clear_float"></div><div class="item_spacer_2px"></div>';
				}
				newHtml += '<a class="promotion" href="' + catEntry.discounts[i].url + '">' + catEntry.discounts[i].description + '</a>';
			}
		}		

				var discountWidgets = dojo.query("div[id^='Discounts_']");
		if(discountWidgets != null){
			for(var i = 0; i<discountWidgets.length; i++){
				if(discountWidgets[i]){
					discountWidgets[i].innerHTML = newHtml;
				}
			}
		}
	},	 

	updateProductImage:function(catEntryId, productId){
		var newFullImage = null;
		var newAngleThumbnail = null;
		var newAngleFullImage = null;
		var newAngleThumbnailShortDesc = null;
		var imageResolved = false;
		var skuFullImageFromService = null;
		var catEntry = null;
		if (productDisplayJS.itemPriceJsonOject[catEntryId] != null) {
			catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
		}
		if (catEntry != null) {
			skuFullImageFromService = catEntry.description[0].fullImage;
		}

				var entitledItemId = "entitledItem_" + productId;
		var imageArr = productDisplayJS.getImageForSKU(entitledItemId);	
		if(imageArr != null){
			newAngleThumbnail = imageArr[2];
			newAngleFullImage = imageArr[3];	
			newAngleThumbnailShortDesc = imageArr[4];	
		}

				if(catEntryId != null &&  imageArr != null){
			newFullImage = imageArr[0];
			if (skuFullImageFromService != null && skuFullImageFromService != "") {
				newFullImage = skuFullImageFromService;
			}
		} else if (productId != null && productDisplayJS.singleSKUProductWithoutDefiningAttribute) {
			newFullImage = productDisplayJS.entitledItems[0].ItemImage467;
			if (skuFullImageFromService != null && skuFullImageFromService != "") {
				newFullImage = skuFullImageFromService;
			}
			newAngleThumbnail = productDisplayJS.entitledItems[0].ItemAngleThumbnail;
			newAngleFullImage = productDisplayJS.entitledItems[0].ItemAngleFullImage;						
			newAngleThumbnailShortDesc = productDisplayJS.entitledItems[0].ItemAngleThumbnailShortDesc;
		} else {		
			var imageFound = false;
			var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];
			for (x in productDisplayJS.entitledItems) {
				var Attributes = productDisplayJS.entitledItems[x].Attributes;

					for(attribute in selectedAttributes){
					var matchingAttributeFound = false;
					if (selectedAttributes[attribute] !== '') {
						for(y in Attributes){
							var index = y.indexOf("_|_");
							var entitledSwatchName = y.substring(0, index);
							var entitledSwatchValue = y.substring(index+3);

														if (entitledSwatchName == attribute && entitledSwatchValue == selectedAttributes[attribute]) {
								matchingAttributeFound = true;
								break;
							}
						}

						if (!matchingAttributeFound) {
							imageFound = false;
							break;
						}
						imageFound = true;
					}
				}

				if (imageFound) {
					imageResolved = true;
					newFullImage = productDisplayJS.entitledItems[x].ItemImage467;
					newAngleThumbnail = productDisplayJS.entitledItems[x].ItemAngleThumbnail;
					newAngleFullImage = productDisplayJS.entitledItems[x].ItemAngleFullImage;						
					newAngleThumbnailShortDesc = productDisplayJS.entitledItems[x].ItemAngleThumbnailShortDesc;
					break;
				}
			}			
		}

				if(!imageResolved){
			if($('#product-image_div .desktop-only img#imgZoom[imageLoaded="false"]').length > 0 && skuFullImageFromService != null){
				var imagePresetSrc = skuFullImageFromService.replace("/wcsstore/PetcoCAS/","")+"?$ProductDetail-large-new$";
				imagePresetSrc = imagePresetSrc.replace("/webapp/wcs/preview/PetcoCAS/","")+"?$ProductDetail-large-new$";
				if (isCloudniaryEnabled()) {
					var skuImage = skuFullImageFromService.replace("/wcsstore/PetcoCAS/","");
					skuImage = skuImage.replace("/webapp/wcs/preview/PetcoCAS/","");
					imagePresetSrc = skuImage.replace('f_auto,q_auto', 'f_auto,q_auto,t_ProductDetail-large-new');
				} 
				$('#product-image_div .desktop-only img#imgZoom[imageLoaded="false"]')[0].src = imagePresetSrc;
				$('#product-image_div .desktop-only img#imgZoom[imageLoaded="false"]').attr("imageLoaded","true");
			}
		}


						var imgWidgets = dojo.query("img[id^='"+productDisplayJS.skuImageId+"']");
		for(var i = 0; i<imgWidgets.length; i++){				
			if(imgWidgets[i] != null && newFullImage != null){
				imgWidgets[i].src = newFullImage;
			}
		}

				var productImgWidgets = dojo.query("input[id^='ProductInfoImage_"+productId+"']");
		for(var i = 0; i<productImgWidgets.length; i++){			
			if(productImgWidgets[i] != null && newFullImage != null){
				productImgWidgets[i].value = newFullImage;
			}
		}

				var prodAngleImageArea = dojo.query("div[id^='ProductAngleProdImagesArea']");
		var skuAngleImageArea = dojo.query("div[id^='ProductAngleImagesArea']");

				if(newAngleThumbnail != null && newAngleFullImage != null){
			if(prodAngleImageArea != null){
				for(var i = 0; i<prodAngleImageArea.length; i++){			
					if(null != prodAngleImageArea[i]){
						prodAngleImageArea[i].style.display = 'none';
					}
				}
			}			
			productDisplayJS.changeViewImages(newAngleThumbnail, newAngleFullImage, newAngleThumbnailShortDesc);
		} else {
			var prodDisplayClass = 'block';
			var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId]; 
			for (attribute in selectedAttributes){
				if(null != selectedAttributes[attribute] && '' != selectedAttributes[attribute]){
					prodDisplayClass = 'none';
				}
			}

			if(prodAngleImageArea != null){
				for(var i = 0; i < prodAngleImageArea.length; i++){			
					if(null != prodAngleImageArea[i]){
						prodAngleImageArea[i].style.display = prodDisplayClass;
					}
				}
			}

			if (skuAngleImageArea != null){
				for(var i = 0; i < skuAngleImageArea.length; i++){			
					if(null != skuAngleImageArea[i]){
						skuAngleImageArea[i].style.display = 'none';
					}
				}
			}		
		}
	}, 
	displayQuantity:function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;	
		var partNumber = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
		var restrictedLiveCricketSKUs = $('#restrictedLiveCricketSKUs').val();
		if(restrictedLiveCricketSKUs != '') {
			if (restrictedLiveCricketSKUs.indexOf(partNumber) > -1) {
				var liveCricketRestrictedQty = $('#liveCricketRestrictedQty').val();
				if($("#quantity_"+productId).length > 0) {
					$("#quantity_"+productId).val(liveCricketRestrictedQty);
					$("#quantity_"+productId).prop("disabled", true);
					$('.qty-minus').prop("disabled", true);
					$('.qty-plus').prop("disabled", true);
				}					
			} else {
				if($("#quantity_"+productId).length > 0) {
					$("#quantity_"+productId).prop("disabled", false);
					$('.qty-minus').prop("disabled", false);
					$('.qty-plus').prop("disabled", false);
				}
			}
		}
	}, 


	 changeAttributeSelction : function(productId, attrName,attrValue,entitledItemId){
		productDisplayJS.canSwapProductPriceDiv = false;
		var temp="";
		var tempSelVal=""
		var catentry_id = "";
		productDisplayJS.baseCatalogEntryId = productId;
		var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];
		var catalogEntryId = null;
		var tempCatentryId=null;
		var counter=0;


		for(a in this.entitledItems[0].Attributes){
			var index = a.indexOf("_");
			var tmpAttrName = a.substring(0, index);
			if(document.getElementById(tmpAttrName) != null){
				tempSelVal = tempSelVal+tmpAttrName+'_'+document.getElementById(tmpAttrName).value+',';
			}

					}

		for(x in this.entitledItems)
		{
			var Attributes = this.entitledItems[x].Attributes;
			for(y in Attributes){
				var index = y.indexOf("_");
				var attrName1 = y.substring(0, index);
				var attrValue1 = y.substring(index+1);
				catentry_id = this.entitledItems[x].catentry_id;
				if(attrName == attrName1 && attrValue==attrValue1){
					temp=temp+x+",";
					catalogEntryId=catentry_id;



				}
			}
		}
		var temp1 = temp.split(',');
		var temp2= temp1;
		var attrNameDisp="";
		var attrValueDisp="";
		var j="";

		var tmp="";
		for(index in temp2){
			j=temp2[index];
			if(j!=""){
				if(this.entitledItems[j]!=undefined){
					var Attributes = this.entitledItems[j].Attributes;
					for(z in Attributes){
						var index = z.indexOf("_");
						attrNameDisp=z.substring(0, index);
						attrValueDisp=z.substring(index+1);
						catentryid = this.entitledItems[j].catentry_id;

						if(attrNameDisp != attrName && tempSelVal.indexOf(z) != -1){
							tmp=tmp+j+",";
							catalogEntryId=catentryid;
						}

					}
				}
			}
		}
		if(tmp == ""){
			tmp = temp;
		}

		var tmp1 = tmp.split(',');
		var tmp2= tmp1;
		var attrNameDis="";
		var attrValueDis="";
		var selectedAttr="";
		var tempAttrName;
		var attrNameSpace;
		var k="";

		for(index in tmp2){
			k=tmp[index];
			if(k!=""){
				if(this.entitledItems[k]!=undefined){
					var Attributes = this.entitledItems[k].Attributes;

					for(z in Attributes){
						var index = z.indexOf("_");
						attrNameDis=z.substring(0, index);
						attrValueDis = z.substring(index+1);

							if(document.getElementById(attrNameDis) != null && document.getElementById(attrNameDis).value != null && document.getElementById(attrNameDis).value == attrValueDis){
								attrValDis = document.getElementById(attrNameDis).value;
							}else{
								attrValDis=z.substring(index+1);
							}
							if(document.getElementById(attrNameDis) != null ){
								document.getElementById(attrNameDis).value =attrValDis;
								this.setSelectedAttribute(attrNameDis, attrValDis, entitledItemId);
							}

					}
				}


			}

		}
		if(catalogEntryId != null && productId!=null){	

						console.log("After Context catalogEntryId-1-dc-5630"+catalogEntryId);		
			dojo.topic.publish('DefiningAttributes_Resolved_'+productId, catalogEntryId, productId);
           var catEntry = productDisplayJS.itemPriceJsonOject[catalogEntryId];
			if(catEntry != null && catEntry != undefined){
				productDisplayJS.publishAttributeResolvedEvent(catalogEntryId, productId);
			}else{
				var parameters = {};
				parameters.storeId = productDisplayJS.storeId;
				parameters.langId= productDisplayJS.langId;
				parameters.catalogId= productDisplayJS.catalogId;
				parameters.catalogEntryId= catalogEntryId;
				parameters.productId = productId;
				productDisplayJS.postCallCatentryDetailsById(parameters);
			}

			for(x in this.entitledItems)
			{
				var catentry_id = this.entitledItems[x].catentry_id;
				var rdPrice = "";
				var rdFlag = "";
				var rdSavings = "";
				if(catentry_id == catalogEntryId)
				{
					rdPrice = this.entitledItems[x].RepeatDeliveryPrice;
					rdFlag = this.entitledItems[x].RepeatDeliveryFlag;
			 }
			}
			if (!$('#personilaztionDisplay').hasClass('active') && !$('#rxPersonilaztionDisplay').hasClass('active') ) {
				persistentPDP();
			}
			setTimeout("cursor_clear()",18000);

		}
	},
	displayProp65WarningMessage: function(catEntry){

				var warningMessage = catEntry.warningMessage;
		if(undefined != warningMessage || null != warningMessage ){	
			var warningSignText = $('#product-prop65-warning-sign-text').html();
			var warningReference = $('#product-prop65-warning-reference').html();
			warningMessage = warningSignText + warningMessage + warningReference;
			var prop65State = getCookie("Edgescape-State");
			if(prop65State === undefined || prop65State == null || prop65State.length <= 0){
			   $('#product-prop65-warning').removeClass('hide').addClass('show');
			   $('#product-prop65-warning').html(warningMessage);
			}
			else if(prop65State == 'CA'){
			   $('#product-prop65-warning').removeClass('hide').addClass('show');
			   $('#product-prop65-warning').html(warningMessage);			   
			}
			else{
			   $('#product-prop65-warning').removeClass('show').addClass('hide');
			   $('#product-prop65-warning').html("");
			}		  
		}
		else if($('#product-prop65-warning').hasClass('show')){
		   $('#product-prop65-warning').removeClass('show').addClass('hide');
		}		
	},

	notifyAttributeChange: function(productId, entitledItemId, isPopup, displayPriceRange){

				productDisplayJS.baseCatalogEntryId = productId;	
		var selectedAttributes = productDisplayJS.selectedAttributesList[entitledItemId];

				productDisplayJS.displayPriceRange = displayPriceRange;
		productDisplayJS.isPopup = isPopup;

				var catalogEntryId = null;		
		if(productDisplayJS.selectedProducts[productId]){
			catalogEntryId = productDisplayJS.getCatalogEntryIdforProduct(productDisplayJS.selectedProducts[productId]);
		} else {
			catalogEntryId = productDisplayJS.getCatalogEntryId(entitledItemId);
		}	
		if(catalogEntryId != null){
			dojo.topic.publish('DefiningAttributes_Resolved_'+productId, catalogEntryId, productId);
			var catEntry = productDisplayJS.itemPriceJsonOject[catalogEntryId];		

						if(catEntry != null && catEntry != undefined){
				productDisplayJS.publishAttributeResolvedEvent(catalogEntryId, productId);
			}

			else{
				var parameters = {};
				parameters.storeId = productDisplayJS.storeId;
				parameters.langId= productDisplayJS.langId;
				parameters.catalogId= productDisplayJS.catalogId;
				parameters.catalogEntryId= catalogEntryId;
				parameters.productId = productId;
				productDisplayJS.postCallCatentryDetailsById(parameters);
			}
			for(x in this.entitledItems)
			{
				var catentry_id = this.entitledItems[x].catentry_id;
				var rdPrice = "";
				var rdFlag = "";
				var rdSavings = "";
				if(catentry_id == catalogEntryId)
				{
					rdPrice = this.entitledItems[x].RepeatDeliveryPrice;
					rdFlag = this.entitledItems[x].RepeatDeliveryFlag;
				}
			}			
			cursor_clear();
		}
		else{
			dojo.topic.publish('DefiningAttributes_Changed', catalogEntryId, productId);
			dojo.topic.publish('DefiningAttributes_Changed_' + productId, catalogEntryId, productId);
			console.debug("Publishing event2 'DefiningAttributes_Changed' with params: catEntryId="+catalogEntryId+", productId="+productId);
			if(document.getElementById("add2CartBtn")!=null && document.getElementById("add2CartBtn")!='undefined'){
				document.getElementById("add2CartBtn").disabled = true;
			}
			if(document.getElementById("selectRDOrderBtn")!=null && document.getElementById("selectRDOrderBtn")!='undefined'){
				document.getElementById("selectRDOrderBtn").disabled = true;
			}
			if(document.getElementById("selectRDOrderBtnCpy")!=null && document.getElementById("selectRDOrderBtnCpy")!='undefined'){
				document.getElementById("selectRDOrderBtnCpy").disabled = true;
			}
			if(document.getElementById("selectRDOrderBtnAddTo")!=null && document.getElementById("selectRDOrderBtnAddTo")!='undefined'){
				document.getElementById("selectRDOrderBtnAddTo").disabled = true;
			}

		}
	},

	publishAttributeResolvedEventServiceResponse: function(serviceResponse, ioArgs){		
		var productId = ioArgs['productId'];
		productDisplayJS.itemPriceJsonOject[serviceResponse.catalogEntry.catalogEntryIdentifier.uniqueID] = serviceResponse;		
		productDisplayJS.publishAttributeResolvedEvent(serviceResponse.catalogEntry.catalogEntryIdentifier.uniqueID, productId);
	 },

	publishAttributeResolvedEvent: function(catEntryId, productId){		
		if (!productDisplayJS.isPopup) {
			dojo.topic.publish('DefiningAttributes_Resolved', catEntryId, productId);
			if( document.getElementById("notifyCatentry")!=null){

				document.getElementById("notifyCatentry").value=catEntryId;

			}			
			this.updateProductPartNumber(catEntryId, productId);
			this.updateProductName(catEntryId, productId);
			this.displayAccessoriesMAForItem(catEntryId);
			 if($("#replacementEligibleItem").length == 0){
			this.displayAttributeInfo(catEntryId, productId);
			 }
			var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
			this.displayProp65WarningMessage(catEntry);
			buildProductDataLayerVariable(catEntry);
			if(null!=productDisplayJS.InventoryDetailsforItems && undefined != productDisplayJS.InventoryDetailsforItems[catEntryId]){
				buildProductInventoryDataLayer(productDisplayJS.InventoryDetailsforItems[catEntryId].onlineInventory_status,'onChange');
			}
			var partNumb = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
			$("#selectedPartNumber").val(partNumb);
			 if($("#replacementEligibleItem").length == 0){
					 productDisplayJS.displayAEMContent(catEntryId,partNumb,true);
			 }
			console.debug("Publishing event1 'DefiningAttributes_Resolved' with params: catEntryId="+catEntryId +", productId="+productId);

					}
	},

	notifyQuantityChange: function(quantity){	
		if(isNaN(quantity)){
			return false;
		}		
		if($('#quantity') != null && $('#quantity') != undefined){
			var qtyChanged = false;
			if($('#quantity').val() != '' && $('#quantity').val() == 0){
				$('#quantity').val('1');
				localStorage.setItem('qtyChangedOnBlur', '1');
	    	}
			if (parseInt($('#quantity').val()) > 1 && parseInt($('#quantity').val()) <= 999) {
				qtyChanged = true;
			}
			petcoCommonJS.updateItemButton(qtyChanged);	
		}
		dojo.topic.publish('ShopperActions_Changed', quantity);		
		console.debug("Publishing event 'ShopperActions_Changed' with params: quantity="+quantity);
	},	

	displayAccessoriesMAForItem: function(catEntryId){
		if(document.getElementById("skuHasAccssoryMAListIds")!='undefined' && document.getElementById("skuHasAccssoryMAListIds")!=null){
			var skuList = document.getElementById("skuHasAccssoryMAListIds").value;
			var skuId = skuList.split(',');
			for(var i = 0;i<(skuId.length);i++){
				var accessoryMADiv = document.getElementById('accessoriesMA_'+skuId[i]);
				if(accessoryMADiv!=undefined || accessoryMADiv!=null){
					if(catEntryId == skuId[i]){
						dojo.style("accessoriesMA_"+skuId[i],"display","block");
					}else{
						dojo.style("accessoriesMA_"+skuId[i],"display","none");
					}
				}
			}

		}
	},

	updateItemSpecificData: function(catEntryId, productId){
		var entitledItemIdsObject = document.getElementById("entitledItem_"+productId);
		var entitledItemIds = eval('('+entitledItemIdsObject.innerHTML +')');
			var parameters = {};
			parameters.storeId = productDisplayJS.storeId;
			parameters.langId= productDisplayJS.langId;
			parameters.catalogId= productDisplayJS.catalogId;
			parameters.catalogEntryId= catEntryId;
			parameters.productId = productId;
			productDisplayJS.postCallCatentryDetailsById(parameters);
	},

	rdAddOnLoginOptions: function(signInUrl,quantity,customParams,isAddToRDRequest) {
		var userType = dojo.cookie('WC_UserType');
		if(petcoPersonalizationJS.isPersonalizedItem() && !petcoPersonalizationJS.isRxMedicineItem()){
				var invalidChars = petcoPersonalizationJS.findInvalidCharSetForUserInput(quantity,customParams);
				if(invalidChars!=""){

					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedRequiredField(quantity,customParams)){
					return false;
				}
				if(!petcoPersonalizationJS.validatePersonalizedTextInputType(quantity,customParams)){
					return false;
				}
		}
		if(userType != 'R'){
			var urlParams = window.location.href;
			var rdSignInUrl = "";
			if (signInUrl != null && signInUrl != 'undefined') {
				rdSignInUrl = signInUrl;
				rdSignInUrl = rdSignInUrl+'&URL='+urlParams;
				dojo.cookie('oneTimeAddOnRD', 'true', {path:'/'});
				document.location.href = rdSignInUrl;
			}
		} else if (isAddToRDRequest) {
			PetcoAddToRepeatDeliveryJS.updateContextForAddToRepeatDelivery();
		} else {
			PetcoRepeatDeliveryAddOnJS.updateContextForRDAddOn();
		}
	},

	preSelectRadioButtonOnPDP: function() {
		if($("#notifybutton_cpy1.show").length > 0){
			return;
		}
		var itemOrProd = document.getElementById('itemOrProd').value;
		var rdNotDisplay = document.getElementById('rdNotDisplay').value;
		if($("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 && $("#repeat-delivery-select-btn.paramStore-deliveryBox").length == 0){
		var oneTimeAddOnRDFlag = dojo.cookie('oneTimeAddOnRD');
		if (oneTimeAddOnRDFlag != null && oneTimeAddOnRDFlag != '' && oneTimeAddOnRDFlag == 'true') {
			var userType = dojo.cookie('WC_UserType');
			if(userType == 'R'){
				if($("input#store-pickup:checked").length == 0 && $("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 ){
				document.getElementById("repeat-delivery-add-on").click();
				document.getElementById("selectRDOrderBtn").click();
				}
				dojo.cookie("oneTimeAddOnRD", null, {expires: -1, path:'/'});
			} else {
				if($("input#store-pickup:checked").length == 0 && $("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 && $("#repeat-delivery-select-btn.paramStore-deliveryBox").length == 0 ){
					if(itemOrProd == 'Item'){
				document.getElementById("one-time-delivery").click();
					} else if(itemOrProd == 'Prod'){
						if(rdNotDisplay == 'Yes') {
							if($("#repeat-delivery-radio:checked").length > 0){
								$("#repeat-delivery-radio")[0].checked = true;
							}
							$("#repeat-delivery-radio").trigger("click");
						} else {
							if($("#one-time-delivery:checked").length > 0){
								$("#one-time-delivery")[0].checked = true;
							}
							$("#one-time-delivery").trigger("click");
						}

											}
				}
				dojo.cookie("oneTimeAddOnRD", null, {expires: -1, path:'/'});
			}
		} else {
			if($("input#store-pickup:checked").length == 0 && $("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 && $("#repeat-delivery-select-btn.paramStore-deliveryBox").length == 0 ){
				if(itemOrProd == 'Item'){
			document.getElementById("one-time-delivery").click();
				} else if(itemOrProd == 'Prod'){
					if(rdNotDisplay == 'Yes') {
						if($("#repeat-delivery-radio:checked").length > 0){
							$("#repeat-delivery-radio")[0].checked = true;
						}
						$("#repeat-delivery-radio").trigger("click");
					} else {
						if($("#one-time-delivery:checked").length > 0){
							$("#one-time-delivery")[0].checked = true;
						}
						$("#one-time-delivery").trigger("click");
					}

									}
			}
		}

					}

	},

	showRDFrequencyOptions: function(optionVal) {
		var isRXMedicine = false;
		if($("#rxMedicine").length >0 && $("#rxMedicine").val() == 'true' ){
			isRXMedicine = true;
		}

			var disableFutureRDInEAOBO = false;
		if($("#hid_disableFutureRDInEAOBO").length >0 && $("#hid_disableFutureRDInEAOBO").val() == 'true' ){
			disableFutureRDInEAOBO = true;
		}
		if (optionVal == 'repeat-delivery') {
			dojo.query("#repeat-delivery-buybutton").addClass('open');
			dojo.query("#rd-option-selectBox").addClass('selected-option-box');


			var isEASession = dojo.cookie('isEASession');
			var isOBOSession = dojo.cookie('isOBOSession');
			var isOBOOrEASession = "no";
			if (isEASession == "yes" || isOBOSession == "yes"){
				isOBOOrEASession = "yes";
			}
			var disableFutureRD = false;
			if(disableFutureRDInEAOBO==true && isRXMedicine==true ){
				disableFutureRD = true;
			}	
			if("yes" == isOBOOrEASession && disableFutureRD==false){
				dojo.query('#future-ship-repeat-delivery-dropdown').addClass('open');
				dojo.query('#repeat-delivery-dropdown').removeClass('open');
			}else{
				dojo.query('#repeat-delivery-dropdown').addClass('open');
				dojo.query('#future-ship-repeat-delivery-dropdown').removeClass('open');
			}
		}
		else {
			dojo.query("#repeat-delivery-buybutton").removeClass('open');
			dojo.query('#repeat-delivery-dropdown').removeClass('open');

			dojo.query('#future-ship-repeat-delivery-dropdown').removeClass('open');
			dojo.query("#rd-option-selectBox").removeClass('selected-option-box');
		}


		if(optionVal == 'one-time-delivery'){
			dojo.query("#onetimeDelivery-buybutton").addClass('open');
			dojo.query("#onetimeDelivery-option-selectBox").addClass('selected-option-box');

		}else{
			dojo.query("#onetimeDelivery-buybutton").removeClass('open');
			dojo.query("#onetimeDelivery-option-selectBox").removeClass('selected-option-box');

		}
		if(optionVal == 'repeat-delivery-add-on'){
			dojo.query("#rd-add-on-buybutton").addClass('open');
			dojo.query("#rd-add-on").addClass('selected-option-box');

		}else{
			dojo.query("#rd-add-on-buybutton").removeClass('open');
			dojo.query("#rd-add-on").removeClass('selected-option-box');

		}

	},



	showAttachmentPage:function(data){
		var pageNumber = data['pageNumber'];
		var pageSize = data['pageSize'];
		pageNumber = dojo.number.parse(pageNumber);
		pageSize = dojo.number.parse(pageSize);

		setCurrentId(data["linkId"]);

		if(!submitRequest()){
			return;
		}

					console.debug(wc.render.getRefreshControllerById('AttachmentPagination_Controller').renderContext.properties);
		var beginIndex = pageSize * ( pageNumber - 1 );
		cursor_wait();
		wc.render.updateContext('AttachmentPagination_Context', {'beginIndex':beginIndex});
		MessageHelper.hideAndClearMessage();
	},

	registerMouseDown:function(node){
		dojo.connect(dojo.byId(node), "onmousedown", 
			function(){
				productDisplayJS.calculateScrollingHeight(node);
			}
		);		
	},

	calculateScrollingHeight:function(node){
		var domGeometry = require("dojo/dom-geometry");		
		var selectedNode = productDisplayJS.findDropdownById(node);
		var nodePosition = null;
		if (selectedNode) {
			nodePosition = domGeometry.position(selectedNode);
		} else {
			return;
		}
		var windowHeight = window.innerHeight;
		if (windowHeight - nodePosition.y > nodePosition.y){
			var newHeight = windowHeight - nodePosition.y;
		}else{
			var newHeight = nodePosition.y;
		}
		if (dojo.byId(node + "_dropdown") != null){
			if (windowHeight - nodePosition.y > nodePosition.y){
				var newHeight = windowHeight - nodePosition.y;
			}else{
				var newHeight = nodePosition.y;
			}
			var dropdownHeight = dojo.byId(node + "_dropdown").clientHeight;	
			if (dropdownHeight > newHeight){
				dojo.byId(node + "_dropdown").style.height = newHeight + "px"; 		
			}				
		}
	},

		findDropdownById: function(node) {
		var newNode = productDisplayJS.removeQuotes(node);
		var nodes = dojo.query('[id^=attrValue_]');
		var foundNode = null;

				dojo.forEach(nodes, function(domNode, key) {
			var id = productDisplayJS.removeQuotes(domNode.id);
			if (newNode === id) {
				foundNode = domNode;
			}
		});

				return foundNode;
	},

	refreshActionButton:function(optionVal, entitledItemId) {
		var isSHowNotifyButton = false;
		if(dojo.byId('notifybutton') != null){
			isSHowNotifyButton = dojo.hasClass('notifybutton','show');
		}
		if(!isSHowNotifyButton){
		if (optionVal == 'repeat-delivery-add-on') {
			dojo.query('#add2CartBtn').addClass('hide');
			dojo.query('#add2CartBtn').removeClass('show');
			dojo.query('#selectRDOrderBtn').removeClass('hide');
			dojo.query('#selectRDOrderBtnCpy').removeClass('hide');
			dojo.query('#selectRDOrderBtnAddTo').removeClass('hide');
			dojo.query('#selectRDOrderBtn').addClass('show');
			dojo.query('#selectRDOrderBtnCpy').addClass('show');
			dojo.query('#selectRDOrderBtnAddTo').addClass('show');
			dojo.query('#addToRDOrderBtn').addClass('hide');
			var entitledItemJSON;
			if (dojo.byId(entitledItemId)!=null) {
			 entitledItemJSON = eval('('+ dojo.byId(entitledItemId).innerHTML +')');
			}else{
			entitledItemJSON = this.getEntitledItemJsonObject();
			}
			productDisplayJS.setEntitledItems(entitledItemJSON);
			var catalogEntryId = productDisplayJS.getCatalogEntryId(entitledItemId);
			var catEntry = productDisplayJS.itemPriceJsonOject[catalogEntryId].catalogEntry;
			try{
				var name = catEntry.description[0].name;
				var productId = catalogEntryId;
				var productName = name.replace(/["']/g, "");
				var rd_sku = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
				if (rd_sku != null && rd_sku != "undefined") {
					if($('#tel_product_id').size()>0){
						  var product_parent_sku = $('#tel_product_id').val();
						}
					var productRDJSON = {"product_id" : productId , 
										 "product_name" : productName , 
										  "conversion_event_id" : "New Product Existing RD Order", 
										  "conversion_category_id" : "Repeat Delivery", 
										  "conversion_action_type" : "1", 
										  "product_sku" : rd_sku, 
										  "product_parent_sku":product_parent_sku,
										  "event_name" : "repeat_delivery_new_prod"};
					pushEvent(productRDJSON);
				}
			}catch(err){
				console.log(err);
			}
		} else if (optionVal == 'add-to-repeat-delivery') { 
			dojo.query('#add2CartBtn').addClass('hide');
			dojo.query('#add2CartBtn').removeClass('show');
			dojo.query('#selectRDOrderBtn').addClass('hide');
			dojo.query('#selectRDOrderBtnCpy').addClass('hide');
			dojo.query('#selectRDOrderBtnAddTo').addClass('hide');
			dojo.query('#selectRDOrderBtn').removeClass('show');
			dojo.query('#selectRDOrderBtnCpy').removeClass('show');
			dojo.query('#selectRDOrderBtnAddTo').removeClass('show');
			dojo.query('#addToRDOrderBtn').removeClass('hide');

					} else {
				dojo.query('#add2CartBtn').removeClass('hide');
				dojo.query('#add2CartBtn').addClass('show');
				dojo.query('#selectRDOrderBtn').addClass('hide');
				dojo.query('#selectRDOrderBtnCpy').addClass('hide');
				dojo.query('#selectRDOrderBtnAddTo').addClass('hide');
				dojo.query('#selectRDOrderBtn').removeClass('show');
				dojo.query('#selectRDOrderBtnCpy').removeClass('show');
				dojo.query('#selectRDOrderBtnAddTo').removeClass('show');
				dojo.query('#addToRDOrderBtn').addClass('hide');
		}

				if (document.getElementById("HolidayShipMsgFlag") != null && document.getElementById("HolidayShipMsgFlag").value == 'Y') {
			console.log(dojo.byId("tabselected").value + " :: " + optionVal + " :: " + entitledItemId + " :: " + productDisplayJS.getCatalogEntryId(entitledItemId));
			if (dojo.byId("tabselected").value != '') {
				if (dojo.byId("tabselected").value != optionVal) {
					console.log("different tab");
					var itemId = productDisplayJS.getCatalogEntryId(entitledItemId);
					if ("store-pickup" == optionVal) {
						if (document.getElementById("ShippingMessage") != null) {		
							$("#ShippingMessage").addClass("hide");
						} 
					}
					dojo.byId("tabselected").value = optionVal;
				}
			} else {
				dojo.byId("tabselected").value = optionVal;
			}
		}
		}
        if (typeof(productDisplayJS) != 'undefined') {
            productDisplayJS.toggleAddonButton();
        }
	},
	sortItemsInCombo:function(){
		dojo.forEach(dojo.query('select[name="attrValue"]'),function(obj){
			 var optionsValue = obj.options;
			 var optionList = {};
			 var optionvaluearray = [];
			 for(var i = 0;i<optionsValue.length;i++){
					var valofoption = optionsValue[i].value;
					if(valofoption == ""){
						valofoption = "0" ;
					}
					var numb = valofoption.match(/[0-9.]/g);
					var numbcombin = (numb.join(""));
					optionList[numbcombin] = optionsValue[i];
					optionvaluearray.push(numbcombin);
			}
			  var j;
			    for(j = obj.options.length - 1 ; j >= 0 ; j--)
			    {
			        obj.remove(j);
			    }
				optionvaluearray = optionvaluearray.sort(function(a, b){return a-b});
				for(var x=0 ; x<optionvaluearray.length ; x++){
					obj.add(optionList[optionvaluearray[x]])
				}
			})
	}
	,changeQuanityInPdP:function(action,catalogEntryID,templateOrder,templateOrderVal,SIGN_IN_URL,ltlorRxflag){
		if(action == 'PREVIOUS'){
		    if (parseInt($('#quantity_'+catalogEntryID).val()) > 1) {
	            $('#quantity_'+catalogEntryID).val((parseInt($('#quantity_'+catalogEntryID).val()) - 1));
	            petcoCommonJS.rateLimiter(250, 'qtyChange', function(){
	            	CheckoutHelperJS.isshowpersonalizationPopup = false;
	            	if(templateOrder == 'true'){
	            		productDisplayJS.updateValueQuanityInPdPUnderTemplateProduct(catalogEntryID,templateOrderVal,$('#quantity_'+catalogEntryID)[0],SIGN_IN_URL,ltlorRxflag);
	            	}else{
	            	productDisplayJS.updateValueQuanityInPdP(catalogEntryID);
	            	}

	            		            });

	            	        }
		}else if(action == 'NEXT'){
			 if (parseInt($('#quantity_'+catalogEntryID).val()) < 999){
				 	if(localStorage.getItem('qtyChangedOnBlur') != null && localStorage.getItem('qtyChangedOnBlur') == '1' && parseInt($('#quantity_'+catalogEntryID).val()) == 1){
				 		localStorage.setItem('qtyChangedOnBlur', '0');
					}else{
						$('#quantity_'+catalogEntryID).val((parseInt($('#quantity_'+catalogEntryID).val()) + 1));
					}
	                petcoCommonJS.rateLimiter(250, 'qtyChange', function(){
	                	CheckoutHelperJS.isshowpersonalizationPopup = false;
	                	if(templateOrder == 'true'){
	                		productDisplayJS.updateValueQuanityInPdPUnderTemplateProduct(catalogEntryID,templateOrderVal,$('#quantity_'+catalogEntryID)[0],SIGN_IN_URL,ltlorRxflag);
		            	}else{
	                	productDisplayJS.updateValueQuanityInPdP(catalogEntryID);
		            	}
	                });
	            }
		}

				try{
			var resolvedcatalogEntryId = productDisplayJS.getCatalogEntryId("entitledItem_"+catalogEntryID);
			var invokeCommitsAPI = false;
			if('true' == $("#isDynamicDevliveryDateActive").val()){
				invokeCommitsAPI = true;
			}else if('true' == $("#isDynamicDevliveryDateActiveEAOBO").val()){
				var isEASession = dojo.cookie('isEASession');
				var isOBOSession = dojo.cookie('isOBOSession');
				if (isEASession == "yes" || isOBOSession == "yes"){
					invokeCommitsAPI = true;
				}
			}
			if(invokeCommitsAPI){
				productDisplayJS.invokeDeliveryDateAPIForPDP(resolvedcatalogEntryId,catalogEntryID);
			}

						var omnitureEnabled
			if(dojo.byId("omnitureEnabled") != undefined && dojo.byId("omnitureEnabled") != null)
				omnitureEnabled = dojo.byId("omnitureEnabled").value;
			if(omnitureEnabled != "undefined" && null != omnitureEnabled && omnitureEnabled !='' && omnitureEnabled == 'true'){
				var productId = productDisplayJS.bopusShowStoreDetailsCatEntryId;
				if(productId != undefined && null != productId){
					var catEntry = productDisplayJS.itemPriceJsonOject[productId].catalogEntry;
					if(catEntry != undefined && catEntry != null){
						var cartOrderId = dojo.cookie("WC_CartOrderId_10151");
						var cartEventDetails = {};
						if(action == 'NEXT'){
							cartEventDetails["event_name"] = "cart_add_units";
							cartEventDetails["cart_units_add"] = "1";}						
						else if(action == 'PREVIOUS'){
							cartEventDetails["event_name"] = "cart_remove_units";
							cartEventDetails["cart_units_remove"] = "1";}							
						if(cartOrderId != null && cartOrderId != "")
							cartEventDetails["cart_id"] = cartOrderId;
						cartEventDetails["product_id"] = productId;
						cartEventDetails["product_sku"] = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
						cartEventDetails["product_name"] = catEntry.description[0].name;
						cartEventDetails["product_quantity"] = $('#quantity_'+catalogEntryID).val();
						if($('#tel_product_id').size()>0){
							cartEventDetails["product_parent_sku"] = $('#tel_product_id').val();
						}
						pushEvent(cartEventDetails);
					}		
				}
			}
		}catch(err){
			console.log(err);
		}


			},updateValueQuanityInPdP:function(catalogEntryID){
		var thisobj =  $('#quantity_'+catalogEntryID);
		if(thisobj.length != 0 ){
			  if(petcoPersonalizationJS.checkPersonalizationUserInput(catalogEntryID,thisobj.val())) {
	                productDisplayJS.notifyQuantityChange(thisobj.val());
	                CheckoutHelperJS.isshowpersonalizationPopup = false;
	                var ltlorRxflagCatentryId = "";
	                if( $("#ltlproduct").length > 0){
	                	ltlorRxflagCatentryId = $("#ltlproduct").val();
	                }
	                wc.render.updateContext('personilaztionDisplayContext',
	                    {'quantity':thisobj.val(),
	                    'persAttr':petcoPersonalizationJS.findPersonalizedAttr(thisobj.val()),
	                    'maxUserInputQuantity':petcoPersonalizationJS.findMaxUserInputQuantity(),
	                    "ltlproduct":ltlorRxflagCatentryId,
						"catentryId":catalogEntryID}
	                );
	            }
	            cmManuallyTriggerEventTrack(thisobj,thisobj.val());
		}
	},
	updateValueQuanityInPdPUnderTemplateProduct:function(catalogEntryID,templateFlagVal,obj,SIGN_IN_URL,ltlorRxflag){
		if(obj.length != 0 ){
			  if(petcoPersonalizationJS.checkPersonalizationUserInput(catalogEntryID,obj.value)) {
	                productDisplayJS.notifyQuantityChange(obj.value);
	                CheckoutHelperJS.isshowpersonalizationPopup = false;
	                var vetDetailsFromWidgetFlag =   $('#isVetDetailsFromVetWidgetEnabled').val();
	                if(vetDetailsFromWidgetFlag != null && vetDetailsFromWidgetFlag == "true"){
	                      petcoPersonalizationJS.updateRXMedModalOnPDPQtyChange(SIGN_IN_URL, ltlorRxflag, catalogEntryID, obj.value, 'pdp', '','1');
	                }
	                else{
	                	   wc.render.updateContext('rxPersonilaztionDisplayContext',
	   	                		{'SIGN_IN_URL':SIGN_IN_URL,'ltlorRxflag':ltlorRxflag,'catentryId':catalogEntryID,'quantity':obj.value, 'fromPage': 'pdp','persAttr':'','maxUserInputQuantity':"1"});
	                }

	            }
	            cmManuallyTriggerEventTrack(obj,obj.value);
		}
	}

		,

		addtoWIshListClicked:function(){

			if (document.getElementById('addToShoppingList') != null && 
			document.getElementById('addToShoppingList') != 'undefined' && 
			"G" == dojo.cookie("WC_UserType")) {
			var urlParams = window.location.href;
			var signInURL = document.getElementById('addToShoppingList').href;
			var currentItemsUrl = "";
			if( typeof(productDisplayJS.itemPriceJsonOject) != "undefined" && 
				typeof(productDisplayJS.itemPriceJsonOject[productDisplayJS.bopusShowStoreDetailsCatEntryId])!= "undefined" ){

							currentItemsUrl = productDisplayJS.itemPriceJsonOject[productDisplayJS.bopusShowStoreDetailsCatEntryId].catalogEntry.description[0].seourl;
			}
			if(currentItemsUrl != ""){
				urlParams = currentItemsUrl;				
			}
			if(urlParams.indexOf('TopCategories') == -1  && urlParams.indexOf('CSR') == -1 && urlParams.indexOf('LogonForm') == -1){
				document.getElementById('addToShoppingList').href=signInURL+'&URL='+urlParams						
			}
		 }


				if(typeof(localStorageHelper) != "undefined"){
			localStorageHelper.set("isWhishListButtonClicked",'yes',1,false);
		}
	},
	addtoWIshListClickedRemoved:function(){
		if(typeof(localStorageHelper) != "undefined"){
			window.localStorage.removeItem('isWhishListButtonClicked');
		}
	},
	InventoryDetailsforItems: {},
    InventoryDetailsDetailsCallCompleted: false,
    bopusInventoryDetailsPopulated: false,
    fetchInventoryDetailsForAllProducts: function(catEntryId, productId) {
        productDisplayJS.bopusShowStoreDetailsCatEntryId = catEntryId;
        productDisplayJS.bopusShowStoreDetailsProductId = productId;
        var inventorySystem = $('#inventorySystem').val();
        if (null != dojo.byId("pdp_atc")) {
            dojo.style("pdp_atc", "display", "block");
        }
        if (null != dojo.byId("splDelvMsg")) {
            var deliveryMsg = dojo.byId("splDelvMsg");
            var deliveryMsgWrapper = dojo.byId("splDelvMsgWrapper");
            var deliveryMsg_clone = dojo.clone(deliveryMsg);
            deliveryMsg_clone.id = "splDelvMsgBopus"
            var deliveryMsgWrapperBopus = dojo.byId("splDelvMsgWrapperBopus");
            if (deliveryMsg != null && deliveryMsgWrapper != null) {
                deliveryMsgWrapper.innerHTML = "";
                dojo.place(deliveryMsg, deliveryMsgWrapper);
                dojo.style("splDelvMsg", "display", "inline");
            }
            if (deliveryMsg_clone != null && deliveryMsgWrapperBopus != null) {
                deliveryMsgWrapperBopus.innerHTML = "";
                dojo.place(deliveryMsg_clone, deliveryMsgWrapperBopus);
                dojo.style("splDelvMsgBopus", "display", "inline");
            }
        }
        var partsList = "";
        var infininteInventoryItem;
        var infinteInvItemList = "";
        if (inventorySystem == -4) {
            for (var item in productDisplayJS.entitledItems) {
                infininteInventoryItem = productDisplayJS.entitledItems[item].isInfiniteInventoryItem;
                if (infininteInventoryItem != 'undefined' && infininteInventoryItem == 'true') {
                    if (infinteInvItemList != "") {
                        infinteInvItemList = infinteInvItemList + ",";
                    }
                    infinteInvItemList = infinteInvItemList + productDisplayJS.entitledItems[item].catentry_id;
                } else {
                    if (partsList != "") {
                        partsList = partsList + ",";
                    }
                    partsList = partsList + productDisplayJS.entitledItems[item].catentry_id;
                }
            }
            if (productDisplayJS.InventoryDetailsDetailsCallCompleted) {
                productDisplayJS.displayInventoryBasedOnFetchedDetails(catEntryId, productId);
            } else {
                productDisplayJS.fetchInventoryDetailsForAllTheProducts(partsList, "", infinteInvItemList);
            }
        }
    },
    fetchInventoryDetailsForAllTheProducts: function(items, stlocId, infiniteInvItems) {
        if (infiniteInvItems != "") {
            var infiniteInvItemsArray = infiniteInvItems.split(",");
            for (var i = 0; i < infiniteInvItemsArray.length; i++) {
                globalInventoryMap[infiniteInvItemsArray[i]]["status"] = "In-Stock";
                globalInventoryMap[infiniteInvItemsArray[i]]["image"] = "widget_product_info/checkmark_icon.png";
                globalInventoryMap[infiniteInvItemsArray[i]]["altText"] = "In-Stock";
            }
        }
        var invetoryJsonDetails = {};
        invetoryJsonDetails["onlineInventory"] = [];
        var counter = 0;
        for (var ix in globalInventoryMap) {
            invetoryJsonDetails["onlineInventory"].push(globalInventoryMap[ix]);
            counter++;
        }
        if (counter == 1) {
            invetoryJsonDetails["onlineInventory"] = {};
            for (var ix in globalInventoryMap) {
                invetoryJsonDetails["onlineInventory"] = globalInventoryMap[ix];
            }
        }

        invetoryJsonDetails["itemCounter"] = counter;
        invetoryJsonDetails["storeCounter"] = 0;
        productDisplayJS.populateInvDetails(invetoryJsonDetails, {});
    }, populateInvDetails: function(serviceResponse, ioArgs) {
        var onlineInventoryDetails = serviceResponse.onlineInventory;
        var inventoryDetails = {};
        if (serviceResponse.itemCounter > 1) {
            for (var i = 0; i < serviceResponse.itemCounter; i++) {
                var individualItems = {};
                individualItems["onlineInventory_status"] = onlineInventoryDetails[i].status;
                individualItems["onlineInventory_image"] = onlineInventoryDetails[i].image;
                individualItems["onlineInventory_altText"] = onlineInventoryDetails[i].altText;
                individualItems["onlineInventory_itemCounter"] = serviceResponse.itemCounter;
                inventoryDetails[onlineInventoryDetails[i].catentryId] = individualItems;
            }
        } else {
            var individualItems = {};
            individualItems["onlineInventory_status"] = onlineInventoryDetails.status;
            individualItems["onlineInventory_image"] = onlineInventoryDetails.image;
            individualItems["onlineInventory_altText"] = onlineInventoryDetails.altText;
            individualItems["onlineInventory_itemCounter"] = serviceResponse.itemCounter;
            inventoryDetails[onlineInventoryDetails.catentryId] = individualItems;
        }
        productDisplayJS.InventoryDetailsDetailsCallCompleted = true;
        productDisplayJS.InventoryDetailsforItems = inventoryDetails;
        buildProductInventoryDataLayer(inventoryDetails, 'onload');
        productDisplayJS.displayInventoryBasedOnFetchedDetails(productDisplayJS.bopusShowStoreDetailsCatEntryId, productDisplayJS.bopusShowStoreDetailsProductId);
    }


    ,
    displayInventoryBasedOnFetchedDetails: function(itemId, productId) {
        var invdetailsforItem = productDisplayJS.InventoryDetailsforItems[itemId];
        var catEntry = productDisplayJS.itemPriceJsonOject[itemId].catalogEntry;
        var isBopusProduct = catEntry.isBopusProduct;
        var isInstoreItem = catEntry.InStoreOnly;
        var isOutOfStock = false;
        var inStock = false;
        if (typeof(invdetailsforItem["onlineInventory_itemCounter"]) != 'undefined' &&
            invdetailsforItem["onlineInventory_itemCounter"] > 0 &&
            isInstoreItem != 'yes') {
            var invStatusTextBopusId = "InventoryStatus_OnlineStatusBopus_" + productId;
            var InventoryStatus_Availability_SectionBopus = "InventoryStatus_Availability_SectionBopus_" + productId;
            dojo.removeClass(invStatusTextBopusId, "in_stock out_of_stock");
            dojo.place("<img id='InventoryStatus_OnlineStatusBopus_Img_" + productId + "' src='" +
                imageDirectoryPath + "\img/" + invdetailsforItem["onlineInventory_image"] +
                "' alt='" + invdetailsforItem["onlineInventory_altText"] + "' border='0' />",
                "InventoryStatus_OnlineStatusBopus_Img_" + productId, "replace");
            if (invdetailsforItem["onlineInventory_status"]) {
                dojo.html.set(dojo.byId("InventoryStatus_OnlineStatusBopus_" + productId), invdetailsforItem["onlineInventory_status"]);
            }
            dojo.style("InventoryStatus_Availability_SectionBopus_" + productId, "display", "block");

            if (document.getElementById("notifybutton") != null) {
                $("#notifybutton").removeClass("show");
                $("#notifybutton").addClass("hide");
            }
            if (document.getElementById("add2CartBtn") != null) {
                document.getElementById("add2CartBtn").style.display = "block";
            }

            if (invdetailsforItem["onlineInventory_status"] && invdetailsforItem["onlineInventory_status"] != 'In-Stock') {
                if ($(".noRdPdpDesign").length > 0) {
                    $("#" + InventoryStatus_Availability_SectionBopus).parent().parent().removeClass("hide");
                }
                dojo.addClass(invStatusTextBopusId, "out_of_stock");
                if (document.getElementById("notice-box") != null) {
                    dojo.style("notice-box", "display", "none");
                }
                if (document.getElementById("add2CartBtn") != null) {
                    document.getElementById("add2CartBtn").style.display = "none";
                    document.getElementById("add2CartBtn").disabled = true;
                }
                $("#add2CartBtn").removeClass("show");
                $("#pdp_atc").parent().removeClass("show");
                $("#notifybutton").removeClass("hide");
                $("#notifybutton").addClass("show");
                if ($("#rd-next-date").parent().length > 0) {
                    $("#rd-next-date").parent().addClass("hide");
                }
                $("#add2CartBtn").addClass("hide");
                $("#pdp_atc").parent().addClass("hide");
                if ($(".product-detailReplacement-page").length > 0) {
                    $("#add2CartBtn_cpy1").removeClass("show");
                    $("#add2CartBtn_cpy1").addClass("hide");
                }
                $("#selectRDOrderBtn").hide();
                $("#selectRDOrderBtnCpy").hide();
                $("#selectRDOrderBtnAddTo").hide();
                $("#selectRDOrderBtn").addClass("hide");
                $("#selectRDOrderBtn").removeClass("show");
                $("#selectRDOrderBtnCpy").addClass("hide");
                $("#selectRDOrderBtnCpy").removeClass("show");

                                $("#selectRDOrderBtnAddTo").addClass("hide");
                $("#selectRDOrderBtnAddTo").removeClass("show");
                if (document.getElementById("notifybutton") != null) {
                    $("#notifybutton").addClass("show");
                    loadreCAPTCHAJSfile();
                }
                if (document.getElementById("selectRDOrderBtn") != null) {
                    document.getElementById("selectRDOrderBtn").disabled = true;
                }
                if (document.getElementById("selectRDOrderBtnCpy") != null) {
                    document.getElementById("selectRDOrderBtnCpy").disabled = true;
                }
                if (document.getElementById("selectRDOrderBtnAddTo") != null) {
                    document.getElementById("selectRDOrderBtnAddTo").disabled = true;
                }
                if (document.getElementById("WC_QuickInfo_Link_addtocart") != null) {
                    document.getElementById("WC_QuickInfo_Link_addtocart").style.cursor = "default";
                    document.getElementById("WC_QuickInfo_Link_addtocart").style.pointerEvents = "none";
                    document.getElementById("WC_QuickInfo_Link_addtocart").style.opacity = "0.65";
                }
                if (document.getElementById("add2RDOrderBtn") != null) {
                    document.getElementById("add2RDOrderBtn").style.cursor = "default";
                    document.getElementById("add2RDOrderBtn").style.pointerEvents = "none";
                    document.getElementById("add2RDOrderBtn").style.opacity = "0.65";
                }
                var rdOptioncontainer = dojo.byId("rd-option-container");
                var rdAddOnOptioncontainer = dojo.byId("rd-add-on");
                if (rdOptioncontainer != null) {
                    dojo.addClass(rdOptioncontainer, "hide");
                    dojo.removeClass(rdOptioncontainer, "show");
                }
                if (rdAddOnOptioncontainer != null) {
                    dojo.addClass(rdAddOnOptioncontainer, "hide");
                    dojo.removeClass(rdAddOnOptioncontainer, "show");
                }
                productDisplayJS.disableAllButtoninpage();
            } else {
                if ($(".noRdPdpDesign").length > 0) {
                    $("#" + InventoryStatus_Availability_SectionBopus).parent().parent().addClass("hide");
                }

                isOutOfStock = true;
                dojo.addClass(invStatusTextBopusId, "in_stock");
                inStock = true;
                if ($("#rd-next-date").parent().length > 0 && document.getElementById("rd-next-date") != null && document.getElementById("rd-next-date").innerHTML != "") {
                    $("#rd-next-date").parent().removeClass("hide");
                }
                var rdOptioncontainer = dojo.byId("rd-option-container");
                if (rdOptioncontainer != null && productDisplayJS.isRdProduct) {
                    dojo.addClass(rdOptioncontainer, "show");
                    dojo.removeClass(rdOptioncontainer, "hide");
                }
                $("#add2CartBtn").removeClass("hide");
                $("#pdp_atc").parent().removeClass("hide");
                $("#notifybutton").removeClass("show");
                $("#notifybutton").addClass("hide");
                $("#add2CartBtn").addClass("show");
                $("#pdp_atc").parent().addClass("show");
                $("#selectRDOrderBtn").show();
                if ($(".product-detailReplacement-page").length > 0) {
                    $("#add2CartBtn_cpy1").removeClass("hide");
                    $("#add2CartBtn_cpy1").addClass("show");
                }
                $("#selectRDOrderBtnCpy").show();
                $("#selectRDOrderBtnAddTo").show();
                $("#selectRDOrderBtn").removeClass("hide");
                $("#selectRDOrderBtn").addClass("show");
                $("#selectRDOrderBtnCpy").removeClass("hide");
                $("#selectRDOrderBtnCpy").addClass("show");
                $("#selectRDOrderBtnAddTo").removeClass("hide");
                $("#selectRDOrderBtnAddTo").addClass("show");
                if (document.getElementById("notice-box") != null && document.getElementById("notice-box") != 'undefined') {
                    if (document.getElementById("pdp-persistent-container") != null && document.getElementById("pdp-persistent-container") != undefined) {
                        if (document.getElementById("pdp-persistent-container").classList.contains("fixed")) {
                            dojo.style("notice-box", "display", "none");
                        } else {
                            dojo.style("notice-box", "display", "block");
                        }
                    }
                }
                if (document.getElementById("selectRDOrderBtn") != null && document.getElementById("selectRDOrderBtn") != 'undefined') {
                    document.getElementById("selectRDOrderBtn").disabled = false;
                }
                if (document.getElementById("selectRDOrderBtnCpy") != null && document.getElementById("selectRDOrderBtnCpy") != 'undefined') {
                    document.getElementById("selectRDOrderBtnCpy").disabled = false;
                }
                if (document.getElementById("selectRDOrderBtnAddTo") != null && document.getElementById("selectRDOrderBtnAddTo") != 'undefined') {
                    document.getElementById("selectRDOrderBtnAddTo").disabled = false;
                }

                if (document.getElementById("WC_QuickInfo_Link_addtocart") != null && document.getElementById("WC_QuickInfo_Link_addtocart") != 'undefined') {
                    document.getElementById("WC_QuickInfo_Link_addtocart").style.cursor = "pointer";
                    document.getElementById("WC_QuickInfo_Link_addtocart").style.pointerEvents = "auto";
                    document.getElementById("WC_QuickInfo_Link_addtocart").style.opacity = "1.0";
                }
                if (document.getElementById("add2RDOrderBtn") != null && document.getElementById("add2RDOrderBtn") != 'undefined') {
                    document.getElementById("add2RDOrderBtn").style.cursor = "pointer";
                    document.getElementById("add2RDOrderBtn").style.pointerEvents = "auto";
                    document.getElementById("add2RDOrderBtn").style.opacity = "1.0";
                }
                productDisplayJS.enableAllButton = true;
                productDisplayJS.enableAllButtoninpage(); 

            }




        }



        var addToCartButton = dojo.byId("add2CartBtn");
        var addToCartButton_cpy1 = dojo.byId("add2CartBtn_cpy1");
        var addToCartButton_cpy2 = dojo.byId("add2CartBtn_cpy2");
        var notifyButton = dojo.byId("notifybutton");
        var notifyButton_cpy1 = dojo.byId("notifybutton_cpy1");

        if (addToCartButton != null) {
            if (addToCartButton_cpy1 != null) {
                var addToCartButton_cpy1_clone = dojo.clone(addToCartButton);
                addToCartButton_cpy1_clone.id = "add2CartBtn_cpy1";
                dojo.place(addToCartButton_cpy1_clone, 'add2CartBtn_cpy1', 'replace');
            }
            if (addToCartButton_cpy2 != null) {
                var addToCartButton_cpy2_clone = dojo.clone(addToCartButton);
                addToCartButton_cpy2_clone.id = "add2CartBtn_cpy2";
                dojo.place(addToCartButton_cpy2_clone, 'add2CartBtn_cpy2', 'replace');
            }
        }

        if (notifyButton != null) {
            if (notifyButton_cpy1 != null) {
                var notifyButton_cpy1_clone = dojo.clone(notifyButton);
                notifyButton_cpy1_clone.id = "notifybutton_cpy1";
                dojo.place(notifyButton_cpy1_clone, 'notifybutton_cpy1', 'replace');
            }
        }

        var inStoreSku = false;
        for (var x in productDisplayJS.entitledItems) {
            var catId = productDisplayJS.entitledItems[x].catentry_id;
            if (typeof(itemId) != "undefined" && catId == itemId) {
                var inStore = productDisplayJS.entitledItems[x].InStoreOnly;
                if (inStore == 'true') {
                    inStoreSku = true;
                }
            }
        }
        if (inStoreSku) {
            if (null != dojo.byId("add-to-controls") && isBopusProduct != 'yes') {
                dojo.style("add-to-controls", "display", "none");
            }

            if (null != dojo.byId("notifyMe"))
                dojo.style("notifyMe", "display", "none");
            if (null != dojo.byId("pdp_atc") && isBopusProduct != 'yes') {
                dojo.style("pdp_atc", "display", "none");
            }

            if (null != dojo.byId("splDelvMsg"))
                dojo.style("splDelvMsg", "display", "none");
        } else {
            if (null != dojo.byId("deliveryDateMsg")) {
                dojo.style("deliveryDateMsg", "display", "inline");
            }
        }


        if (dojo.byId("tabselected") != null) {
            dojo.byId("tabselected").value = '';
        }

        if ($(".instore-deliveryBox").length == 0 && $(".paramStore-deliveryBox").length == 0 && !inStoreSku) {
            productDisplayJS.correctAndFixButtonBasedOnSelection(isOutOfStock);
        }

        if ($(".delivery-option-input:checked").length > 0 && $(".delivery-option-input:checked").length == 1 && productDisplayJS.bopusShowStoreDetailsProductId != "") {

            productDisplayJS.refreshActionButton($(".delivery-option-input:checked").val(), 'entitledItem_' + productDisplayJS.bopusShowStoreDetailsProductId);
        }
        document.getElementById("restrictionMessage").innerHTML = "";
        if (!inStoreSku) {
            var zipcode = productDisplayJS.getZipCodeForRestrictedCheck();
            document.getElementById("restrictionMessage").innerHTML = "";
            var brand = document.getElementById("brand_pdp").value;
            if (zipcode != null) {
                isRestricted(itemId, zipcode, brand);
            }
        }
        if (document.getElementById("HolidayShipMsgFlag") != null) {
            if (document.getElementById("HolidayShipMsgFlag").value == 'Y') {
                if (inStoreSku || !inStock) {
                    if (document.getElementById("ShippingMessage") != null) {
                        $("#ShippingMessage").addClass("hide");
                    }
                } else {
                    if (null != dojo.byId("ShippingMessage")) {
                        dojo.style("ShippingMessage", "display", "inline");
                    }
                    getShippingMessage(productDisplayJS.storeId, productDisplayJS.catalogId, productDisplayJS.langId, '', 'Y', itemId, 'N');
                }

            }
        }
    },
    currentStlocId:"",
    fetchBOPUSInventoryDetailsForAllProducts: function(catEntryId, productId) {
		var stlocId = productDisplayJS.getStoreIdFromPhyicalSToreIdCookie();

	    productDisplayJS.definngaAttributeResolved = true;
            productDisplayJS.bopusShowStoreDetailsCatEntryId = catEntryId;
            productDisplayJS.bopusShowStoreDetailsProductId = productId;
    		var inventorySystem = $('#inventorySystem').val();
    		var partsList = "";
    		var infininteInventoryItem;
    		var infinteInvItemList = "";
    		if(inventorySystem == -4) {
    			for(var item in productDisplayJS.entitledItems){
    				infininteInventoryItem = productDisplayJS.entitledItems[item].isInfiniteInventoryItem;
    				if(infininteInventoryItem != 'undefined' && infininteInventoryItem == 'true') {
    					if(infinteInvItemList !=""){
    						infinteInvItemList = infinteInvItemList+",";
    					}
    					infinteInvItemList = infinteInvItemList + productDisplayJS.entitledItems[item].catentry_id;
    				} else {
    					if(partsList !=""){
    						partsList = partsList+",";
    					}
    					partsList = partsList + productDisplayJS.entitledItems[item].catentry_id;
    				}			
    			}	
    				var isBOPUSEnabledItem = $("#isBOPUSEnabledItem").val();
    				var isBOPUSEnabledItemflag = false;
    				if(isBOPUSEnabledItem != null && isBOPUSEnabledItem == "yes"){
    					isBOPUSEnabledItemflag = true;
    				}
    				if(isBOPUSEnabledItemflag){

    					    					if(productDisplayJS.bopusInventoryDetailsPopulated ){
    						productDisplayJS.displayBopusInventoryBasedOnFetchedDetails(catEntryId,productId);
    					} else {
    						if(stlocId != "" && stlocId != "NA"){
    							productDisplayJS.fetchBopusInventoryDetailsForAllTheProducts(partsList,stlocId, infinteInvItemList);
    						}else if(stlocId != "NA"){
    							var iterationCount = 7;
    							var isCallHappened=false;
    							var IntervalValue = setInterval(function(){
    							stlocId = productDisplayJS.getStoreIdFromPhyicalSToreIdCookie();
    							if(stlocId != "" && stlocId != "NA"){
    								isCallHappened = true;
    								clearTimeout(IntervalValue);
    								productDisplayJS.fetchBopusInventoryDetailsForAllTheProducts(partsList,stlocId,infinteInvItemList);
    							} else if(stlocId == "NA"){							
    								clearTimeout(IntervalValue);
    							}
    							if(iterationCount == 0 ){
    								clearTimeout(IntervalValue);
    								if( !isCallHappened){
    									productDisplayJS.fetchBopusInventoryDetailsForAllTheProducts(partsList,"",infinteInvItemList);
    								}
    							}		
    							iterationCount--;
    							},200)					
    						}else if(stlocId == "NA"){
    							productDisplayJS.fetchBopusInventoryDetailsForAllTheProducts(partsList,"",infinteInvItemList);
    						}
    					}
    				}
    		} 
    },

    	fetchBopusInventoryDetailsForAllTheProducts:function(items, stlocId, infiniteInvItems){
    		if(infiniteInvItems == "" && items == ""){
    			return;
    		}
    		productDisplayJS.currentStlocId = stlocId;
    		var params = new Object();
    		params.itemId = items;
    		params.langId = productDisplayJS.langId;
    		params.storeId = productDisplayJS.storeId;
    		params.catalogId = productDisplayJS.catalogId;		
    		var inventorySystem = $('#inventorySystem').val();
    		if(inventorySystem == -4) {
    			var isBOPUSEnabledItemflag = false;
    			var isBOPUSEnabledItem = dojo.byId("isBOPUSEnabledItem");
    			if(isBOPUSEnabledItem != null && isBOPUSEnabledItem.value == "yes"){
    				if(stlocId != 'undefined' && stlocId != ""){
    					isBOPUSEnabledItemflag = true;
    					params.storeLoc = stlocId;
    				}			
    			}
    			if(infiniteInvItems != "") {
    				params.infiniteInvItems = infiniteInvItems;
    			}
    			params.fetchStoreInventory = isBOPUSEnabledItemflag;
    		} else {
    			params.fetchStoreInventory = false;	
    		}
    		if(params.fetchStoreInventory){
    			if(typeof(productDisplayJS.cachedBopusInventoryDetails[stlocId]) != "undefined"){
    				productDisplayJS.populateBOPUSInvDetails(productDisplayJS.cachedBopusInventoryDetails[stlocId],null);
    			}else{
        			dojo.xhrPost({
    					url: getAbsoluteURL() + "GetInventoryStatusByIDView",
    					handleAs: "json-comment-filtered",
    					content: params,
    					service: this,
    					load: productDisplayJS.populateBOPUSInvDetails,
    					error: function(errObj,ioArgs) {
    						console.log(storeNLS["INV_STATUS_RETRIEVAL_ERROR"]);
    					}
    			});

    				    			}
    		}
    	},
    	cachedBopusInventoryDetails:{},
    	populateBOPUSInvDetails:function(serviceResponse, ioArgs){
    		productDisplayJS.cachedBopusInventoryDetails[productDisplayJS.getStoreIdFromPhyicalSToreIdCookie()] = serviceResponse;
    		var inventoryDetails = {};
    		var inventorySystem = $('#inventorySystem').val();
    		if(inventorySystem == -4) {
    			productDisplayJS.InventoryDetailsforBOPUSItems = {};
    			if(typeof(serviceResponse.inStoreInventory) != 'undefined'){
    				var storeInventoryDetails = serviceResponse.inStoreInventory.stores; 
    				for(var j = 0 ; j < serviceResponse.storeCounter ; j++) {
    					var individualItems = {};
    					if(typeof(inventoryDetails[storeInventoryDetails[j].catentryId]) != "undefined"){
    						individualItems = inventoryDetails[storeInventoryDetails[j].catentryId];
    					}
    					var catEntryId = storeInventoryDetails[j].catentryId;
    					var availableQuantity = storeInventoryDetails[j].availableQuantity;
    					var alertLevel = storeInventoryDetails[j].alertLevel;
    					var stlocId = storeInventoryDetails[j].id;	
    					var stlocIdentifier = storeInventoryDetails[j].name;	

    					    					if(!productDisplayJS.isBopusEligibileForStore(stlocId,catEntryId)){
    						alertLevel = 'RED';
    					}
    					buildBopusProductInventoryDataLayer(alertLevel,stlocIdentifier);
    					individualItems["inStoreInventory_availableQuantity_"+stlocId] = storeInventoryDetails[j].availableQuantity;
    					individualItems["inStoreInventory_alertLevel_"+stlocId] = alertLevel;
    					inventoryDetails[catEntryId] = individualItems;
    				}
    				productDisplayJS.bopusInventoryDetailsPopulated = true;
    			}
    		} 	
    		productDisplayJS.InventoryDetailsforBOPUSItems = inventoryDetails;
    		productDisplayJS.displayBopusInventoryBasedOnFetchedDetails(productDisplayJS.bopusShowStoreDetailsCatEntryId,productDisplayJS.bopusShowStoreDetailsProductId );
    	},
    	displayBopusInventoryBasedOnFetchedDetails : function(itemId,productId){ 

    		    		var invdetailsforItem = productDisplayJS.InventoryDetailsforBOPUSItems[itemId];
    		if(typeof(invdetailsforItem)== "undefined"){
    			return;
    		}
    		var catEntry = productDisplayJS.itemPriceJsonOject[itemId].catalogEntry;
    		var isBopusProduct = catEntry.isBopusProduct;
    		var isInstoreItem = catEntry.InStoreOnly;
    		var isOutOfStock = false;
    		var inStock = false;

    			    		var inventorySystem = $('#inventorySystem').val();
    		if(inventorySystem == -4){
    			var instockstatus = dojo.byId("bopusdeailsInStock");
    			var outstockstatus = dojo.byId("bopusdeailsNotAvailable");
    			var lowstockstatus = dojo.byId("bopusdeailsLowInventory");	
    			var instockstatusSelectable = dojo.byId("bopusdeailsInStockSelectable");
    			var outstockstatusSelectable = dojo.byId("bopusdeailsNotAvailableSelectable");
    			var lowstockstatusSelectable = dojo.byId("bopusdeailsLowInventorySelectable");	
    			var storeId = productDisplayJS.getStoreIdFromPhyicalSToreIdCookie();
    			if(storeId != "" && storeId != "NA"){			
    				dojo.forEach(dojo.query(".bopustockavailabilitymsg"),function(obj){
    					dojo.removeClass(obj,"show");
    				})
    				dojo.forEach(dojo.query(".bopustockavailabilitymsg"),function(obj){
    					dojo.addClass(obj,"hide");
    				})

    				dojo.query("#InventoryStatus_InStore_Section_"+productId).orphan();			
    				if(invdetailsforItem["inStoreInventory_alertLevel_"+storeId] == 'RED'){
    					if(lowstockstatus != null){
    						dojo.addClass(outstockstatus,"show");
    						dojo.removeClass(outstockstatus,"hide");
    					}
    					if(outstockstatusSelectable != null){
    						dojo.addClass(outstockstatusSelectable,"show");
    						dojo.removeClass(outstockstatusSelectable,"hide");
    					}

    					    				}else if(invdetailsforItem["inStoreInventory_alertLevel_"+storeId] == 'GREEN'){
    					if(instockstatus != null){
    						dojo.addClass(instockstatus,"show");
    						dojo.removeClass(instockstatus,"hide");
    					}
    					if(instockstatusSelectable != null){
    						dojo.addClass(instockstatusSelectable,"show");
    						dojo.removeClass(instockstatusSelectable,"hide");
    					}
    				}else{
    					if(lowstockstatus != null){
    						dojo.addClass(lowstockstatus,"show");
    						dojo.removeClass(lowstockstatus,"hide");
    					}
    					if(lowstockstatusSelectable != null){
    						dojo.addClass(lowstockstatusSelectable,"show");
    						dojo.removeClass(lowstockstatusSelectable,"hide");
    					}
    				}		
    			}
    			if(dojo.query(".bopustockavailabilitymsg.show").length==0 && outstockstatus != null ){
    				dojo.addClass(outstockstatus,"show");
    				dojo.removeClass(outstockstatus,"hide");
    			}
    			if(dojo.query(".bopustockavailabilitymsg.show").length==0 && outstockstatusSelectable != null ){
    				dojo.addClass(outstockstatusSelectable,"show");
    				dojo.removeClass(outstockstatusSelectable,"hide");
    			}

    		    			if(($('#bopusdeailsNotAvailableSelectable.show').length > 0 || $('#bopusdeailsLowInventorySelectable.show').length > 0) && $('#add2CartBtn_bopus').length > 0){
    				$('#add2CartBtn_bopus')[0].innerHTML="Not Available";
    				$('#add2CartBtn_bopus')[0].disabled = true;
    			}else if(($('#bopusdeailsInStockSelectable.show').length > 0) && $('#add2CartBtn_bopus').length > 0){
    				$('#add2CartBtn_bopus')[0].innerHTML="Add to Cart";
    				$('#add2CartBtn_bopus')[0].disabled = false;
    				if($("#pdp_atc").parent().length > 0 && $("#pdp_atc").parent()[0].classList.contains("hide")){
    					 $("#pdp_atc").parent().removeClass('hide');
    				}
    			}
    		}


    	    		    		var inStoreSku = false;
    		for (var x in productDisplayJS.entitledItems) {
    			var catId = productDisplayJS.entitledItems[x].catentry_id;
    			if(typeof(itemId) != "undefined" && catId == itemId){
    				var inStore = productDisplayJS.entitledItems[x].InStoreOnly;
    				if(inStore == 'true'){
    					inStoreSku = true;
    				}
    			}
    		}

    		    		if(($('#bopusdeailsNotAvailableSelectable.show').length > 0 || $('#bopusdeailsLowInventorySelectable.show').length > 0) && $('#add2CartBtn_bopus').length > 0){
    			$('#add2CartBtn_bopus')[0].innerHTML="Not Available";
    			$('#add2CartBtn_bopus')[0].disabled = true;
    		}else if(($('#bopusdeailsInStockSelectable.show').length > 0) && $('#add2CartBtn_bopus').length > 0){
    			$('#add2CartBtn_bopus')[0].innerHTML="Add to Cart";
    			$('#add2CartBtn_bopus')[0].disabled = false;
    			if($("#pdp_atc").parent().length > 0 && $("#pdp_atc").parent()[0].classList.contains("hide")){
    				 $("#pdp_atc").parent().removeClass('hide');
    			}
    		}
    	},
    bopusShowStoreDetailsBasedOnLocaltionCallComplete:function(task){
    		var iterationCount = 25;
    		var inventorySystem = $('#inventorySystem').val();
    		var IntervalValue = setInterval(function(){
    			if(productDisplayJS.definngaAttributeResolved){
    				clearTimeout(IntervalValue);
    				productDisplayJS.bopusShowStoreDetails(productDisplayJS.bopusShowStoreDetailsCatEntryId,productDisplayJS.bopusShowStoreDetailsProductId);
    			}
    			if(iterationCount == 0){

    				    				clearTimeout(IntervalValue);
    			}		
    			iterationCount--;
    		},50);
    		if(inventorySystem == -4) {
    			if(productDisplayJS.definngaAttributeResolved){				
    				if(productDisplayJS.bopusInventoryDetailsPopulated == false){
    					productDisplayJS.fetchBOPUSInventoryDetailsForAllProducts(productDisplayJS.bopusShowStoreDetailsCatEntryId,productDisplayJS.bopusShowStoreDetailsProductId);
    				}
    			}
    		}
    		$("#repeat-delivery-select").removeClass("paramStore-deliveryBox");
    		if(task == 'show'){
    			if($("#store-pickup").length > 0){
    				 var urlParam = window.location.search.substring(1);
    				 if( urlParam != "" ){
    					 var urlParamArray = urlParam.split("&");
    					 for( var i = 0 ; i < urlParamArray.length ; i++ ){
    						 var urlParamArrayVal = urlParamArray[i].split("=");
    						 if(urlParamArrayVal[0] == 'store_code' && urlParamArrayVal[1].trim() != '' ){
    			$("#repeat-delivery-select").addClass("paramStore-deliveryBox");
    				$("#store-pickup")[0].click();
    			}
    		}
    				 }
    			}
    		}
    	},

    		InventoryDetailsforBOPUSItems:{},
	resetAddToCartollPosition:function(){
		if(dojo.query('div#pdp-persistent-spacer div#pdp-persistent-placeholder').length >0 ){
			if(dojo.byId("pdp-persistent-placeholder") != null && dojo.byId("pdp-persistent-placeholder") != null  ){
				dojo.place('pdp-persistent-placeholder','pdp-product-info',"after");
			}
		}
	},


	getShipModeIdForDefault:function(){
		var defaultShipMode = "";
		var LOCALSTORAGE_STORES_KEY = 'petcostores';
		var oShipModes = JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) != null ? JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) : {};
		if(typeof(oShipModes.shipmodes) != "undefined" && oShipModes.shipmodes.length > 0 ){
			var shipmodes = JSON.parse(oShipModes.shipmodes);
			if(typeof(shipmodes.DEFAULT_SHIPMODE) != "undefined"){
				defaultShipMode = shipmodes.DEFAULT_SHIPMODE;
			}
		}
		return defaultShipMode;
	},
	getZipCodeForRestrictedCheck:function(){
		var zipcode = null;
		var LOCALSTORAGE_STORES_KEY = 'petcostores';
		var zipcodes = JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) != null ? JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) : {};
		if(typeof(zipcodes.petcostores) != "undefined" && zipcodes.petcostores.length > 0 ){
			var nearestZip = JSON.parse(zipcodes.petcostores);
			if(nearestZip.length > 0){
				zipcode = JSON.parse(zipcodes.petcostores)[0].ZIPCODE;
			}
		}
		return zipcode;
	},
	getFfmCenterDefault:function(){
		var defaultFfmCeter = "";
		var LOCALSTORAGE_STORES_KEY = 'petcostores';
		var oShipModes = JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) != null ? JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) : {};
		if(typeof(oShipModes.shipmodes) != "undefined" && oShipModes.shipmodes.length > 0 ){
			var shipmodes = JSON.parse(oShipModes.shipmodes);
			if(typeof(shipmodes.DEFAULT_FFMCENTER) != "undefined"){
				defaultFfmCeter = shipmodes.DEFAULT_FFMCENTER;
			}
		}
		return defaultFfmCeter;
	},
	getShipModeIdForBOPUS:function(){
		var bopusShipMode = "";
		var LOCALSTORAGE_STORES_KEY = 'petcostores';
		var oShipModes = JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) != null ? JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) : {};
		if(typeof(oShipModes.shipmodes) != "undefined" && oShipModes.shipmodes.length > 0 ){
			var shipmodes = JSON.parse(oShipModes.shipmodes);
			if(typeof(shipmodes.BOPUS_SHIPMODE) != "undefined"){
				bopusShipMode = shipmodes.BOPUS_SHIPMODE;
			}
		}
		return bopusShipMode;
	},
	getStoreIdFromPhyicalSToreIdCookie:function(){
		var storeId = "";
		var physicalStoreCookie = dojo.cookie("WC_physicalStores");
		if(typeof(physicalStoreCookie) != "undefined"){
			var stores = dojo.cookie("WC_physicalStores").split(",");
			if(stores.length > 0 && stores[0] != "undefined"){
				storeId = stores[0];
			}
		}
		if(storeId == ""){
			var LOCALSTORAGE_STORES_KEY = 'petcostores';
			var oNeareststores = JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) != null ? JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) : {};
			if(typeof(oNeareststores.petcostores) != "undefined" && oNeareststores.petcostores.length > 0 ){
				var nearestSTores = JSON.parse(oNeareststores.petcostores);
				if(nearestSTores.length > 0){
					storeId = JSON.parse(oNeareststores.petcostores)[0].STLOC_ID;
				}
			}else if(typeof(oNeareststores.petcostores) == "undefined" && typeof(oNeareststores.userDetails) != "undefined"){
				storeId = 'NA';
			}

					}

				return storeId;
	},

		removeQuotes: function(str) {
		if (str) {
			return str.replace(/&#039;/g, '').replace(/\\'/g, '').replace(/'/g, '');
		} else {
			return str; }
		},
	correctAndFixButtonBasedOnSelection:function(isOutOfStock){
		var rdOptionsSelectarray = dojo.query('input[name="rdOptionsSelect"][type=radio]:checked');
		if(rdOptionsSelectarray.length > 0 ){
			var rdOptionsSelectVal = rdOptionsSelectarray[0].value;
			if(!isOutOfStock){
				var rdOptionsSelectarray = dojo.query('input#one-time-delivery[name="rdOptionsSelect"][type=radio]');
				if(rdOptionsSelectarray.length > 0){
					rdOptionsSelectarray[0].checked = true;

					if($(".noRdPdpDesign").length == 0){
				  var $radioButtons = $("input[type='radio'].delivery-option-input");
				  $radioButtons.parents('.radio').removeClass('selected');
			      $radioButtons.filter(':not(:checked)').removeAttr('checked');
			      $radioButtons.filter(':checked').attr('checked', '');
			      $radioButtons.filter(':checked').parents('.radio').addClass('selected');
			      if($('#store-pickup-dropdown').length > 0){
			          if($('#store-pickup:checked.store-pickup-option-input').length > 0){
			     	$('#store-pickup-dropdown').removeClass('half').addClass('open');
			     }else{
			     	$('#store-pickup-dropdown').addClass('half').removeClass('open');
			     }
			      }
}     

							}
			}else{

				if(rdOptionsSelectVal == 'repeat-delivery-add-on'){
					if(dojo.byId("selectRDOrderBtn") != null){
					dojo.addClass("selectRDOrderBtn","show");
					dojo.removeClass("selectRDOrderBtn","hide");
					}
					if(dojo.byId("add2CartBtn") != null){
					dojo.removeClass("add2CartBtn","show");
					dojo.addClass("add2CartBtn","hide");
					}

									}else{

										if(document.getElementById('itemOrProd') != null && document.getElementById('itemOrProd') != undefined
					&& $("input#store-pickup:checked").length == 0 && $("#repeat-delivery-select-btn.instore-deliveryBox").length == 0 && $("#repeat-delivery-select-btn.paramStore-deliveryBox").length == 0 ){
						var itemOrProd = document.getElementById('itemOrProd').value;
						var rdNotDisplay = document.getElementById('rdNotDisplay').value;
						if(itemOrProd == 'Item'){
							document.getElementById("one-time-delivery").click();
						} else if(itemOrProd == 'Prod'){
							if(rdNotDisplay == 'Yes') {
								if($("#repeat-delivery-radio:checked").length > 0){
									$("#repeat-delivery-radio")[0].checked = true;
								}
								$("#repeat-delivery-radio").trigger("click");
							} else {
								if($("#one-time-delivery:checked").length > 0){
									$("#one-time-delivery")[0].checked = true;
								}
								$("#one-time-delivery").trigger("click");
							}

													}
					}

									}
			}
		}
	},
	disableAllButtoninpage: function(){
		$("#selectRDOrderBtn").prop("disabled",true);
		$("#add2CartBtn").prop("disabled",true);
		$("#add2CartBtn_cpy").prop("disabled",true);
		$("#add2CartBtn_cpy1").prop("disabled",true);
		$("#add2CartBtn_cpy2").prop("disabled",true);
		$("#selectRDOrderBtnCpy").prop("disabled",true);
		$("#selectRDOrderBtnAddTo").prop("disabled",true);
	},
	enableAllButtoninpage: function(){
		$("#selectRDOrderBtn").prop("disabled",false);
		$("#add2CartBtn").prop("disabled",false);
		$("#add2CartBtn_cpy").prop("disabled",false);
		$("#add2CartBtn_cpy1").prop("disabled",false);
		$("#add2CartBtn_cpy2").prop("disabled",false);
		$("#selectRDOrderBtnCpy").prop("disabled",false);
		$("#selectRDOrderBtnAddTo").prop("disabled",false);
	},
    loadRxLegalInformation:function(){
		var catEntry = productDisplayJS.itemPriceJsonOject[productDisplayJS.bopusShowStoreDetailsCatEntryId].catalogEntry;
		var partnumber = catEntry.catalogEntryIdentifier.externalIdentifier.partNumber;
    	if($("#rxLegalInfoUrl").length > 0){
    		var url=$("#rxLegalInfoUrl").val();
    		url = url+"/"+partnumber+"/?format=json";
    	 	$.ajax({
    	 		url : url,
    	 		type : 'GET',
    	 		 cache: false,
    	 		contentType: 'application/json',
    	 		dataType: 'json',
    	 		data: '',
    	 		success: function(data){
    	 		if(data.pharmainfo != null){
    	 			$('div#rxMoreInformation div.rxMoreInformationContainer').html(data.pharmainfo.html);
    	 		}else{
    	 			$('div#rxMoreInformation div.rxMoreInformationContainer').html("");
	}
    	 		},
    	 		error: function(){
    	 			console.log(storeNLS["INV_STATUS_RETRIEVAL_ERROR"]);
    	 		}
    	 	});  
    	}
    },
    isBopusEligibileForStore:function(StoreId,catentryid){

    			var LOCALSTORAGE_STORES_KEY = 'petcostores';
		var oNeareststores = JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) != null ? JSON.parse(localStorage.getItem(LOCALSTORAGE_STORES_KEY)) : {};
		if(typeof(oNeareststores.petcostores) != "undefined" && oNeareststores.petcostores.length > 0 ){
			var nearestSTores = JSON.parse(oNeareststores.petcostores);
			var vflag = 'N';
			if(nearestSTores.length == 1 && typeof(nearestSTores) != "undefined" && typeof(nearestSTores.BOPUS) != "undefined" ){
				vflag = nearestSTores.BOPUS;
			}else if(nearestSTores.length > 0  && typeof(nearestSTores) != "undefined" && typeof(nearestSTores[0].BOPUS) != "undefined" ){
				vflag = nearestSTores[0].BOPUS;
			}
			if(vflag == "Y" && $('#CurrentBOPUSState').length > 0 ){

								var currentbopuststae = $('#CurrentBOPUSState').val();

								var catEntryObj = productDisplayJS.itemPriceJsonOject[catentryid];
				var isBOPUSAreaExcludedItemArray = {};
				for (x in productDisplayJS.entitledItems) {
					if(typeof(productDisplayJS.entitledItems[x].isBOPUSAreaExcludedItem) != 'undefined'){
						isBOPUSAreaExcludedItemArray[productDisplayJS.entitledItems[x].catentry_id] = productDisplayJS.entitledItems[x].catentry_id;
					}

									}

								if(typeof(isBOPUSAreaExcludedItemArray[catentryid]) != 'undefined'){
					var isBOPUSAreaRestictionItem = isBOPUSAreaExcludedItemArray[catentryid];
					if($("#isBOPUSAreaRestictionEnabeled").length > 0 && $("#isBOPUSAreaRestictionEnabeled").val() == 'true'
						&& isBOPUSAreaRestictionItem == catentryid){
						var states = "";
						if($("#BOPUSAreaRestictionState").length > 0){
							states = $("#BOPUSAreaRestictionState").val();
							var stateArray = states.split(",");

														for(var ix = 0 ; ix < stateArray.length ; ix++){
								if(stateArray[ix] == currentbopuststae){
									vflag = "N";
								}
							}
						}
					}
				}


								}
			if(vflag == "Y"){
				return true;
			}else{
			 return false;
			}

					}else if(typeof(oNeareststores.petcostores) == "undefined" && typeof(oNeareststores.userDetails) != "undefined"){
			return false;
		}


            },
    evaluateInventoryQuanityBeforeAddToCart: function(catentry,Quanity){
    	if(productDisplayJS.bopusShowStoreDetailsProductId == catentry){
    		var inventoryDetails = productDisplayJS.InventoryDetailsforItems[productDisplayJS.bopusShowStoreDetailsCatEntryId];
        	var inQuanity = inventoryDetails["inStoreInventory_availableQuantity_"+productDisplayJS.getStoreIdFromPhyicalSToreIdCookie()];
        	if(!isNaN(inQuanity)){
        		if(Quanity*1 > inQuanity*1){
        			var message = "";
        			var messageVal = $("#bupsQuantityErrorMessage");
        			if(messageVal.length > 0){
        				message = $(messageVal).html().trim().replace("{0}",inQuanity*1);
        			}
        			  $('#modal-notification-alert-message').html(message);
                    	  $('#modal-notification-alert .alert').addClass('error-box');
                      petcoCommonJS.showModal('modal-notification-alert');
        			return false;
        		}
        	}

    		    	}
    	return true;
    },
	deliverDateMessages:null,
    invokeDeliveryDateAPIForPDP:function(catEntryId, productId){
    	var partsList = "";
    	var samedayList = "";
    	var resolvedItemPartNum;
		for(var item in productDisplayJS.entitledItems){
			if(partsList !=""){
				partsList = partsList+",";
			}
			if(catEntryId == productDisplayJS.entitledItems[item].catentry_id){
				resolvedItemPartNum = productDisplayJS.entitledItems[item].PartNum;
				var isSamedayEligible = productDisplayJS.entitledItems[item].isSamedayEligibleItem;
				if(isSamedayEligible)
					samedayList = resolvedItemPartNum
			}
			var partnumber = productDisplayJS.entitledItems[item].PartNum;
			partsList = partsList + partnumber;
		}
		if(partsList == ""){
			return;
		}
		var zipCode = null;
		if(document.getElementById('sameday-zipcode')!= null &&  document.getElementById('sameday-zipcode') != undefined){
			zipCode = document.getElementById('sameday-zipcode').innerHTML;
			if(zipCode == null)
				zipCode= productDisplayJS.getZipCodeForSameday();
			if(zipCode == null)
				if($("#SDdefaultZip").length > 0){
					zipCode = $("#SDdefaultZip").val();
				}
		}

				var quantity = $('#quantity_'+productId).val();
		var params = [];
		params["catEntryId"] = catEntryId;
		params["storeId"] = this.storeId;
		params["catalogId"] = this.catalogId;
		params["langId"] = this.langId;
		params["productList"] = partsList;
		params["quantity"] = quantity;
		params["fromPage"] = "PDP";
		params["resolvedItem"] = resolvedItemPartNum;
		params["zipCode"] = zipCode;
		params["productId"] = productId;
		params["eligibleSDProductList"] =samedayList;
		var invokeCommitsAPI = false;

				if('true' == $("#isDynamicDevliveryDateActive").val()){
			invokeCommitsAPI = true;
		}else if('true' == $("#isDynamicDevliveryDateActiveEAOBO").val()){
			var isEASession = dojo.cookie('isEASession');
			var isOBOSession = dojo.cookie('isOBOSession');
			if (isEASession == "yes" || isOBOSession == "yes"){
				invokeCommitsAPI = true;
			}
		}

				if(invokeCommitsAPI){
			if(productDisplayJS.deliverDateMessages == null || quantity > 1){
				wc.service.invoke("AjaxInvokeDeliveryDateAPI",params);
			}else{
				var dateTimeString =  productDisplayJS.deliverDateMessages[resolvedItemPartNum];
				var dateTime = [];
				dateTime = dateTimeString.split("|");

									var cutoffTime =  new Date(dateTime[1]);
				var currentDate =  new Date();
				if(cutoffTime > currentDate){
					productDisplayJS.displayDeliveryDateMsg(resolvedItemPartNum,productDisplayJS.deliverDateMessages);
				}else{
					wc.service.invoke("AjaxInvokeDeliveryDateAPI",params);
				}
			}
		}
	},

		displayDeliveryDateMsg:function(resolvedItem, itemDeliveryDate){

				var dateTimeString =  itemDeliveryDate[resolvedItem];
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

				var dateTime = [];
		dateTime = dateTimeString.split("|");

					var deliveryDate = new Date(dateTime[0]);
		var cutoffTime =  new Date(dateTime[1]);
		var currentDate =  new Date();

		 		var dateDiff = cutoffTime.getDate() - currentDate.getDate();
		var dateString

				switch(dateDiff) {
		  case 0:
			  dateString= "Today";
		    break;
		  case 1:
			  dateString= "Tomorrow";
		    break;
		  default:
			  dateString= days[deliveryDate.getDay()];
		}



				document.getElementById("deliverydate").innerHTML = days[deliveryDate.getDay()] + " , " + deliveryDate.getDate() + " " +months[deliveryDate.getMonth()]; ;
		if (document.getElementById("HolidayShipMsgFlag") != null && document.getElementById("HolidayShipMsgFlag").value == 'Y') {

		if(holidayMsgCallCompleted && !holidayPreCutOff){
			$("#deliveryDateMsg").removeClass("hide");
		}
		else if(holidayMsgCallCompleted && holidayPreCutOff && !$("#deliveryDateMsg").hasClass("hide")){
			$("#deliveryDateMsg").addClass("hide");
		}
		else{
			$("#deliveryDateMsg").removeClass("hide");	
		}
		} else{
			$("#deliveryDateMsg").removeClass("hide");		
		}

				},
		invokeEspot:function(catEntryId){
			if(typeof productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry != 'undefined'){
			var espotCtx=productDisplayJS.getEspotContextDetails();
			var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
			var param1 =productDisplayJS.getOrderTypeSelected();
			var param2 = catEntry.offerPrice;
			var emsName = "PriceStack_Promo1";
			try{
				if(null !=param2 && "" != param2){
					var url =getAbsoluteURL() + 'PetcoRefreshMarketingContents';
					var params = {};
					params.substitutionName10='OrderType';
					params.substitutionValue10=param1;
					params.substitutionName12='itemPrice';
					params.substitutionValue12=param2;
					params.emsName=emsName;
					params.catalogEntryId=espotCtx.productId;
					params.custom_view="true";
					params.customTarget='PDP';
					params.categoryId=espotCtx.categoryId;
					params.parent_category_rn=espotCtx.parent_category_rn;
					params.top_category=espotCtx.top_category;
					params.productCustomToken="product";
					$.ajax({
				 		url : url,
				 		type : 'POST',
				 		cache: false,
				 		data: params,
				 		success: function(data){
				 			$( "#pricestack-promo-1" ).html('');
				 			$( "#pricestack-promo-1" ).html(data);
				 			lazy_image_loader(false);
				 		},
				 		error: function(){
				 			console.log("Error invoking E Spot");
				 		}
				 	}); 



														}
				}catch(err){}
		}
		},
		invokeOrderTypeEspot:function(catEntryId){


						if(typeof(productDisplayJS.itemPriceJsonOject) == "undefined"){
				return;
			}
			if(catEntryId.indexOf("entitledItem_") != -1){
				var catalogEntryId = productDisplayJS.getCatalogEntryId(catEntryId);
				catEntryId =catalogEntryId;
			}
			if(typeof productDisplayJS.itemPriceJsonOject[catEntryId] != 'undefined' && typeof productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry != 'undefined'){
			var espotCtx=productDisplayJS.getEspotContextDetails();
			var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
			var param1 =productDisplayJS.getOrderTypeSelected();
			var param2 = catEntry.offerPrice;
			var emsName = "PDP_Promo2";
			try{
				if(null !=param2 && "" != param2){
					var url =getAbsoluteURL() + 'PetcoRefreshEspot';
					var params = {};
					params.substitutionName10='OrderType';
					params.substitutionValue10=param1;
					params.substitutionName12='itemPrice';
					params.substitutionValue12=param2;
					params.emsName=emsName;
					params.catalogEntryId=espotCtx.productId;
					params.custom_view="true";
					params.customTarget='PDP';
					params.categoryId=espotCtx.categoryId;
					params.parent_category_rn=espotCtx.parent_category_rn;
					params.top_category=espotCtx.top_category;
					params.productCustomToken="product";
					$.ajax({
				 		url : url,
				 		type : 'POST',
				 		cache: false,
				 		data: params,
				 		success: function(data){
				 			$( "#pdp-promo-2" ).html('');
				 			$( "#pdp-promo-2" ).html(data);
				 			lazy_image_loader(false);
				 		},
				 		error: function(){
				 			console.log("Error invoking E Spot");
				 		}
				 	}); 
				}
				}catch(err){}
			}
		},
		getOrderTypeSelected:function(){
			var orderType='';
			if($("input[name='rdOptionsSelect']").length>0){
				var selectedOrderType = $("input[name='rdOptionsSelect']:checked").val();

								if(selectedOrderType =='one-time-delivery'){
					orderType='OTD';
				}else if (selectedOrderType =='repeat-delivery'){
					orderType='RD';
				}else if(selectedOrderType =='store-pickup'){
					orderType='BOPUS';
				}			

							}
			return orderType;
	},
	getEspotContextDetails:function(){
		var espotContext = {};
		espotContext.productId=$('#es_productId').val();
		espotContext.categoryId=$('#es_categoryId').val();
		espotContext.parent_category_rn=$('#es_parent_category_rn').val();
		espotContext.top_category=$('#es_top_category').val();
		return espotContext;
	},
	makeRadioChecked:function(obj){
		if(obj.length > 0){
			obj[0].checked = true;
		}
	},
	displayZipcodeForSameday : function(){
		var zipcode = productDisplayJS.getZipCodeForSameday(); 

				if(document.getElementById('sameday-zipcode')!= null &&  document.getElementById('sameday-zipcode') != undefined){
			document.getElementById('sameday-zipcode').innerHTML = zipcode; 
	}
	},
	getZipCodeForSameday:function(){  

				var zipcode = localStorage.getItem('obo_sameday_zipcode'); 

				if(zipcode == 'null' || zipcode == null || zipcode == undefined){ 
			if($("#SDdefaultZip").length > 0){
				zipcode = $("#SDdefaultZip").val(); 
			} 
			localStorage.setItem('obo_sameday_zipcode',zipcode)
		}

				return zipcode;
	},
	displayZipcodeModal:function(catEntryId, productId){
		var catEntry = productDisplayJS.itemPriceJsonOject[catEntryId].catalogEntry;
		var isSamedayProduct = catEntry.isSamedayProduct;
		console.log("isSamedayEnabled  ",$("#isSamedayEnabled").length);
		console.log("isSamedayProduct  ",isSamedayProduct);
		if($("#isSamedayEnabled").length > 0){
			samedyEnabled = $("#isSamedayEnabled").val(); 
		}
		if(isSamedayProduct== 'yes' && samedyEnabled == 'true' ){
			petcoNearestLocations.fnOpenZipCodefoModal();
		}

			}

	}
require(["dojo/on", "dojo/has", "dojo/_base/sniff", "dojo/domReady!"], function(on, has) {
	if (has("ie") < 9) {
		on(document, ".compare_target > input[type=\"checkbox\"]:click", function(event) {
			this.blur();
			this.focus();
		});
	}
	if(typeof(petcoCommonJS) != 'undefined' ){petcoCommonJS.clearPromotionLessThanZero()}
	productDisplayJS.sortItemsInCombo();
});


var zoomImg ={
    height: 1500,
    width: 1500
}

function setZoomImgDimensions(zoomImgSrc, img) {
	var image = new Image();
	image.onload = function() {
  	zoomImg.height = image.height;
  	zoomImg.width = image.width;
	};
	image.src = zoomImgSrc;
}

function zoomThisData(element) {
	if (element && element.getAttribute) {
		var baseImageUrl = element.getAttribute("data-baseImageUrl");
		var altImageId = element.getAttribute("data-altImageId");
		zoomThis(baseImageUrl, altImageId);
		$('.thumb-active').removeClass('thumb-active');
		$(element).parent().addClass('thumb-active');
	}
}
function zoomThis(id,altImage) {
	if(id == null ){
		return;
	}
	var imagePresetSrc = id;
	id= id+"?$ProductDetail-large$";
	if (isCloudniaryEnabled()) {
		id = imagePresetSrc.replace('f_auto,q_auto', 'f_auto,q_auto,t_ProductDetail-large');
	} 
	if(altImage!='1' && altImage!='' && altImage != null){
		id=id+"&$"+altImage+"$";
	}
	var img = document.getElementById("imgZoom");
	var element = document.getElementById("overlay");
	img.src = id;
	zoomImgSrc = img.src.replace("large","xlarge")
	element.style.backgroundImage = 'url("' + zoomImgSrc + '")';
	setZoomImgDimensions(zoomImgSrc, img);
}

function getPageTopLeft(el) {
    var rect = el.getBoundingClientRect();
    var docEl = document.documentElement;
    return {
        left: rect.left + (window.pageXOffset || docEl.scrollLeft || 0),
        top: rect.top + (window.pageYOffset || docEl.scrollTop || 0)
    };
}

function zoomIn(event) {
  var element = document.getElementById("overlay");
  element.classList.add("active");
  var img = document.getElementById("imgZoom");
  var zoomImgWidth = zoomImg.width;
  var zoomImgHeight = zoomImg.height;

  var posY, posX;

  if (event.type === "touchmove"){
	  event.preventDefault();
	  posX = event.originalEvent.touches ?  event.originalEvent.touches[0].pageX : event.pageX - img.offsetLeft;
	  posY = event.originalEvent.touches ?  event.originalEvent.touches[0].pageY - getPageTopLeft(img).top : event.pageY - img-offsetTop;
  } else {
	  posX = event.offsetX ? (event.offsetX) : event.pageX - img.offsetLeft;
	  posY = event.offsetY ? (event.offsetY) : event.pageY - img.offsetTop;
  }

  var zoomXRatio = zoomImgWidth / img.clientWidth;
  var zoomYRatio = zoomImgHeight / img.clientHeight;
  var zoomXOffset = -posX * zoomXRatio + element.offsetWidth / 2;
  var zoomYOffset = -posY * zoomYRatio + element.offsetHeight / 2;
  element.style.backgroundPosition=zoomXOffset.toString()+"px "+zoomYOffset.toString()+"px";
  element.style.backgroundSize =  zoomImg.width+"px "+ zoomImg.height+"px";
}

$(function(){
	$('#imgZoom').on('mousemove touchmove', function(e){
		zoomIn(e);
	});

	$('#imgZoom').on('mouseout touchend', function(e){
		zoomOut(e);
	});
});
globalSliderRef = null;
function initializeMobileImageSlider() {
	if (!window.matchMedia( "(max-width: 768px)" ).matches)
		return;

		$.getScript(WCParamJS.jsAssetsDir+"/javascript/Widgets/Content/tiny-slider.js", function() {
		globalSliderRef = tns({
			container: document.querySelector("#mobile-slider"),
			items: 1,
			controls: false,
			mousedrag: true,
			nav: true,
			speed: 100,
			loop: false,
			slideBy: "page"
		});
		});


	}
function destroyMobileImageSlider() {
	var cleanup = false;
	if (globalSliderRef) {
		globalSliderRef.destroy();
		globalSliderRef = null;
		cleanup = true;
	}
	if (window.sliderC !== undefined) {
		if (sliderC) {
			sliderC.destroy();
			sliderC = null;
			cleanup = true;
		}
	}
	if (cleanup) {
		$("#mobile-slider").children().attr("style", "").attr("id", "");
	}
}

function zoomOut() {
  var element = document.getElementById("overlay");
  element.classList.remove("active");
}

function hidePersistentAddToCart() {
	var persistentCTA = document.getElementById("pdp-persistent-container");
	var placeholder = document.getElementById("pdp-persistent-placeholder");
	if (persistentCTA && persistentCTA.classList.contains("fixed")) {
		persistentCTA.classList.remove("fixed");
		$('#pdp-promo-1').show();
		$('#notice-box').show();
		if (placeholder) {
			placeholder.style.height = "auto";
		}
		if (window.matchMedia("(max-width: 768px)").matches) {
			removeFooterAddToCart();
		}
	}
}

function removeFooterAddToCart() {
	var persistentCTA = document.getElementById("pdp-persistent-container");
	if (persistentCTA) {
		persistentCTA.classList.remove("fixed-to-footer");
		persistentCTA.style.top = "auto";
	}
}

var initialBottomPositionPixelMobile = 0;



function persistentPDP() {
	 var header = document.getElementById("header");
	 var bottomPromo = document.getElementsByClassName(".banner-promo")[0];
	 var persistentCTA = document.getElementById("pdp-persistent-container");
	 var placeholder = document.getElementById("pdp-persistent-placeholder");
	 var widgetTop = document.getElementById(
	  "container_rfk_recommendation_scroll"
	 );
	 var spacer = document.getElementById("pdp-persistent-spacer");
	 var isMobile = window.matchMedia("(max-width: 768px)").matches;
	 var scrollTop =
	  (document.documentElement && document.documentElement.scrollTop) ||
	  document.body.scrollTop;
	 var element = document.getElementById("repeat-delivery-select-btn");
	 if (element != null && element.classList.contains('hide')) {
	  element = document.getElementById('inStoreMessaging');
	 }
	 if (element != null) {
	  var elementRect = element.getBoundingClientRect();
	  var offset = elementRect.bottom;
	  var intersectingBottom = offset - header.offsetHeight <= 0;
	 }
	    var isInStoreSku = false;
	    var isBopusSku = false;
	    var selectedPartNumber = $("#selectedPartNumber").val();
	    for (var x in productDisplayJS.entitledItems) {
	     var partNumber = productDisplayJS.entitledItems[x].PartNum;
	     if (partNumber == selectedPartNumber) {
	      var inStoreOnlyAttr = productDisplayJS.entitledItems[x].InStoreOnly;
	      if (inStoreOnlyAttr == "true") {
	       isInStoreSku = true;
	      }
	      var isBopusEligibleItemAttr =
	       productDisplayJS.entitledItems[x].isBopusEligibleItem;
	      if (isBopusEligibleItemAttr == "true") {
	       isBopusSku = true;
	      }
	      break;
	     }
	    }
	 if (!isMobile) {
	  persistentCTA.style.top = null;
	  var bottomPromoOffsetHeight = 0;
	  if (bottomPromo != null) {
	   bottomPromoOffsetHeight = bottomPromo.offsetHeight;
	  }
	  var calcscrollHeight =
	   placeholder.offsetTop +
	   placeholder.offsetHeight +
	   bottomPromoOffsetHeight -
	   header.offsetHeight;
	  if (widgetTop != undefined) {
	   calcscrollHeight += widgetTop.offsetHeight;
	  }
	  if (typeof intersectingBottom == "undefined") {
	   var intersectingBottom = !(scrollTop <= calcscrollHeight);
	  } else {
	   if ($(element).hasClass("hide")) {
	    intersectingBottom = !(scrollTop <= calcscrollHeight);
	   }
	  }
	  if (!intersectingBottom) {
	   hideAddtocartButtonOnScroll();
	   hidePersistentAddToCart();
	   if (dojo.byId("pdp-product-features_id") != null && dojo.hasClass("pdp-product-features_id", "hide")) {
	    dojo.removeClass("pdp-product-features_id", "hide");
	   }
	   if (dojo.byId("inStoreNoticeBox") != null && dojo.hasClass("inStoreNoticeBox", "hide")) {
	    if (isInStoreSku && !isBopusSku) {
	     dojo.removeClass("inStoreNoticeBox", "hide");
	     dojo.addClass("inStoreNoticeBox", "show");
	    }
	   }
	  } else if (!persistentCTA.classList.contains("fixed")) {
	   showAddtocartButtonOnScroll();
	   placeholder.style.height = 0;
	   persistentCTA.classList.add("fixed");
	   var subcategoryIdentifier = $("#subcategoryIdentifier") ?
	    $("#subcategoryIdentifier").val() :
	    "";
	   if (subcategoryIdentifier != "Live Small Animals" && subcategoryIdentifier != "Live Birds" && subcategoryIdentifier != "Live Reptiles") {
	    $("#pdp-promo-1").hide();
	   }
	   $("#notice-box").hide();
	   spacer.style.height = "0";
	   if (dojo.byId("inStoreNoticeBox") != null && dojo.hasClass("inStoreNoticeBox", "show")) {
	    dojo.removeClass("inStoreNoticeBox", "show");
	    dojo.addClass("inStoreNoticeBox", "hide");
	   }
	  }
	 } else {
	  showAddtocartButtonOnScroll();
	  var postFixedHeight = persistentCTA.offsetHeight.toString() + "px";
	  var container_rfk_recommendation_PDP_TOP = 0;
	  var pdpPromo2Height = 0;
	  if ($("#container_rfk_recommendation_PDP_TOP")) {
	   container_rfk_recommendation_PDP_TOP = $(
	    "#container_rfk_recommendation_PDP_TOP"
	   ).height();
	  }
	  if ($("#pdp-promo-2")) {
	   pdpPromo2Height = $("#pdp-promo-2").height();
	  }
	  initialBottomPositionPixelMobile =
	   $(".pdp-product-top").height() +
	   container_rfk_recommendation_PDP_TOP +
	   $("#header").height() +
	   100 -
	   pdpPromo2Height;
	  if (!intersectingBottom) {
	   hideAddtocartButtonOnScroll();
	   hidePersistentAddToCart();
	   if (
	    dojo.byId("pdp-product-features_id") != null &&
	    dojo.hasClass("pdp-product-features_id", "hide")
	   ) {
	    dojo.removeClass("pdp-product-features_id", "hide");
	   }
	  } else if (!persistentCTA.classList.contains("fixed")) {
	   var preFixedHeight = persistentCTA.offsetHeight.toString() + "px";
	   placeholder.style.minHeight = preFixedHeight;
	   showAddtocartButtonOnScroll();
	   persistentCTA.classList.add("fixed");
	   $("#pdp-promo-1").hide();
	   $("#notice-box").hide();
	   spacer.style.height = postFixedHeight;
	  } else if (persistentCTA.classList.contains("fixed")) {
	   var footer = document.getElementById("footer");
	   showAddtocartButtonOnScroll();
	   if (scrollTop + $(window).height() >= footer.offsetTop) {
	    persistentCTA.classList.add("fixed-to-footer");
	    dojo.place("pdp-persistent-placeholder", "pdp-persistent-spacer");
	    spacer.style.height = postFixedHeight;
	   } else {
	    dojo.place("pdp-persistent-placeholder", "pdp-product-info", "after");
	    removeFooterAddToCart();
	   }
	  }
	 }
	 if(!isInStoreSku){
		 if($("#one-time-delivery:checked").length == 0){
				$("#one-time-delivery")[0].checked = true;
				$("#one-time-delivery").trigger("click");
			}
	 }

	 	 if ($("div.store-pickup.show.selected#bopusSelectEnabledBopusAttrOn input#store-pickup:checked").length > 0 && $("div.store-pickup.show.selected#bopusSelectEnabledBopusAttrOn button#add2CartBtn_bopus:disabled").length > 0) {
		  if ($("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn").length > 0) {
		   $("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn")[0].disabled = true;
		  }
	 }
	 else if($("div#repeat-delivery-select-btn.instore-deliveryBox").length >0 && $("#BOPUSDeliveryDetailsConatiner.hide").length >0){
		 if ($("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn").length > 0) {
			   $("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn")[0].disabled = true;
		}
	 }
	 else {
		  if ($("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn").length > 0) {
		   $("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn")[0].disabled = false;
		  }
	 }
	}


function showAddtocartButtonOnScroll(){
	var  restricted = document.getElementById("restrictionMessage").innerHTML;
	var InStoreDisplay= document.getElementById("inStoreMessaging").style.display;	
	 if(restricted != "" && InStoreDisplay =='none') {	   
		 $("#restrictionMessage").addClass("hide");
			$("#restrictionMessage").removeClass("show");		
   }
	if(document.getElementById("BOPUS_hide_addtocartButton") != null && restricted == "" ){
		document.getElementById("BOPUS_hide_addtocartButton").classList.add("show");
		document.getElementById("BOPUS_hide_addtocartButton").classList.remove("hide");
	}
	if(document.getElementById("BuyNowLinkbtn") != null){
		document.getElementById("BuyNowLinkbtn").classList.add("show");
		document.getElementById("BuyNowLinkbtn").classList.remove("hide");
	}
	if(null != dojo.byId("inStoreMessagingforfixedscroll") && dojo.hasClass("inStoreMessagingforfixedscroll","showonscrollforinstock")){
		document.getElementById("inStoreMessagingforfixedscroll").classList.add("show");
		document.getElementById("inStoreMessagingforfixedscroll").classList.remove("hide");
	}
	var PriceMatchGuarantee = dojo.byId("PriceMatchGuarantee");
	if(PriceMatchGuarantee != null){
		dojo.addClass(PriceMatchGuarantee,"hide");
		dojo.removeClass(PriceMatchGuarantee,"show");
	}
	var pricestackpromo2 = dojo.byId("pricestack-promo-2");
	if(pricestackpromo2 != null){
		dojo.addClass(pricestackpromo2,"hide");
		dojo.removeClass(pricestackpromo2,"show");
	}
	var pricestackpromo1 = dojo.byId("pricestack-promo-1");
	if(pricestackpromo1 != null){
		dojo.addClass(pricestackpromo1,"hide");
		dojo.removeClass(pricestackpromo1,"show");
	}
}
function hideAddtocartButtonOnScroll(){
	if(document.getElementById("BOPUS_hide_addtocartButton") != null){
		document.getElementById("BOPUS_hide_addtocartButton").classList.remove("show");
		document.getElementById("BOPUS_hide_addtocartButton").classList.add("hide");
	}
	if(document.getElementById("BuyNowLinkbtn") != null){
		document.getElementById("BuyNowLinkbtn").classList.remove("show");
		document.getElementById("BuyNowLinkbtn").classList.add("hide");
	}
	if(null != dojo.byId("inStoreMessagingforfixedscroll") && dojo.hasClass("inStoreMessagingforfixedscroll","showonscrollforinstock")){
		document.getElementById("inStoreMessagingforfixedscroll").classList.add("hide");
		document.getElementById("inStoreMessagingforfixedscroll").classList.remove("show");
	}

	var InStoreDisplay= document.getElementById("inStoreMessaging").style.display;
	var PriceMatchGuarantee = dojo.byId("PriceMatchGuarantee");
	if(PriceMatchGuarantee != null && InStoreDisplay != null && InStoreDisplay =='none'){
		dojo.removeClass(PriceMatchGuarantee,"hide");
		dojo.addClass(PriceMatchGuarantee,"show");
	}
	var pricestackpromo2 = dojo.byId("pricestack-promo-2");
	if(pricestackpromo2 != null && InStoreDisplay != null && InStoreDisplay =='none'){
		dojo.removeClass(pricestackpromo2,"hide");
		dojo.addClass(pricestackpromo2,"show");
	}
	var pricestackpromo1 = dojo.byId("pricestack-promo-1");
	if(pricestackpromo1 != null && InStoreDisplay != null && InStoreDisplay =='none'){
		dojo.removeClass(pricestackpromo1,"hide");
		dojo.addClass(pricestackpromo1,"show");
	}
	var  restricted = document.getElementById("restrictionMessage").innerHTML;	
	   if(restricted != "" && InStoreDisplay =='none') {		   
		   $("#restrictionMessage").addClass("show");
			$("#restrictionMessage").removeClass("hide");		
	   }
}
function setUpPersistentReplacementPDP(){
	if (document.querySelectorAll(".pdp-fixed-product-info")[0] && document.querySelectorAll(".pdp-product-features")[0]){
		var persistentCTA = document.getElementById("pdp-persistent-container");
		var stickyOffset = $(persistentCTA).offset().top;
		window.onscroll = function(){
			if (!$('#personilaztionDisplay').hasClass('active') && !$('#rxPersonilaztionDisplay').hasClass('active') ) {
				persistentReplacementPDP(stickyOffset);
			}
		}
	}
}

function persistentReplacementPDP(stickyOffset){
	var header = document.querySelectorAll("#header")[0];
	var bottomPromo = document.querySelectorAll(".banner-promo")[0];
	var attrSection = document.getElementById("attrGroupDesc");
	var persistentCTA = document.getElementById("pdp-persistent-container");
	var placeholder = document.getElementById("pdp-persistent-placeholder");
	var spacer = document.getElementById("pdp-persistent-spacer");
	var isMobile = window.matchMedia("(max-width: 768px)").matches;
	if($("#pdp-persistent-spacer").length ==0){
		var newFooterSpacer = document.createElement("div");
		newFooterSpacer.setAttribute("id", "pdp-persistent-spacer");
		var footer = document.querySelectorAll('.footer_wrapper_position')[0];
		$(footer).prepend(newFooterSpacer);
		$("#pdp-persistent-spacer").height("120px");
	}

	var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;


		if (!isMobile) {
	  var sticky = $(persistentCTA),
		      scroll = $(window).scrollTop();

		  if (scroll >= stickyOffset) sticky.addClass('fixed');
		  else sticky.removeClass('fixed');

	} else {
		var sticky = $(persistentCTA),
	      scroll = $(window).scrollTop();
		var scrollHeightMobile = $(window).height()+$(window).scrollTop();
		var scroolOfPageMobile = $("#pdp-persistent-spacer").offset().top;

				var heightOfConatner = $("#pdp-persistent-wrapper").height();
		if(scroolOfPageMobile < (scrollHeightMobile+heightOfConatner)){
			sticky.addClass('fixed-to-footer');
			$("#pdp-persistent-spacer").append($("#pdp-persistent-placeholder"));
			$("#pdp-persistent-spacer #pdp-product-attributes_id").removeClass("show");
			$("#pdp-persistent-spacer #pdp-product-attributes_id").addClass("hide");
		}else{
			$("#pdp-persistent-spacer #pdp-product-attributes_id").removeClass("hide");
			$("#pdp-persistent-spacer #pdp-product-attributes_id").addClass("show");
			$("#pdp-persistent-container").prepend($("#pdp-persistent-wrapper"));

						sticky.removeClass('fixed-to-footer');
			var heightOfStickyHeader = stickyOffset+$(window).height();
			if($("#header").length > 0){
				heightOfStickyHeader = heightOfStickyHeader - $("#header").height();
			}
		  if (scroll >= heightOfStickyHeader){
			  sticky.addClass('fixed');
			  var preFixedHeight = (persistentCTA.offsetHeight).toString() + "px";
				placeholder.style.height = preFixedHeight; 
		  }
		  else {
			  sticky.removeClass('fixed');
			  $("#pdp-persistent-placeholder").insertAfter($(".product-ingredients-title"));  
			  $("#pdp-persistent-placeholder").css('height', 'auto');
		  }
		}


				}


		if($("div.store-pickup.show.selected#bopusSelectEnabledBopusAttrOn input#store-pickup:checked").length > 0 && $("div.store-pickup.show.selected#bopusSelectEnabledBopusAttrOn button#add2CartBtn_bopus:disabled").length > 0){
		if($("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn").length > 0 ){
			$("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn")[0].disabled = true;
		}
	}else{
	if($("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn").length > 0 ){
			$("div#add-to-controls div#BOPUS_hide_addtocartButton.show button#add2CartBtn")[0].disabled = false;
		}
	}
}

function setUpPersistentPDP(){

		if(!$('input[name="rdOptionsSelect"]:checked').parent().parent().parent().hasClass("selected")){
		$('input[name="rdOptionsSelect"]:checked').parent().parent().parent().addClass("selected");
	}

		if (document.querySelectorAll(".pdp-fixed-product-info")[0] && document.querySelectorAll(".pdp-product-features")[0]){
		var pdpFixedInfo = document.querySelectorAll(".pdp-fixed-product-info")[0],
			pdpAtts = document.querySelectorAll(".pdp-product-attributes")[0],
			pdpFeats = document.querySelectorAll(".pdp-product-features")[0],
			newWrapper = document.createElement("div"),
			newContainer = document.createElement("div"),
			newPlaceholder = document.createElement("div"),
			newFooterSpacer = document.createElement("div"),
			featuresHeight = pdpFeats.offsetHeight,
			featuresTop = pdpFeats.offsetTop;

		if ($('#pdp-persistent-placeholder')) {
			$('#pdp-persistent-placeholder').remove();
		}
		if ($('#pdp-persistent-spacer')) {
			$('#pdp-persistent-spacer').remove();
		}
		newFooterSpacer.setAttribute("id", "pdp-persistent-spacer");
		newPlaceholder.setAttribute("id", "pdp-persistent-placeholder");
		newContainer.setAttribute("id", "pdp-persistent-container");
		newContainer.setAttribute("class", "col-12");
		newWrapper.setAttribute("id", "pdp-persistent-wrapper");
		newWrapper.appendChild(pdpFixedInfo);
		newWrapper.appendChild(pdpAtts);
		newWrapper.appendChild(pdpFeats);
		newContainer.appendChild(newWrapper);
		newPlaceholder.appendChild(newContainer);
		$(newPlaceholder).insertAfter('#pdp-product-info');
		var footer = document.querySelectorAll('.footer_wrapper_position')[0];
		$(footer).prepend(newFooterSpacer);

		initialBottomPositionPixelMobile = 0;
		persistentPDP();
		window.onscroll = function(){
			if (!$('#personilaztionDisplay').hasClass('active') && !$('#rxPersonilaztionDisplay').hasClass('active') ) {
				persistentPDP();
			}
		}
	}
}
function moveMobilePDPTitle(){
	if (document.querySelectorAll(".pdp-product-info-mobile")[0]){
		var parent = document.querySelectorAll(".pdp-product-top")[0],
			mobileInfo = document.querySelectorAll(".pdp-product-info-mobile")[0];
		$(parent).prepend(mobileInfo);
	}
}


	$(document).ready(function(){
		if($('div[data-pageType="product-detail-page"]').length > 0){
			$(document).on({
			    mouseenter: function(){
					var left = $(this).offset().left + ($(this).outerWidth()/2),
					top = $(this).offset().top - ($(this).outerHeight()),
					dataModal = $(this).data('target');

					$(dataModal).show().find('.modal-dialog').css({
					top: top,
					left: left
					});

					$(dataModal).removeClass('modal').removeClass('fade');

			    },
			    mouseleave: function(){
			    	var dataModal = $(this).data('target');
					$(dataModal).hide();
					$(dataModal).addClass('modal').addClass('fade');
			    }
			}, '.rd-help');

					}


	})


function loadreCAPTCHAJSfile() {
	var captchURL = "https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit";
	if (!isScriptAlreadyIncluded(captchURL)) {
		var fileref=document.createElement('script');
	    fileref.setAttribute("type","text/javascript");
	    fileref.setAttribute("src", captchURL);
	    if (typeof fileref!="undefined") {
	        document.getElementsByTagName("head")[0].appendChild(fileref);
		}
	}
}

function isScriptAlreadyIncluded(src){
    var scripts = document.getElementsByTagName("script");
    for(var i = 0; i < scripts.length; i++) 
       if(scripts[i].getAttribute('src') == src) return true;
    return false;
}

function getShippingMessage(storeId, catalogId, langId, orderId, fromPDP, catalogEntryID, bopusSKU) { 

	var params = [];
 	params["storeId"] = storeId;
 	params["catalogId"] = catalogId;
 	params["langId"] = langId;
 	params["orderId"] = orderId;
 	params["fromPDP"] = fromPDP;
 	params["catalogEntryID"] = catalogEntryID;
 	params["isBopusSku"] = bopusSKU;	
	wc.service.invoke("AjaxHolidayShippingMsg", params);

	}
function isRestricted(catentryId,zipcode,brand) { 
	var params = [];
	params["sku"] = productDisplayJS.itemPriceJsonOject[catentryId].catalogEntry.catalogEntryIdentifier.externalIdentifier.partNumber;	
 	params["zipcode"] = zipcode; 
 	params["brand"] = brand; 	

					wc.service.invoke("AjaxRestrictedAddCart", params);

	}
function  rdChange(){
	if (document.getElementById("addToRDDropDown") != undefined && document.getElementById("addToRDDropDown").value == "ADDON"){
		$('div[id^="shipsEveryDiv_"]').addClass('hide');
		$('div[id^="addOnceTextOnAddToRD"]').removeClass('hide'); 			
    } else{
    	$('div[id^="shipsEveryDiv_"]').removeClass('hide');
    	$('div[id^="addOnceTextOnAddToRD"]').addClass('hide');
    }
}

