var localStorageHelper = {
	lsSupported: false,
	supportChecked: false,
	defaultExpirationHours: 1,

	checkSupport:function(type) {
		this.supportChecked = true;
        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            if(storage.getItem(x) == null || (storage.getItem(x) != null && storage.getItem(x) != x )){
            	this.lsSupported = false;    
            }else{
                storage.removeItem(x);
                this.lsSupported = true;
            }
        } catch(e) {
        	this.lsSupported = false;
        }

            },

	get:function(key,isIos) {
    	if(isIos){
    		return this.readCookie(key);    		
    	}
        if(!this.supportChecked) {
        	this.checkSupport('localStorage');
        }

                var data;
        if(this.lsSupported) {
        	data = JSON.parse(localStorage.getItem(key));
        	if(data == null || typeof data == 'undefined') {return null;}
    		if (new Date().getTime() > data.timestamp){
    			return null;
			}
    		return data.value;
        } else {
        	data = this.readCookie(key);
        	return data;
        }
    },

	set:function(key,value,expiration,isIos) {
    	if(isIos){
    		this.createCookie(key, value, expiration);    		
    	}

    	    	if(!this.supportChecked) {
        	this.checkSupport('localStorage');
        }

        if (value === null) {
            if (this.lsSupported) { 
                localStorage.removeItem(key);
            } else { 
                this.createCookie(key, '', -1);
            }
        } else {
        	if (typeof expiration === "undefined" || expiration === null) {
        		expiration = this.defaultExpirationHours;
        	}

        		        if(this.lsSupported) {
	    	    var exp = (expiration * 60 * 60 * 1000);
	    		var record = {value: value, timestamp: new Date().getTime() + exp}
	    		localStorage.setItem(key, JSON.stringify(record));
	        } else {
	        	this.createCookie(key, value, expiration);
	        }
        }
    },

	remove:function(key) {
    	if(!this.supportChecked) {
        	this.checkSupport('localStorage');
        }

        if (this.lsSupported) {
            localStorage.removeItem(key);
        } else {
            this.createCookie(key, '', -1);
        }
    },

    clearAll:function() {
    	if(!this.supportChecked) {
        	this.checkSupport('localStorage');
        }

        if (this.lsSupported) {
        	localStorage.clear();
        }
    },



	    createCookie:function(key, value, exp) {
	    var date = new Date();
	    if(typeof(exp) == "undefined"){
	    	exp = this.defaultExpirationHours;
	    }
	    date.setTime(date.getTime() + (exp * 60 * 60 * 1000));
	    document.cookie = key + "=" + value + "; path=/";
	},


	    readCookie:function(key) {
	    var nameEQ = key + "=";
	    var ca = document.cookie.split(';');
	    for (var i = 0, max = ca.length; i < max; i++) {
	        var c = ca[i];
	        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
	        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	    }
	    return null;
	}
}